<ul class="bookings-list" *ngIf="bookingList?.length">
  <li *ngFor="let booking of bookingList">
    <div class="main-title">{{booking.bike.name}}</div>
    <div class="title">{{booking.bike.location}}</div>
    <div class="sub-title">{{booking.fromTime | date:dateFormat}}</div>
    <i class="delete-icon simple-icon icon-close" (click)="onDeleteBooking($event, booking.id)"></i>
  </li>
</ul>

<div *ngIf="!bookingList?.length" class="no-data-container">
  No bookings
</div>

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'carol-ui-no-ride-data',
  templateUrl: './no-ride-data.component.html',
  styleUrls: ['./no-ride-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoRideDataComponent {

  @Input()
  public title: string;

}


<h1>Terms and Conditions | CAROL Bike</h1>
<h3>1. Introduction</h3>
<p>1.1. This document (together with any documents referred to within it) conveys the terms and conditions (the ‘Conditions’) upon which we will sell you the Goods. You may print a copy for future reference.</p>
<p>1.2. ‘Business Day’ refers to any day other than a Saturday, Sunday, or public holiday, on which banks in London are open for business.</p>
<p>1.3. ‘Event Outside Our Control’ has the meaning given in clause 15.</p>
<p>1.4. ‘Goods’ refers to the goods listed on our website and apps (together, ‘the Site’) that we may supply, including, but not limited to, CAROL Bikes, accessories, and memberships.</p>
<p>1.5. Before confirming your order, please read through these Conditions and, in particular, our cancellations and returns policy in clause 11 and the limitation of our liability and your indemnity in clause 13.</p>
<p>1.6. By ordering any of the Goods, you agree to be legally bound by these Conditions. You will be unable to proceed with your transaction if you do not accept these terms and conditions.</p>
<h3>2. About us</h3>
<p>2.1. We, Integrated Health Partners Limited, own and operate this Site. We are a limited company registered in England and Wales under company number 06107341. Our registered office address is 2 Underwood Row, London, N1 7LQ, United Kingdom. Our VAT Number is 925250636. Our business address is 167 City Road, London, EC1V 1AW, United Kingdom.</p>
<p>2.2. Our telephone number in the US: +1 (646) 809 7241; in the UK : +44 20 3808 5137.</p>
<p>2.3. Our email address is info@carolbike.com.</p>
<h3>3. Overseas orders</h3>
<p>3.1. We may accept orders from individuals located outside the United Kingdom or the United States and ship overseas subject to the customer paying any additional shipping or postage costs.</p>
<p>3.2. We will inform you of any additional shipping or postage costs as soon as possible after you have placed the order. If you do not wish to pay these additional costs, you may cancel your order. If you wish to continue with the order, you must confirm that you will pay these additional costs within 7 days of being notified of those costs. If we do not receive this confirmation from you within this time period, we will treat you as having canceled your order.</p>
<p>3.3. Please note that when shipping products internationally, cross-border shipments are subject to opening and inspection by customs authorities.</p>
<p>3.4. If we agree to supply any Goods ordered from the Site for delivery outside the United Kingdom or the United States, those Goods may be subject to import duties and/or additional taxes or expenses incurred pursuant to foreign regulatory requirements or laws. You will be responsible for payment of any such duties and/or taxes in addition to our price, including the VAT, any other applicable sales tax, and the cost of delivery. Please note that we have no control over these charges and cannot predict their amount. Please contact your local customs office or taxation authority for further information before placing your order.</p>
<h3>4. Eligibility to purchase from the Site</h3>
<p>4.1. To be eligible to purchase the Goods and lawfully enter into and form a contract with us, you must be 18 years of age or older.</p>
<h3>5. Prices</h3>
<p>5.1. The prices of the Goods are quoted on the order page.</p>
<p>5.2. Unless otherwise specified, the prices and any other charges quoted on the order page are based on delivery of the Goods in the United Kingdom or the United States.</p>
<p>5.3. Prices quoted do not include the costs of delivery of the Goods. The costs of delivery of the Goods will be shown separately on the order page and added to the amount payable by you.</p>
<p>5.4. Unless otherwise stated, the prices quoted include the VAT in the United Kingdom. Applicable sales or local taxes in the United States will be separately identified during the checkout process.</p>
<h3>6. Payment</h3>
<p>6.1. Payment can be made by any major credit or debit card or through the consumer financing options that we may offer on our Site.</p>
<p>6.2. By placing an order, you consent to payment being charged to your debit or credit card account as provided on the order form.</p>
<p>6.3. If you pay by credit or debit card, we will take payment from your card for the Goods before we dispatch the Goods to you or make the Goods otherwise available to you.</p>
<p>6.4. We shall contact you should any problems occur with the authorization of your credit or debit card.</p>
<h3>7. Interest</h3>
<p>7.1. Unless clause 7.2 applies, you must pay us interest on any amounts that you owed and failed to pay by the due date. Interest will be charged at the rate of 3% a year above the base lending rate of the Bank of England from time to time, accruing daily from the due date until the date of payment, whether before or after judgment.</p>
<p>7.2. We will not charge interest for the period of dispute in respect to an invoice that you dispute in good faith, provided you have advised us within a reasonable time of receiving the invoice that you dispute it and that you provide your basis for disputing it.</p>
<h3>8. Order process and formation of the contract</h3>
<p>8.1. Our Site, catalog, and brochure merely illustrate our Goods and their packaging. Although we aim to accurately depict our Goods and their packaging, there may be differences between the packaging and the colors of the Goods delivered to you and those shown on our Site, catalog, or brochure. For example, your computer may not accurately display the colors of the Goods.</p>
<p>8.2. All orders are subject to acceptance and availability. If we are unable to supply you with the Goods in your order due to matters such as unavailability of stock, materials, key staff, or an Event Outside Our Control, or because we have identified a mistake in the description of the Goods or the price stated by us, we will notify you. We will not proceed with the order and will refund any sums you have paid us.</p>
<p>8.3. If the Goods are temporarily unavailable, we will notify you by email of the date they are expected to be available. You will have the option either to wait until they are available or cancel your order. If you cancel your order, we will refund any sums you have paid us.</p>
<p>8.4. Any order placed by you for the Goods constitutes an offer to purchase them from us.</p>
<p>8.5. You agree that if we contact you to acknowledge receipt of your order, such communication shall not amount to our acceptance of your offer to purchase the Goods.</p>
<p>8.6. A ‘Confirmation Notice’ refers to an email that we send to you to confirm that we have dispatched the Goods. A Confirmation Notice will represent our acceptance of the offer made in the order to which that Confirmation Notice refers.</p>
<p>8.7. A contract between you and us for the supply of the Goods (the ‘Contract’) incorporating the version of these Conditions in force at the time of your order will come into existence when we send you the Confirmation Notice relating to your order. You may print and keep a copy of the Confirmation Notice for future reference.</p>
<p>8.8. If you believe that there is a mistake in the Confirmation Notice or if you wish to make any changes, please contact us. If you request a change, we will inform you whether that is possible and will communicate any changes to the price, delivery or performance dates, or any other changes that we need to make as a result of your request. We will inquire if you wish to proceed with the change.</p>
<p>8.9. We may make:</p>
<p>8.9.1. minor technical adjustments to the Goods to improve them or to comply with relevant laws and regulatory requirements;</p>
<p>8.9.2. changes to these Conditions as a result of changes in any relevant laws and regulatory requirements;</p>
<p>8.9.3. changes to these Conditions as a result of changes in how we accept payment;</p>
<p>8.9.4. changes in the amount payable by you to the extent of any changes in the VAT included in the price or payable in relation to the price.</p>
<p>8.10. If we make any changes in accordance with clause 8.9, we will provide written notice of those changes before we supply the Goods. You can choose to cancel the Contract if the change would be significantly to your disadvantage.</p>
<p>8.11. Any variation to these Conditions that has been incorporated into the Contract, or any changes to the Contract other than those mentioned in clause 8.9, shall only be binding when agreed to in writing and signed by you and us.</p>
<h3>9. Delivery</h3>
<p>9.1. The Goods will be delivered to you at the address you provided during the order process, which may be an address other than the billing address.</p>
<p>9.2. Any dates quoted for delivery of the Goods are approximate only. If no date is specified, delivery will take place as soon as reasonably possible, but in any event within 30 days of the date of the Confirmation Notice, unless there is an Event Outside Our Control, in which case clause 15 shall apply.</p>
<p>9.3. If you have agreed to collect the Goods from our premises:</p>
<p>9.3.1. you must collect the Goods during our working hours of 9am to 5pm within 3 Business Days of the date we notify you that the Goods are ready for collection;</p>
<p>9.3.2. delivery shall occur at our premises when we hand the Goods to you.</p>
<p>9.4. Unless clause 9.5 applies, if we have agreed to deliver the Goods to a delivery address that you have provided, delivery shall occur when we place the Goods in the physical possession of you or anyone you have identified as the person authorized by you to take delivery of the Goods.</p>
<p>9.5. If you have asked us to use an independent carrier other than our normal carrier to deliver the Goods to you, delivery shall occur when we deliver the Goods to that carrier.</p>
<p>9.6. You must examine the Goods within a reasonable time after their arrival and inform us as soon as reasonably possible if they are faulty, damaged, or not as described.</p>
<p>9.7. If no one is present at your delivery address to take delivery, we will advise you of our attempted delivery. You must then contact us to make arrangements for us to deliver the Goods.</p>
<p>9.8. We may end the Contract and charge you for any extra storage costs we have incurred if:</p>
<p>9.8.1. you have arranged to collect the Goods but do not do so within 14 Business Days of the date we notify you that they are ready for collection; or</p>
<p>9.8.2. we have agreed to deliver the Goods, you have not been available to take delivery on the date we agreed to deliver them, and we have not been able to rearrange delivery within 14 Business Days of the original delivery date.</p>
<p>9.9. For Christmas deliveries, we recommend that you check the Site for the last order date. We will endeavor to dispatch all Goods that are in stock within 24 hours. However, we cannot guarantee delivery by December 24.</p>
<h3>10. Risk and title</h3>
<p>10.1. The Goods will be at your risk from the time of delivery.</p>
<p>10.2. Ownership of the Goods will only pass to you when we receive full payment of all sums due, including the VAT, any applicable sales or local taxes, and the cost of delivery.</p>
<h3>11. Canceling your Contract and returns</h3>
<p>11.1. Cancellation before Confirmation Notice</p>
<p>11.1.1. You may cancel your order for the Goods by notifying us of your decision to cancel at any time prior to us sending you a Confirmation Notice.</p>
<p>11.1.2. You may notify us of your decision to cancel by:</p>
<p>11.1.2.1. completing the cancellation form found on our website, printing it, and emailing or posting it as detailed below; or</p>
<p>11.1.2.2. providing a statement saying that you wish to cancel, including your name, address, the name or a description of the Goods, and your order reference number by:</p>
<p>11.1.2.2.1. Telephone in the US: +1 (646) 809 7241 or in the UK: +44 20 3808 5137;</p>
<p>11.1.2.2.2. Email at support@carolbike.com; or</p>
<p>11.1.2.2.3. Post at 167 City Road, London, EC1V 1AW, United Kingdom.</p>
<p>11.2. Cancellation after Confirmation Notice</p>
<p>11.2.1. The ‘Cancellation Period’ refers to the period between the date on which we send you a Confirmation Notice and the expiration of 30 calendar days (or a longer period of days advertised from time to time) after the day you receive the Goods.</p>
<p>11.2.2. Subject to clause 11.5, you may cancel the Contract at any time during the Cancellation Period by notifying us of your decision to cancel.</p>
<p>11.2.3. You may notify us of your decision to cancel by:</p>
<p>11.2.3.1. completing the cancellation form found on our website, printing it, and emailing or posting it as detailed below; or</p>
<p>11.2.3.2. sending a statement saying that you wish to cancel, quoting your name, address, the name or a description of the Goods, and your order reference number by:</p>
<p>11.2.3.2.1. Telephone in the US: +1 (646) 809 7241 or in the UK: +44 20 3808 5137;</p>
<p>11.2.3.2.2. Email at support@carolbike.com; or</p>
<p>11.2.3.2.3. Post at 167 City Road, London, EC1V 1AW, United Kingdom.</p>
<p>11.3. Return of Goods</p>
<p>11.3.1. Upon receiving notice of your cancellation, we will contact you and provide details regarding where you must return the Goods and other relevant instructions. You must then return the Goods to us without delay and, at the latest, within 14 days of notifying us of your cancellation.</p>
<p>11.3.2. You must return the Goods at your own risk (you should ensure that your chosen carrier adequately protects against damage in transit) and at your own cost unless we offered to collect the Goods at our risk and/or to pay the cost of return. If we offer to collect the Goods, we will contact you to ascertain whether you agree that we will be collecting the Goods and whether you agree to pay the costs of collection if we are charging you for the collection (and, if so, to make arrangements for the collection). If you agree to pay the costs of collection, we may deduct this from any sum we owe you.</p>
<p>11.3.3. If the value of the Goods is reduced as a result of your handling of them beyond what is necessary to determine the nature, characteristics, or functioning of the Goods, we will be entitled to claim this reduction in value from you and to deduct it from any money that you have paid us.</p>
<p>11.4. Refunds on cancellation</p>
<p>11.4.1. Provided you are entitled to cancel and have complied with your obligations under clauses 11.2 and 11.3, we will refund you the balance of the price and any standard delivery costs you paid to us after deducting:</p>
<p>11.4.1.1. any reduction in the value of the Goods in accordance with clause 11.3.3; and</p>
<p>11.4.1.2. any cost to us of collecting the Goods (if applicable).</p>
<p>11.4.2. If the Contract is for the supply of Goods only, or for Goods and services with the main purpose being the supply of Goods, we will refund you the sum in clause 11.4.1 within 14 days of the day on which we receive the Goods from you.</p>
<p>11.4.3. We will refund you the sum in clause 11.4.1 using the same method of payment used by you unless you agree to a refund by a different method of payment.</p>
<p>11.5. Exception to the right to cancel</p>
<p>11.5.1. You will not have a right to cancel in the following situations:</p>
<p>11.5.1.1. The Contract is for Goods that are bespoke or have been personalized; or</p>
<p>11.5.1.2. The Contract is for Goods and/or services the price of which is dependent on fluctuations in the financial market that cannot be controlled by us.</p>
<h3>12. Complaints</h3>
<p>12.1. If you have a comment, concern, or complaint about any Goods you have purchased from us, please contact us by telephone at US +1 (646) 809 7241 or UK +44 20 3808 5137, by email at support@carolbike.com, or by post at 167 City Road, London, EC1V 1AW, United Kingdom.</p>
<h3>13. Liability and indemnity</h3>
<p>13.1. We have a duty to supply Goods to you that conform to the Contract, including a duty to ensure that:</p>
<p>13.1.1. the Goods are as described in the Contract;</p>
<p>13.1.2. the Goods correspond to any samples we have sent you; and</p>
<p>13.1.3. the Goods are fit for any purpose you specifically told us they were required for and are not faulty.</p>
<p>13.2. We cannot exclude our liability for a failure to comply with these duties mentioned in this sub-clause. Nothing in these Conditions affects your legal rights if these duties are not complied with. You can obtain advice about your legal rights from Citizens Advice or equivalent advisors if you need to.</p>
<p>13.3. We cannot exclude or limit our responsibility to you for:</p>
<p>13.3.1. death or personal injury resulting from our negligence or the negligence of our employees;</p>
<p>13.3.2. fraud or fraudulent misrepresentation; or</p>
<p>13.3.3. a claim against us for a defective product if we do not give you the name of the person who supplied the product to us within a reasonable time of your request that we do so.</p>
<p>13.4. We are responsible for foreseeable loss or damage that you suffer as a result of a breach by us of the Contract or as a result of our failure to act with reasonable care and skill. Loss or damage is foreseeable if it is obvious that it will happen or if, at the time the Contract was made, both parties knew it might happen; for example, if you discussed it with us during the sales process. We are not responsible for unforeseeable losses.</p>
<p>13.5. You are purchasing the Goods as a consumer. If you purchase the Goods for any business purpose, including for resale, we will not be liable for any business losses, including loss of profits, loss of contracts, loss of business opportunities, loss of management time, loss of business data, or losses due to interruption of your business.</p>
<p>13.6. We will not be responsible for any delay in delivering the Goods if:</p>
<p>13.6.1. we have asked you to provide specific information that is necessary for delivering the Goods; and</p>
<p>13.6.2. you have failed to provide complete and accurate information or you have provided such information later than the date by which we asked you to supply it.</p>
<p>13.7. We will not accept liability for any damage caused by pre-existing defects in any fittings, walls, pipes, wiring, or gas installations on your premises or to any goods to which we connect or install or attach the Goods unless we have been negligent in not realizing that such damage may occur or in the way we did the work.</p>
<h3>14. Our rights of termination</h3>
<p>14.1. We reserve the right to terminate the Contract by writing to you if you fail to make any payment to us when due and you still do not make payment within 14 days of our reminder to you that payment is due.</p>
<h3>15. Events Outside Our Control</h3>
<p>15.1. Except for our obligations under this clause, we shall not be responsible for delays or failures in the delivery or performance of our obligations to you resulting from any act, event, omission, failure, or accident outside our reasonable control (‘Event Outside Our Control’).</p>
<p>15.2. We will take all reasonable steps to minimize a delay in performing our obligations to you that arises from an Event Outside Our Control.</p>
<p>15.3. We will promptly notify you of any Event Outside Our Control that prevents us from—or delays us in—performing our obligations to you, providing details of it and (where possible) the extent and likely duration of the delay.</p>
<p>15.4. Our performance will be deemed suspended for the period that the Event Outside Our Control continues.</p>
<p>15.5. You may end the Contract after we have notified you of an Event Outside Our Control, and we will then refund you any money you have paid to us under the Contract for the Goods that we have been unable to deliver to you.</p>
<h3>16. Use of personal data</h3>
<p>16.1. We envisage that we will request personal information from you whilst you use our Site. This information will only be requested and processed in accordance with our Privacy Policy, which can be found at www.carolbike.com.</p>
<p>16.2. We will act fairly in connection with the personal information requested from you; when we request information, we will alert you to our Privacy Policy; and unless the information is necessary for a reason specified in the General Data Protection Regulation (GDPR), we will only process it with your consent. Before receiving your information, we will endeavor to provide the information required by the GDPR.</p>
<h3>17. Third-party rights</h3>
<p>17.1. Except for our affiliates, directors, employees, or representatives, a person who is not a party to the Contract has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract; however, this does not affect any right or remedy of a third party that exists or is available apart from that Act.</p>
<h3>18. Other important terms</h3>
<p>18.1. We reserve the right to change the domain address of this Site as well as any services, products, product prices, product specifications, or availability at any time.</p>
<p>18.2. Every effort is made to keep information on the Site regarding stock availability up to date. However, we do not guarantee that this is the case, or that stock will always be available.</p>
<p>18.3. If any provision of these terms and conditions is held by any competent authority to be invalid or unenforceable in whole or in part, the validity of the other provisions of the Contract and the remainder of the provision in question will not be affected.</p>
<p>18.4. All Contracts are conducted in English only.</p>
<p>18.5. If we fail, at any time during the term of a Contract, to insist upon strict performance of any of your obligations under it or any of these terms and conditions, or if we fail to exercise any of the rights or remedies to which we are entitled under the Contract, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with your obligations.</p>
<p>18.6. A waiver by us of any default shall not constitute a waiver of any subsequent default.</p>
<h3>19. Governing law</h3>
<p>19.1. These Conditions and the Contract are governed by the laws of England and Wales.</p>
<h3>20. Governing jurisdiction</h3>
<p>20.1. You can bring legal proceedings with respect to the Goods in the courts of England and Wales. If you live in Scotland, you can bring legal proceedings with respect to the Goods in either the courts of Scotland or those of England and Wales. If you live in Northern Ireland, you can bring legal proceedings with respect to the Goods in either the courts of Northern Ireland or those of England and Wales.</p>

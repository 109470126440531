import {Pipe, PipeTransform} from '@angular/core';
import {PowerTypes} from '@carol-nx/data';
import {getLocale} from '../common.utils';

@Pipe({
  name: 'formatScore'
})
export class FormatScorePipe implements PipeTransform {

  transform(value: number, powerType: PowerTypes): string {
    const numberFormatOptions: Intl.NumberFormatOptions = {};

    if (powerType === PowerTypes.FITNESS_SCORE) {
      numberFormatOptions.minimumFractionDigits = 1;
    } else if (powerType === PowerTypes.FITNESS_SCORE_PER_LB || powerType === PowerTypes.PEAK_PER_LB) {
      numberFormatOptions.minimumFractionDigits = 2;
    }
    return Number(value).toLocaleString(getLocale(), numberFormatOptions)
  }

}

import {
  AdminBikeCalibrationModel,
  AdminBikeModel,
  AdminBikeSettingsModel,
  AdminDiscountLimitModel,
  AdminDiscountModel,
  AdminLicenseModel,
  AdminRideModel,
  AdminRiderModel,
  BikeAppInfoModel,
  ExtendedRideData,
  FirmwaresModel,
  MembershipUsageData,
  PaymentInvoice,
  TablePagingData,
  TabletHistoryModel
} from '@carol-nx/data';
import { MembershipStatsModel } from '../../../../data/src/lib/models/MembershipStatsModel';

export interface AdminState {
  rides?: AdminRideModel[];
  ridesPaging?: TablePagingData;
  selectedRideId?: number;
  selectedRideExtendData?: ExtendedRideData;
  bikes?: AdminBikeModel[];
  bikesForModal?: AdminBikeModel[];
  bikesPaging?: TablePagingData;
  selectedBikeId?: number;
  bikeDetail?: AdminBikeModel;
  riders?: AdminRiderModel[];
  ridersPaging?: TablePagingData;
  selectedRiderId?: number;
  riderDetail?: AdminRiderModel;
  licenses?: AdminLicenseModel[];
  selectedLicenseId?: number;
  selectedLicense?: AdminLicenseModel;
  licensesPaging?: TablePagingData;
  bikesIsLoading?: boolean;
  bikeAppInfo?: BikeAppInfoModel;
  tabletHistory?: TabletHistoryModel[];
  appVersions?: string[];
  selectedBikeFrame?: AdminBikeCalibrationModel;
  bikeSettings?: AdminBikeSettingsModel;
  licenseDiscount?: AdminDiscountModel;
  licenseDiscountLimit?: AdminDiscountLimitModel;
  paymentInvoices?: PaymentInvoice[];
  membershipStats?: MembershipStatsModel;
  firmwares?: FirmwaresModel;
  membershipUsage?: MembershipUsageData[];
  membershipUsageDataIsLoading?: boolean;
}

export const initialAdminState = {};

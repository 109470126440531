import {MatDateFormats} from '@angular/material/core/datetime/date-formats';

export type CarolDateFormats = MatDateFormats & {
  display: {
    // extended
    usDateFormat: string,
    usDateTimeFormat: string,
    commonDate: string,
    commonDateTime: string,
  },
  backendDate: string,
}

export const CAROL_DATE_FORMATS: CarolDateFormats = {
  parse: {
    dateInput: 'dd-MMM-y'
  },
  display: {
    dateInput: 'dd-MMM-y',
    monthYearLabel: 'MMM y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM y',

    usDateFormat: 'MMM dd, y',
    usDateTimeFormat: 'MMM dd, y HH:mm',
    commonDate: 'dd MMM y',
    commonDateTime: 'dd MMM y HH:mm',
  },
  backendDate: 'y-MM-dd'
};

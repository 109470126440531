import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'carol-nx-input-cleanable',
  templateUrl: './input-cleanable.component.html',
  styleUrls: ['./input-cleanable.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InputCleanableComponent }]
})
export class InputCleanableComponent implements  OnInit, ControlValueAccessor, MatFormFieldControl<any>, OnDestroy {

  private static nextId = 0;
  @Input() matAutocomplete?: MatAutocomplete;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @HostBinding('id') id = `carol-nx-input-cleanable-${InputCleanableComponent.nextId++}`;

  public parts: FormGroup;
  public stateChanges = new Subject<void>();
  public focused = false;
  // noinspection JSUnusedGlobalSymbols
  public errorState = false;
  // noinspection JSUnusedGlobalSymbols
  public controlType = 'carol-nx-input-cleanable';

  private $placeholder: string;
  private $required = false;
  private $disabled = false;

  get empty() {
    const {value: { cleanable }} = this.parts;
    return cleanable === null || cleanable === '';
  }

  @Input()
  public cleanTooltip = 'Clear field';

  @Output()
  public clearClick: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  public type?: 'number' | 'text';

  @Input()
  get placeholder(): string { return this.$placeholder; }
  set placeholder(value: string) {
    this.$placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this.$required; }
  set required(value: boolean) {
    this.$required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this.$disabled; }
  set disabled(value: boolean) {
    this.$disabled = coerceBooleanProperty(value);
    this.$disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    const {value: { cleanable }} = this.parts;
    return cleanable === null ? '' : cleanable;
  }

  // noinspection JSUnusedGlobalSymbols
  get shouldLabelFloat() { return this.focused || !this.empty; }

  set value(val: any) {
    const v = val != null ? val : '';
    this.parts.setValue({cleanable: v});
    this.onChange(v);
    this.stateChanges.next();
  }

  constructor(
    formBuilder: FormBuilder,
    private $focusMonitor: FocusMonitor,
    private $elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    this.parts = formBuilder.group({
      cleanable: ''
    });

    $focusMonitor.monitor($elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.matAutocomplete) {
      this.matAutocomplete.optionSelected.subscribe( (selectedOption: MatAutocompleteSelectedEvent) => {
        this.onChange(selectedOption.option.value);
      });
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.$focusMonitor.stopMonitoring(this.$elementRef);
  }

  // noinspection JSUnusedGlobalSymbols
  public setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  // noinspection JSUnusedGlobalSymbols
  public onContainerClick() {
  }

  public writeValue(val: any): void {
    if (val !== undefined) {
      this.value = val;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public handleInput(): void {
    this.onChange(this.parts.value.cleanable);
  }

  public canBeCleaned(value: string | any[]) {
    if (typeof value !== 'string') {
      return true;
    } else {
      return value.length > 0;
    }
  }

  public clean() {
    this.value = '';
    this.clearClick.next();
  }

  private onChange(event: any) {
  }

  private onTouched() {
  }

}

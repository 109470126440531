import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataModule } from '@carol-nx/data';
import { UtilsModule } from '@carol-nx/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'angular-highcharts';
import { ChartComponent } from './chart/chart.component';
import { CommonErrorModalComponent } from './common-error-modal/common-error-modal.component';
import { CommonSuccessModalComponent } from './common-success-modal/common-success-modal.component';
import { InputCleanableComponent } from './input-cleanable/input-cleanable.component';
import { LeaderboardListComponent } from './leaderboard-list/leaderboard-list.component';
import { LoaderDialogComponent } from './loader-dialog/loader-dialog.component';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';
import { RidesChartComponent } from './rides-chart/rides-chart.component';
import { SimpleListComponent } from './simple-list/simple-list.component';
import { SvgLogoComponent } from './svg-logo/svg-logo.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { SimpleLineChartComponent } from './simple-line-chart/simple-line-chart.component';
import { TotalRidesCardComponent } from './total-rides-card/total-rides-card.component';
import { ActivityCalendarComponent } from './activity-calendar/activity-calendar.component';
import { AvatarComponent } from './avatar/avatar.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ButtonComponent } from './button/button.component';
import { NoRideDataComponent } from './no-ride-data/no-ride-data.component';
import { InfoContentComponent } from './info-content/info-content.component';
import { PhysicalActivityComponent } from './physical-activity/physical-activity.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ShareComponent } from './share/share.component';
import { RouterModule } from '@angular/router';
import { AppDownloadIconComponent } from './app-download-icon/app-download-icon.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddFollowModalComponent } from './add-follow-modal/add-follow-modal.component';
import { DelFollowModalComponent } from './del-follow-modal/del-follow-modal.component';
import { ResizeDirective } from './directives/resize.directive';
import { HabitScoreChartComponent } from './habit-score-chart/habit-score-chart.component';
import { HabitScoreDashboardComponent } from './habit-score-dashboard/habit-score-dashboard.component';
import { HabitScorePopupComponent } from './habit-score-popup/habit-score-popup.component';
import { DefinitionsMainComponent } from './definitions/definitions-main.component';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';

const components = [
  ChartComponent,
  RidesChartComponent,
  SvgLogoComponent,
  InputCleanableComponent,
  SimpleListComponent,
  LoaderDialogComponent,
  LogoutModalComponent,
  CommonErrorModalComponent,
  CommonSuccessModalComponent,
  LeaderboardListComponent,
  SvgIconComponent,
  GaugeChartComponent,
  SimpleLineChartComponent,
  TotalRidesCardComponent,
  ActivityCalendarComponent,
  AvatarComponent,
  LoginFormComponent,
  ButtonComponent,
  NoRideDataComponent,
  InfoContentComponent,
  PhysicalActivityComponent,
  ShareComponent,
  AppDownloadIconComponent,
  AddFollowModalComponent,
  ResizeDirective,
  HabitScoreChartComponent,
  HabitScoreDashboardComponent,
  HabitScorePopupComponent,
  DefinitionsMainComponent
];

@NgModule({
  declarations: [
    ...components,
    DelFollowModalComponent,
  ],
  imports: [
    CommonModule,
    DataModule,
    UtilsModule,

    ChartModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    TranslateModule
  ],
  exports: [
    ...components,
  ],
  providers: [
    CustomDateAdapter,
    DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ]
})
export class UiModule {
}

import {createAction, props} from '@ngrx/store';
import {CountryModel, ModalTypes} from '@carol-nx/data';

export const SetCommonError = createAction(
  '[info] Set common error',
  props<{title?: string, message?: string}>()
);

export const ClearCommonMessages = createAction(
  '[info] Clear common messages',
);

export const SetCommonSuccess = createAction(
  '[info] Set common success',
  props<{title?: string, message?: string}>()
);

export const ClearCommonSuccess = createAction(
  '[info] Clear common error',
);

export const GetCountriesList = createAction(
  '[info] Get countries list',
);

export const SetCountriesList = createAction(
  '[info] Set countries list',
  props<{countries: CountryModel[]}>()
);

export const OpenModal = createAction(
  '[info] Open modal',
  props<{modalType: ModalTypes}>()
);

export const CloseModal = createAction(
  '[info] Close modal'
);

export const SetLoadingStatus = createAction(
  '[info] Set loading status',
  props<{isLoading: boolean}>()
);

export const SetTheme = createAction(
  '[info] Set theme',
  props<{theme: string}>()
);

export const ToggleNetworkStatusAlert = createAction(
  '[info] Toggle network status',
  props<{connected: boolean}>()
);

export const SetPageTitle = createAction(
  '[info] Set page title',
  props<{pageTitle: string}>()
);

export const SetTableDataLoaded = createAction(
  '[info] Set table data loaded',
  props<{tableDataLoaded: boolean}>()
);

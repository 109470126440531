import {RiderState} from './rider.state';
import {AuthState, initialAuthState} from './auth.state';
import {RidesState} from './rides.state';
import {InfoState, initialInfoState} from './info.state';
import {BookingState} from './booking.state';
import {AdminState} from './admin.state';
import {LeaderboardState} from './leaderboard.state';
import {OwnerState} from "./owner.state";

export interface AppState {
  auth: AuthState;
  rider?: RiderState;
  rides?: RidesState;
  info: InfoState;
  booking?: BookingState;
  admin?: AdminState;
  leaderboard?: LeaderboardState;
  owner?: OwnerState;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  info: initialInfoState,
};

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState, AuthSelectors} from '@carol-nx/store';
import {map, withLatestFrom} from 'rxjs/operators';
import {AuthService} from '@carol-nx/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authService: AuthService,
              private router: Router,
              private store: Store<AppState>) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(AuthSelectors.selectAuthStatus).pipe(
        withLatestFrom(this.authService.checkIfCanLogIn()),
        map(([authFromStore, authFromService]) => {

          if (authFromService === null && authFromStore === false) {
            this.router.navigate(['/auth']);
          }

          return authFromStore;
        })
      );
  }
}

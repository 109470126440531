import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GaugeChartModel } from '@carol-nx/data';
import { isValidChartData } from '@carol-nx/utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-power-info-box',
  templateUrl: './power-info-box.component.html',
  styleUrls: ['./power-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowerInfoBoxComponent implements OnInit {

  public title: string;
  public chartData: GaugeChartModel;
  public isInvalidRide: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<PowerInfoBoxComponent>
  ) {
  }

  get isValidChartData(): boolean {
    return isValidChartData(this.chartData);
  }

  ngOnInit() {
    this.chartData = this.data.chartData;
    this.isInvalidRide = this.data.isInvalidRide;
    this.title = this.chartData.title || this.data.title;
  }

  public close() {
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GaucheResizeService {
  private events: Subject<number> = new Subject();

  public getEvents() {
    return this.events;
  }

  public notifyResize(size: number) {
    this.events.next(size);
  }
}

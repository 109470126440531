import {Action, createReducer, on} from '@ngrx/store';
import {BookingActions} from '../actions';
import {BookingState, initialBookingState} from '../state/booking.state';
import reject from 'lodash/reject';

export const bookingFeatureKey = 'booking';

const bookingReducer = createReducer(
  initialBookingState,

  on(BookingActions.SetBookingsList,
    (state, {bookings}) => ({...state, bookings: bookings ? bookings : []})
  ),

  on(BookingActions.DeleteBookingFromList,
    (state, {bookingId}) => {
      const newBookings = reject(state.bookings, {id: bookingId});
      return {...state, bookings: newBookings};
    }
  ),

  on(BookingActions.SetBikesForBooking,
    (state, {bookingBikes}) => ({...state, bikes: bookingBikes})
  ),

  on(BookingActions.SetSelectedLocation,
    (state, {location}) => ({...state, selectedLocation: location})
  ),

  on(BookingActions.SetSelectedBikeId,
    (state, {bikeId}) => ({...state, selectedBikeId: bikeId})
  ),

  on(BookingActions.SetBookedTimeSlotsForBike,
    (state, {bookedSlots}) => ({...state, bookedSlots})
  ),

  on(BookingActions.SetAvailableTimeSlotsForBike,
    (state, {availableBookingSlots}) => ({...state, availableBookingSlots})
  ),
);

export function reducer(state: BookingState | undefined, action: Action) {
  return bookingReducer(state, action);
}

import { Injectable } from '@angular/core';
import { GenderFilter, PowerTypes, TopUserModel } from '@carol-nx/data';
import { LeaderboardService } from '@carol-nx/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LeaderboardActions } from '../actions';
import { LeaderboardSelectors, RiderSelectors } from '../selectors';
import { AppState } from '../state';
import { EffectsMain } from './effects-main';

@Injectable()
export class LeaderboardEffects extends EffectsMain {

  getTopUsersList$ = createEffect(() => combineLatest([
      this.actions$.pipe(ofType(LeaderboardActions.GetTopUsersList)),
      this.store.select(RiderSelectors.selectRiderId),
      this.store.select(LeaderboardSelectors.selectGenderFilter),
      this.store.select(LeaderboardSelectors.selectActiveLeaderboardTypeValue),
      this.store.select(LeaderboardSelectors.selectActivePowerTypeValue),
      this.store.select(LeaderboardSelectors.selectActiveSubPowerTypeValue)
    ]).pipe(
      filter(([action,
                riderId,
                lbFilter,
                activeSwitcherValue,
                activePowerType,
                activeSubPowerType]) => !!riderId),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderCurrentLicense)),
      debounceTime(0),//hack for excluding double request
      switchMap(([
                   [
                     action,
                     riderId,
                     lbFilter,
                     activeSwitcherValue,
                     activePowerType,
                     activeSubPowerType
                   ],
                   license
                 ]) => {
        const parentPowerType = activePowerType.powerType;
        let currentSubPowerType: PowerTypes | undefined = activeSubPowerType?.powerType;
        const powerType = currentSubPowerType ? currentSubPowerType : parentPowerType;
        const gender: number = lbFilter !== GenderFilter.ALL ? +lbFilter : undefined;
        const licenseId: number = license && license.id || undefined;

        const combineRequestArr = [];
        if (licenseId > 0 || ['global', 'friends'].includes(activeSwitcherValue.name)) {
          combineRequestArr.push(
            this.leaderboardService.getWeeklyPowerTopUsersByPowerTypeAndGender(
              activeSwitcherValue.name,
              powerType,
              licenseId,
              riderId,
              gender,
              action.page,
              action.size
            ).pipe(
              shareReplay(),
              tap(response => response && action.callBack && action.callBack(response.last)),
              map(response => response && ({
                usersList: response.content,
                total: response.totalElements,
                powerType
              }))
            )
          );
        }
        combineRequestArr.push(
          this.leaderboardService.getWeeklyUserPositionByPowerTypeAndGender(
            activeSwitcherValue.name,
            powerType,
            licenseId > 0 ? licenseId : undefined,
            riderId,
            gender,
            action.size
          ).pipe(
            shareReplay()
          )
        );

        if (combineRequestArr.length === 1) {
          return combineLatest(combineRequestArr).pipe(switchMap(([riderPosition]) => of(
            LeaderboardActions.SetTopUsersList({ powerType, usersList: [], total: 0 }),
            LeaderboardActions.SetUserTopPositionByPowerType({ powerType, riderPosition })
          )));
        } else {
          return combineLatest(combineRequestArr).pipe(
            filter(([topUsersList, riderPosition]: [any, TopUserModel]) => !!topUsersList),
            switchMap(([topUsersList, riderPosition]: [any, TopUserModel]) => {
              let actions;
              if (action.page) {
                actions = [LeaderboardActions.AddTopUsersList(topUsersList)];
              } else {
                actions = [LeaderboardActions.SetTopUsersList({ powerType, usersList: [], total: 0 }),
                  LeaderboardActions.SetTopUsersList(topUsersList)];
              }
              actions.push(LeaderboardActions.SetUserTopPositionByPowerType({ powerType, riderPosition }));
              return of(...actions);
            })
          );
        }
      })
    )
  );

  constructor(protected actions$: Actions,
              protected store: Store<AppState>,
              private leaderboardService: LeaderboardService) {
    super(actions$, store, null);
  }
}

import {BookingBikeModel, BookingModel, TimeSlotModel} from '@carol-nx/data';

export interface BookingState {
  bookings?: BookingModel[];
  bikes?: BookingBikeModel[];
  selectedLocation?: string;
  selectedBikeId?: number;
  bookedSlots?: TimeSlotModel[];
  availableBookingSlots?: TimeSlotModel[];
}

export const initialBookingState: BookingState = {
};

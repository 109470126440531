import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ThemeTypes, TicketModel } from '@carol-nx/data';
import axios from 'axios';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private http: HttpClient) {
  }

  public getCountriesList(): Observable<any> {
    return this.http.get(`/web-api/country`);
  }

  public getBikeLatestVersion(): Observable<any> {
    return this.http.get(`/bike-api/bikeapp`);
  }

  public sendTicket(ticketData: TicketModel): Promise<any> {
    const formData = new FormData();
    for (const p in ticketData) {
      if (ticketData.hasOwnProperty(p)) {
        formData.append(p, ticketData[p]);
      }
    }

    return axios.post(environment.url + '/web-api/hubspot/ticket', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  public setTheme(mode: ThemeTypes) {
    if (mode === ThemeTypes.Dark) {
      document.body.classList.add('carol-dark');
    } else {
      document.body.classList.remove('carol-dark');
    }
    localStorage.setItem('theme', HelperService.getColorMode(mode));
  }

  public getMinDobDate(): Date {
    const maxAge = 85;
    let date = new Date();
    date.setFullYear(date.getFullYear() - maxAge);
    return date;
  }

  public getMaxDobDate(): Date {
    const minAge = 10;
    let date = new Date();
    date.setFullYear(date.getFullYear() - minAge);
    return date;
  }

  public detectCountry(): Promise<string> {
    return this.http.get(`https://tools.carolbike.com/lookup/countrycode`)
      .toPromise()
      .then((data: { code: string }) => data.code);
  }

  public detectBrowserLocale(): string {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    // @ts-ignore
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1)
      shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1)
      shortLang = shortLang.split('_')[0];

    if (shortLang !== 'es' && shortLang !== 'de') {
      shortLang = 'en';
    }

    return shortLang.toUpperCase();
  }

  public getCountryStateByPostalCode(countryId: number, postCode: string): Observable<any> {
    return this.http.get(`/web-api/country/state`, { params: { countryId: countryId.toString(), postCode } });
  }

  private static getColorMode(mode: ThemeTypes): string {
    switch (mode) {
      case ThemeTypes.Dark:
        return 'dark';
      case ThemeTypes.Light:
        return 'light';
      default:
        return 'dark';
    }
  }
}

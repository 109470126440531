<div class="page-wrapper animated fadeIn">
  <mat-card class="activity-hs-wrapper mat-wrapper">
    <mat-card-title routerLink="activity" class="router-link">
      <div>{{'DASHBOARD_INFO_ACTIVITY' | translate}}</div>
    </mat-card-title>
    <mat-card-content>
      <div class="activity-cards">
        <carol-nx-total-rides-card [types]="[RideTypes.REHIT,RideTypes.RapidFatBurnRides,RideTypes.FreeCustomRides,RideTypes.FitnessTestsRides]" [validSubscription]="(validSubscription | async)"></carol-nx-total-rides-card>
        <carol-nx-activity-calendar></carol-nx-activity-calendar>
        <carol-nx-habit-score-dashboard></carol-nx-habit-score-dashboard>
      </div>
    </mat-card-content>
  </mat-card>

  <carol-nx-dashboard-chart class="first-chart" [data]="fitness | async" route="fitness"></carol-nx-dashboard-chart>
  <carol-nx-dashboard-chart [data]="burn | async" route="burn"></carol-nx-dashboard-chart>
  <carol-nx-dashboard-chart [data]="power | async" route="power"></carol-nx-dashboard-chart>
  <carol-nx-dashboard-chart [data]="energy | async" route="energy" ></carol-nx-dashboard-chart>
</div>


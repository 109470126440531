import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ChartType, GaugeChartModel } from '@carol-nx/data';
import { Observable } from 'rxjs';
import { RiderSelectors } from '@carol-nx/store';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  templateUrl: './habit-score-popup.component.html',
  styleUrls: ['./habit-score-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HabitScorePopupComponent implements OnInit {
  public chartData: GaugeChartModel;
  public habitScore: Observable<number>;

  constructor(private dialogRef: MatDialogRef<HabitScorePopupComponent>,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: number) {
  }

  ngOnInit() {
    this.chartData = { chartType: ChartType.HabitScore };
    this.habitScore = this.store.select(RiderSelectors.selectRiderHabitScore);
  }

  public close() {
    this.dialogRef.close();
  }
}

import {Injectable} from '@angular/core';
import {PaymentCard, PaymentIntent} from "@carol-nx/data";
import {from} from 'rxjs';
import {environment} from "../../../../environments/environment";

declare var Chargebee: any;

@Injectable({
  providedIn: 'root'
})
export class ChargeBeeService {

  private chargeBeeInstance: any = undefined;

  constructor() {
  }

  public initChargeBee() {
    this.chargeBeeInstance = Chargebee.init({
      site: environment.chargebeeProfile,
    });
  }

  public appendPaymentCard2(paymentIntent: PaymentIntent,
                            paymentCard: PaymentCard,
                            successFunc?: (intent) => void,
                            errorFunc?: (err) => void) {
    if (!this.chargeBeeInstance) {
      this.initChargeBee();
    }
    this.chargeBeeInstance.load3DSHandler().then(threeDSHandler => {
      // Set the created paymentIntent
      threeDSHandler.setPaymentIntent(paymentIntent);
      // // The paymentIntent can be updated whenever the amount gets changed
      // threeDSHandler.updatePaymentIntent(paymentIntent)

      threeDSHandler.handleCardPayment({
        card: paymentCard,
        additionalData: {
          // you can pass additional information to improve the chances to do a frictionless transaction
        }
      }, {
        change: function (intent) {
          console.log(intent);
        },
        success: function (intent) {
          sessionStorage.setItem('intent', intent);
          if (successFunc) {
            successFunc(intent);
          }
        },
        error: function (intent, error) {
          console.error(error);
          sessionStorage.setItem('intent', 'error');
          if (errorFunc) {
            errorFunc(error);
          }
        }
      })

    });
  }

  public load3DSHandler() {
    if (!this.chargeBeeInstance) {
      this.initChargeBee();
    }
    return from(this.chargeBeeInstance.load3DSHandler());
  }

  public appendPaymentCard(threeDSHandler, paymentIntent: PaymentIntent, paymentCard: PaymentCard, before3dsFunc: () => void) {
    if (!this.chargeBeeInstance) {
      this.initChargeBee();
    }
    // Set the created paymentIntent
    threeDSHandler.setPaymentIntent(paymentIntent);
    // // The paymentIntent can be updated whenever the amount gets changed
    // threeDSHandler.updatePaymentIntent(paymentIntent)
    let billingAddress = {
      firstName: paymentCard.firstName,
      lastName: paymentCard.lastName,
      addressLine1: paymentCard.billing?.address,
      city: paymentCard.billing?.city,
      countryCode: paymentCard.billing?.countryCode,
      zip: paymentCard.billing?.postCode
    };

    return from(threeDSHandler.handleCardPayment({
      card: paymentCard,
      additionalData: {billingAddress, customerBillingAddress: billingAddress}
    }, {
      change: (intent) => {
        if (intent?.status === 'in_progress') {
          if (before3dsFunc) before3dsFunc();
          window.postMessage({runProcessing: 'intentInProgress'}, '*');
        }
      },
      success: (intent) => {
        if (intent?.status === 'authorized') {
          window.postMessage({runProcessing: 'intentAuthorized'}, '*');
        }
      }
    }));

  }
}

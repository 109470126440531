import {Actions, createEffect, ofType} from '@ngrx/effects';
import {InfoActions} from '../actions';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ModalTypes} from '@carol-nx/data';
import {HelperService} from '@carol-nx/services';

@Injectable()
export class InfoEffects {

  getCountriesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoActions.GetCountriesList),
      mergeMap((action) => this.helperService.getCountriesList()
        .pipe(
          map(countries => InfoActions.SetCountriesList({countries})),
          catchError(err => of(InfoActions.SetCommonError({message: err.statusText})))
        ))
    )
  );

  setCommonError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoActions.SetCommonError),
      switchMap(action => {
        // this.dialogService.openCertainModal(ModalTypes.CommonErrorModal, null);
        return of(InfoActions.OpenModal({modalType: ModalTypes.CommonErrorModal}));
      })
    )
  );

  setCommonSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoActions.SetCommonSuccess),
      switchMap(action => {
        // this.dialogService.openCertainModal(ModalTypes.CommonSuccessModal);
        return of(InfoActions.OpenModal({modalType: ModalTypes.CommonSuccessModal}));
      })
    )
  );

  closeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoActions.CloseModal),
      switchMap(action => {
        // this.dialogService.closeModal();
        return of(InfoActions.ClearCommonMessages());
      })
    )
  );

  constructor(private actions$: Actions,
              private helperService: HelperService) {
  }

}

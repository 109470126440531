import { Action, createReducer, on } from '@ngrx/store';
import { LeaderboardActions } from '../actions';
import { initialLeaderboardState, LeaderboardState } from '../state/leaderboard.state'; //don't change

export const leaderboardFeatureKey = 'leaderboard';

const leaderboardReducer = createReducer(
  initialLeaderboardState,
  on(LeaderboardActions.SetUserTopPositionByPowerType,
    (state: LeaderboardState, {powerType, riderPosition}) => {
      const listsData = Object.assign(
        {}, state.topListsPosition, {[powerType]: riderPosition}
      );
      return {
        ...state,
        topListsPosition: listsData
      };
    }
  ),
  on(LeaderboardActions.SetTopUsersList,
    (state: LeaderboardState, {powerType, usersList, total}) => {
      const listsData = Object.assign(
        {}, state.topListsData, {[powerType]: usersList}
      );
      return {
        ...state,
        topListsData: listsData,
        topListsTotalElements: total
      };
    }
  ),
  on(LeaderboardActions.AddTopUsersList, (state: LeaderboardState, {powerType, usersList}) => ({
    ...state,
    topListsData: {
      ...state.topListsData,
      [powerType]: [
        ...(state?.topListsData[powerType] ? state?.topListsData[powerType] : []),
        ...usersList
      ]
    }
  })),
  on(LeaderboardActions.SetPowerTypeValues,
    (state, {powerTypeValues}) => ({...state, powerTypeValues: [...powerTypeValues]})
  ),
  on(LeaderboardActions.SetActivePowerTypeValue,
    (state, {activePowerTypeValue}) => {
      const acVal = {...activePowerTypeValue, active: true};
      return ({...state, activePowerTypeValue: acVal, activeSubPowerTypeValue: null})
    }
  ),
  on(LeaderboardActions.SetActiveSubPowerTypeValue,
    (state, {activeSubPowerTypeValue}) => ({...state, activeSubPowerTypeValue})
  ),
  on(LeaderboardActions.SetLeaderboardTypeValues,
    (state, {leaderboardTypeValues}) => ({...state, leaderboardTypeValues: [...leaderboardTypeValues]})
  ),
  on(LeaderboardActions.SetLeaderboardTypeId,
    (state, { leaderboardTypeId }) => ({
      ...state,
      topListsData: initialLeaderboardState.topListsData,
      topListsPosition: initialLeaderboardState.topListsPosition,
      topListsTotalElements: initialLeaderboardState.topListsTotalElements,
      activeLeaderboardTypeId: leaderboardTypeId
    })
  ),
  on(LeaderboardActions.SetGenderFilter,
    (state, {genderFilter}) => ({...state, genderFilter: genderFilter})
  ),
  on(LeaderboardActions.SetFollowTopUser, (state, {riderId, observedRider, newState}) => {
      const topListsData = Object.assign({}, state.topListsData);
      if (topListsData) {
        Object.keys(topListsData).forEach(key => {
          let indexObservedRider = topListsData[key].findIndex(f => f.riderId === observedRider.riderId);
          if (indexObservedRider != null) {
            topListsData[key] = Object.assign([], topListsData[key]);
            topListsData[key][indexObservedRider] = Object.assign({}, topListsData[key][indexObservedRider]);
            topListsData[key][indexObservedRider].following = newState;
          }
        });
      }

      return {
        ...state,
        topListsData
      };
    }
  )
);

export function reducer(state: LeaderboardState | undefined, action: Action) {
  return leaderboardReducer(state, action);
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AppState } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { TrainerService } from '../../../services/trainer.service';
import { DashboardPowerMainComponent } from '@carol-nx/ui';


@Component({
  selector: 'carol-nx-dashboard-power',
  templateUrl: './dashboard-power.component.html',
  styleUrls: ['./dashboard-power.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPowerComponent extends DashboardPowerMainComponent {
  constructor(
    protected store: Store<AppState>,
    protected changeDetectorRef: ChangeDetectorRef,
    trainerService: TrainerService) {
    super(store, changeDetectorRef);
    trainerService.init();
  }
}

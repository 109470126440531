export enum ChartType {
  FitnessScore = 'OCTANE_SCORE',
  FitnessScorePerLB = 'OCTANE_SCORE_PER_LB',
  FitnessScorePerKG = 'OCTANE_SCORE_PER_KG',
  CaloriesExclEPOC = 'CALORIES_EXCL_EPOC',
  CaloriesInclEPOC = 'CALORIES_INCL_EPOC',
  PeakPower = 'PEAK_POWER',
  PeakPowerPerLB = 'PEAK_POWER_PER_LB',
  PeakPowerPerKG = 'PEAK_POWER_PER_KG',
  FunctionalThresholdPower = 'FUNCTIONAL_THRESHOLD_POWER',
  MaxAerobicPower = 'MAX_AEROBIC_POWER',
  MaxAnaerobicPower = 'MAX_ANAEROBIC_POWER',
  AveragePower = 'AVERAGE_POWER',
  EnergyOutput = 'ENERGY_OUTPUT',
  RidesPerWeek = 'RIDES_PER_WEEK',
  VO2Max = 'VO2MAX',
  PeakHeartRate = 'PEAK_HEART_RATE',
  Distance = 'DISTANCE',
  AverageSpeed = 'AVERAGE_SPEED',
  AverageSprintPower = 'AVERAGE_SPRINT_POWER',
  AverageTestPower = 'AVERAGE_TEST_POWER',
  HabitScore = 'HABIT_SCORE',

  // For web only
  RidesCount = 'RIDES_COUNT',
  RidersCount = 'RIDERS_COUNT',
}

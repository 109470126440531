import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState, AuthSelectors} from '@carol-nx/store';
import {map} from 'rxjs/operators';
import {AuthService} from '@carol-nx/services';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard {

  constructor(private authService: AuthService,
              private router: Router,
              private store: Store<AppState>) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(AuthSelectors.selectAuthStatus)
      .pipe(map((data) => {

        // Redirect to dashboard if authorized
        if (data) {
          this.router.navigate(['/main']);
        }

        return !data;
      }));
  }
}

import { Injectable } from '@angular/core';
import { RideTypes } from '@carol-nx/data';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RideService {
  private rideTypeNameDict: Record<RideTypes, string> = {
    [RideTypes.REHIT]: 'SPEEDOMETER_INFO_REHIT',
    [RideTypes.IntensesRides]: 'SPEEDOMETER_INFO_INTENSE',
    [RideTypes.IntenseRides]: 'SPEEDOMETER_INFO_INTENSE_2X20',
    [RideTypes.IntermediatesRides]: 'SPEEDOMETER_INFO_INTERMEDIATE',
    [RideTypes.IntermediateRides]: 'SPEEDOMETER_INFO_INTERMEDIATE_2X15',
    [RideTypes.Intermediate3x15]: 'SPEEDOMETER_INFO_INTERMEDIATE_3X15',
    [RideTypes.EnergisersRides]: 'SPEEDOMETER_INFO_ENERGISER',
    [RideTypes.EnergiserRides]: 'SPEEDOMETER_INFO_ENERGISER_2X10',
    [RideTypes.Energiser3x10]: 'SPEEDOMETER_INFO_ENERGISER_3X10',
    [RideTypes.GibalaIntervalsRides]: 'SPEEDOMETER_INFO_GIBALA_INTERVALS',
    [RideTypes.CoyleIntervalsRides]: 'SPEEDOMETER_INFO_COYLE_INTERVALS',
    [RideTypes.DrAdamsonIntervals]: 'SPEEDOMETER_INFO_DR_ADAMSON_INTERVALS',
    [RideTypes.RapidFatBurnRides]: 'SPEEDOMETER_INFO_FAT_BURN',
    [RideTypes.RapidFatBurn30Rides]: 'SPEEDOMETER_INFO_FAT_BURN_30',
    [RideTypes.RapidFatBurn45Rides]: 'SPEEDOMETER_INFO_FAT_BURN_45',
    [RideTypes.RapidFatBurn60Rides]: 'SPEEDOMETER_INFO_FAT_BURN_60',
    [RideTypes.FreeRides]: 'SPEEDOMETER_INFO_FREE',
    [RideTypes.EnduranceRides]: 'SPEEDOMETER_INFO_ENDURANCE',
    [RideTypes.FtpTest20Rides]: 'SPEEDOMETER_INFO_FTP_TEST_20',
    [RideTypes.FtpTest2x8Rides]: 'SPEEDOMETER_INFO_FTP_TEST_2_X_8',
    [RideTypes.RampUpRides]: 'SPEEDOMETER_INFO_RAMP_UP',
    [RideTypes.FreeCustomRides]: 'SPEEDOMETER_INFO_FREE',
    [RideTypes.FreeRideWithLaps]: 'SPEEDOMETER_INFO_FREE',
    [RideTypes.FitnessTestsRides]: 'SPEEDOMETER_INFO_ENDURANCE',
    [RideTypes.EkblomBakRides]: 'SPEEDOMETER_INFO_EKBLOM_BAK',
    [RideTypes.NorwegianZoneIntervals]: 'SPEEDOMETER_INFO_NORWEGIAN_ZONE_INTERVALS'
  };

  constructor(private translate: TranslateService) {
  }

  public getRideTypeName(rideType: RideTypes): Observable<string> {
    return rideType && this.translate.get(this.rideTypeNameDict[rideType]) || of('');
  }
}

<div *ngIf='isValidChartData && chartData.starCount'>
  <h2 [innerHTML]="'SPEEDOMETER_INFO_DIALOG_TITLE' | translate:({ rating: getStarDisplayText() | async })"></h2>
</div>

<div *ngIf="chartInfo.message && chartData.validSubscription">
  <p [innerHTML]="chartInfo.message | async"></p>
</div>

<div *ngIf="chartInfo.performance && chartData.validSubscription">
  <p [innerHTML]="chartInfo.performance | async"></p>
</div>

<div *ngIf='chartInfo.description'>
  <p [innerHTML]='chartInfo.description | async'></p>
</div>

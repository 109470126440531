import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {PhysicallyActive} from '@carol-nx/data';

@Component({
  selector: 'carol-ui-physical-activity-switcher',
  templateUrl: './physical-activity.component.html',
  styleUrls: ['./physical-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhysicalActivityComponent {
  @Input()
  @HostBinding('class.mobile')
  public mobile: boolean = false;

  @Output()
  public changed: EventEmitter<PhysicallyActive> = new EventEmitter();

  public physicallyActiveToggleButtons = [
    { type: PhysicallyActive.Rarely, title: 'Rarely' },
    { type: PhysicallyActive.Moderately, title: 'Moderately' },
    { type: PhysicallyActive.Very, title: 'Very' }
  ]

  public valueChanged(event) {
    this.changed.emit(event.value);
  }
}

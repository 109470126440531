import {
  AdminLicenseModel,
  CountryModel,
  MonthlyStatsModel,
  PaymentIntent,
  Price,
  StatsModel,
  Subscription
} from "@carol-nx/data";

export interface OwnerState {
  price?: Price;
  subscription?: Subscription; // Actual (or last) subscription
  paymentIntent?: PaymentIntent;
  bikeCountry?: CountryModel;
  stats?: StatsModel;
  licenseDatesWithRides?: MonthlyStatsModel[];
  licenseDetails?: AdminLicenseModel;
}

export const initialOwnerState: OwnerState = {};

import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SvgService} from "@carol-nx/services";

@Component({
  selector: 'carol-nx-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  @Output()
  svgReady: EventEmitter<any> = new EventEmitter();
  @Input()
  src: string;
  @Input()
  native: boolean;
  @Input()
  icon: string;
  @Input()
  colors: string[];
  @Input()
  color: string;

  constructor(private elem: ElementRef,
              private svgService: SvgService) {
  }

  ngOnInit() {
    if (this.native) {
      this.svgService.loadSvg(this.getSrc()).then((svg: string) => {
        const svgNativePlace = this.elem.nativeElement.querySelector('.native_svg_place');
        svgNativePlace.innerHTML = svg;
        this.svgReady.emit(svgNativePlace);
      }).catch(console.error);
    }
  }

  getSrc() {
    return this.icon ? `assets/svg/icon-${this.icon}.svg` : this.src;
  }

}

<ng-container *ngIf="(ridesData | async) as rides">
    <carol-nx-simple-line-chart
      *ngFor="let chart of rides.rides"
      [showVsFill]="true"
      [chartData]="chart"
      [barHeightUnit]="'0.3em'"
      [resizeInitAfter]="resizeInitAfter"
      [total]="rides.total">
    </carol-nx-simple-line-chart>
</ng-container>

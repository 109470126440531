import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingModel} from '@carol-nx/data';

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsListComponent implements OnInit {
  @Output()
  public deleteBooking: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public bookingList: BookingModel[];
  @Input()
  public dateFormat: string;

  constructor() {
  }

  ngOnInit() {
  }

  public onDeleteBooking(event, bookingId: number) {
    event.preventDefault();
    this.deleteBooking.emit(bookingId);
  }

}

import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartType, NeedRideTypes } from '@carol-nx/data';
import { AppState } from '@carol-nx/store';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DashboardCardsMainComponent } from '@carol-nx/ui';
import { TrainerService } from '../../services/trainer.service';

@Component({
  selector: 'carol-nx-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardsComponent extends DashboardCardsMainComponent implements OnInit {
  @HostBinding('style.--repeatCount') repeatCount: number;
  public neededRideTypes: NeedRideTypes[];
  public chartType: ChartType;

  constructor(protected store: Store<AppState>,
              private route: ActivatedRoute,
              private trainerService: TrainerService) {
    super(store);
    this.trainerService.init();
  }

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.neededRideTypes = data.neededRideTypes;
      this.chartType = data.chartType;
      this.repeatCount = data.repeatCount;
      super.init();
    });
  }
}

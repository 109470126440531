import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@carol-nx/store';
import { TrainerService } from '../../../services/trainer.service';
import { RiderService } from '../../../services/rider.service';
import { DashboardFitnessMainComponent } from '@carol-nx/ui';

@Component({
  selector: 'carol-nx-dashboard-fitness',
  templateUrl: './dashboard-fitness.component.html',
  styleUrls: ['./dashboard-fitness.component.scss']
})
export class DashboardFitnessComponent extends DashboardFitnessMainComponent implements OnInit {
  constructor(protected store: Store<AppState>, trainerService: TrainerService, private riderService: RiderService) {
    super(store);
    trainerService.init();
  }

  ngOnInit(): void {
    super.dispatch();
    super.init();
    this.riderService.selectRiderId();
  }
}

import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {debounceTime, switchMap,} from 'rxjs/operators';
import {BehaviorSubject, of} from 'rxjs';
import {AdminRiderModel} from '@carol-nx/data';
import {AdminService, AuthService, OwnerService} from '@carol-nx/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-search-rider-modal',
  templateUrl: './search-rider-modal.component.html',
  styleUrls: ['./search-rider-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRiderModalComponent implements OnInit {
  public riderSearch: FormControl;
  public isLoading: boolean;
  public selectedUser: AdminRiderModel;
  public searchingUsers: BehaviorSubject<AdminRiderModel[]> = new BehaviorSubject<AdminRiderModel[]>([]);

  constructor(private formBuilder: FormBuilder,
              private adminService: AdminService,
              private ownerService: OwnerService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<SearchRiderModalComponent>) {
  }

  ngOnInit() {
    this.riderSearch = new FormControl('', [Validators.required]);
    this.riderSearch.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(360),
        switchMap((query: string) => {
            if (!this.data.api || this.data.api.toLowerCase() === "admin") {
              this.adminService.getRiders({fullTextSearch: query})
                .subscribe((resp: any) => {
                  if (resp && resp.content.length) {
                    this.searchingUsers.next(resp.content);
                  }
                });
            } else if (this.data.api.toLowerCase() === "owner") {
              this.authService.checkIfCanLogIn().pipe(untilDestroyed(this)).subscribe(ownerId => {
                this.ownerService.getOwnedRidersById(ownerId, {fullTextSearch: query})
                  .subscribe((resp: any) => {
                    if (resp && resp.content.length) {
                      this.searchingUsers.next(resp.content);
                    }
                  });
              });
            }
            return of(query);
          }
        )
      ).subscribe();
  }

  public riderSelected() {
    const userExist = this.selectedUser && this.selectedUser.id;
    this.dialogRef.close({
      newRider: this.selectedUser, success: userExist
    });
  }

  public userDisplay(user: AdminRiderModel): AdminRiderModel | string {
    return user ? `${user.firstName} ${user.lastName}` : user;
  }

}

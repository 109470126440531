import {Component, OnInit} from '@angular/core';
import {OsTypes} from '@carol-nx/data';
import {getMobileOS} from '@carol-nx/utils';

@Component({
  selector: 'carol-nx-app-download-icon',
  templateUrl: './app-download-icon.component.html',
  styleUrls: ['./app-download-icon.component.scss'],
})
export class AppDownloadIconComponent implements OnInit {
  public currentOs: OsTypes;

  constructor() {
  }

  get oSType() {
    return OsTypes;
  }

  ngOnInit() {
    this.currentOs = getMobileOS();
  }

  public openStorePage(os: OsTypes) {
    switch (os) {
      case OsTypes.ios:
        window.open('https://apps.apple.com/us/app/car-o-l/id1441582519', '_blank');
        break;
      case OsTypes.android:
        window.open('https://play.google.com/store/apps/details?id=com.dinamexoft.carol.app', '_blank');
        break;
    }
  }
}

import { RidePeriodicElement, TablePagingData } from "@carol-nx/data";
import { Action, createReducer, on } from "@ngrx/store";
import cloneDeep from "lodash/cloneDeep";
import remove from "lodash/remove";
import uniqBy from "lodash/uniqBy";
import { RidesActions } from "../actions";
import { initialRidesState, RidesState } from "../state/rides.state";

export const ridesFeatureKey = 'rides';

const ridesReducer = createReducer(
  initialRidesState,

  on(RidesActions.SetRideType,
    ((state, {rideType}) => {
      return {...state, rideType};
    })
  ),

  on(RidesActions.SetSelectedRideId,
    ((state, {id}) => ({...state, selectedRideId: id}))
  ),

  on(RidesActions.SetRideInfoByRideIdAndRiderId,
    ((state: RidesState, {rideData}) => ({...state, selectedRideExtendData: rideData}))
  ),

  on(RidesActions.SetDisplayedStatValuesForRide,
    ((state: RidesState, {stats}) => ({...state, selectedRideStatValues: stats}))
  ),
  on(RidesActions.DeleteRideSuccess,
    ((state: RidesState, {rideId}) => {
      const allRides = cloneDeep(state.rides);
      const rideType = state.rideType;
      let newRidesPaging = state.ridesPaging;
      if (Array.isArray(allRides[rideType])) {
        const updateRides = Object.assign([], allRides[rideType]);
        allRides[rideType] = remove(updateRides, (ride: RidePeriodicElement) => ride.id && ride.id !== rideId);
        if (allRides[rideType].length !== state.ridesPaging[rideType].totalElements) {
          newRidesPaging = Object.assign({}, state.ridesPaging);
          const newPaging: TablePagingData = Object.assign({}, state.ridesPaging[rideType]);
          newPaging.totalElements = newPaging.totalElements - 1;
          newRidesPaging[rideType] = newPaging;
        }
      }
      return {...state, rides: allRides, ridesPaging: newRidesPaging};
    })
  ),
  on(RidesActions.SetRidesByType,
    (state: RidesState, {ridePeriodicElements, rideType}) => {
      const actionRideType = rideType || state.rideType;
      const allRides = {};
      if (state.rides) {
        Object.assign(allRides, state.rides);
      }
      allRides[actionRideType] = uniqBy([...(allRides[actionRideType] ? allRides[actionRideType] : []), ...ridePeriodicElements], 'id');
      return {...state, rides: allRides};
    }
  ),
  on(RidesActions.ResetRidesByType,
    (state: RidesState, {ridePeriodicElements, rideType}) => {
      const actionRideType = rideType || state.rideType;
      const allRides = Object.assign({}, state.rides);
      allRides[actionRideType] = ridePeriodicElements;
      return {...state, rides: allRides};
    }
  ),
  on(RidesActions.SetRidesPagingDataByType,
    (state: RidesState, {pagingData, rideType}) => {
      const currentType = rideType || state.rideType;
      const allPaging = {};
      if (state.ridesPaging) {
        Object.assign(allPaging, state.ridesPaging);
      }
      allPaging[currentType] = pagingData;
      return {...state, ridesPaging: allPaging};
    }
  ),
  on(RidesActions.SetStatsArrByRideId,    (state: RidesState, {statsArr}) =>
    ({...state, statsArray: statsArr})),

  on(RidesActions.UnsetAllRides,    (state: RidesState) =>
    ({...state, rides: undefined, selectedRideId: undefined})),

  on(RidesActions.SetLastRide, (state:RidesState, {rideData}) =>
    ({...state, lastRide: rideData})),
);

export function reducer(state: RidesState | undefined, action: Action) {
  return ridesReducer(state, action);
}

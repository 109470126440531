import {ChangeDetectionStrategy, Component, OnInit, Self} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TicketModel, UserModel} from '@carol-nx/data';
import {DestroyService, DialogService, HelperService} from '@carol-nx/services';
import {AppState, RiderSelectors} from '@carol-nx/store';
import {Store} from '@ngrx/store';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {CommonErrorModalComponent, CommonSuccessModalComponent} from '@carol-nx/ui';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  public messageForm: FormGroup;
  public formSent: boolean;
  private userData: UserModel;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private helperService: HelperService,
    @Self() private onDestroy$: DestroyService,
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.messageForm = this.formBuilder.group({
      fromName: ['', [Validators.required, Validators.minLength(3)]],
      replyTo: ['', [Validators.required, Validators.email, Validators.minLength(3)]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.store.select(RiderSelectors.selectRider).pipe(
      filter(userData => !!userData),
      tap(userData => {
        this.userData = userData;
        this.messageForm.patchValue({
          replyTo: userData.email,
          fromName: `${userData.firstName} ${userData.lastName}`
        });
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  private resetForm() {
    this.messageForm.reset();
    this.messageForm.patchValue({
      replyTo: this.userData.email,
      fromName: `${this.userData.firstName} ${this.userData.lastName}`
    });
  }

  public sendMessage() {
    this.formSent = true;
    const {fromName, subject, body, replyTo} = this.messageForm.value;
    const infoPayload = `\n
      From - ${fromName}\n
      AppVersion - ${environment.version}\n
      User Agent - ${navigator.userAgent}
    `;

    const ticketObject: TicketModel = {
      subject,
      bikeId: '',
      text: body + '\n' + infoPayload,
      email: replyTo || this.userData.email,
    };

    this.helperService.sendTicket(ticketObject)
      .then(resp => {
        if (resp.status === 200) {
          this.dialogService.openCertainModal(CommonSuccessModalComponent, {
            data: {
              message: 'Message sent successfully.'
            }
          });
          this.resetForm();
          this.formSent = false;
        }
      }).catch(() => {
        this.dialogService.openCertainModal(CommonErrorModalComponent, {
          data: {
            title: 'Error',
            message: 'Something went wrong, please try again in a few minutes'
          }
        });
        this.formSent = false;
      });
  }
}



import {Injectable} from '@angular/core';
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class SvgService {
  svgCache: {[src: string]: string} = {};
  loadingSvgs: {[src: string]: Promise<string>} = {};

  constructor() {
  }

  loadSvg(src: string): any {
    if (this.svgCache[src]) {
      return Promise.resolve(this.svgCache[src]);
    }

    if (this.loadingSvgs[src]) {
      return this.loadingSvgs[src];
    }

    const promise = axios.get(src)
      .then((result: any) => result.data.substr(result.data.indexOf('<svg')))
      .then((svg: string) => {
        this.loadingSvgs[src] = null;
        this.svgCache[src] = svg;
        return svg;
      })
      .catch(e => {
        this.loadingSvgs[src] = null;
        console.log(`Can't load svg: `, e);
        return Promise.reject(e);
      });

    this.loadingSvgs[src] = promise;

    return promise;
  }
}

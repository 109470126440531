import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState, RiderSelectors} from '@carol-nx/store';
import {UserModel} from '@carol-nx/data';
import {tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'carol-nx-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutModalComponent implements OnInit {
  public rider: UserModel;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.select(RiderSelectors.selectRider).pipe(
      tap(rider => this.rider = rider),
      untilDestroyed(this)
    ).subscribe();
  }
}

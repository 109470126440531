import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { TrainerService } from '../../../services/trainer.service';
import { RiderService } from '../../../services/rider.service';
import { DashboardMainMainComponent } from '@carol-nx/ui';
import { RideTypes } from '@carol-nx/data';

@Component({
  selector: 'carol-nx-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardMainComponent extends DashboardMainMainComponent implements OnInit {
  constructor(protected store: Store<AppState>, private trainerService: TrainerService, private riderService: RiderService) {
    super(store);
    this.trainerService.init();
  }

  public ngOnInit(): void {
    this.riderService.selectRiderIdWithStats();
    super.init();
  }

  public readonly RideTypes = RideTypes;
}

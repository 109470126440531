import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DefinitionsComponent } from './pages/definitions/definitions.component';
import { TrendsComponent } from './pages/trends/trends.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ComponentsModule } from './components/components.module';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ASSETS_PREFIX, CAROL_DATE_FORMATS, DataModule, Language } from '@carol-nx/data';
import { UtilsModule } from '@carol-nx/utils';
import { DashboardPowerMainComponent, UiModule } from '@carol-nx/ui';
import { CarolStoreModule } from '@carol-nx/store';
import { ContactComponent } from './pages/contact/contact.component';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { DashboardActivityComponent } from './pages/dashboard/dashboard-activity/dashboard-activity.component';
import { DashboardMainComponent } from './pages/dashboard/dashboard-main/dashboard-main.component';
import { DashboardFitnessComponent } from './pages/dashboard/dashboard-fitness/dashboard-fitness.component';
import { DashboardPowerComponent } from './pages/dashboard/dashboard-power/dashboard-power.component';
import { MatMenuModule } from '@angular/material/menu';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { DownloadAppModalComponent } from './components/modals/download-app-modal/download-app-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { VersionInterceptor } from './interceptors/version.interceptor';

const defaultDialogConfig = {
  disableClose: false,
  hasBackdrop: true,
  autoFocus: true,
  height: 'auto'
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=4.2.0');
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ConfirmationModalComponent,
    DefinitionsComponent,
    TrendsComponent,
    NotFoundComponent,
    ContactComponent,
    DashboardActivityComponent,
    DashboardMainComponent,
    DashboardFitnessComponent,
    DashboardPowerComponent,
    DownloadAppModalComponent,
    DashboardPowerMainComponent
  ],
  imports: [
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatMenuModule,

    DataModule,
    UiModule,
    CarolStoreModule,
    UtilsModule,
    ComponentsModule,
    ChartModule,

    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    MatAutocompleteModule,
    TranslateModule.forRoot({
      defaultLanguage: Language.English,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ScreenTrackingService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: CAROL_DATE_FORMATS },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: defaultDialogConfig },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: ASSETS_PREFIX, useValue: '/dashboard' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

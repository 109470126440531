import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { DialogService } from '@carol-nx/services';
import { verboseErrorMessage } from '@carol-nx/utils';
import { CommonErrorModalComponent } from '@carol-nx/ui';
import { AppState, AuthActions, AuthSelectors, InfoActions } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AuthFormStateTypes } from '@carol-nx/data';

@UntilDestroy()
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  public authenticated: boolean;
  private passThroughErrors = [400, 401, 403, 504];

  constructor(private dialogService: DialogService, private store: Store<AppState>, private router: Router) {
    this.store.select(AuthSelectors.selectAuthStatus).pipe(
      filter(isAuth => !!isAuth),
      tap((authenticated: boolean) => {
        this.authenticated = authenticated;
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private static makeErrorDialog(err): { title, message } {
    const commonErrorMessage = 'Please, try again later or contact with support';

    return {
      title: err.status && err.error && err.error.error
        ? `${err.status}  ${err.error.error}`
        : err.status === 0
          ? 'Internet Connection Error'
          : (err.status === 502 || err.status === 500)
            ? 'Server Error'
            : 'Error',
      message: err.error && err.error.message
        ? verboseErrorMessage(err.error.message)
        : err.status === 0
          ? 'Please, check your internet connection and try again'
          : err.status === 502 || err.status === 500
            ? commonErrorMessage
            : err.message || commonErrorMessage
    };
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.dialogService.hideLoader();
            setTimeout(() => this.store.dispatch(InfoActions.SetTableDataLoaded({ tableDataLoaded: true })));
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 403 && err.url.endsWith('/rider-api/auth/reset-confirm')) {
            this.dialogService.hideLoader();
            this.dialogService.openCertainModal(CommonErrorModalComponent, { data: ResponseInterceptor.makeErrorDialog(err) })
              .afterClosed().pipe(untilDestroyed(this)).subscribe(() => {
              this.store.dispatch(AuthActions.SetAuthFormState({ formState: AuthFormStateTypes.Restore }));
              this.router.navigate(['/auth']);
            });
          } else if (err instanceof HttpErrorResponse && this.passThroughErrors.includes(err.status)) {
            // Pass through errors until we are done with error handling
            console.log('--- Pass through 400, 401, 403, 504 errors');
          } else if (err instanceof HttpErrorResponse && (err.status != 401 || this.authenticated !== true)) {
            this.dialogService.hideLoader();
            this.dialogService.openCertainModal(CommonErrorModalComponent, { data: ResponseInterceptor.makeErrorDialog(err) });
          }
          setTimeout(() => this.store.dispatch(InfoActions.SetTableDataLoaded({ tableDataLoaded: true })));
        }
      ));
  }
}

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { ASSETS_PREFIX } from '@carol-nx/data';

@Pipe({
  name: 'avatarFullPath'
})
export class AvatarFullPathPipe implements PipeTransform {

  constructor(
    @Inject(ASSETS_PREFIX) private assetsPrefix: boolean
  ) {
  }

  transform(path: string): SafeResourceUrl {
    return !!path && path.includes('/static/avatar/')
      ? `${environment.url}${path}`
      : `${environment.url}/static/avatar/${path}`;
  }

}

import { Store } from '@ngrx/store';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ChartType, RideTypes } from '@carol-nx/data';
import { formatChartData, isImperial } from '@carol-nx/utils';
import isEqual from 'lodash/isEqual';

export class DashboardFitnessMainComponent {
  public userFitnessScore;
  public userFitnessScorePer;
  public userVO2Max;
  public userPeakHeartRate;

  constructor(protected store: Store<AppState>) {
  }

  dispatch(): void {
    this.store.dispatch(RiderActions.GetRiderFitnessScoreChartStats());
    const fitnessScorePerAction = isImperial()
      ? RiderActions.GetRiderFitnessScorePerLbChartStats()
      : RiderActions.GetRiderFitnessScorePerKgChartStats();
    this.store.dispatch(fitnessScorePerAction);
    this.store.dispatch(RiderActions.GetRiderVO2MaxChartStats());
    this.store.dispatch(RiderActions.GetRiderPeakHeartRateChartStats());
  }

  init(): void {
    this.userFitnessScore = this.store.select(RiderSelectors.selectFitnessScoreChartStats).pipe(
      filter(fitnessScoreChartStats => !!fitnessScoreChartStats),
      distinctUntilChanged(isEqual),
      map(fitnessScoreChartStats => {
          return {
            title: 'DASHBOARD_INFO_FITNESS_SCORE',
            chartData: formatChartData(ChartType.FitnessScore, this.checkMissingIntenseData(fitnessScoreChartStats))
          };
        }
      )
    );

    let selectorFitnesScorePer;
    let fitnessScorePerChartType: ChartType;
    let titleFitnessScorePer: string;

    if (isImperial()) {
      selectorFitnesScorePer = RiderSelectors.selectFitnessScorePerLbChartStats;
      fitnessScorePerChartType = ChartType.FitnessScorePerLB;
      titleFitnessScorePer = 'FITNESS_SCORE_PER_LB';
    } else {
      selectorFitnesScorePer = RiderSelectors.selectFitnessScorePerKgChartStats;
      fitnessScorePerChartType = ChartType.FitnessScorePerKG;
      titleFitnessScorePer = 'FITNESS_SCORE_PER_KG';
    }

    this.userFitnessScorePer = this.store.select(selectorFitnesScorePer).pipe(
      filter(fitnessScorePerChartStats => !!fitnessScorePerChartStats),
      distinctUntilChanged(isEqual),
      map(fitnessScorePerChartStats => ({
          title: titleFitnessScorePer,
          chartData: formatChartData(fitnessScorePerChartType, this.checkMissingIntenseData(fitnessScorePerChartStats))
        })
      )
    );

    this.userVO2Max = this.store.select(RiderSelectors.selectVO2MaxChartStats).pipe(
      filter(VO2Max => !!VO2Max),
      map(VO2Max => ({
          title: 'DASHBOARD_INFO_VO2MAX',
          chartData: formatChartData(ChartType.VO2Max, this.checkMissingIntenseData(VO2Max))
        })
      )
    );

    this.userPeakHeartRate = this.store.select(RiderSelectors.selectPeakHeartRateChartStats).pipe(
      filter(peakHeartRate => !!peakHeartRate),
      map(peakHeartRate => ({
          title: 'DASHBOARD_INFO_FITNESS_PEAK_HEART_RATE',
          chartData: formatChartData(ChartType.PeakHeartRate, this.checkMissingIntenseData(peakHeartRate)),
        })
      )
    );
  }

  private checkMissingIntenseData(dataSource) {
    const data = {...dataSource};
    if (data.rideType === 0) {
      data.rideType = RideTypes.IntenseRides;
    }
    return data;
  }

}

<div class="header">
  <div class="back" (click)="goBack()">
    <carol-nx-svg-icon icon="arrow-left" [native]="true"></carol-nx-svg-icon>
  </div>
  <div class="title">{{title}}</div>
  <div class="forward" (click)="goForward()" [class.disabled]="isCurrentMonth(datePointer)">
    <carol-nx-svg-icon icon="arrow-right" [native]="true"></carol-nx-svg-icon>
  </div>
</div>
<table @showTrigger *ngIf="dates" (swiperight)="goBack()" (swipeleft)="goForward()">
  <tr>
    <th *ngIf="isFirstSunday">S</th>
    <th>M</th>
    <th>T</th>
    <th>W</th>
    <th>T</th>
    <th>F</th>
    <th>S</th>
    <th *ngIf="!isFirstSunday">S</th>
  </tr>
  <tr *ngFor="let week of dates">
    <td *ngFor="let day of week" [ngClass]="day.class">
      <span class="date">{{day.value}}</span>
    </td>
  </tr>
</table>

import { Component } from '@angular/core';
import { DefinitionsMainComponent } from '@carol-nx/ui';

@Component({
  selector: 'app-definitions',
  templateUrl: './definitions.component.html',
  styleUrls: ['./definitions.component.scss']
})
export class DefinitionsComponent extends DefinitionsMainComponent {
}



import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-login-logout-nav-item',
  templateUrl: './login-logout-nav-item.component.html',
  styleUrls: ['./login-logout-nav-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginLogoutNavItemComponent implements OnInit {
  @Input()
  public isAuthenticated: boolean;
  @Output()
  public logoutClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public logOut(event: any) {
    this.logoutClicked.emit(event);
  }

}

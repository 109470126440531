<div class="main-container" #fullscreen id="fullscreen"
     [class.is-mobile]="mobileQuery.matches"
     [class.blurred]="modalTypes.LogoutModal === (openedModal | async)">
  <mat-toolbar class="dashboard-toolbar" id="header" *ngIf="(isAuthenticated | async)">
    <button mat-icon-button [disableRipple]="true" (click)="menuChanged()" class="main-nav-btn">
      <img [src]="(darkMode | async) ? 'assets/svg/icon-burger-alt.svg' : 'assets/svg/icon-burger.svg'" alt="menu"/>
    </button>
    <span class="toolbar-spacer">
          <a class="main-logo" [class.filter-icons]="isFilterIcon" [routerLink]="['/']" [ngClass]="{'center-logo': isHideTitle}">
            <carol-nx-svg-logo></carol-nx-svg-logo>
          </a>
    </span>
    <div class="page-title">{{pageTitle | async | translate}}</div>
    <div class="filter-icons" *ngIf="isFilterIcon">
      <label class="user-short-info" *ngIf="isHideTitle">
        <button mat-icon-button class="button-filter" [matMenuTriggerFor]="licenseMenu" (menuOpened)="menuOpened()" (menuClosed)="menuClosed()">
          <carol-nx-svg-icon icon="ride" class="license-icon" [native]="true"></carol-nx-svg-icon>
        </button>
        <div class="license-name">{{(currentLicense| async)?.name}}</div>
      </label>

      <mat-menu #licenseMenu="matMenu" class="filter-menu wider-filter-menu" yPosition="below">
        <mat-radio-group class="radio-group" labelPosition="before" color="accent">
          <mat-radio-button (change)="selectLicense(l)" *ngFor="let l of licenses.getValue(); let i = index" [checked]="l.id===(currentLicense| async)?.id" [value]="l">
            {{l.name}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-menu>

      <label class="user-short-info" *ngIf="isLeaderboards && containSubType">
        <button mat-icon-button [matMenuTriggerFor]="powerTypeSubTypeMenu" class="button-filter"
                (menuOpened)="menuOpened()" (menuClosed)="menuClosed()">
          <mat-icon>tune</mat-icon>
        </button>
      </label>

      <mat-menu #powerTypeSubTypeMenu="matMenu" class="filter-menu wider-filter-menu" yPosition="below">
        <mat-radio-group [(ngModel)]="activeSubType" class="radio-group" color="accent" labelPosition="before">
          <mat-radio-button (change)="selectSubType(st)" *ngFor="let st of activeSubTypes.getValue()" [value]="st">
            {{st.title}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-menu>

      <label *ngIf="isSubTypesToShow" [class.accent]="rideSubTypes.getValue()?.indexOf(rideSubTypeSelected)" class="user-short-info">
        <button (menuClosed)="menuClosed()" (menuOpened)="menuOpened()" [matMenuTriggerFor]="rideTypeSubTypeMenu"
                class="button-filter" mat-icon-button>
          <mat-icon>tune</mat-icon>
        </button>
      </label>

      <mat-menu #rideTypeSubTypeMenu="matMenu" class="filter-menu wider-filter-menu" yPosition="below">
        <mat-radio-group [(ngModel)]="rideSubTypeSelected" class="radio-group" color="accent" labelPosition="before">
          <mat-radio-button (change)="selectRideSubType(st)" *ngFor="let st of rideSubTypes.getValue()" [value]="st">
            {{getSubTypeName(st)}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-menu>

      <label *ngIf="isLeaderboards" class="user-short-info">
        <button mat-icon-button [matMenuTriggerFor]="genderMenu" class="button-filter" (menuOpened)="menuOpened()"
                (menuClosed)="menuClosed()">
          <carol-nx-svg-icon *ngIf="curGender === genderFilter.ALL" icon="gender" class="gender-icon"
                             [native]="true"></carol-nx-svg-icon>
          <carol-nx-svg-icon *ngIf="curGender === genderFilter.MALE" icon="male" class="gender-icon gender-male"
                             [native]="true"></carol-nx-svg-icon>
          <carol-nx-svg-icon *ngIf="curGender === genderFilter.FEMALE" icon="female" class="gender-icon"
                             [native]="true"></carol-nx-svg-icon>
        </button>
        <div class="gender-name">{{curGenderLabel | translate}}</div>
      </label>

      <mat-menu #genderMenu="matMenu" yPosition="below" class="filter-menu">
        <mat-radio-group class="radio-group" labelPosition="before" color="accent">
          <mat-radio-button *ngFor="let g of gendersList; let i = index" [value]="g.val" [checked]="i === 0"
                            (change)="selectGender(g.val)">
            {{g.view | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-menu>
    </div>
    <div class="lang-switcher">
      <carol-nx-svg-icon class="small-switcher" native="true"
                         [matMenuTriggerFor]="langMenu"
                         [icon]="'globe-select'"></carol-nx-svg-icon>
      <mat-menu #langMenu="matMenu">
        <button mat-menu-item *ngFor="let lang of languages"
                (click)="updateLang(lang)">
          <span>{{lang}} <mat-icon *ngIf="curLang === lang">check</mat-icon></span>
        </button>
      </mat-menu>
    </div>
    <div class="user-short-info" *ngIf="isAuthenticated | async">
      <ng-container *ngIf="(rider | async) as riderData">
        <div mat-card-avatar class="header-avatar" [routerLink]="['preferences']">
          <avatar [rider]="riderData"></avatar>
        </div>
        <div class="nickname" [routerLink]="['preferences']">
          {{riderData.firstName}} {{riderData.lastName}}
        </div>
      </ng-container>
      <ng-container *ngIf="(client | async) as clientData">
        <mat-icon>chevron_right</mat-icon>
        <div mat-card-avatar class="header-avatar">
          <avatar [rider]="clientData"></avatar>
        </div>
        <div class="nickname">
          {{clientData.firstName}} {{clientData.lastName}}
        </div>
      </ng-container>
    </div>
    <div class="theme-switcher hidden-block">
      <mat-slide-toggle [checked]="darkMode | async" (change)="changeThemeMode($event)" [disableRipple]="true">
        {{(darkMode | async) ? 'Light mode' : 'Dark mode'}}
      </mat-slide-toggle>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="main-sidenav-container">
    <mat-sidenav #mainSideNav fixedTopGap="56" id="main-side-nav"
                 [opened]="isHorizontalOrientation && (isAuthenticated | async)"
                 [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" (click)="clickMenu($event)">

      <!--Main nav-->
      <mat-list class="nav-items-list main-nav foldable-menu">
        <mat-list-item *ngFor="let item of mainNav.items" class="nav-item" routerLinkActive="active">
          <a [routerLink]="item.link" routerLinkActive="active">
            {{item.title | translate}}
          </a>
        </mat-list-item>
      </mat-list>

      <!--Membership owner nav-->
      <mat-list class="nav-items-list main-nav foldable-menu" *ngIf="(isOwnerMenuAvailable | async) && !clientId">
        <mat-list-item class="nav-item first-item" [class.active-parent]="activeNavName === 'membership'" (click)="foldNavList('membership')">
          <a [routerLink]="['/membership/']" routerLinkActive="active" class="nav-list-title">
            {{'NAV_ITEMS.MEMBERSHIP' | translate}}
            <mat-icon>{{licenseNav.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          </a>
        </mat-list-item>
        <div class="items-holder" [class.active]="licenseNav.active" (mouseenter)="activeNavName = 'membership'" (mouseleave)="activeNavName = ''">
          <mat-list-item class="nav-item nested-nav-item" *ngFor="let item of licenseNav.items" routerLinkActive="active">
            <a [routerLink]="item.link" routerLinkActive="active">
              {{item.title | translate}}
            </a>
          </mat-list-item>
        </div>
      </mat-list>

      <!--Admin nav-->
      <mat-list class="nav-items-list main-nav foldable-menu" *ngIf="(isAuthenticated | async) && (isAdminRole || isSupportRole) && !clientId">
        <mat-list-item class="nav-item first-item" [class.active-parent]="activeNavName === 'admin'" (click)="foldNavList('admin')">
          <a href="javascript:void(0)" class="nav-list-title">
            {{'NAV_ITEMS.ADMIN' | translate}}
            <mat-icon>{{adminNav.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          </a>
        </mat-list-item>
        <div class="items-holder" [class.active]="adminNav.active" (mouseenter)="activeNavName = 'admin'" (mouseleave)="activeNavName = ''">
          <mat-list-item class="nav-item nested-nav-item" *ngFor="let item of adminNav.items" routerLinkActive="active">
            <a [routerLink]="item.link" routerLinkActive="active">
              {{item.title | translate}}
            </a>
          </mat-list-item>
          <mat-list-item class="nav-item" *ngIf="isApkRole" routerLinkActive="active">
            <a [routerLink]="['/admin/bikeapp/']" routerLinkActive="active">
              {{'NAV_ITEMS.BIKE_APP' | translate}}
            </a>
          </mat-list-item>
          <mat-list-item class="nav-item" *ngIf="isApkRole" routerLinkActive="active">
            <a [routerLink]="['/admin/firmware/']" routerLinkActive="active">
              {{'NAV_ITEMS.BIKE_FIRMWARE' | translate}}
            </a>
          </mat-list-item>
        </div>
      </mat-list>

      <!--Logout nav-->
      <mat-list class="nav-items-list main-nav">
        <app-login-logout-nav-item
          [isAuthenticated]="isAuthenticated | async"
          (logoutClicked)="logOut()">
        </app-login-logout-nav-item>
      </mat-list>

      <carol-nx-app-download-icon></carol-nx-app-download-icon>
    </mat-sidenav>

    <mat-sidenav-content [class.main-side-nav-cnt]="mainSideNav.opened">
      <div class="router-wrapper fullscreen"
           [ngStyle]="{filter: isOpenMenu ? 'blur(0.21rem)' : 'unset'}" cdkScrollable>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartOptions, PlotOptions, TooltipOptions, XAxisOptions, YAxisOptions } from 'highcharts';
import { Chart } from 'angular-highcharts';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { MAIN_ACCENT_COLOR, NOT_VALID_SUBSCRIPTION_COLOR } from '@carol-nx/data';
import { Store } from '@ngrx/store';

@UntilDestroy()
@Component({
  selector: 'carol-nx-habit-score-chart',
  templateUrl: './habit-score-chart.component.html',
  styleUrls: ['./habit-score-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HabitScoreChartComponent implements OnInit {
  @Input()
  public initDate: number;
  @Input()
  public doNotLoad: boolean;
  private lineColor = 'white';
  private chartInstance: Chart;
  private dataTrend: number[];
  private dayInMillis = 24 * 60 * 60 * 1000;

  constructor(private store: Store<AppState>, private ref: ChangeDetectorRef) {
  }

  get data() {
    return this.dataTrend;
  }

  public ngOnInit() {
    if (!this.doNotLoad) {
      this.store.dispatch(RiderActions.GetRiderHabitScoreChart({ date: this.initDate }));
    }
    this.initData();
  }

  public getChart() {
    return this.chartInstance;
  }

  public initSingleLineChart() {
    if (!this.dataTrend)
      return;
    const transparentColor = 'rgba(0,0,0,0)';
    const axisColor = 'rgba(247, 248, 253, 0.1)';
    const tooltipOptions: TooltipOptions = {
      borderRadius: 6,
      borderWidth: 1,
      borderColor: 'rgba(0,0,0,.1)',
      backgroundColor: '#ffffff',
      shadow: true,
      shared: false,
      xDateFormat: '%d %b %H:%M',
      pointFormat: '{point.y:.0f}<br/>',
      headerFormat: '<span style="font-size: 0.6rem">{point.key}</span><br/>',
      style: {
        fontSize: '0.8rem'
      }
    };
    const currentFontSize = Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
    const lineWidth = Math.round(3 / 24 * currentFontSize);
    const gridLineWidth = Math.round(1 / 24 * currentFontSize);
    const xAxisOptions: XAxisOptions = {
      type: 'datetime',
      range: 27 * this.dayInMillis,
      lineColor: axisColor,
      lineWidth,
      tickColor: transparentColor,
      max: Date.now(),
      labels: { enabled: false }
    };
    const yAxisOptions: YAxisOptions = {
      gridLineColor: axisColor,
      gridLineWidth,
      tickAmount: 3,
      min: 0,
      max: 100,
      minRange: 100,
      showEmpty: true,
      labels: { enabled: false },
      title: { text: null }
    };
    const plotOptions: PlotOptions = {
      series: {
        lineWidth: gridLineWidth,
        marker: {
          radius: 0
        }
      }
    };
    const chartOptions: ChartOptions = {
      type: 'areaspline',
      panKey: 'shift',
      zooming: {type: 'x', pinchType: 'x'},
      spacingRight: 0,
      spacingLeft: 0,
      marginBottom: 2,
      backgroundColor: transparentColor,
      style: {
        color: transparentColor
      }
    };
    this.chartInstance = new Chart({
      accessibility: { enabled: false },
      chart: {
        ...chartOptions
      },
      title: {
        text: ''
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
        ...xAxisOptions
      },
      yAxis: {
        ...yAxisOptions
      },
      tooltip: {
        ...tooltipOptions
      },
      plotOptions: {
        ...plotOptions
      },
      legend: {
        enabled: false
      },
      series: [{
        clip: false,
        color: {},
        lineWidth,
        lineColor: this.lineColor,
        data: this.buildDataWithTime(this.data)
      }]
    } as Highcharts.Options);
  }

  private buildDataWithTime(data: number[]) {
    const days = data.length;
    return data.map((el, index) => {
      return ([Date.now() - (days - index - 1) * this.dayInMillis, el]);
    });
  }

  private initData() {
    this.store.select(RiderSelectors.selectRiderHabitScoreChart).pipe(
      withLatestFrom(this.store.select(RiderSelectors.selectRider).pipe(
        untilDestroyed(this)
      )),
      filter(([habitScoreChart, rider]) => !!habitScoreChart && !!rider),
      map(([habitScoreChart, rider]) => {
        if (rider.validSubscription === false) {
          this.lineColor = NOT_VALID_SUBSCRIPTION_COLOR;
          this.dataTrend = [];
          this.store.dispatch(RiderActions.SetRidersHabitScore({ habitScore: undefined }));
        } else {
          this.lineColor = MAIN_ACCENT_COLOR;
          this.dataTrend = habitScoreChart;
        }
        this.initSingleLineChart();
      }),
      tap(() => this.ref.markForCheck()),
      untilDestroyed(this)
    ).subscribe();
  }
}

import { Injectable } from '@angular/core';
import { AppState, RiderActions } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainerService {
  private selectedTraineeId?: number = undefined;
  private selectedClientId?: number = undefined;

  constructor(private store: Store<AppState>, private router: Router) {
  }

  public init(): void {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0)
    ).subscribe((event: ActivationEnd) => {
      let clientId = event.snapshot.params.clientId;
      this.initClientOrTrainee(clientId);
    });
  }

  private initClientOrTrainee(clientId?: number) {
    if (clientId) {
      if (this.selectedClientId !== clientId) {
        this.selectedClientId = clientId;
        this.store.dispatch(RiderActions.GetSelectedTrainee({ riderId: clientId }));
        this.store.dispatch(RiderActions.SetClientRiderId({ clientRiderId: clientId }));
      }
    } else if (this.selectedTraineeId !== undefined) {
      this.selectedTraineeId = undefined;
      this.store.dispatch(RiderActions.SetSelectedTrainee({ selectedTrainee: undefined }));
      this.store.dispatch(RiderActions.SetClientRiderId({ clientRiderId: clientId }));
    }
  }
}

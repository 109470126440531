export enum PowerTypes {
  TOTAL_INTENSE = 'TOTAL_INTENSE',
  TOTAL_INTERMEDIATE = 'TOTAL_INTERMEDIATE',
  TOTAL_INTERMEDIATE_3X15 = 'TOTAL_INTERMEDIATE_3X15',
  TOTAL_GIBALA = 'TOTAL_GIBALA',
  TOTAL_ENERGISER = 'TOTAL_ENERGISER',
  TOTAL_ENERGISER_3X10 = 'TOTAL_ENERGISER_3X10',
  TOTAL_FAT_BURN = 'TOTAL_FAT_BURN',
  TOTAL_RAMP_UP = 'TOTAL_RAMP_UP',
  TOTAL_FREE = 'TOTAL_FREE',
  TOTAL_ENDURANCE = 'TOTAL_ENDURANCE',
  PEAK = 'PEAK',
  TOTAL_COYLE = 'TOTAL_COYLE',
  TOTAL_ADAMSON = 'TOTAL_ADAMSON',
  PEAK_PER_LB = 'PEAK_PER_LB',
  PEAK_PER_KG = 'PEAK_PER_KG',
  FITNESS_SCORE = 'OCTANE_SCORE',
  FITNESS_SCORE_PER_LB = 'OCTANE_SCORE_PER_LB',
  FITNESS_SCORE_PER_KG = 'OCTANE_SCORE_PER_KG',
  TOTAL_REHIT = 'TOTAL_REHIT',
  TOTAL_FAT_BURN_30 = 'TOTAL_FAT_BURN_30',
  TOTAL_FAT_BURN_45 = 'TOTAL_FAT_BURN_45',
  TOTAL_FAT_BURN_60 = 'TOTAL_FAT_BURN_60',
  TOTAL_FREE_AND_CUSTOM = 'TOTAL_FREE_AND_CUSTOM',
  TOTAL_NORWEGIAN_ZONE = 'TOTAL_NORWEGIAN_ZONE'
}

import {ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {rowSelectionClassName} from "./row-selection.directive";
import {KEY_CODE} from "@carol-nx/data";
import {DOCUMENT} from "@angular/common";
import {Subject} from "rxjs";
import { debounceTime } from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
  selector: '[carolNxTableSelection]'
})
export class TableSelectionDirective implements OnInit {

  private currentRowSubject = new Subject<any>();

  constructor(private element: ElementRef,
              private changeDetectorRef: ChangeDetectorRef,
              private ngZone: NgZone,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
  }

  @HostListener('document:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const rows = Array.prototype.slice.call(this.element.nativeElement.querySelectorAll('tbody tr[carolNxRowSelection]'));
    let index = rows.findIndex(f => f.classList.contains(rowSelectionClassName));
    if (index >= 0) {
      if (event.code === KEY_CODE.UP_ARROW && index > 0) {
        const row = rows[index - 1];
        this.currentRowSubject.next(row);
        this.onSelectRow(row)
        event.preventDefault();
      }

      if (event.code ===  KEY_CODE.DOWN_ARROW && index < (rows.length - 1)) {
        const row = rows[index + 1];
        this.currentRowSubject.next(row);
        this.onSelectRow(row)
        event.preventDefault();
      }
    }
  }

  ngOnInit() {
    this.currentRowSubject.pipe(untilDestroyed(this), debounceTime(300)).subscribe(row => {
      row.click();
    })
  }

  onSelectRow(row) {
    if (!row.classList.contains(rowSelectionClassName)) {
      row.closest('table').querySelectorAll(`tr[carolNxRowSelection].${rowSelectionClassName}`).forEach(node => {
        node.classList.remove(rowSelectionClassName)
      });
      row.classList.add(rowSelectionClassName)
    }
  }
}

import { TrendChart } from './TrendChart';
import { Store } from '@ngrx/store';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  MAIN_ACCENT_COLOR,
  NOT_VALID_SUBSCRIPTION_COLOR,
  RideStats,
  RideTypes,
  TrendsModel,
  UserModel
} from '@carol-nx/data';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { enumKeys } from '@carol-nx/utils';
import { TabTypesComponent } from '../tab-types/tab-types.component';
import { PowerService } from '@carol-nx/services';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export abstract class TrendsMainComponent extends TabTypesComponent implements OnInit, OnDestroy {
  public selectedType: RideTypes;
  public selectedSubType: RideTypes;
  public rideTypeTitleMap: Partial<Record<RideTypes, string>> = {
    [RideTypes.REHIT]: 'REHIT',
    [RideTypes.RapidFatBurnRides]: 'Fat Burn',
    [RideTypes.FreeCustomRides]: 'Free & Custom',
    [RideTypes.FitnessTestsRides]: 'Fitness Tests'
  };
  public isLoaded: boolean;
  public trendCharts: TrendChart[] = [];
  public rideTypes: RideTypes[];
  protected rider: UserModel;
  private readonly trendTitles: {
    [Property in keyof TrendsModel]: string
  } = {
    peakPowerTrend: 'Peak Power',
    octaneScoreTrend: 'Fitness Score',
    functionalThresholdPowerTrend: 'Functional Threshold Power',
    caloriesInclEpocTrend: 'Calories',
    caloriesTrend: 'Calories',
    totalPowerTrend: 'Energy Output',
    averagePowerTrend: 'Average Power',
    heartRateMaxTrend: 'Peak Heart Rate'
  };

  private readonly trendRideStats: {
    [Property in keyof TrendsModel]: RideStats
  } = {
    peakPowerTrend: RideStats.PeakPower,
    octaneScoreTrend: RideStats.FitnessScore,
    functionalThresholdPowerTrend: RideStats.Ftp,
    caloriesInclEpocTrend: RideStats.CaloriesInclEpoc,
    caloriesTrend: RideStats.Calories,
    totalPowerTrend: RideStats.TotalPower,
    averagePowerTrend: RideStats.AveragePower,
    heartRateMaxTrend: RideStats.HeartRateMax
  };
  protected loaderSubject: Subject<void> = new Subject();

  protected constructor(protected store: Store<AppState>,
                        protected ref: ChangeDetectorRef,
                        protected powerService: PowerService) {
    super(powerService);
    this.rideTypes = enumKeys(this.rideTypeTitleMap);
    this.setParentRideType(this.rideTypes[0]);
  }

  ngOnDestroy() {
    this.powerService.setSubTypes(undefined);
    this.powerService.setSubTypeSelected(undefined);
    this.store.dispatch(RiderActions.UnsetRidersTrendsData());
    this.loaderSubject.complete();
  }

  ngOnInit() {
    this.store.select(RiderSelectors.selectRiderTrendsData).pipe(
      filter(trendsData => !!trendsData),
      withLatestFrom(this.store.select(RiderSelectors.selectRider).pipe(
        tap(rider => this.rider = rider),
        untilDestroyed(this)
      )),
      map(([trendsData, rider]) => Object.keys(this.trendRideStats).reduce(
        (charts: TrendChart[], trendName: string): TrendChart[] => {
          if (trendsData[trendName] && trendsData[trendName].length) {
            charts.push({
              title: this.trendTitles[trendName],
              rideStats: this.trendRideStats[trendName],
              trends: trendsData[trendName],
              chartColor: rider.validSubscription === false
                ? NOT_VALID_SUBSCRIPTION_COLOR
                : MAIN_ACCENT_COLOR
            });
          }
          return charts;
        }, []
      )),
      tap((trendsSlides: TrendChart[]) => this.trendCharts = trendsSlides as TrendChart[]),
      tap(() => this.isLoaded = true),
      tap(() => this.ref.markForCheck()),
      untilDestroyed(this)
    ).subscribe();
    this.powerService.getSubTypeSelected().pipe(
      tap((rideType: RideTypes) => {
        if (rideType && rideType !== this.selectedSubType) {
          this.onSubTypeChanged(rideType);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  public setParentRideType(rideType: RideTypes): boolean {
    this.loaderSubject.next();
    this.selectedType = rideType;
    if (this.rideSubTypes[this.selectedType]) {
      this.selectedSubType = this.rideSubTypes[this.selectedType][0];
    } else {
      this.selectedSubType = undefined;
    }
    this.setParentTypeFilterMenu(this.selectedType);
    this.dispatchChange();
    return !!this.selectedSubType;
  }

  public onSubTypeChanged(rideType: RideTypes): void {
    this.loaderSubject.next();
    this.selectedSubType = rideType;
    this.dispatchChange();
  }

  public typeIsLast() {
    const currentIndex = this.getCurrentRideIndex();
    return currentIndex === (this.rideTypes.length - 1);
  }

  public typeIsFirst() {
    const currentIndex = this.getCurrentRideIndex();
    return currentIndex === 0;
  }

  public selectBackType() {
    if (!this.typeIsFirst()) {
      const currentIndex = this.getCurrentRideIndex();
      this.setParentRideType(this.rideTypes[currentIndex - 1]);
    }
  }

  public selectForwardType() {
    if (!this.typeIsLast()) {
      const currentIndex = this.getCurrentRideIndex();
      this.setParentRideType(this.rideTypes[currentIndex + 1]);
    }
  }

  protected getCurrentRideIndex(): number {
    return this.rideTypes.findIndex(f => f === this.selectedType);
  }

  protected swipeSubTypeChange(swipe: 'previous' | 'next') {
    if (this.rideSubTypes[this.selectedType] && (swipe !== 'previous' || !this.subTypeIsFirst()) && (swipe !== 'next' || !this.subTypeIsLast())) {
      if (swipe === 'next') {
        this.selectForwardSubType();
      } else if (swipe === 'previous') {
        this.selectBackSubType();
      }
    } else {
      if (swipe === 'next') {
        if (!this.typeIsLast()) {
          this.selectForwardType();
          return swipe;
        }
      } else if (swipe === 'previous') {
        if (!this.typeIsFirst()) {
          const currentIndex = this.getCurrentRideIndex();
          if (this.rideSubTypes[this.rideTypes[currentIndex - 1]]) {
            let rideType = this.rideTypes[currentIndex - 1];
            this.selectedType = rideType;
            this.setParentTypeFilterMenu(this.selectedType);
            let subRideType = this.rideSubTypes[rideType].slice(-1)[0];
            this.onSubTypeChanged(subRideType);
            return swipe;
          } else {
            this.selectBackType();
            return swipe;
          }
        }
      }
    }
  }

  protected selectBackSubType() {
    if (!this.subTypeIsFirst()) {
      const currentIndex = this.getCurrentSubRideIndex();
      this.onSubTypeChanged(this.rideSubTypes[this.selectedType][currentIndex - 1]);
    }
  }

  protected selectForwardSubType() {
    if (!this.subTypeIsLast()) {
      const currentIndex = this.getCurrentSubRideIndex();
      this.onSubTypeChanged(this.rideSubTypes[this.selectedType][currentIndex + 1]);
    }
  }

  protected subTypeIsFirst() {
    const currentIndex = this.getCurrentSubRideIndex();
    return currentIndex === 0;
  }

  protected subTypeIsLast() {
    const currentIndex = this.getCurrentSubRideIndex();
    return currentIndex && currentIndex === (this.rideSubTypes[this.selectedType].length - 1);
  }

  protected getCurrentSubRideIndex(): number {
    const subTypes = this.rideSubTypes[this.selectedType];
    if (subTypes) {
      return subTypes.findIndex((f: RideTypes) => f === this.selectedSubType);
    }
    return null;
  }

  private dispatchChange() {
    this.setSubTypeFilterMenu(this.selectedSubType);
    this.rideTypes.forEach(rt => {
      if (rt === this.selectedType && this.tabNames[this.selectedSubType])
        this.rideTypeTitleMap[rt] = this.tabNames[this.selectedSubType];
      else if (this.tabNames[rt])
        this.rideTypeTitleMap[rt] = this.tabNames[rt];
    });
    this.store.dispatch(RiderActions.GetRiderTrendsByRideType({ rideType: this.rideSubTypes[this.selectedType] ? this.selectedSubType : this.selectedType }));
  }
}

import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartOptions, PlotOptions, SeriesOptionsType, TooltipOptions } from 'highcharts';
import { MAIN_ACCENT_COLOR, RidesChartSeriesModel, SECOND_TEXT_COLOR } from '@carol-nx/data';

@Component({
  selector: 'app-rides-chart',
  templateUrl: './rides-chart.component.html',
  styleUrls: ['./rides-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RidesChartComponent {
  @Input()
  public series: RidesChartSeriesModel[];
  private chart: Chart;

  constructor() {
  }

  public initChart() {
    const transparentColor = 'rgba(0,0,0,0)';
    const grayColor = '#8e8e8e';
    const gridColor = 'rgba(147,147,147,0.36)';
    const hintsColor = 'rgba(250,250,250, 0.5)';
    const currentFontSize = Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)[0]);
    const chartHeight = Math.round(250 / 24 * currentFontSize);
    const chartOptions: ChartOptions = {
      panning: {
        enabled: true,
        type: 'x'
      },
      height: chartHeight,
      zooming: { type: 'x', pinchType: 'x' },
      spacingRight: 20,
      borderWidth: 0,
      backgroundColor: transparentColor,
      plotBorderColor: grayColor,
      plotBorderWidth: 1,
      style: {
        'fontFamily': 'Roobert !important',
        'fontSize': '1rem',
        'fontWeight': 'lighter',
        'text-transform': 'capitalize'
      },
      events: {
        load: function() {
          const chart = this;
          chart.series.filter(f => f.points.length > 0).forEach(s => {
            const maxPoint = s.points.reduce((acc, current) => {
              return (acc.y > current.y ? acc : current);
            });
            if (maxPoint.y > 0) {
              maxPoint.update({
                dataLabels: {
                  enabled: true,
                  crop: false,
                  overflow: 'allow',
                  padding: 3,
                  formatter: function() {
                    return String(Math.round(this.y));
                  },
                  style: {
                    color: maxPoint.color as string,
                    fontSize: '0.7em',
                    textOutline: 'none'
                  }
                }
              });
            }
          });
        }
      }
    };

    const tooltipOptions: TooltipOptions = {
      style: {
        fontFamily: 'Roobert !important',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.7em'
      },
      borderRadius: 20,
      borderWidth: 2,
      backgroundColor: grayColor,
      formatter() {
        return `${this.y}`;
      }
    };
    const plotOptions: PlotOptions = {
      series: {
        lineWidth: 1,
        marker: {
          enabled: false
        }
      },
      spline: {
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        marker: {
          enabled: false
        }
      }
    };

    const colors = [MAIN_ACCENT_COLOR, SECOND_TEXT_COLOR];
    let chartSeries: SeriesOptionsType[] = [];
    for (let i = 0; i < this.series.length; i++) {
      chartSeries.push({
        color: colors[i],
        ...this.series[i],
        yAxis: i,
        type: 'spline'
      });
    }

    return this.chart = new Chart({
      accessibility: { enabled: false },
      chart: {
        ...chartOptions
      },
      title: { text: null },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: {
        backgroundColor: transparentColor,
        symbolWidth: 20,
        symbolPadding: 5,
        verticalAlign: 'bottom',
        align: 'left',
        itemMarginBottom: 0,
        borderWidth: 0,
        itemHoverStyle: {
          color: hintsColor
        },
        itemHiddenStyle: {
          color: '#666666'
        },
        itemStyle: {
          color: hintsColor,
          fontFamily: 'Roobert !important',
          fontSize: '0.7em'
        },
        y: 10
      },
      xAxis: {
        type: 'linear',
        labels: {
          enabled: true,
          style: {
            color: SECOND_TEXT_COLOR,
            fontFamily: 'Roobert !important',
            fontSize: '0.7em'
          }
        },
        lineColor: grayColor,
        gridLineColor: gridColor,
        lineWidth: 2,
        gridLineWidth: 1,
        tickColor: grayColor,
        title: {
          style: {
            color: SECOND_TEXT_COLOR,
            fontFamily: 'Roobert !important'
          }
        },
        tickLength: 0
      },
      yAxis: [
        {
          title: {
            style: {
              color: MAIN_ACCENT_COLOR,
              fontFamily: 'Roobert !important',
              fontSize: '0.7em',
              display: 'none'
            }
          },
          labels: {
            style: {
              color: MAIN_ACCENT_COLOR,
              fontFamily: 'Roobert !important',
              fontSize: '0.7em'
            }
          },
          min: 0,
          gridLineColor: gridColor,
          gridLineWidth: 1,
          lineColor: grayColor,
          lineWidth: 2,
          showEmpty: false
        }, {
          title: {
            style: {
              color: SECOND_TEXT_COLOR,
              fontFamily: 'Roobert !important',
              fontSize: '0.7em',
              display: 'none'
            }
          },
          labels: {
            style: {
              color: SECOND_TEXT_COLOR,
              fontFamily: 'Roobert !important',
              fontSize: '0.7em'
            }
          },
          min: 0,
          gridLineColor: gridColor,
          gridLineWidth: 1,
          lineColor: grayColor,
          lineWidth: 2,
          showEmpty: false,
          opposite: true
        }
      ],
      plotOptions: {
        ...plotOptions
      },
      tooltip: {
        ...tooltipOptions
      },
      series: chartSeries,
      time: {
        useUTC: false
      }
    });
  }

  @HostListener('window:message', ['$event'])
  public showMenuChanging(message) {
    if (message?.data?.showMenuChanging && this.chart) {
      this.chart.ref.reflow();
    }
  }

}

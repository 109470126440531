import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.params.get('v') && request.url.indexOf('./assets') === -1) {
      const httpParams = request.params.set('v', '3.4.27');
      request = request.clone({ params: httpParams });
    }
    return next.handle(request);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ExtendedRideData,
  MAIN_ACCENT_COLOR,
  MAIN_LINE_COLOR,
  NOT_VALID_SUBSCRIPTION_COLOR,
  RideCountStatsModel,
  RideTypes
} from '@carol-nx/data';
import { AppState, OwnerSelectors, RiderSelectors, RidesSelectors } from '@carol-nx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'carol-nx-total-rides-card',
  templateUrl: './total-rides-card.component.html',
  styleUrls: ['./total-rides-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalRidesCardComponent implements OnInit {
  @Input()
  public types: RideTypes[] = [RideTypes.IntenseRides, RideTypes.IntermediatesRides, RideTypes.EnergisersRides, RideTypes.RapidFatBurnRides, RideTypes.FreeCustomRides, RideTypes.FitnessTestsRides];

  @Input()
  set validSubscription(isValid: boolean) {
    this._validSubscription.next(isValid);
  };

  @Input()
  public resizeInitAfter = 5;

  public ridesData: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private _validSubscription = new BehaviorSubject(false);
  private isMembership: boolean;

  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    let selector = RiderSelectors.selectRiderStats;
    this.isMembership = this.router.url.startsWith('/membership');
    if (this.isMembership) {
      selector = OwnerSelectors.selectOwnedStats;
    }

    if (this.isMembership) {
      this.store.select(selector).pipe(
        filter((stats) => !!stats),
        tap((stats) => this.ridesData.next(
          this.buildRidesData(stats.rideCounts, null, null)
        )),
        untilDestroyed(this)
      ).subscribe();
    } else {
      combineLatest([
        this.store.select(selector),
        this.store.select(RidesSelectors.selectLastRide),
        this._validSubscription]
      ).pipe(
        filter(([stats, lastRide]) => !!stats && !!lastRide),
        tap(([stats, lastRide, isValid]) => this.ridesData.next(
          this.buildRidesData(stats.rideCounts, isValid, lastRide)
        )),
        untilDestroyed(this)
      ).subscribe();
    }
  }

  private buildRidesData(data: RideCountStatsModel, isValid: boolean, lastRide: ExtendedRideData): any {
    const getColor = (rideTypes: Array<RideTypes>) => {
      if (isValid === false && !this.isMembership) {
        return NOT_VALID_SUBSCRIPTION_COLOR;
      } else if (lastRide === null) {
        return MAIN_LINE_COLOR;
      } else {
        if (rideTypes.includes(lastRide.type)) {
          return MAIN_ACCENT_COLOR;
        } else {
          if (lastRide.type === RideTypes.RampUpRides) {
            if (rideTypes[0] === RideTypes.IntenseRides && lastRide.sprintDuration === 20
              || rideTypes[0] === RideTypes.IntermediateRides && lastRide.sprintDuration === 15
              || rideTypes[0] === RideTypes.EnergiserRides && lastRide.sprintDuration === 10) {
              return MAIN_ACCENT_COLOR;
            }
          }
          return MAIN_LINE_COLOR;
        }
      }
    };

    const allRides = [
      {
        title: 'REHIT',
        type: RideTypes.REHIT,
        vsFill: this.countREHITRides(data),
        color: getColor([RideTypes.RampUpRides, RideTypes.IntenseRides, RideTypes.IntermediateRides, RideTypes.Intermediate3x15, RideTypes.EnergiserRides, RideTypes.Energiser3x10])
      },
      {
        title: 'Intense',
        type: RideTypes.IntenseRides,
        vsFill: data.rideCountIntense,
        color: getColor([RideTypes.IntenseRides])
      },
      {
        title: 'Intermediate',
        type: RideTypes.IntermediatesRides,
        vsFill: data.rideCountIntermediate,
        color: getColor([RideTypes.IntermediateRides, RideTypes.Intermediate3x15])
      },
      {
        title: 'Energizer',
        type: RideTypes.EnergisersRides,
        vsFill: data.rideCountEnergiser,
        color: getColor([RideTypes.EnergiserRides, RideTypes.Energiser3x10])
      },
      {
        title: 'Fat burn',
        type: RideTypes.RapidFatBurnRides,
        vsFill: data.rideCountFatBurn,
        color: getColor([
          RideTypes.RapidFatBurnRides,
          RideTypes.RapidFatBurn30Rides,
          RideTypes.RapidFatBurn45Rides,
          RideTypes.RapidFatBurn60Rides
        ])
      },
      {
        title: 'Free & Custom',
        type: RideTypes.FreeCustomRides,
        vsFill: data.rideCountFreeAndCustom,
        color: getColor([
          RideTypes.FreeRides,
          RideTypes.GibalaIntervalsRides,
          RideTypes.CoyleIntervalsRides,
          RideTypes.DrAdamsonIntervals,
          RideTypes.NorwegianZoneIntervals
        ])
      },
      {
        title: 'Fitness Tests',
        type: RideTypes.FitnessTestsRides,
        vsFill: data.rideCountFitnessTests,
        color: getColor([
          RideTypes.EnduranceRides,
          RideTypes.FtpTest2x8Rides,
          RideTypes.FtpTest20Rides,
          RideTypes.EkblomBakRides
        ])
      }
    ];

    return {
      total: Math.round(Math.max(
        this.countREHITRides(data),
        data.rideCountIntense || 0,
        data.rideCountIntermediate || 0,
        data.rideCountEnergiser || 0,
        data.rideCountFatBurn || 0,
        data.rideCountFreeAndCustom || 0,
        data.rideCountFitnessTests || 0
      ) * 1.2),
      rides: allRides.filter(ride => {
        return this.types.includes(ride.type);
      })
    };
  }

  private countREHITRides(data: RideCountStatsModel) {
    return data.rideCountIntense + data.rideCountIntermediate + data.rideCountEnergiser;
  }
}

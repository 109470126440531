import { createSelector } from "@ngrx/store";
import { AppState } from "../state";

const selectRidesState = (state: AppState) => state && state.rides;

export const selectRideType = createSelector(
  selectRidesState,
  (state) => state.rideType
);

export const selectLastRide = createSelector(
  selectRidesState,
  (state) => state.lastRide
);

export const selectRidesByType = createSelector(
  selectRidesState,
  selectRideType,
  (state, type) => state.rides ? state.rides[type] : []
);

export const selectRideId = createSelector(
  selectRidesState,
  (rides => rides.selectedRideId)
);

export const  selectExtendedRideData = createSelector(
  selectRidesState,
  (rides) => rides && rides.selectedRideExtendData
);

export const selectRideStatValues = createSelector(
  selectRidesState,
  state => state.selectedRideStatValues
);

export const selectRidesPagingByType = createSelector(
  selectRidesState,
  selectRideType,
  (state, type) => {
    return state.ridesPaging ? state.ridesPaging[type] : null;
  }
);

export const selectStatsArray = createSelector(
  selectRidesState,
  state => state.statsArray
);

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminLicenseModel, QueryParamsModel, RideTypes } from '@carol-nx/data';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { getCurrentTimeZone } from '@carol-nx/utils';

@Injectable({
  providedIn: 'root'
})
export class RiderService {
  private riderApiUrl = '/rider-api/rider';
  private trainerApiUrl = '/rider-api/trainers';

  constructor(private http: HttpClient) {
  }

  public unsubscribe(riderId: number, uuid: string): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/unsubscribe`, { data: uuid });
  }

  public getRiderById(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}`);
  }

  public getRiderStatisticValues(riderId: number): Observable<any> {
    return forkJoin({
      commonStats: this.getStats(riderId),
      rideCounts: this.getRidesCount(riderId),
      ridesPerWeek: this.getRidesPerWeekCount(riderId)
    });
  }

  public getRiderTrendsByRideType(userId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${userId}/trends`, { params: { type: rideType } });
  }

  public getRiderHabitScoreChart(userId: number, date?: number): Observable<any> {
    let timezoneRegion: string = getCurrentTimeZone();
    const params: any = { timezoneRegion };
    if (date) {
      params.date = date;
    }
    return this.http.get(`${this.riderApiUrl}/${userId}/trends/habit-score`, { params });
  }

  public uploadAvatar(riderId: number, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return new Observable(observer => {
      fetch(`${environment.url + this.riderApiUrl}/${riderId}/avatar-upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
      })
        .then(res => {
          return res.json();
        })
        .then(body => {
          observer.next(body);
          /*Complete the Observable as it won't produce any more event */
          observer.complete();
        });
    });
  }

  public updatePassword(riderId: number, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/password`, { oldPassword, newPassword });
  }

  public updateRiderInfo(riderId: number, riderInfo: any): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}`, riderInfo);
  }

  public getRidersLicenses(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/license`);
  }

  public updateLicenseAvatar(riderId: number, licenseId: number, fileUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileUpload);
    return new Observable(observer => {
      fetch(`${this.riderApiUrl}/${riderId}/license/${licenseId}/avatar`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
      })
        .then(res => {
          return res.json();
        })
        .then(body => {
          observer.next(body);
          /*Complete the Observable as it won't produce any more event */
          observer.complete();
        });
    });
  }

  public updateOwnedLicense(riderId: number, licenseData: AdminLicenseModel): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/license/${licenseData.id}`, licenseData);
  }

  public getRidersOwnedBikes(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/bike/owned`);
  }

  public getRiderOwnerMenuAvailable(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/license/menu-available`);
  }

  public getRiderStreaks(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/week-streaks`);
  }

  public getMonthlyRideCalendar(riderId: number, month: number, year: number): Observable<any> {
    let timezoneRegion: string = getCurrentTimeZone();
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/monthly/${year}/${month}`, { params: { timezoneRegion } });
  }

  public getMonthlyLastRides(riderId: number): Observable<any> {
    let timezoneRegion: string = getCurrentTimeZone();
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/calendar/last`, { params: { timezoneRegion } });
  }

  public getCaloriesByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/calories/ride-type/${rideType}`);
  }

  public getTotalPowerByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/total-power/ride-type/${rideType}`);
  }

  public getCalories(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/calories`);
  }

  public getPeakPowerByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power/ride-type/${rideType}`);
  }

  public getPeakPowerPerLbByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power-per-lb/ride-type/${rideType}`);
  }

  public getPeakPowerPerKgByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power-per-kg/ride-type/${rideType}`);
  }

  public getRiderPeakPowerStats(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power`);
  }

  public getRiderTotalPowerStats(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/total-power`);
  }

  public getRiderPeakPowerPerLbChartStats(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power-per-lb`);
  }

  public getRiderPeakPowerPerKgChartStats(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-power-per-kg`);
  }

  public getRiderMaximumAnaerobicPower(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/manp`);
  }

  public getRiderMaximumAerobicPower(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/maep`);
  }

  public getRiderFunctionalThresholdPower(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/ftp`);
  }

  public getRiderFitnessScore(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/os`);
  }

  public getRiderFitnessScorePerLb(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/os-per-lb`);
  }

  public getRiderFitnessScorePerKg(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/os-per-kg`);
  }

  public getRiderVO2Max(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/vo2max`);
  }

  public getRiderPeakHeartRate(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-hr`);
  }

  public getPeakHeartRateByRideType(riderId: number, rideType: RideTypes): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/peak-hr/ride-type/${rideType}`);
  }

  public getStatByNameAndRideId(riderId: number, rideId: number, statName: string) {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/${statName}/ride/${rideId}`);
  }

  private getStats(riderId: number) {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats`);
  }

  private getRidesCount(riderId: number) {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/ride-count`);
  }

  public getRidesPerWeekCount(userId: number) {
    return this.http.get(`${this.riderApiUrl}/${userId}/stats/rides-per-week`);
  }

  public getRiderLastRideAchievements(riderId: number) {
    return this.http.get(`${this.riderApiUrl}/${riderId}/stats/achievements/last`);
  }

  // public getRiderLocale(riderId: number) {
  //   return this.http.get(`${this.riderApiUrl}/${riderId}/locale`);
  // }

  public setRiderLocale(riderId: number, locale: 'EN' | 'DE' | 'ES') {
    return this.http.post(`${this.riderApiUrl}/${riderId}/locale`, { locale });
  }

  public getRiderTrainers(riderId: number, query?: QueryParamsModel): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/trainers`,
      { params: query as HttpParams });
  }

  public findRiderTrainer(riderId: number, data: string): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/trainers/search`, { data });
  }

  public assignRiderTrainer(riderId: number, email: string): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/trainers`, { email });
  }

  public deleteRiderTrainer(riderId: number, trainerId: number): Observable<any> {
    return this.http.delete(`${this.riderApiUrl}/${riderId}/trainers/${trainerId}`);
  }

  public getRidersTrainees(trainerId: number, query?: QueryParamsModel): Observable<any> {
    return this.http.get(`${this.trainerApiUrl}/${trainerId}/rider`,
      { params: query as HttpParams });
  }

  public appendRiderTrainee(trainerId: number, riderId: number): Observable<any> {
    return this.http.post(`${this.trainerApiUrl}/${trainerId}/rider/${riderId}`, undefined);
  }

  public declineRiderTrainee(trainerId: number, riderId: number): Observable<any> {
    return this.http.post(`${this.trainerApiUrl}/${trainerId}/rider/${riderId}/decline`, undefined);
  }

  /*
    @@ Get all about RIDES for rider
   */
  public getRidesByType(riderId: number, rideType: RideTypes, pagingData?: HttpParams): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/ride/type/${rideType}`, { params: pagingData });
  }

  public getRideDataByIdAndRiderId(rideId: number, riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/ride/${rideId}`);
  }

  public getLastRideByRiderId(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/ride/last`);
  }

  public deleteRideById(riderId: number, rideId: number): Observable<any> {
    return this.http.delete(`${this.riderApiUrl}/${riderId}/ride/${rideId}`);
  }

  public updatePushNotificationsToken(riderId: number, data: string): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/mobile-app-fcm-token`, { data });
  }

  public getBikeInfo(riderId: number, bikeId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/bike/${bikeId}`);
  }

  /*
  @@ Follow
  */
  public appendFollow(riderId: number, observedRiderId: number): Observable<any> {
    return this.http.put(`${this.riderApiUrl}/${riderId}/follow/${observedRiderId}`, {});
  }

  public removeFollow(riderId: number, observedRiderId: number): Observable<any> {
    return this.http.delete(`${this.riderApiUrl}/${riderId}/follow/${observedRiderId}`, {});
  }
}


import {Component, Inject, OnInit} from '@angular/core';
import {CommonMessageModel} from '@carol-nx/data';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'carol-nx-common-error-modal',
  templateUrl: './common-error-modal.component.html',
  styleUrls: ['./common-error-modal.component.scss']
})
export class CommonErrorModalComponent implements OnInit {
  public error: CommonMessageModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CommonMessageModel) {
  }

  ngOnInit() {
    this.error = this.data;
  }
}

import { createAction, props } from '@ngrx/store';
import {
  AdminBikeCalibrationModel,
  AdminBikeInfoModel,
  AdminBikeModel,
  AdminBikeSettingsModel,
  AdminDiscountLimitModel,
  AdminDiscountModel,
  AdminLicenseModel,
  AdminQueriesModel,
  AdminRideModel,
  AdminRiderModel,
  BikeAppInfoModel,
  DiscountBase,
  ExtendedRideData,
  FirmwaresModel,
  MembershipUsageData,
  OwnerModel,
  PaymentInvoice,
  TablePagingData,
  TabletHistoryModel
} from '@carol-nx/data';
import { FirmwareType } from '../../../../data/src/lib/models/FirmwareType';

export const SetAdminRides = createAction(
  '[Admin] Set admin rides',
  props<{rides: AdminRideModel[]}>()
);

export const SetSelectedAdminRideId = createAction(
  '[Admin] Set selected admin ride id',
  props<{rideId: number}>()
);

export const SetAdminRidesPagingData = createAction(
  '[Admin] Set admin rides paging data',
  props<{ridesPaging: TablePagingData}>()
);

export const DeleteAdminRide = createAction(
  '[Admin] Delete admin ride',
  props<{rideId: number}>()
);

export const DeleteAdminRideSuccess = createAction(
  '[Admin] Delete admin ride success',
  props<{rideId: number}>()
);

export const RecalculateAdminRide = createAction(
  '[Admin] Recalculate admin ride',
  props<{bikeId: number, rideId: number}>()
);

export const RecalculateAdminRideSuccess = createAction(
  '[Admin] Recalculate admin ride success',
  props<{bikeId: number, rideId: number, ride: AdminRideModel}>()
);

export const GetAdminRidesList = createAction(
  '[Admin] Get rides list',
  props<{query?: AdminQueriesModel}>()
);

export const GetAdminRideExtended = createAction(
  '[Admin] Get extended ride',
  props<{rideId: number}>()
);

export const SetAdminRideExtended = createAction(
  '[Admin] Set extended ride',
  props<{extendedRide: ExtendedRideData}>()
);

export const GetAdminRidersList = createAction(
  '[Admin] Get admin riders list',
  props<{query?: AdminQueriesModel}>()
);

export const GetAdminRidersBySearchString = createAction(
  '[Admin] Get admin riders fresh list',
  props<{query?: AdminQueriesModel, doNotCloseModal?: boolean}>()
);

export const SetAdminRidersList = createAction(
  '[Admin] Set admin riders list',
  props<{riders: AdminRiderModel[]}>()
);

export const ResetAdminRidersList = createAction(
  '[Admin] Reset admin riders list',
  props<{riders: AdminRiderModel[]}>()
);

export const SetAdminRidersPagingData = createAction(
  '[Admin] Set admin riders paging data',
  props<{pagingData: TablePagingData}>()
);

export const SetSelectedAdminRiderId = createAction(
  '[Admin] Set selected rider id',
  props<{riderId: number}>()
);

export const GetAdminLicensesList = createAction(
  '[Admin] Get licenses list',
  props<{query?: AdminQueriesModel}>()
);

export const GetAdminLicensesListBySearchString = createAction(
  '[Admin] Get licenses list by search string',
  props<{query?: AdminQueriesModel}>()
);

export const GetAdminLicensesNamesBySearchString = createAction(
  '[Admin] Get licenses names by search string',
  props<{query?: AdminQueriesModel, doNotCloseModal?: boolean}>()
);

export const SetAdminLicensesList = createAction(
  '[Admin] Set licenses list',
  props<{licenses?: AdminLicenseModel[]}>()
);

export const ResetAdminLicensesList = createAction(
  '[Admin] Reset licenses list'
);

export const SetAdminLicensesPagingData = createAction(
  '[Admin] Set admin licenses paging data',
  props<{pagingData?: TablePagingData}>()
);

export const SetOwnerToLicense = createAction(
  '[Admin] Set owner to license',
  props<{license: AdminLicenseModel, newOwner: OwnerModel}>()
);

export const SetOwnerToLicenseSuccess = createAction(
  '[Admin] Set owner to license success',
  props<{license: AdminLicenseModel, newOwner: OwnerModel}>()
);

export const SetRiderToLicense = createAction(
  '[Admin] Set rider to license',
  props<{license: AdminLicenseModel, newRider: AdminRiderModel}>()
);

export const SetRiderToLicenseSuccess = createAction(
  '[Admin] Set rider to license success',
  props<{license: AdminLicenseModel, newRider: AdminRiderModel}>()
);

export const DeleteOwnerLicense = createAction(
  '[Admin] Delete owner from license',
  props<{licenseId: number, deleteOwner: OwnerModel | AdminRiderModel}>()
);

export const DeleteOwnerLicenseSuccess = createAction(
  '[Admin] Delete owner from license success',
  props<{licenseId: number, deleteOwner: OwnerModel | AdminRiderModel}>()
);

export const UpdateAdminRiderInfo = createAction(
  '[Admin] Update rider info',
  props<{riderId?: number, riderInfo: any}>()
);

export const UpdateAdminRiderInfoSuccess = createAction(
  '[Admin] Update rider info success',
  props<{rider: AdminRiderModel}>()
);

export const DeleteAdminRider = createAction(
  '[Admin] Delete admin rider',
  props<{riderId: number}>()
);

export const DeleteAdminRiderSuccess = createAction(
  '[Admin] Delete admin rider success',
  props<{riderId: number}>()
);

export const UnlockAdminRider = createAction(
  '[Admin] Unlock rider',
  props<{riderId: number}>()
);

export const SetAdminRiderLock = createAction(
  '[Admin] Set admin rider locked',
  props<{riderId: number, reason: string}>()
);

export const SetAdminRiderRole = createAction(
  '[Admin] Set admin rider role (admin)',
  props<{riderId: number, admin: boolean}>()
);

export const SetServiceRiderRole = createAction(
  '[Admin] Set service rider role (admin)',
  props<{riderId: number, service: boolean}>()
);

export const SetFactoryRiderRole = createAction(
  '[Admin] Set factory rider role (admin)',
  props<{riderId: number, factory: boolean}>()
);

export const SetSupportRiderRole = createAction(
  '[Admin] Set support rider role (admin)',
  props<{riderId: number, support: boolean}>()
);

export const UpdateAdminRiderFieldSuccess = createAction(
  '[Admin] Update rider field success',
  props<{riderId: number, riderPart: any}>()
);
export const SetAdminBikesLoading = createAction(
  '[Admin] Set bikes list loading status',
  props<{isLoading: boolean}>()
);

export const SetSelectedAdminBikeId = createAction(
  '[Admin] Set selected bike id',
  props<{selectedBikeId: number}>()
);

export const GetAdminBikesPage = createAction(
  '[Admin] Get bikes page',
  props<{query?: AdminQueriesModel}>()
);

export const SetAdminBikesPage = createAction(
  '[Admin] Set bikes page',
  props<{bikesData: AdminBikeModel[]}>()
);

export const ResetAdminBikes = createAction(
  '[Admin] Reset bikes list',
  props<{bikesData?: AdminBikeModel[]}>()
);

export const ResetAdminBikesForModal = createAction(
  '[Admin] Reset bikes list for modal',
  props<{ bikesData?: AdminBikeModel[] }>()
);

export const SetAdminBikesPagingData = createAction(
  '[Admin] Set bikes paging data',
  props<{pagingData: TablePagingData}>()
);

export const GetAdminBikesBySearchString = createAction(
  '[Admin] Get admin bikes fresh list',
  props<{query?: AdminQueriesModel, doNotCloseModal?: boolean}>()
);

export const UpdateAdminBike = createAction(
  '[Admin] Update bike information and calibration',
  props<{bikeId?: number, bike: AdminBikeInfoModel & AdminBikeCalibrationModel}>()
);

export const UpdateAdminBikeInfo = createAction(
  '[Admin] Update bike information',
  props<{bikeId?: number, bike: AdminBikeInfoModel}>()
);

export const UpdateAdminBikeInfoSuccess = createAction(
  '[Admin] Update bike information success',
  props<{bike: AdminBikeModel}>()
);

export const UpdateAdminBikeCalibration = createAction(
  '[Admin] Update bike calibration',
  props<{bikeId?: number, statusUpdate: string, bike: AdminBikeCalibrationModel}>()
);

export const UpdateAdminBikeCalibrationSuccess = createAction(
  '[Admin] Update bike calibration success',
  props<{bikeId?: number, bike: AdminBikeModel}>()
);

export const SetSelectedAdminLicenseId = createAction(
  '[Admin] Set selected license id',
  props<{selectedLicenseId: number}>()
);

export const SetSelectedAdminLicense = createAction(
  '[Admin] Set selected license',
  props<{selectedLicense: AdminLicenseModel}>()
);

export const UpdateAdminLicense = createAction(
  '[Admin] Update license',
  props<{licenseInfo: AdminLicenseModel}>()
);

export const UpdateAdminLicenseAvatar = createAction(
  '[Admin] Update license avatar',
  props<{licenseId: number, fileUpload: any}>()
);

export const UpdateAdminLicenseSuccess = createAction(
  '[Admin] Update license success',
  props<{licenseData: AdminLicenseModel}>()
);

export const UpdateBikeApp = createAction(
  '[Admin] Update bike application',
  props<{bikeAppInfo: any}>()
);

export const GetBikeAppInfo = createAction(
  '[Admin] Get bike application info',
);

export const SetBikeAppInfo = createAction(
  '[Admin] Set bike application info',
  props<{bikeAppInfo: BikeAppInfoModel}>()
);

export const GetAdminBikeDetail = createAction(
  '[Admin] Get admin bike detail',
  props<{bikeId: number}>()
);

export const SetAdminBikeDetail = createAction(
  '[Admin] Set admin bike detail',
  props<{bikeDetail: AdminBikeModel}>()
);


export const GetAdminBikeTabletHistory = createAction(
  '[Admin] Get admin tablet history',
  props<{bikeId?: number}>()
);

export const SetAdminBikeTabletHistory = createAction(
  '[Admin] Set admin tablet history',
  props<{tabletHistory?: TabletHistoryModel}>()
);

export const UpdateCalfactors = createAction(
  '[Admin] Update calfactors',
  props<{calfactors: any}>()
);

export const GetAdminAppVersions = createAction(
  '[Admin] Get admin app versions',
);

export const SetAdminAppVersions = createAction(
  '[Admin] Set admin app versions',
  props<{appVersions?: string[]}>()
);


export const SetAdminRiderConfirm = createAction(
  '[Admin] Confirm rider',
  props<{riderId: number}>()
);

export const SetAdminRiderReset = createAction(
  '[Admin] Reset rider',
  props<{riderId: number}>()
);

export const SetAdminRiderResetSuccess = createAction(
  '[Admin] Reset rider success',
  props<{riderId: number}>()
);

export const SetAdminRiderResetResistance = createAction(
  '[Admin] Reset rider resistance',
  props<{riderId: number}>()
);

export const SetAdminRiderResetResistanceSuccess = createAction(
  '[Admin] Reset rider resistance success',
  props<{riderId: number}>()
);

export const GetAdminFrame = createAction(
  '[Admin] Get bike frame',
  props<{frameId: number}>()
);

export const GetAdminFrameSuccess = createAction(
  '[Admin] Get bike frame success',
  props<{frame: AdminBikeCalibrationModel}>()
);

export const DeleteAdminRiderFromLicense = createAction(
  '[Admin] Delete admin rider from license',
  props<{licenseId: number, riderId: number}>()
);

export const DeleteAdminRiderFromLicenseSuccess = createAction(
  '[Admin] Delete admin rider from license success',
  props<{licenseId: number, riderId: number}>()
);

export const UpdateAdminBikeLicense = createAction(
  '[Admin] Update bike license',
  props<{bikeId: number, license: AdminLicenseModel}>()
);

export const UpdateAdminBikeLicenseSuccess = createAction(
  '[Admin] Update bike license success',
  props<{bikeId: number, license: AdminLicenseModel}>()
);

export const MoveRideToRider = createAction(
  '[Admin] move ride to rider',
  props<{rider: {id: number, nickname: string}, rideId: number}>()
);

export const MoveRideToRiderSuccess = createAction(
  '[Admin] move ride to rider success',
  props<{rider: {id: number, nickname: string}, rideId: number}>()
);

export const GetAdminRiderDetail = createAction(
  '[Admin] Get admin rider detail',
  props<{riderId: number}>()
);

export const SetAdminRiderDetail = createAction(
  '[Admin] Set admin rider detail',
  props<{riderDetail: AdminRiderModel}>()
);

export const CreateAdminLicense = createAction(
  '[Admin] Create admin license',
  props<{name: string, licenseType: string}>()
);

export const CreateAdminLicenseSuccess = createAction(
  '[Admin] Create admin license success',
  props<{newLicense: AdminLicenseModel}>()
);

export const GetAdminBikeSettings = createAction(
  '[Admin] Get admin bike settings',
  props<{bikeId: number}>()
);

export const GetAdminBikeSettingsSuccess = createAction(
  '[Admin] Get admin bike settings success',
  props<{bikeSettings: AdminBikeSettingsModel}>()
);

export const UpdateAdminBikeSettings = createAction(
  '[Admin] Update admin bike settings',
  props<{bikeId: number, bikeSettings: AdminBikeSettingsModel}>()
);

export const UpdateAdminBikeSettingsSuccess = createAction(
  '[Admin] Update admin bike settings success',
  props<{bikeSettings: AdminBikeSettingsModel}>()
);

export const SetAdminRiderPassword = createAction(
  '[Admin] Set admin rider password',
  props<{riderId: number, newPassword: string}>()
);

export const SetAdminRiderPasswordSuccess = createAction(
  '[Admin] Set admin rider password success',
  props<{riderId: number}>()
);

export const GetAdminLicenseDiscount = createAction(
  '[Admin] Get admin licenes discount',
  props<{licenseId: number}>()
);

export const SetAdminLicenseDiscount = createAction(
  '[Admin] Set admin licenes discount',
  props<{discount: AdminDiscountModel}>()
);

export const UpdateAdminLicenseDiscount = createAction(
  '[Admin] Update admin licenes discount',
  props<{licenseId:number, discount: DiscountBase}>()
);

export const UpdateAdminLicenseNeedSubscription = createAction(
  '[Admin] Update admin licenes need subscription',
  props<{licenseId:number, needSubscription: boolean}>()
);

export const SetAdminLicenseNeedSubscription = createAction(
  '[Admin] Set admin licenes need subscription',
  props<{licenseId:number, needSubscription: boolean}>()
);

export const UpdateAdminLicenseResetSubscription = createAction(
  '[Admin] Update admin licenes reset subscription',
  props<{licenseId:number}>()
);

export const SetAdminLicenseResetSubscription = createAction(
  '[Admin] Set admin licenes reset subscription',
  props<{license:AdminLicenseModel}>()
);

export const UpdateAdminLicenseResetCardSubscription = createAction(
  '[Admin] Update admin licenes reset card subscription',
  props<{licenseId:number}>()
);

export const GetAdminLicenseDiscountLimit = createAction(
  '[Admin] Get admin licenes discount limit',
  props<{licenseId: number}>()
);

export const SetAdminLicenseDiscountLimit = createAction(
  '[Admin] Set admin licenes discount limit',
  props<{discountLimit: AdminDiscountLimitModel}>()
);

export const GetAdminLicensePaymentInvoices = createAction(
  '[Admin] Get admin licene payment invoices',
  props<{licenseId: number}>()
);

export const SetAdminLicensePaymentInvoices = createAction(
  '[Admin] Set admin licene payment invoices',
  props<{paymentInvoices: PaymentInvoice[]}>()
);

export const GetAdminLicenseHideSubscription = createAction(
  '[Admin] Get admin licene hide subscription',
  props<{licenseId: number}>()
);

export const SetAdminLicenseHideSubscription = createAction(
  '[Admin] Set admin licene hide subscription',
  props<{licenseId: number, hideSubscription: boolean}>()
);

export const UpdateAdminLicenseHideSubscription = createAction(
  '[Admin] Update admin licene hide subscription',
  props<{licenseId: number, hideSubscription: boolean}>()
);

export const GetMembershipStats = createAction(
  '[Admin] Get membership stats'
);

export const SetMembershipStats = createAction(
  '[Admin] Set membership stats',
  props<{membershipStats: number}>()
);

export const UpdateBikeFirmware = createAction(
  '[Admin] Update bike firmware',
  props<{firmwareType: FirmwareType, bikeFirmwareInfo: any}>()
);

export const GetBikeFirmwareInfo = createAction(
  '[Admin] Get bike firmwares info',
);

export const SetBikeFirmwareInfo = createAction(
  '[Admin] Set bike firmware info',
  props<{firmwareType: FirmwareType, bikeFirmwareInfo: BikeAppInfoModel}>()
);

export const SetBikeFirmwares = createAction(
  '[Admin] Set bike firmwares',
  props<{bikeFirmwares: FirmwaresModel}>()
);

export const SetAdminMembershipUsageDataLoading = createAction(
  '[Admin] Set membership usage data loading',
  props<{isLoading: boolean}>()
);

export const GetAdminMembershipUsageList = createAction(
  '[Admin] Get membership usage list',
);

export const SetAdminMembershipUsageList = createAction(
  '[Admin] Set membership usage list',
  props<{usageData: MembershipUsageData[]}>()
);

<div class="page-wrapper animated fadeIn">
  <mat-card class="mat-wrapper">
    <mat-card-title>
      <div>{{'DASHBOARD_INFO_ACTIVITY_RIDE_COUNT' | translate:({totalRides: (userStats | async)?.rideCounts.rideCount})}}</div>
    </mat-card-title>
    <mat-card-content>
      <carol-nx-total-rides-card *ngIf="(ridesChartData | async)?.chartData as chartData"
                                 [validSubscription]="chartData.validSubscription">
      </carol-nx-total-rides-card>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-wrapper calendar">
    <mat-card-title>
      <div>{{'DASHBOARD_INFO_ACTIVITY_MONTHLY_ACTIVITY' | translate}}</div>
    </mat-card-title>
    <mat-card-content>
      <carol-nx-activity-calendar></carol-nx-activity-calendar>
    </mat-card-content>
  </mat-card>

  <carol-habit-score-card></carol-habit-score-card>

  <mat-card class="mat-wrapper">
    <mat-card-title>
      <div>{{'DASHBOARD_INFO_ACTIVITY_STREAKS' | translate}}</div>
    </mat-card-title>
    <mat-card-content class="mat-streaks">
      <div class="streaks-list" *ngIf="(userStreaks | async) as streaks">
        <div class="item">
          <div class="label">{{pluralize(streaks.currentStreak, 'DASHBOARD_INFO_ACTIVITY_WEEK' | translate)}}</div>
          <div class="text">{{'DASHBOARD_INFO_ACTIVITY_CURRENT_STREAK' | translate}}</div>
        </div>
        <div class="item">
          <div class="label">{{pluralize(streaks.longestStreak, 'DASHBOARD_INFO_ACTIVITY_WEEK' | translate)}}</div>
          <div class="text">{{'DASHBOARD_INFO_ACTIVITY_LONGEST_STREAK' | translate}}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <carol-nx-dashboard-chart [data]="ridesChartData | async"></carol-nx-dashboard-chart>
</div>

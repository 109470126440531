import {createAction, props} from '@ngrx/store';
import {BookingBikeModel, BookingModel, TimeSlotModel} from '@carol-nx/data';

export const GetBookingsList = createAction(
  '[Booking] Get booking list'
);

export const SetBookingsList = createAction(
  '[Booking] Reset bookings list',
  props<{bookings: BookingModel[]}>()
);

export const CreateBooking = createAction(
  '[Booking] Create booking',
  props<{bikeId: number, fromTime: number, success?: (resp?: any) => void, onError?: (resp?: any) => void}>()
);

export const DeleteBooking = createAction(
  '[Booking] Delete booking',
  props<{bookingId: number}>()
);

export const DeleteBookingFromList = createAction(
  '[Booking] Delete booking from list',
  props<{bookingId: number}>()
);

export const GetBikesForBooking = createAction(
  '[Booking] Get bikes for booking',
);

export const SetBikesForBooking = createAction(
  '[Booking] Set bikes for booking',
  props<{bookingBikes: BookingBikeModel[]}>()
);

export const GetBookedTimeSlotsForBike = createAction(
  '[Booking] Get booked time slots'
);

export const SetBookedTimeSlotsForBike = createAction(
  '[Booking] Set booked time slots',
  props<{bookedSlots: TimeSlotModel[]}>()
);

export const SetAvailableTimeSlotsForBike = createAction(
  '[Booking] Set available time slots',
  props<{availableBookingSlots: TimeSlotModel[]}>()
);

export const SetSelectedLocation = createAction(
  '[Booking] Set selected location',
  props<{location: string}>()
);

export const SetSelectedBikeId = createAction(
  '[Booking] Set selected bike id',
  props<{bikeId: number}>()
);

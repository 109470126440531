import {AppState} from '../state';
import {createSelector} from '@ngrx/store';

const selectInfoState = (state: AppState) => state && state.info;

export const selectCountries = createSelector(
  selectInfoState,
  (state) => state.countries
);

export const selectFilteredCountries = createSelector(
  selectCountries,
  (countries, props) => countries && countries.filter(el => el && el.name.toUpperCase().includes(props.name.toUpperCase()))
);

export const selectOpenedModal = createSelector(
  selectInfoState,
  (state => state.openedModal)
);

export const selectCommonError = createSelector(
  selectInfoState,
  (state => state.error)
);

export const selectCommonSuccess = createSelector(
  selectInfoState,
  (state => state.success)
);

export const selectTheme = createSelector(
  selectInfoState,
  (state => state.theme)
);

export const selectNetworkStatus = createSelector(
  selectInfoState,
  state => state.connected
);

export const selectPageTitle = createSelector(
  selectInfoState,
  state => state.pageTitle
);

export  const selectTableDataLoadingStatus = createSelector(
  selectInfoState,
  state => state.tableDataLoaded
)

import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, RiderSelectors } from '@carol-nx/store';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  private riderCountryId = 222;

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
              @Inject(Platform) platform: Platform,
              @Inject(Store) store: Store<AppState>,
              @Inject(DatePipe) private datePipe: DatePipe
  ) {
    super(dateLocale, platform);

    store.select(RiderSelectors.selectRider).pipe(filter(rider => !!rider)).subscribe(rider => {
      this.riderCountryId = rider.countryId;
    });
  }

  format(date: Date, displayFormat: any): string {
    return this.datePipe.transform(date, displayFormat);
  }

  getFirstDayOfWeek(): number {
    return this.isFirstSunday() ? 0 : 1;
  }

  public isFirstSunday(): boolean {
    try {
      return new Intl['Locale'](navigator.language).weekInfo.firstDay === 7;
    } catch (ignoredError) {
      return [9, 13, 16, 18, 22, 25, 29, 30, 35, 37, 43, 44, 63, 67, 86, 91, 95, 96, 100, 102, 103, 106, 108, 109, 133,
        141, 143, 145, 149, 151, 157, 160, 162, 163, 164, 166, 180, 183, 194, 204, 207, 222, 227, 232, 234].includes(this.riderCountryId);
    }
  }
}

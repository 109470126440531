import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import {
  ChartType,
  GaugeChartModel,
  InnerCardDataModel,
  NeedRideTypes,
  PowerStatsChartModel,
  PowerTypes,
  RideTypes
} from '@carol-nx/data';
import { formatChartData } from '@carol-nx/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component } from '@angular/core';
import { PowerService } from '@carol-nx/services';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  template: ''
})
export class DashboardCardsMainComponent {
  public cards: BehaviorSubject<InnerCardDataModel[]> = new BehaviorSubject<InnerCardDataModel[]>([]);
  public neededRideTypes: NeedRideTypes[];
  public chartType: ChartType;

  constructor(protected store: Store<AppState>) {
  }

  init(): void {
    const selector = this.getSelector();
    this.store.dispatch(RiderActions.GetChartsByRideTypes({
      chartType: this.chartType,
      rideTypes: this.neededRideTypes.map(m => m.rideType)
    }));

    this.store.select(selector)
      .pipe(untilDestroyed(this)).subscribe(resp => {
      const cardsArr = [];
      for (const rideTypeString in resp) {
        const rideType = rideTypeString as RideTypes;
        const needRideType = this.neededRideTypes.find(f => f.rideType === rideType);
        const powerTitle = PowerService.getNeededPowerTitle(rideType);
        const chartStats: GaugeChartModel = { ...resp[rideType] };
        if (!chartStats.rideType)
          chartStats.rideType = rideType;
        cardsArr.push({
          title: powerTitle,
          chartData: formatChartData(this.chartType, chartStats, this.getMetricTitle(this.chartType)),
          route: needRideType?.route,
          routeTitle: needRideType?.title
        });
      }
      this.drawCards(cardsArr);
    }, () => {
      this.drawCards([]);
    });
  }

  private getMetricTitle(chartType: ChartType): string | undefined {
    switch (chartType) {
      case ChartType.PeakPower:
        return 'DASHBOARD_INFO_POWER_PEAK_POWER';
      case ChartType.PeakPowerPerKG:
        return 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_KG';
      case ChartType.PeakPowerPerLB:
        return 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_KG';
      case ChartType.PeakHeartRate:
        return 'DASHBOARD_INFO_FITNESS_PEAK_HEART_RATE';
      case ChartType.EnergyOutput:
        return 'DASHBOARD_INFO_ENERGY';
      default:
        return undefined;
    }
  }

  private drawCards(cardsArr: InnerCardDataModel[]) {
    this.cards.next(cardsArr);
  }

  private getSelector(): MemoizedSelector<AppState, Record<PowerTypes, PowerStatsChartModel>, DefaultProjectorFn<Record<PowerTypes, PowerStatsChartModel>>> {
    switch (this.chartType) {
      case ChartType.PeakPower:
        return RiderSelectors.selectRiderPeakPower;
      case ChartType.PeakPowerPerLB:
        return RiderSelectors.selectRiderPeakPowerPerLb;
      case ChartType.PeakPowerPerKG:
        return RiderSelectors.selectRiderPeakPowerPerKg;
      case ChartType.EnergyOutput:
        return RiderSelectors.selectRiderTotalPower;
      case ChartType.PeakHeartRate:
        return RiderSelectors.selectRiderPeakHeartRate;
      case ChartType.CaloriesInclEPOC:
      case ChartType.CaloriesExclEPOC:
        return RiderSelectors.selectRiderCaloriesByRideTypeData;
    }
  }
}

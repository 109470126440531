import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { LoaderDialogComponent } from '@carol-nx/ui';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private loaderInstance: MatDialogRef<LoaderDialogComponent>;
  private mediumConf: MatDialogConfig = {};

  constructor(private dialog: MatDialog) {
  }

  public showLoader() {
    if (!this.loaderInstance || this.loaderInstance.getState() !== MatDialogState.OPEN)
      this.loaderInstance = this.dialog.open(LoaderDialogComponent, { disableClose: true, hasBackdrop: true });
  }

  public hideLoader() {
    if (this.loaderInstance)
      this.loaderInstance.close();
  }

  public openCertainModal(neededComponent: TemplateRef<any> | ComponentType<any>, conf?: MatDialogConfig) {
    if (conf) {
      this.mediumConf.data = { component: neededComponent, ...conf.data };
    }
    const modalConf = { ...this.mediumConf, ...conf };
    return this.dialog.open(neededComponent, modalConf);
  }

  public closeModal(): Observable<any> {
    this.dialog.closeAll();
    return of(null);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface MessageModalData {
  title: string;
  description: string;
  buttonText?: string;
}

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageModalData) { }

  ngOnInit() {
  }

  get buttonText() {
    return this.data.buttonText ? this.data.buttonText : 'Ok';
  }

}

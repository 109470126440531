import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartType, LastRideAchievements } from '@carol-nx/data';
import { OwnerService, RiderService } from '@carol-nx/services';
import { buildPagingObject, setInLocalStorage, verboseErrorMessage } from '@carol-nx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthActions, InfoActions, LeaderboardActions, RiderActions } from '../actions';
import { AppState } from '../state';
import { EffectsMain } from './effects-main';

@Injectable()
export class RiderEffects extends EffectsMain {

  getRiderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiderActions.GetRiderData),
      switchMap((action) => {
          const token = localStorage.getItem('accessToken');
          return this.riderService.getRiderById(action.riderId)
            .pipe(
              switchMap(data => {
                const riderData = Object.assign({ riderId: action.riderId }, data);
                const action1 = AuthActions.LogInSuccess({ tokens: token });
                const action2 = RiderActions.SetRiderData({ riderData });
                return of(action2, action1);
              }),
              catchError(err => of(RiderActions.GetRiderError({ err })))
            );
        }
      )
    )
  );

  getRiderStatisticValues$ = this.createEffectSwitchMap(RiderActions.GetRiderStatisticValues, (action, riderId) => this.riderService.getRiderStatisticValues(riderId)
    .pipe(
      map(data => RiderActions.SetRiderStatisticValues({ stats: data })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getRiderLastRideAchievements$ = this.createEffectSwitchMap(RiderActions.GetRiderLastRideAchievements, (action, riderId) => this.riderService.getRiderLastRideAchievements(riderId)
    .pipe(
      map(data => RiderActions.SetRiderLastRideAchievements({ lastRideAchievements: data as LastRideAchievements })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getRiderStreaks$ = this.createEffectSwitchMap(RiderActions.GetRiderStreaks, (action, riderId) => this.riderService.getRiderStreaks(riderId)
    .pipe(
      map(streaks => RiderActions.SetRiderStreaks({ streaks }))
    ));

  getRidersLicenses$ = this.createEffectSwitchMap(RiderActions.GetRidersLicenses, (action, riderId) => this.riderService.getRidersLicenses(riderId ? riderId : action.riderId)
    .pipe(
      switchMap(resp => {
        if (action.callBack) {
          action.callBack();
        }
        return of(RiderActions.SetRidersLicenses({ licenses: resp }));
      })
    ), true);

  getRidersOwnedLicenses$ = this.createEffectWithMergeMap(RiderActions.GetRidersOwnedLicenses,
    (action, riderId) => this.ownerService.getRidersOwnedLicenses(riderId ? riderId : action.riderId)
      .pipe(
        map(resp => RiderActions.SetRidersOwnedLicenses({ownedLicenses: resp}))
      ),
    undefined,
    true
  );

  getRiderMonthlyActivity$ = this.createEffectSwitchMap(RiderActions.GetRiderMonthlyActivity, (action, riderId) => this.riderService.getMonthlyRideCalendar(riderId, action.month, action.year)
    .pipe(
      switchMap(data => data.datesWithRides &&
        of(RiderActions.SetRiderMonthlyActivity({ datesWithRides: data.datesWithRides })))
    ));

  getRiderLastActivity$ = this.createEffectSwitchMap(RiderActions.GetRiderLastActivity, (action, riderId) => this.riderService.getMonthlyLastRides(riderId)
    .pipe(
      switchMap(data => data.datesWithRides &&
        of(RiderActions.SetRiderMonthlyActivity({ datesWithRides: data.datesWithRides })))
    ));

  uploadRiderAvatar$ = this.createEffectWithLatestSwitchMap(RiderActions.UploadRiderAvatar, (action, riderId) => this.riderService.uploadAvatar(riderId, action.file)
    .pipe(
      map(data => RiderActions.SetRiderData({ riderData: { avatarPath: data.avatarPath + '?v=' + new Date().getUTCMilliseconds() } })),//todo remove v, avatar path is unique
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  updateRiderInfo$ = this.createEffectSwitchMap(RiderActions.UpdateRiderInfo, (action, riderId) => this.riderService.updateRiderInfo(riderId, action.riderInfo)
    .pipe(
      map(data => {
        if (data) {
          setInLocalStorage(data);
          this.store.dispatch(AuthActions.LogInSuccess(data));
        }
        if (action.onSuccess) {
          action.onSuccess(data);
        }
        return RiderActions.SetRiderData({ riderData: action.riderInfo });
      }),
      catchError(err => {
        if (action.onError) {
          action.onError(err);
        }
        return of(RiderActions.GetRiderError({ err }));
      })
    ));

  getRiderError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiderActions.GetRiderError),
      switchMap(action => of(InfoActions.SetCommonError({ message: action?.err?.error?.message })))
    )
  );

  updatePassword$ = this.createEffectSwitchMap(RiderActions.UpdatePassword, (action, riderId) => this.riderService.updatePassword(riderId, action.oldPassword, action.newPassword)
    .pipe(
      switchMap(resp => {
          setInLocalStorage(resp);
          const action1 = AuthActions.LogInSuccess(resp);
          if (action.callback) {
            action.callback(resp);
          }
          return of(action1);
        }
      ),
      catchError(({ error }) => {
        action.callback({ isError: true, message: verboseErrorMessage(error.message) });
        return of(InfoActions.SetCommonError({
          title: 'Error',
          message: verboseErrorMessage(error.message)
        }));
      })
    ));

  getOwnerMenuAvailable$ = this.createEffectSwitchMap(RiderActions.GetOwnerMenuAvailable, (action, riderId) => this.riderService.getRiderOwnerMenuAvailable(riderId)
    .pipe(
      map(data => RiderActions.SetOwnerMenuAvailable({ available: data.is })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ), true);

  getOwnedBikes$ = this.createEffectSwitchMap(RiderActions.GetOwnedBikes, (action, riderId) => this.riderService.getRidersOwnedBikes(riderId)
    .pipe(
      map(data => RiderActions.SetOwnedBikes({ bikes: data })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  updateOwnedBike$ = this.createEffectSwitchMap(RiderActions.UpdateOwnedBike, (action, riderId) => this.ownerService.updateOwnedBike(riderId, action.bikeId, action.bike)
    .pipe(
      switchMap(data => {
        this.openSnackBar('Bike updated!');
        const action1 = RiderActions.UpdateOwnedBikeSuccess({ bikeId: action.bikeId, bike: action.bike });
        return of(action1);
      }),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));


  getOwnedRidersPage$ = this.createEffectWithLatestSwitchMap(RiderActions.GetOwnedRidersPage, (action, riderId) => this.ownerService.getOwnedRidersById(riderId, action.query)
    .pipe(
      switchMap(resp => {
        const pagingDataObject = buildPagingObject(resp);
        const action1 = RiderActions.SetOwnedRiders({ riders: resp.content });
        const action2 = RiderActions.SetRidersPagingData({ pagingData: pagingDataObject });
        return of(action1, action2);
      }),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getOwnedRidersBySearchString$ = this.createEffectWithLatestSwitchMap(RiderActions.GetOwnedRidersBySearchString, (action, riderId) => this.ownerService.getOwnedRidersById(riderId, action.query)
    .pipe(
      switchMap(resp => {
        const pagingDataObject = buildPagingObject(resp);
        const action1 = RiderActions.ResetOwnedRiders({ riders: resp.content });
        const action2 = RiderActions.SetRidersPagingData({ pagingData: pagingDataObject });
        return of(action1, action2);
      }),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  updateOwnedLicense$ = this.createEffectSwitchMap(RiderActions.UpdateOwnedLicense, (action, riderId) => this.riderService.updateOwnedLicense(riderId, action.licenseInfo)
    .pipe(
      map(data => {
        if (!data.error) {
          this.openSnackBar('Membership updated!');
          return RiderActions.UpdateOwnedLicenseSuccess({ license: data });
        }
        return InfoActions.SetCommonError({ message: verboseErrorMessage(data.error.message) });
      }),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  updateOwnedLicenseAvatar$ = this.createEffectSwitchMap(RiderActions.UpdateOwnedLicenseAvatar, (action, riderId) => this.riderService.updateLicenseAvatar(riderId, action.licenseId, action.fileUpload)
    .pipe(
      switchMap(data => {
        this.openSnackBar('Membership is updated!');
        return of(RiderActions.UpdateOwnedLicenseSuccess({ license: data }));
      }),
      catchError(err => of(InfoActions.SetCommonError({
        title: 'Error',
        message: 'Membership could not changed. Please try again later'
      })))
    ));

  setOwnerToLicense$ = this.createEffectSwitchMap(RiderActions.SetOwnerToLicense, (action, riderId) => this.ownerService.setOwnerToLicense(action.licenseId, action.newOwner.id)
    .pipe(
      switchMap(resp => {
        const action1 = InfoActions.SetCommonSuccess({
          title: 'Success',
          message: 'User was successfully assigned to membership'
        });
        const action2 = RiderActions.SetOwnerToLicenseSuccess({
          licenseId: action.licenseId,
          newOwner: action.newOwner
        });
        return of(action1, action2);
      }),
      catchError(err => of(InfoActions.SetCommonError({
        title: 'Error',
        message: verboseErrorMessage(err.error.message)
      })))
    ));


  deleteOwnerLicense$ = this.createEffectSwitchMap(RiderActions.DeleteOwnerLicense, (action, riderId) => this.ownerService.deleteOwnerFromLicense(action.licenseId, action.deleteOwner.id)
    .pipe(
      switchMap(resp => {
        const action1 = InfoActions.SetCommonSuccess({
          title: 'Success',
          message: `User ${action.deleteOwner.nickname} has been successfully deleted from membership owners`
        });
        const action2 = RiderActions.DeleteOwnerLicenseSuccess({
          licenseId: action.licenseId,
          deleteOwner: action.deleteOwner
        });
        if (riderId === action.deleteOwner.id) {
          const action3 = RiderActions.GetOwnerMenuAvailable({ riderId });
          return of(action1, action2, action3);
        } else {
          return of(action1, action2);
        }
      }),
      catchError(err => of(InfoActions.SetCommonError({
        title: 'Error',
        message: verboseErrorMessage(err.error.message)
      })))
    ));

  getRiderOwnedLicensesShortly$ = this.createEffectWithMergeMap(RiderActions.GetRiderOwnedLicensesShortly,
    (action, riderId) => this.ownerService.getRiderOwnedLicensesShortly(riderId ? riderId : action.riderId)
      .pipe(
        map(resp => RiderActions.SetRidersOwnedLicensesShortly({ownedLicensesShortly: resp})),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  getRiderTrendsByRideType$ = this.createEffectSwitchMap(RiderActions.GetRiderTrendsByRideType, (action, riderId) => this.riderService.getRiderTrendsByRideType(riderId, action.rideType)
    .pipe(
      map(trends => RiderActions.SetRidersTrendsData({ trends })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getRiderHabitScoreChart$ = this.createEffectSwitchMap(RiderActions.GetRiderHabitScoreChart, (action, riderId) => this.riderService.getRiderHabitScoreChart(riderId, action.date)
    .pipe(
      map(habitScoreChart => RiderActions.SetRidersHabitScoreChart({ habitScoreChart })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getCalories$ = this.createEffectWithMergeMap(RiderActions.GetCalories,
    (action, riderId) =>this.riderService.getCalories(riderId)
      .pipe(
        map(resp => RiderActions.SetCalories({ calories: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  getCaloriesByRideType$ = this.createEffectWithMergeMap(RiderActions.GetCaloriesByRideType,
    (action, riderId) => this.riderService.getCaloriesByRideType(riderId, action.rideType)
      .pipe(
        map(resp => RiderActions.SetCaloriesByRideType({
          caloriesData: resp,
          rideType: action.rideType
        })),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  GetPeakPowerByRideType$ = this.createEffectWithMergeMap(RiderActions.GetPeakPowerByRideType,
    (action, riderId) => this.riderService.getPeakPowerByRideType(riderId, action.rideType)
      .pipe(
        map(resp => RiderActions.SetPeakPowerByRideType({
          peakPower: resp,
          rideType: action.rideType
        })),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  GetPeakPowerPerLbByRideType$ = this.createEffectWithMergeMap(RiderActions.GetPeakPowerPerLbByRideType,
    (action, riderId) => this.riderService.getPeakPowerPerLbByRideType(riderId, action.rideType)
      .pipe(
        map(resp => RiderActions.SetPeakPowerPerLbByRideType({
          peakPowerPerLb: resp,
          rideType: action.rideType
        })),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  getRiderPeakPowerStats$ = this.createEffectSwitchMap(RiderActions.GetRiderPeakPowerStats, (action, riderId) => this.riderService.getRiderPeakPowerStats(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderPeakPowerStats({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getUserPeakPowerPerLbStats$ = this.createEffectSwitchMap(RiderActions.GetRiderPeakPowerPerLbStats, (action, riderId) => this.riderService.getRiderPeakPowerPerLbChartStats(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderPeakPowerPerLbStats({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getUserPeakPowerPerKgStats$ = this.createEffectSwitchMap(RiderActions.GetRiderPeakPowerPerKgStats, (action, riderId) => this.riderService.getRiderPeakPowerPerKgChartStats(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderPeakPowerPerKgStats({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getUserMaximumAnaerobicPower$ = this.createEffectSwitchMap(RiderActions.GetRiderMaximumAnaerobicPower, (action, riderId) => this.riderService.getRiderMaximumAnaerobicPower(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderMaximumAnaerobicPower({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getUserMaximumAerobicPower$ = this.createEffectSwitchMap(RiderActions.GetRiderMaximumAerobicPower, (action, riderId) => this.riderService.getRiderMaximumAerobicPower(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderMaximumAerobicPower({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getUserFunctionalThresholdPower$ = this.createEffectSwitchMap(RiderActions.GetRiderFunctionalThresholdPower, (action, riderId) => this.riderService.getRiderFunctionalThresholdPower(riderId)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderFunctionalThresholdPower({ stats: resp }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getTotalPowerByRideType$ = this.createEffectWithMergeMap(RiderActions.GetTotalPowerByRideType,
    (action, riderId) => this.riderService.getTotalPowerByRideType(riderId, action.rideType)
      .pipe(
        map(resp => RiderActions.SetTotalPowerByRideType({
          totalPower: resp,
          rideType: action.rideType
        })),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  getRiderTotalPowerStats$ = this.createEffectWithMergeMap(RiderActions.GetRiderTotalPowerStats,
    (action, riderId) => this.riderService.getRiderTotalPowerStats(riderId)
      .pipe(
        map(resp => RiderActions.SetRiderTotalPowerStats({ stats: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  getRiderFitnessScoreChartStats$ = this.createEffectSwitchMap(RiderActions.GetRiderFitnessScoreChartStats, (action, riderId) => this.riderService.getRiderFitnessScore(riderId)
    .pipe(
      map(resp => RiderActions.SetRiderFitnessScoreChartStats({ stats: resp })),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getRiderFitnessScorePerLbChartStats$ = this.createEffectWithMergeMap(RiderActions.GetRiderFitnessScorePerLbChartStats,
    (action, riderId) => this.riderService.getRiderFitnessScorePerLb(riderId)
      .pipe(
        map(resp => RiderActions.SetRiderFitnessScorePerLbChartStats({ stats: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  getRiderFitnessScorePerKgChartStats$ = this.createEffectWithMergeMap(RiderActions.GetRiderFitnessScorePerKgChartStats,
    (action, riderId) => this.riderService.getRiderFitnessScorePerKg(riderId)
      .pipe(
        map(resp => RiderActions.SetRiderFitnessScorePerKgChartStats({ stats: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  getRiderVO2MaxChartStats$ = this.createEffectWithMergeMap(RiderActions.GetRiderVO2MaxChartStats,
    (action, riderId) => this.riderService.getRiderVO2Max(riderId)
      .pipe(
        map(resp => RiderActions.SetRiderVO2MaxChartStats({ stats: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  getRiderPeakHeartRateChartStats$ = this.createEffectWithMergeMap(RiderActions.GetRiderPeakHeartRateChartStats,
    (action, riderId) => this.riderService.getRiderPeakHeartRate(riderId)
      .pipe(
        map(resp => RiderActions.SetRiderPeakHeartRateChartStats({ stats: resp })),
        catchError(err => of(RiderActions.GetRiderError({ err })))
      )
  );

  GetPeakHeartRateByRideType$ = this.createEffectWithMergeMap(RiderActions.GetPeakHeartRateByRideType,
    (action, riderId) => this.riderService.getPeakHeartRateByRideType(riderId, action.rideType)
      .pipe(
        map(resp => RiderActions.SetPeakHeartRateByRideType({
          peakHeartRate: resp,
          rideType: action.rideType
        })),
        catchError(err => of(RiderActions.GetRiderError({err})))
      )
  );

  GetChartsByRideTypes$ = this.createEffectSwitchMap(RiderActions.GetChartsByRideTypes, (action, riderId) => {
    const requests = [];
    for (const rideType of action.rideTypes) {
      let req: Observable<any>;
      switch (action.chartType) {
        case ChartType.PeakPower:
          req = this.riderService.getPeakPowerByRideType(riderId, rideType);
          break;
        case ChartType.PeakPowerPerLB:
          req = this.riderService.getPeakPowerPerLbByRideType(riderId, rideType);
          break;
        case ChartType.PeakPowerPerKG:
          req = this.riderService.getPeakPowerPerKgByRideType(riderId, rideType);
          break;
        case ChartType.EnergyOutput:
          req = this.riderService.getTotalPowerByRideType(riderId, rideType);
          break;
        case ChartType.PeakHeartRate:
          req = this.riderService.getPeakHeartRateByRideType(riderId, rideType);
          break;
        case ChartType.CaloriesInclEPOC:
        case ChartType.CaloriesExclEPOC:
          req = this.riderService.getCaloriesByRideType(riderId, rideType);
          break;
      }
      requests.push(req);
    }
    return forkJoin(requests).pipe(
      switchMap(resp => of(RiderActions.SetChartsByRideTypes({
        chartType: action.chartType,
        rideTypes: action.rideTypes,
        resp
      }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    );
  });

  unsubscribeRider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiderActions.UnsubscribeRider),
      switchMap(action => this.riderService.unsubscribe(action.riderId, action.uuid)
        .pipe(
          map(data => {
            if (!data) {
              this.openSnackBar('Rider unsubscribed!');
              if(action.onSuccess) {
                action.onSuccess();
              }
              return RiderActions.UnsubscribeRiderSuccess({riderId: action.riderId, uuid: action.uuid});
            }
            return InfoActions.SetCommonError({message: verboseErrorMessage(data.error.message)});
          }),
          catchError(err => of(RiderActions.GetRiderError({err})))
        )
      )
    )
  );

  setRiderPushNotificationToken$ = this.createEffectSwitchMap(RiderActions.SetRiderPushNotificationToken, (action, riderId) => this.riderService.updatePushNotificationsToken(riderId, action.token)
    .pipe(
      switchMap(resp => of(RiderActions.SetRiderPushNotificationTokenSuccess({ token: action.token }))),
      catchError(err => of(RiderActions.GetRiderError({ err })))
    ));

  getRiderBikeInfo$ = this.createEffectSwitchMap(RiderActions.GetRiderBikeInfo, (action, riderId) => this.riderService.getBikeInfo(riderId ? riderId : action.riderId, action.bikeId)
    .pipe(
      map(bike => RiderActions.SetSelectedBike({ bike }))
    ));

  // getRiderLocale$ = this.createEffectWithLatestSwitchMap(RiderActions.GetRiderLocale, (action, riderId) => this.riderService.getRiderLocale(riderId ? riderId : action.riderId)
  //   .pipe(
  //     switchMap((resp: any, riderId) => {
  //       console.log('getRiderLocale$', resp, riderId)
  //       return of(RiderActions.SetRiderLocale({
  //       riderId: riderId || action.riderId,
  //       locale: resp.locale
  //     }))})
  //   ));

  setRiderLocale$ = this.createEffectSwitchMap(RiderActions.SetRiderLocale, (action, riderId) => this.riderService.setRiderLocale(riderId ? riderId : action.riderId, action.locale)
    .pipe(
      switchMap(() => of(RiderActions.SetRiderLocaleSuccess({ locale: action.locale }))),
      catchError((err: any) => of(InfoActions.SetCommonError({ message: verboseErrorMessage(err.error?.message) })))
    ));

  getRiderTrainers$ = this.createEffectSwitchMap(RiderActions.GetRiderTrainers, (action, riderId) => this.riderService.getRiderTrainers(riderId, action.query).pipe(
    switchMap((resp: any) => of(RiderActions.SetRiderTrainers({
      trainers: resp.content,
      trainersPaging: buildPagingObject(resp)
    }))),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ), true);

  findRiderTrainer$ = this.createEffectSwitchMap(RiderActions.FindRiderTrainer, (action, riderId) => this.riderService.findRiderTrainer(riderId, action.email).pipe(
    filter((resp: any) => Array.isArray(resp) && !!resp.length),
    switchMap((resp: any) => of(RiderActions.AddRiderTrainer({ trainer: resp[0] }))),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ));

  assignRiderTrainer$ = this.createEffectSwitchMap(RiderActions.AssignRiderTrainer, (action, riderId) => this.riderService.assignRiderTrainer(riderId, action.email).pipe(
    switchMap((resp: any) => {
      if (action.callBack) {
        action.callBack();
      }
      return of(RiderActions.AssignRiderTrainerSuccess());
    }),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ));

  removeRiderTrainer$ = this.createEffectSwitchMap(RiderActions.DeleteRiderTrainer, (action, riderId) => this.riderService.deleteRiderTrainer(riderId, action.trainerId).pipe(
    switchMap((resp: any) => of(RiderActions.DeleteRiderTrainerSuccess({ trainerId: action.trainerId }))),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ));

  getRidersTrainees$ = this.createEffectSwitchMap(RiderActions.GetRidersTrainees, (action, riderId) => this.riderService.getRidersTrainees(riderId ? riderId : action.riderId, action.query).pipe(
    switchMap(resp => {
      if (action.callBack) {
        action.callBack();
      }
      return of(RiderActions.SetRiderTrainees({ trainees: resp }));
    }),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ), true);

  appendRidersTrainee$ = this.createEffectSwitchMap(RiderActions.AppendRiderTrainee, (action, trainerId) => this.riderService.appendRiderTrainee(trainerId ? trainerId : action.trainerId, action.riderId).pipe(
    switchMap(resp => {
      if (action.callBack) {
        action.callBack();
      }
      return of(RiderActions.AppendRiderTraineeSuccess());
    }),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ));

  declineRidersTrainee$ = this.createEffectSwitchMap(RiderActions.DeclineRiderTrainee, (action, trainerId) => this.riderService.declineRiderTrainee(trainerId ? trainerId : action.trainerId, action.riderId).pipe(
    switchMap(() => {
      if (action.callBack) {
        action.callBack();
      }
      return of(RiderActions.UnsetSelectedTrainee({}));
    }),
    catchError(err => of(RiderActions.GetRiderError({ err })))
  ));

  getSelectedTrainee$ = createEffect(() => this.actions$.pipe(
      ofType(RiderActions.GetSelectedTrainee),
      switchMap((action) => this.riderService.getRiderById(action.riderId).pipe(
          map(data => {
            if (!data.riderId) {
              return RiderActions.SetSelectedTrainee({ selectedTrainee: Object.assign({ riderId: action.riderId }, data) });
            }
          }),
          catchError(err => of(RiderActions.GetRiderError({ err })))
        )
      )
    )
  );

  appendFollow$ = this.createEffectWithLatestMergeMap(RiderActions.AppendFollow,
    (action, riderId) => this.riderService.appendFollow(riderId, action.observedRider.riderId).pipe(
      mergeMap(resp => {
        let props = {riderId, observedRider: action.observedRider, newState: true};
        let action1 = RiderActions.AppendFollowSuccess(props);
        let action2 = LeaderboardActions.SetFollowTopUser(props);
        return of(action1, action2);
      }),
      catchError(err => of(RiderActions.GetRiderError({err})))
    )
  );

  removeFollow$ = this.createEffectWithLatestMergeMap(RiderActions.RemoveFollow,
    (action, riderId) => this.riderService.removeFollow(riderId, action.observedRider.riderId).pipe(
      mergeMap(resp => {
        let props = {riderId, observedRider: action.observedRider, newState: false};
        let action1 = RiderActions.RemoveFollowSuccess(props);
        let action2 = LeaderboardActions.SetFollowTopUser(props);
        return of(action1, action2);
      }),
      catchError(err => of(RiderActions.GetRiderError({err})))
    )
  );

  constructor(protected actions$: Actions,
              protected store: Store<AppState>,
              private riderService: RiderService,
              private ownerService: OwnerService,
              snackBar: MatSnackBar) {
    super(actions$, store, snackBar);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatScorePipe } from './pipes/formatScore.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { AvatarFullPathPipe } from './pipes/avatar-path.pipe';
import { FormStateTitlePipe } from './pipes/form-state-title.pipe';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CoalescePipe } from './pipes/coalesce.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SanitizeUrlPipe,
    FormStateTitlePipe,
    AvatarFullPathPipe,
    FormatScorePipe,
    CoalescePipe,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
  ],
  exports: [
    SanitizeUrlPipe,
    FormStateTitlePipe,
    AvatarFullPathPipe,
    FormatScorePipe,
    CoalescePipe,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
  ],
})
export class UtilsModule {
}

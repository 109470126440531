import {GenderFilter, LeaderboardType, PowerTypes, PowerTypeValue, TopUserModel} from '@carol-nx/data';
import {createAction, props} from '@ngrx/store';

export const GetTopUsersList = createAction(
  '[User] Get top users list',
  props<{page?: number, size?: number, callBack?: (resp?: any) => void}>()
);

export const SetTopUsersList = createAction(
  '[User] Set top users list',
  props<{powerType: PowerTypes, usersList: TopUserModel[], total: number}>()
);

export const AddTopUsersList = createAction(
  '[User] Add top users list',
  props<{powerType: PowerTypes, usersList: TopUserModel[]}>()
);

export const SetUserTopPositionByPowerType = createAction(
  '[User] Set rider top position',
  props<{powerType: PowerTypes, riderPosition: TopUserModel}>()
);

export const SetLeaderboardTypeValues = createAction(
  '[User] Set LeaderboardType values',
  props<{leaderboardTypeValues: LeaderboardType[]}>()
);

export const SetLeaderboardTypeId = createAction(
  '[User] Set active LeaderboardType value',
  props<{leaderboardTypeId: number}>()
);

export const SetPowerTypeValues = createAction(
  '[User] Set PowerType values',
  props<{powerTypeValues: PowerTypeValue[]}>()
);

export const SetActivePowerTypeValue = createAction(
  '[User] Set active PowerTypeValue',
  props<{activePowerTypeValue: PowerTypeValue}>()
);

export const SetActiveSubPowerTypeValue = createAction(
  '[User] Set active SubPowerTypeValue',
  props<{activeSubPowerTypeValue: PowerTypeValue}>()
);

export const SetGenderFilter = createAction(
  '[User] Set active gender filter value',
  props<{genderFilter: GenderFilter}>()
);

export const SetFollowTopUser = createAction(
  '[User] Set follow top user',
  props<{riderId: number, observedRider: TopUserModel, newState: boolean}>()
);

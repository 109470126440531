<div class = "root">
  <div class="body-form">
    <div class="center-info">
      <div class="text-info">You are now following {{data.topUser.firstName}} {{data.topUser.lastName}} and can see {{data.topUser.gender ? 'him' : 'her'}} in the in the Following leaderboard</div>
      <div class="avatar-holder">
        <avatar [rider]="data.topUser"></avatar>
      </div>
      <button mat-stroked-button [mat-dialog-close]="true">OK</button>
    </div>
  </div>
</div>

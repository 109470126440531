import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnDestroy
} from '@angular/core';

export const rowSelectionClassName = 'expanded-row';

@Directive({
  selector: '[carolNxRowSelection]'
})
export class RowSelectionDirective implements OnDestroy, AfterViewInit{

  constructor(private element: ElementRef,
              private _cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener('click', this.onClickRow.bind(this));
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener('click', this.onClickRow.bind(this));
  }

  onClickRow(event) {
    if (!this.element.nativeElement.classList.contains(rowSelectionClassName)) {
      const tableElement = this.element.nativeElement.closest('table');
      if (tableElement) {
        tableElement.querySelectorAll(`tr[carolNxRowSelection].${rowSelectionClassName}`).forEach(node => {
          node.classList.remove(rowSelectionClassName);
        });
      }
      this.element.nativeElement.classList.add(rowSelectionClassName);
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartInfo, ChartType, GaugeChartModel, RideTypes, UserModel } from '@carol-nx/data';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { isValidChartData } from '@carol-nx/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RideService } from '@carol-nx/services';

const DEFAULT_PEERS_LOWER = 30;
const DEFAULT_VS_UPPER = 100 - DEFAULT_PEERS_LOWER;
const VS_PEERS_UPPER = 1;

@UntilDestroy()
@Component({
  selector: 'carol-nx-info-content',
  templateUrl: './info-content.component.html',
  styleUrls: ['./info-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoContentComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public chartData: GaugeChartModel;

  @Input()
  public isInvalidRide: boolean;

  public chartInfo: ChartInfo = {};
  public userData: UserModel;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private rideService: RideService
  ) {
  }

  public isValidChartData(): boolean {
    return isValidChartData(this.chartData);
  }

  public getStarDisplayText(): Observable<string> {
    const starCountDict: Record<number, string> = {
      1: 'SPEEDOMETER_INFO_DIALOG_GOOD',
      2: 'SPEEDOMETER_INFO_DIALOG_GREAT',
      3: 'SPEEDOMETER_INFO_DIALOG_EXCELLENT'
    };

    return this.translate.get(starCountDict[this.chartData.starCount]);
  }

  public ngOnInit(): void {
    const initNoDataChartInfo = () => {
      let chartInfo: ChartInfo = {
        message: this.translate.get('SPEEDOMETER_INFO_NO_HEADER'),
        description: this.rideService.getRideTypeName(this.chartData.rideType).pipe(
          switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_NO_DATA', { rideType }))
        )
      };
      if (this.chartData.chartType.toString().startsWith(ChartType.FitnessScore.toString())) {
        let chartInfoNoFS: ChartInfo = {
          message: this.translate.get('SPEEDOMETER_INFO_NO_HEADER'),
          performance: this.translate.get('SPEEDOMETER_INFO_NO_FITNESS_SCORE_PART_1'),
          description: this.translate.get('SPEEDOMETER_INFO_NO_FITNESS_SCORE_PART_2')
        };
        if (this.chartData.sequential) {
          //if rides page
          this.chartInfo = chartInfoNoFS;
        } else {
          //dashboard-main page && fitness page
          console.log('info-content');
          this.store.select(RiderSelectors.selectRiderId).pipe(
            filter(riderId => !!riderId),
            untilDestroyed(this)
          ).subscribe(riderId => {
            this.store.select(RiderSelectors.selectRiderStats).pipe(
              tap(stats => {
                if (!stats) {//if fitness page
                  this.store.dispatch(RiderActions.GetRiderStatisticValues({ riderId }));
                }
              }),
              filter(stats => !!stats),
              map(stats => stats.rideCounts),
              untilDestroyed(this)
            ).subscribe((rideCounts) => {
              console.log('this.store.select(RiderSelectors.selectRiderStats - new value', rideCounts);
              if (rideCounts.rideCountIntense + rideCounts.rideCountEnergiser + rideCounts.rideCountIntermediate + rideCounts.rideCountRampUp) {
                chartInfo = chartInfoNoFS;
              }
              this.chartInfo = chartInfo;
              this.cdr.detectChanges();
            });
          });
        }
      } else {
        this.chartInfo = chartInfo;
      }
    };

    const initDataChartInfo = (userData: UserModel) => {
      this.userData = userData;

      switch (this.chartData.chartType) {
        case ChartType.PeakPower:
        case ChartType.PeakPowerPerLB:
        case ChartType.PeakPowerPerKG:
        case ChartType.EnergyOutput:
          this.peakPowerInfoUseCase();
          break;
        case ChartType.FitnessScore:
        case ChartType.FitnessScorePerLB:
        case ChartType.FitnessScorePerKG:
          this.fitnessScoreInfoUseCase();
          break;
        case ChartType.PeakHeartRate:
          this.peakHeartRateInfoUseCase();
          break;
        case ChartType.CaloriesExclEPOC:
        case ChartType.CaloriesInclEPOC:
          this.caloriesInfoUseCase();
          break;
        case ChartType.FunctionalThresholdPower:
        case ChartType.MaxAerobicPower:
        case ChartType.MaxAnaerobicPower:
          this.powerInfoUseCase();
          break;
        case ChartType.AveragePower:
        case ChartType.AverageSprintPower:
        case ChartType.AverageTestPower:
          this.averagePowerInfoUseCase();
          break;
        case ChartType.RidesPerWeek:
          this.ridesPerWeekInfoUseCase();
          break;
        case ChartType.AverageSpeed:
        case ChartType.Distance:
          this.speedDistanceInfoUseCase();
          break;
        case ChartType.HabitScore:
          this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_HABIT_SCORE_DESCRIPTION');
          break;
        case ChartType.VO2Max:
          this.VO2MaxInfoUseCase();
          break;
      }
    };

    const initChartInfo = (userData: UserModel) => this.isValidChartData()
      ? initDataChartInfo(userData)
      : initNoDataChartInfo();

    this.store.select(RiderSelectors.selectRider).pipe(
      tap(initChartInfo),
      untilDestroyed(this)
    ).subscribe();
  }


  private isRideType(rideType: RideTypes): boolean {
    return this.chartData.rideType === rideType;
  }

  private isNewBest(): boolean {
    return this.chartData.lastFull >= this.chartData.bestFull;
  }

  private getImprovement(): number {
    return this.chartData.lastFull && this.chartData.improvement == null
    && this.chartData.baseFull && this.chartData.baseFull != 0.0
      ? Math.round(this.chartData.lastFull / this.chartData.baseFull * 100 - 100)
      : this.chartData.improvement;
  }

  private getImprovementAbs(): number {
    return Math.abs(this.getImprovement());
  }

  private getImprovementType(): Observable<string> {
    return this.getImprovement() >= 0
      ? this.translate.get('SPEEDOMETER_INFO_ABOVE')
      : this.translate.get('SPEEDOMETER_INFO_BELOW');
  }

  private getPerformance(): Observable<string> {
    if (this.chartData.vsPeers?.vs && this.chartData.vsPeers?.ageMin && this.chartData.vsPeers?.ageMax) {
      return combineLatest([
        this.getPeersPercent(),
        this.getGender()
      ]).pipe(
        switchMap(([peersPercent, gender]) => this.translate.get('SPEEDOMETER_INFO_POWER_DESCRIPTION_BOTH', {
          peersPercent,
          ageMin: this.chartData.vsPeers.ageMin,
          ageMax: this.chartData.vsPeers.ageMax,
          gender
        }))
      );
    } else if (this.chartData.vsPeers?.vs && this.chartData.vsPeers?.ageMin) {
      return combineLatest([
        this.getPeersPercent(),
        this.getGender()
      ]).pipe(
        switchMap(([peersPercent, gender]) => this.translate.get('SPEEDOMETER_INFO_POWER_DESCRIPTION_MIN', {
          peersPercent,
          ageMin: this.chartData.vsPeers.ageMin,
          gender
        }))
      );
    } else if (this.chartData.vsPeers?.vs && this.chartData.vsPeers?.ageMax) {
      return combineLatest([
        this.getPeersPercent(),
        this.getGender()
      ]).pipe(
        switchMap(([peersPercent, gender]) => this.translate.get('SPEEDOMETER_INFO_POWER_DESCRIPTION_MAX', {
          peersPercent,
          ageMax: this.chartData.vsPeers.ageMax,
          gender
        }))
      );
    } else {
      return undefined;
    }
  }

  private getGender(): Observable<string> {
    return this.userData.gender
      ? this.translate.get('SPEEDOMETER_INFO_MALES')
      : this.translate.get('SPEEDOMETER_INFO_FEMALES');
  }

  private getPeersPercent(): Observable<string> {
    if (this.chartData.vsPeers?.vs > DEFAULT_VS_UPPER || this.chartData.vsPeers?.lower) {
      return this.translate.get('SPEEDOMETER_INFO_LOWER_PERCENT', {
        percent: this.chartData.vsPeers?.lower || DEFAULT_PEERS_LOWER
      });
    } else {
      return this.translate.get('SPEEDOMETER_INFO_TOP_PERCENT', {
        percent: Math.max(this.chartData.vsPeers?.vs, VS_PEERS_UPPER)
      });
    }
  }

  private getDisplayRideName(isFrom: boolean = false): Observable<string> {
    if (this.chartData.ridesPage)
      return this.translate.get(isFrom ? 'SPEEDOMETER_INFO_FROM_THIS_RIDE' : 'SPEEDOMETER_INFO_IN_THIS_RIDE');
    else//this is for dashboard
      return this.rideService.getRideTypeName(this.chartData.rideType).pipe(
        switchMap(rideName => isFrom
          ? this.translate.get('SPEEDOMETER_INFO_FROM_YOUR_LAST_RIDE', { rideName })
          : this.translate.get('SPEEDOMETER_INFO_IN_YOUR_LAST_RIDE', { rideName })
        )
      );
  }

  private getRideTypeNameWithPrefix(): Observable<string> {
    const rideTypeNameWithPrefixDict: Record<RideTypes, string> = {
      [RideTypes.REHIT]: 'SPEEDOMETER_INFO_A_REHIT',
      [RideTypes.IntensesRides]: 'SPEEDOMETER_INFO_AN_INTENSE',
      [RideTypes.IntenseRides]: 'SPEEDOMETER_INFO_AN_INTENSE',
      [RideTypes.IntermediatesRides]: 'SPEEDOMETER_INFO_AN_INTERMEDIATE',
      [RideTypes.IntermediateRides]: 'SPEEDOMETER_INFO_AN_INTERMEDIATE',
      [RideTypes.Intermediate3x15]: 'SPEEDOMETER_INFO_AN_INTERMEDIATE_3X15',
      [RideTypes.EnergisersRides]: 'SPEEDOMETER_INFO_AN_ENERGISER',
      [RideTypes.EnergiserRides]: 'SPEEDOMETER_INFO_AN_ENERGISER',
      [RideTypes.Energiser3x10]: 'SPEEDOMETER_INFO_AN_ENERGISER_3X10',
      [RideTypes.GibalaIntervalsRides]: 'SPEEDOMETER_INFO_GIBALA_INTERVALS',
      [RideTypes.RapidFatBurnRides]: 'SPEEDOMETER_INFO_A_FAT_BURN',
      [RideTypes.RapidFatBurn30Rides]: 'SPEEDOMETER_INFO_A_FAT_BURN_30',
      [RideTypes.RapidFatBurn45Rides]: 'SPEEDOMETER_INFO_A_FAT_BURN_45',
      [RideTypes.RapidFatBurn60Rides]: 'SPEEDOMETER_INFO_A_FAT_BURN_60',
      [RideTypes.CoyleIntervalsRides]: 'SPEEDOMETER_INFO_COYLE_INTERVALS',
      [RideTypes.DrAdamsonIntervals]: 'SPEEDOMETER_INFO_DR_ADAMSON_INTERVALS',
      [RideTypes.FreeRides]: 'SPEEDOMETER_INFO_A_FREE',
      [RideTypes.EnduranceRides]: 'SPEEDOMETER_INFO_AN_ENDURANCE',
      [RideTypes.FtpTest20Rides]: 'SPEEDOMETER_INFO_AN_FTP_TEST_20',
      [RideTypes.FtpTest2x8Rides]: 'SPEEDOMETER_INFO_AN_FTP_TEST_2_X_8',
      [RideTypes.RampUpRides]: 'SPEEDOMETER_INFO_A_RAMP_UP',
      [RideTypes.FreeCustomRides]: 'SPEEDOMETER_INFO_A_FREE',
      [RideTypes.FreeRideWithLaps]: 'SPEEDOMETER_INFO_A_FREE',
      [RideTypes.FitnessTestsRides]: 'SPEEDOMETER_INFO_AN_ENDURANCE',
      [RideTypes.EkblomBakRides]: 'SPEEDOMETER_INFO_AN_EKBLOM_BAK',
      [RideTypes.NorwegianZoneIntervals]: 'SPEEDOMETER_INFO_NORWEGIAN_ZONE_INTERVALS'
    };

    return this.translate.get(rideTypeNameWithPrefixDict[this.chartData.rideType]);
  }

  private peakPowerInfoUseCase(): void {
    const getTypePrefix = (): Observable<string> => {
      switch (this.chartData.chartType) {
        case ChartType.PeakPowerPerLB:
          return this.translate.get('LB').pipe(
            switchMap(measure => this.translate.get('SPEEDOMETER_INFO_ACHIEVED_PEAK_POWER_PER', { measure }))
          );
        case ChartType.PeakPowerPerKG:
          return this.translate.get('KG').pipe(
            switchMap(measure => this.translate.get('SPEEDOMETER_INFO_ACHIEVED_PEAK_POWER_PER', { measure }))
          );
        case ChartType.EnergyOutput:
          return this.translate.get('SPEEDOMETER_INFO_GENERATED_ENERGY_OUTPUT');
        default:
          return this.translate.get('SPEEDOMETER_INFO_ACHIEVED_PEAK_POWER');
      }
    };

    if (this.chartData.base && this.chartData.best && this.isRideType(RideTypes.RampUpRides) && this.isNewBest()) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName(),
        this.getImprovementType()
      ]).pipe(
        switchMap(([typePrefix, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_RAMP_UP_BEST', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base
          })
        )
      );
    } else if (this.chartData.base && this.chartData.best && this.isRideType(RideTypes.RampUpRides)) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName(),
        this.getImprovementType()
      ]).pipe(
        switchMap(([typePrefix, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_RAMP_UP', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
    } else if (this.chartData.base && this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName(),
        this.getImprovementType(),
        this.getRideTypeNameWithPrefix()
      ]).pipe(
        switchMap(([typePrefix, rideType, improvementType, rideTypeWithPrefix]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_OTHER_BEST', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            rideTypeWithPrefix
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.base && this.chartData.best) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName(),
        this.getImprovementType(),
        this.getRideTypeNameWithPrefix()
      ]).pipe(
        switchMap(([typePrefix, rideType, improvementType, rideTypeWithPrefix]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_OTHER', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best,
            rideTypeWithPrefix
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName()
      ]).pipe(
        switchMap(([typePrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_SECOND_RAMP_UP_BEST', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType
          })
        )
      );
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName()
      ]).pipe(
        switchMap(([typePrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_SECOND_RAMP_UP', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
    } else {
      this.chartInfo.message = combineLatest([
        getTypePrefix(),
        this.getDisplayRideName()
      ]).pipe(
        switchMap(([typePrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_FIRST_RAMP_UP', {
            typePrefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType
          })
        )
      );
    }

    switch (this.chartData.chartType) {
      case ChartType.PeakPowerPerLB:
        this.chartInfo.description = this.translate.get('DEFINITIONS_POUND').pipe(
          switchMap(measureFull => this.translate.get(
            'SPEEDOMETER_INFO_PEAK_POWER_PER_DESCRIPTION',
            { measureFull }
          ))
        );
        break;
      case ChartType.PeakPowerPerKG:
        this.chartInfo.description = this.translate.get('DEFINITIONS_KILOGRAM').pipe(
          switchMap(measureFull => this.translate.get(
            'SPEEDOMETER_INFO_PEAK_POWER_PER_DESCRIPTION',
            { measureFull }
          ))
        );
        break;
      case ChartType.EnergyOutput:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_ENERGY_OUTPUT_DESCRIPTION');
        break;
      case ChartType.PeakPower:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_PEAK_POWER_DESCRIPTION');
        break;
    }
  }

  private fitnessScoreInfoUseCase(): void {
    const perKgLbPrefix = (): Observable<string> => {
      switch (this.chartData.chartType) {
        case ChartType.FitnessScorePerLB:
          return this.translate.get('LB').pipe(
            switchMap(measure => this.translate.get('SPEEDOMETER_INFO_PER_PREFIX', { measure }))
          );
        case ChartType.FitnessScorePerKG:
          return this.translate.get('KG').pipe(
            switchMap(measure => this.translate.get('SPEEDOMETER_INFO_PER_PREFIX', { measure }))
          );
        default:
          return of('');
      }
    };

    if (this.chartData.base && this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        perKgLbPrefix(),
        this.getDisplayRideName(),
        this.getImprovementType()
      ])).pipe(
        switchMap(([perKgLbPrefix, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_OTHER_BEST', {
            perKgLbPrefix,
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.base && this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        perKgLbPrefix(),
        this.getDisplayRideName(),
        this.getImprovementType()
      ])).pipe(
        switchMap(([perKgLbPrefix, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_OTHER', {
            perKgLbPrefix,
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        perKgLbPrefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([perKgLbPrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_SECOND_BEST', {
            perKgLbPrefix,
            value: this.chartData.last,
            rideType
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        perKgLbPrefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([perKgLbPrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_SECOND', {
            perKgLbPrefix,
            value: this.chartData.last,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else {
      this.chartInfo.message = combineLatest(([
        perKgLbPrefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([perKgLbPrefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_FIRST', {
            perKgLbPrefix,
            value: this.chartData.last,
            rideType
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    }

    switch (this.chartData.chartType) {
      case ChartType.FitnessScorePerLB:
        this.chartInfo.description = combineLatest(([
          this.translate.get('LB'),
          this.translate.get('DEFINITIONS_POUND')
        ])).pipe(
          switchMap(([measure, measureFull]) => this.translate.get(
            'SPEEDOMETER_INFO_FITNESS_SCORE_PER_DESCRIPTION',
            { measure, measureFull }
          ))
        );
        break;
      case ChartType.FitnessScorePerKG:
        this.chartInfo.description = combineLatest(([
          this.translate.get('KG'),
          this.translate.get('DEFINITIONS_KILOGRAM')
        ])).pipe(
          switchMap(([measure, measureFull]) => this.translate.get(
            'SPEEDOMETER_INFO_FITNESS_SCORE_PER_DESCRIPTION',
            { measure, measureFull }
          ))
        );
        break;
      case ChartType.FitnessScore:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_FITNESS_SCORE_DESCRIPTION');
        break;
    }
  }

  private VO2MaxInfoUseCase(): void {
    if (this.chartData.base && this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        this.getImprovementType()
      ])).pipe(
        switchMap(([rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_VO2MAX_OTHER_BEST', {
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.base && this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        this.getImprovementType()
      ])).pipe(
        switchMap(([rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_VO2MAX_OTHER', {
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_VO2MAX_SECOND_BEST', {
            value: this.chartData.last,
            rideType
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_VO2MAX_SECOND', {
            value: this.chartData.last,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    } else {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_VO2MAX_FIRST', {
            value: this.chartData.last,
            rideType
          })
        )
      );
      this.chartInfo.performance = this.getPerformance();
    }
    this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_VO2MAX_DESCRIPTION');
  }

  private peakHeartRateInfoUseCase(): void {
    const hrDesc = (): Observable<string> => {
      const lastHr: number = this.chartData.lastFull || Number(this.chartData.last);
      if (lastHr < this.chartData.targetMin) {
        return this.translate.get('SPEEDOMETER_INFO_BELOW');
      } else if (lastHr > this.chartData.targetMax) {
        return this.translate.get('SPEEDOMETER_INFO_ABOVE');
      } else {
        return this.translate.get('SPEEDOMETER_INFO_WITHIN');
      }
    };

    if (this.chartData.targetMin && this.chartData.targetMax && this.chartData) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        hrDesc()
      ])).pipe(
        switchMap(([rideType, hrDesc]) => this.translate.get('SPEEDOMETER_INFO_PEAK_HEART_RATE', {
          value: this.chartData.last,
          rideType,
          hrDesc,
          base: this.chartData.targetMin,
          best: this.chartData.targetMax
        }))
      );
    }

    this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_PEAK_HEART_RATE_INFO');
  }

  private caloriesInfoUseCase(): void {
    const isInclEpoc = () => this.isRideType(RideTypes.IntenseRides)
      || this.isRideType(RideTypes.GibalaIntervalsRides)
      || this.isRideType(RideTypes.RapidFatBurnRides)
      || this.isRideType(RideTypes.RapidFatBurn30Rides)
      || this.isRideType(RideTypes.RapidFatBurn45Rides)
      || this.isRideType(RideTypes.RapidFatBurn60Rides);

    if (isInclEpoc() && this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_INCL_EPOC_OTHER_BEST', {
          value: this.chartData.last,
          rideType
        }))
      );
    } else if (isInclEpoc() && this.chartData.best) {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_INCL_EPOC_OTHER', {
          value: this.chartData.last,
          rideType,
          belowBest: this.chartData.belowBest,
          best: this.chartData.bestFullString || this.chartData.best
        }))
      );
    } else if (isInclEpoc()) {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_INCL_EPOC_FIRST', {
          value: this.chartData.last,
          rideType
        }))
      );
    } else if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_OTHER_BEST', {
          value: this.chartData.last,
          rideType
        }))
      );
    } else if (this.chartData.best) {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_OTHER', {
          value: this.chartData.last,
          rideType,
          belowBest: this.chartData.belowBest,
          best: this.chartData.bestFullString || this.chartData.best
        }))
      );
    } else {
      this.chartInfo.message = this.getDisplayRideName(true).pipe(
        switchMap(rideType => this.translate.get('SPEEDOMETER_INFO_CALORIES_FIRST', {
          value: this.chartData.last,
          rideType
        }))
      );
    }

    this.chartInfo.description = isInclEpoc()
      ? this.translate.get('SPEEDOMETER_INFO_CALORIES_INCL_EPOC_DESCRIPTION')
      : this.translate.get('SPEEDOMETER_INFO_CALORIES_DESCRIPTION');
  }

  private powerInfoUseCase(): void {
    const powerLabel = (): Observable<string> => {
      switch (this.chartData.chartType) {
        case ChartType.FunctionalThresholdPower:
          return this.translate.get('SPEEDOMETER_INFO_AN_FTP');
        case ChartType.MaxAerobicPower:
          return this.translate.get('SPEEDOMETER_INFO_A_MAEP');
        case ChartType.MaxAnaerobicPower:
          return this.translate.get('SPEEDOMETER_INFO_A_MANP');
      }
    };

    if (this.chartData.base && this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        powerLabel(),
        this.getDisplayRideName(true),
        this.getImprovementType()
      ])).pipe(
        switchMap(([powerLabel, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_POWER_OTHER_BEST', {
            powerLabel,
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base
          })
        )
      );
    } else if (this.chartData.base && this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        powerLabel(),
        this.getDisplayRideName(true),
        this.getImprovementType()
      ])).pipe(
        switchMap(([powerLabel, rideType, improvementType]) =>
          this.translate.get('SPEEDOMETER_INFO_POWER_OTHER', {
            powerLabel,
            value: this.chartData.last,
            rideType,
            improvement: this.getImprovementAbs(),
            improvementType,
            base: this.chartData.baseFullString || this.chartData.base,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
    } else if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        powerLabel(),
        this.getDisplayRideName(true)
      ])).pipe(
        switchMap(([powerLabel, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_POWER_SECOND_BEST', {
            powerLabel,
            value: this.chartData.last,
            rideType
          })
        )
      );
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        powerLabel(),
        this.getDisplayRideName(true)
      ])).pipe(
        switchMap(([powerLabel, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_POWER_SECOND', {
            powerLabel,
            value: this.chartData.last,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
    } else {
      this.chartInfo.message = combineLatest(([
        powerLabel(),
        this.getDisplayRideName(true)
      ])).pipe(
        switchMap(([powerLabel, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_POWER_FIRST', {
            powerLabel,
            value: this.chartData.last,
            rideType
          })
        )
      );
    }

    this.chartInfo.performance = this.getPerformance();

    switch (this.chartData.chartType) {
      case ChartType.FunctionalThresholdPower:
        this.chartInfo.description = this.chartData.base || this.chartData.best
          ? combineLatest([
            this.translate.get('SPEEDOMETER_INFO_FTP_DESCRIPTION'),
            this.translate.get('SPEEDOMETER_INFO_FTP_BASE_BEST_NOTICE')
          ]).pipe(map(([SPEEDOMETER_INFO_FTP_DESCRIPTION, SPEEDOMETER_INFO_FTP_BASE_BEST_NOTICE]) =>
            `${SPEEDOMETER_INFO_FTP_DESCRIPTION}<br><br>${SPEEDOMETER_INFO_FTP_BASE_BEST_NOTICE}`))
          : this.translate.get('SPEEDOMETER_INFO_FTP_DESCRIPTION');
        break;
      case ChartType.MaxAerobicPower:
        this.chartInfo.description = this.chartData.base || this.chartData.best
          ? combineLatest([
            this.translate.get('SPEEDOMETER_INFO_MAEP_DESCRIPTION'),
            this.translate.get('SPEEDOMETER_INFO_MAEP_BASE_BEST_NOTICE')
          ]).pipe(map(([SPEEDOMETER_INFO_MAEP_DESCRIPTION, SPEEDOMETER_INFO_MAEP_BASE_BEST_NOTICE]) =>
            `${SPEEDOMETER_INFO_MAEP_DESCRIPTION}<br><br>${SPEEDOMETER_INFO_MAEP_BASE_BEST_NOTICE}`))
          : this.translate.get('SPEEDOMETER_INFO_MAEP_DESCRIPTION');
        break;
      case ChartType.MaxAnaerobicPower:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_MANP_DESCRIPTION');
        break;
    }
  }

  private averagePowerInfoUseCase(): void {
    const avgPowerLabel = (): Observable<string> => {
      switch (this.chartData.chartType) {
        case ChartType.AverageSprintPower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPRINT_POWER');
        case ChartType.AveragePower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_POWER');
        case ChartType.AverageTestPower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_TEST_POWER');
      }
    };

    if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        avgPowerLabel()
      ])).pipe(
        switchMap(([rideType, avgPowerLabel]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_POWER_OTHER_BEST', {
            value: this.chartData.last,
            rideType,
            avgPowerLabel
          })
        )
      );
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        avgPowerLabel()
      ])).pipe(
        switchMap(([rideType, avgPowerLabel]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_POWER_OTHER', {
            value: this.chartData.last,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best,
            avgPowerLabel
          })
        )
      );
    } else {
      this.chartInfo.message = combineLatest(([
        this.getDisplayRideName(),
        avgPowerLabel()
      ])).pipe(
        switchMap(([rideType, avgPowerLabel]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_POWER_FIRST', {
            value: this.chartData.last,
            rideType,
            avgPowerLabel
          })
        )
      );
    }

    this.chartInfo.description = (() => {
      switch (this.chartData.chartType) {
        case ChartType.AverageSprintPower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPRINT_POWER_DESCRIPTION');
        case ChartType.AveragePower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_POWER_DESCRIPTION');
        case ChartType.AverageTestPower:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_TEST_POWER_DESCRIPTION');
      }
    })();
  }

  private ridesPerWeekInfoUseCase(): void {
    const weeksDict: Record<number, string> = {
      0: 'SPEEDOMETER_INFO_ONE_WEEK',
      1: 'SPEEDOMETER_INFO_ONE_WEEK',
      2: 'SPEEDOMETER_INFO_TWO_WEEKS',
      3: 'SPEEDOMETER_INFO_THREE_WEEKS',
      4: 'SPEEDOMETER_INFO_FOUR_WEEKS',
      5: 'SPEEDOMETER_INFO_FIVE_WEEKS',
      6: 'SPEEDOMETER_INFO_SIX_WEEKS'
    };

    if (this.chartData.last) {
      this.chartInfo.message = this.translate.get(weeksDict[this.chartData.weekCount]).pipe(
        switchMap(weekCount => this.translate.get('SPEEDOMETER_INFO_RIDES_PER_WEEK', {
          value: this.chartData.last,
          weekCount
        }))
      );
    } else {
      this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_RIDES_PER_WEEK_INFO');
    }
  }

  private speedDistanceInfoUseCase(): void {
    const prefix = (): Observable<string> => {
      switch (this.chartData.chartType) {
        case ChartType.AverageSpeed:
          return this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPEED');
        case ChartType.Distance:
          return this.translate.get('SPEEDOMETER_INFO_TRAVELLED');
      }
    };

    if (this.chartData.best && this.isNewBest()) {
      this.chartInfo.message = combineLatest(([
        prefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([prefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPEED_OTHER_BEST', {
            prefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType
          })
        )
      );
    } else if (this.chartData.best) {
      this.chartInfo.message = combineLatest(([
        prefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([prefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPEED_OTHER', {
            prefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType,
            belowBest: this.chartData.belowBest,
            best: this.chartData.bestFullString || this.chartData.best
          })
        )
      );
    } else {
      this.chartInfo.message = combineLatest(([
        prefix(),
        this.getDisplayRideName()
      ])).pipe(
        switchMap(([prefix, rideType]) =>
          this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPEED_FIRST', {
            prefix,
            value: this.chartData.last,
            units: this.chartData.units,
            rideType
          })
        )
      );
    }

    switch (this.chartData.chartType) {
      case ChartType.AverageSpeed:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_AVERAGE_SPEED_DESCRIPTION');
        break;
      case ChartType.Distance:
        this.chartInfo.description = this.translate.get('SPEEDOMETER_INFO_DISTANCE_DESCRIPTION');
        break;
    }
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {CommonMessageModel} from '@carol-nx/data';
import {Store} from '@ngrx/store';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'carol-ui-common-success-modal',
  templateUrl: './common-success-modal.component.html',
  styleUrls: ['./common-success-modal.component.scss']
})
export class CommonSuccessModalComponent implements OnInit {

  public success: CommonMessageModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CommonMessageModel){
  }

  ngOnInit() {
    if (this.data) {
      this.success = this.data;
    }
  }

  public closeDialog() {
    if (this.data.callback) {
      this.data.callback();
    }
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'carol-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {

  @Input()
  public title: string;
  @Input()
  public isDisabled: boolean
  @Input()
  public stroked: boolean;

  constructor() {
  }

  ngOnInit() {

  }

}

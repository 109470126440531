<div class="states-wrapper" [ngSwitch]="formState | async">
  <div class="logo-holder">
    <div class="main-header-logo"></div>
  </div>
  <div class="title">
    {{formState | async | formStateTitle}}
    <p *ngIf="(formState | async) === formStates.Restore">
      Please enter your email address.
    </p>
  </div>

  <div class="auth-card" *ngSwitchCase="formStates.Login">
    <div class="content">
      <form [formGroup]="loginForm" class="login-form" (keydown)="tryToLogin($event)">
        <mat-form-field floatLabel="never">
          <mat-label>
            Username or Email
          </mat-label>
          <input matInput
                 id="usi"
                 (change)="onChangeInput('username', $event.target.value)"
                 (blur)="onBlurInput('username')"
                 name="username"
                 type="text"
                 autocomplete="username"
                 formControlName="username"/>
          <mat-error [class.from-web]="fromWeb">Enter a valid username</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="never">
          <mat-label>
            Password
          </mat-label>
          <input #passInput matInput
                 (change)="onChangeInput('password', $event.target.value)"
                 (blur)="onBlurInput('password')"
                 name="password"
                 type="password"
                 autocomplete="current-password"
                 formControlName="password"/>
          <mat-icon matSuffix class="small-eye" (click)="togglePass(passInput)">
            remove_red_eye
          </mat-icon>
          <mat-error [class.from-web]="fromWeb">Enter a valid password</mat-error>
        </mat-form-field>
      </form>
      <div class="restore-pass">
        <mat-checkbox class="remember-chkbox link-bth" [(ngModel)]="rememberMe">Remember me</mat-checkbox>
        <div class="link-bth full-link" (click)="switchState(formStates.Restore)">Forgot password?</div>
      </div>
    </div>
    <div class="actions">
      <carol-ui-button (click)="loginSubmit()">Login
      </carol-ui-button>
      <carol-ui-button [stroked]="true" (click)="startRegistration()">
        Register
      </carol-ui-button>
    </div>
  </div>

  <div class="auth-card" *ngSwitchCase="formStates.Restore">
    <div class="content" [ngStyle]="{marginBottom: '40px'}">
      <mat-form-field [floatLabel]="'never'">
        <mat-label>Your email</mat-label>
        <input matInput placeholder="Your email" name="email" autocomplete="email" type="email" #restoreEmail/>
      </mat-form-field>
    </div>
    <div class="actions">
      <carol-ui-button (click)="resetPassword(restoreEmail.value)"
              [isDisabled]="!restoreEmail.value">
        Continue
      </carol-ui-button>
      <carol-ui-button [stroked]="true" (click)="switchState(formStates.Login)">
        Cancel
      </carol-ui-button>
    </div>
  </div>

  <div class="auth-card" *ngSwitchCase="formStates.CheckEmail">
    <div class="svg-holder">
      <carol-nx-svg-icon icon="mail-open" [native]="true"></carol-nx-svg-icon>
    </div>
    <div class="title">
      Check your email
    </div>
    <div class="title">
      <p class="animated-classic fadeInUp">
        We have sent you instructions to reset your password.
      </p>
    </div>

    <div class="actions">
      <carol-ui-button (click)="switchState(formStates.Login)">Ok</carol-ui-button>
    </div>
  </div>
</div>


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../state';
import { Store } from '@ngrx/store';
import { AdminActions, InfoActions, RiderActions } from '../actions';
import { catchError, debounceTime, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { AdminBikeModel } from '@carol-nx/data';
import { AdminService, HelperService } from '@carol-nx/services';
import { buildPagingObject, verboseErrorMessage } from '@carol-nx/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminSelectors, RiderSelectors } from '../selectors';
import { EffectsMain } from './effects-main';

@Injectable()
export class AdminEffects extends EffectsMain {

  //@ts-ignore
  deleteAdminRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.DeleteAdminRide),
      mergeMap(action => this.adminService.deleteRideById(action.rideId)
        .pipe(
          map(resp => {
            return AdminActions.DeleteAdminRideSuccess({rideId: action.rideId});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not delete the ride. Please try again later'})))
        )
      )
    )
  );

  getAdminRidersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminRidersList),
      switchMap(action => action.query.riderId
        ? this.adminService.getRiderById(action.query.riderId).pipe(
          switchMap(rider => of(AdminActions.SetAdminRidersList({ riders: [{ id: action.query.riderId, ...rider }] }))),
          catchError(err => of(err))
        )
        : this.adminService.getRiders(action.query ? action.query : {}).pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = AdminActions.SetAdminRidersList({ riders: resp.content });
            const action2 = AdminActions.SetAdminRidersPagingData({ pagingData: pagingDataObject });
            const action3 = InfoActions.SetTableDataLoaded({tableDataLoaded: true});
            //@ts-ignore
            return of(action1, action2, action3);
          }),
          catchError(err => of(err))
        )
      )
    )
  );

  getAdminRidersBySearchString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminRidersBySearchString),
      debounceTime(320),
      switchMap(action => this.adminService.getRiders(action.query ? action.query : {})
        .pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = AdminActions.ResetAdminRidersList({riders: resp.content});
            const action2 = AdminActions.SetAdminRidersPagingData({pagingData: pagingDataObject});
            const action3 = InfoActions.SetTableDataLoaded({tableDataLoaded: true});
            //@ts-ignore
            return of(action1, action2, action3);
          }),
          catchError(err => of(err))
        )
      )
    )
  );

  //@ts-ignore
  getAdminLicensesBySearchString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicensesListBySearchString),
      debounceTime(320),
      switchMap(action => this.adminService.getAllLicensees(action.query ? action.query : {})
        .pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = AdminActions.ResetAdminLicensesList();
            const action2 = AdminActions.SetAdminLicensesList({licenses: resp.content});
            const action3 = AdminActions.SetAdminLicensesPagingData({pagingData: pagingDataObject});
            //@ts-ignore
            return of(action1, action2, action3);
          })
        )
      )
    )
  );

  //@ts-ignore
  getAdminLicensesNamesBySearchString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicensesNamesBySearchString),
      debounceTime(320),
      switchMap(action => this.adminService.getAllLicenseesNames(action.query ? action.query : {})
        .pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = AdminActions.ResetAdminLicensesList();
            const action2 = AdminActions.SetAdminLicensesList({licenses: resp.content});
            const action3 = AdminActions.SetAdminLicensesPagingData({pagingData: pagingDataObject});
            //@ts-ignore
            return of(action1, action2, action3);
          })
        )
      )
    )
  );

  //@ts-ignore
  getAdminLicensesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicensesList),
      switchMap(action => this.adminService.getAllLicensees(action.query ? action.query : {})
        .pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            const action1 = AdminActions.SetAdminLicensesList({licenses: resp.content});
            const action2 = AdminActions.SetAdminLicensesPagingData({pagingData: pagingDataObject});
            const action3 = AdminActions.ResetAdminLicensesList();
            if (!resp.content.length) {
              //@ts-ignore
              return of(action1, action2, action3);
            }
            //@ts-ignore
            return of(action1, action2);
          }),
          catchError(err => {
            return of(InfoActions.SetCommonError({message: 'Something went wrong!'}));
          })
        )
      )
    )
  );

  //@ts-ignore
  setRiderToLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetRiderToLicense),
      switchMap(action => this.adminService.setRiderToLicense(action.license.id, action.newRider.id)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetRiderToLicenseSuccess(action);
            const action2 = AdminActions.UpdateAdminRiderFieldSuccess({
              riderId: action.newRider.id,
              riderPart: {licenses: [...action.newRider.licenses, action.license]}
            });
            const action3 = InfoActions.SetCommonSuccess({title: 'Success', message: 'User was successfully assigned to membership'});
            const action4 = InfoActions.SetTableDataLoaded({tableDataLoaded: true});
            //@ts-ignore
            return of(action1, action2, action3, action4);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  setOwnerToLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetOwnerToLicense),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId)),
      switchMap(([action, currentRiderId]) => this.adminService.setOwner(action.license.id, action.newOwner.id)
        .pipe(
          switchMap(resp => {
            const action1 = InfoActions.SetCommonSuccess({title: 'Success', message: 'User was successfully assigned to membership'});
            const action2 = RiderActions.GetRidersLicenses({riderId: action.newOwner.id});
            const action3 = AdminActions.SetOwnerToLicenseSuccess(action);
            if (currentRiderId === action.newOwner.id) {
              const action4 = RiderActions.GetOwnerMenuAvailable({ riderId: currentRiderId});
              //@ts-ignore
              return of(action1, action2, action3, action4);
            } else {
              //@ts-ignore
              return of(action1, action2, action3);
            }
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  deleteOwnerLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.DeleteOwnerLicense),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId)),
      switchMap(([action, currentRiderId]) => this.adminService.deleteOwner(action.licenseId, action.deleteOwner.id)
        .pipe(
          switchMap(resp => {
            const action1 = InfoActions.SetCommonSuccess({
              title: 'Success',
              message: `User ${action.deleteOwner.nickname} has been successfully deleted from membership owners`
            });
            const action2 = AdminActions.DeleteOwnerLicenseSuccess({licenseId: action.licenseId, deleteOwner: action.deleteOwner});
            if (currentRiderId === action.deleteOwner.id) {
              const action3 = RiderActions.GetOwnerMenuAvailable({ riderId: currentRiderId});
              //@ts-ignore
              return of(action1, action2, action3);
            } else {
              //@ts-ignore
              return of(action1, action2);
            }
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  updateRiderInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminRiderInfo),
      switchMap(action => this.adminService.updateRiderInfo(action.riderId, action.riderInfo)
        .pipe(
          map(data => {
            this.openSnackBar('Rider info is updated!');
            return AdminActions.UpdateAdminRiderInfoSuccess({rider: data});
          }),
          catchError(err => of(RiderActions.GetRiderError({err})))
        )
      )
    )
  );

  //@ts-ignore
  deleteAdminRider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.DeleteAdminRider),
      mergeMap(action => this.adminService.deleteRiderById(action.riderId)
        .pipe(
          map(resp => {
            return AdminActions.DeleteAdminRiderSuccess({riderId: action.riderId});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not delete the rider. Please try again later'})))
        )
      )
    )
  );

  //@ts-ignore
  unlockAdminRider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UnlockAdminRider),
      mergeMap(action => this.adminService.unblockRider(action.riderId)
        .pipe(
          map(resp => {
            this.openSnackBar('User unlocked!');
            return AdminActions.UpdateAdminRiderFieldSuccess({
              riderId: action.riderId,
              riderPart: {blocked: false, blockReason: null}
            });
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not unlock the rider. Please try again later'})))
        )
      )
    )
  );

  //@ts-ignore
  setAdminRiderLock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderLock),
      mergeMap(action => this.adminService.blockRider(action.riderId)
        .pipe(
          map(resp => {
            this.openSnackBar('User locked!');
            return AdminActions.UpdateAdminRiderFieldSuccess({
              riderId: action.riderId,
              riderPart: {blocked: true, blockReason: 'rider.locked.admin'}
            });
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not lock the rider. Please try again later'})))
        )
      )
    )
  );

  //@ts-ignore
  setAdminRiderRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderRole),
      mergeMap(action => this.adminService.adminRoleRider(action.riderId, action.admin)
        .pipe(
          map(resp => {
            this.openSnackBar('Role changed!');
            return AdminActions.UpdateAdminRiderFieldSuccess({riderId: action.riderId, riderPart: {admin: action.admin}});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  setServiceRiderRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetServiceRiderRole),
      mergeMap(action => this.adminService.serviceRoleRider(action.riderId, action.service)
        .pipe(
          map(resp => {
            this.openSnackBar('Role changed!');
            return AdminActions.UpdateAdminRiderFieldSuccess({riderId: action.riderId, riderPart: {service: action.service}});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  setFactoryRiderRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetFactoryRiderRole),
      mergeMap(action => this.adminService.factoryRoleRider(action.riderId, action.factory)
        .pipe(
          map(resp => {
            this.openSnackBar('Role changed!');
            return AdminActions.UpdateAdminRiderFieldSuccess({riderId: action.riderId, riderPart: {factory: action.factory}});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  setSupportRiderRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetSupportRiderRole),
      mergeMap(action => this.adminService.supportRoleRider(action.riderId, action.support)
        .pipe(
          map(resp => {
            this.openSnackBar('Role changed!');
            return AdminActions.UpdateAdminRiderFieldSuccess({riderId: action.riderId, riderPart: {support: action.support}});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  //@ts-ignore
  getAdminBikesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminBikesPage),
      switchMap((action) => this.adminService.getBikesPage(action.query).pipe(
        switchMap(resp => {
          const pagingDataObject = buildPagingObject(resp);
          const action1 = AdminActions.SetAdminBikesPage({bikesData: resp.content});
          const action2 = AdminActions.SetAdminBikesPagingData({pagingData: pagingDataObject});
          const action3 = AdminActions.SetAdminBikesLoading({isLoading: false});
          //@ts-ignore
          return of(action1, action2, action3);
        }),
        catchError(errMessage => {
          const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
          const action2 = AdminActions.SetAdminBikesLoading({isLoading: false});
          //@ts-ignore
          return of(action1, action2);
        })
        )
      )
    )
  );

  //@ts-ignore
  getAdminBikesBySearchString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminBikesBySearchString),
      debounceTime(320),
      switchMap(action => action.query.bikeId
        ? this.adminService.getBikeDetail(action.query.bikeId).pipe(
          switchMap(bike => {
            const action1 = AdminActions.ResetAdminBikes({ bikesData: [bike] });
            const action2 = AdminActions.SetAdminBikesLoading({ isLoading: false });
            //@ts-ignore
            return of(action1, action2);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' });
            const action2 = AdminActions.SetAdminBikesLoading({ isLoading: false });
            //@ts-ignore
            return of(action1, action2);
          })
        )
        : this.adminService.getBikesPage(action.query ? action.query : {}).pipe(
          switchMap(resp => {
            const pagingDataObject = buildPagingObject(resp);
            let actions;
            if (action.doNotCloseModal) {
              actions = [AdminActions.ResetAdminBikesForModal({ bikesData: resp.content })];
            } else {
              actions = [AdminActions.ResetAdminBikes({ bikesData: resp.content }),
                AdminActions.SetAdminBikesPagingData({ pagingData: pagingDataObject })];
            }
            actions.push(AdminActions.SetAdminBikesLoading({ isLoading: false }));
            return of(...actions);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({ title: 'Error', message: 'Something went wrong.' });
            const action2 = AdminActions.SetAdminBikesLoading({ isLoading: false });
            //@ts-ignore
            return of(action1, action2);
          })
        )
      )
    )
  );


  getAdminBikeDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminBikeDetail),
      switchMap(action => this.adminService.getBikeDetail(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminBikeDetail({bikeDetail: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );


  updateAdminBikeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminBikeInfo),
      switchMap(action => this.adminService.updateBikeInfo(action.bikeId, action.bike)
        .pipe(
          map(data => {
            this.openSnackBar('Bike information is updated!');
            return AdminActions.UpdateAdminBikeInfoSuccess({bike: data});
          }),
          catchError(err => of(RiderActions.GetRiderError({err})))
        )
      )
    )
  );

  updateAdminBikeCalibration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminBikeCalibration),
      switchMap(action => this.adminService.updateBikeCalibration(action.bikeId, action.statusUpdate, action.bike)
        .pipe(
          map(data => {
            this.openSnackBar('Bike calibration is updated!');
            return AdminActions.UpdateAdminBikeCalibrationSuccess({bike: data});
          }),
          catchError(err => of(RiderActions.GetRiderError({err})))
        )
      )
    )
  );

  setSelectedAdminLicenseId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetSelectedAdminLicenseId),
      switchMap(action => {
          return this.adminService.getLicenseDetail(action.selectedLicenseId)
            .pipe(
              switchMap(resp => {
                const action1 = AdminActions.SetSelectedAdminLicense({selectedLicense: resp});
                return of(action1);
              }),
              catchError(errMessage => {
                const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
                return of(action1);
              })
            );
        }
      )
    )
  );

  updateAdminLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicense),
      switchMap(action => this.adminService.updateLicense(action.licenseInfo)
        .pipe(
          switchMap(dataInfo => {
            this.openSnackBar('Membership is updated!');
            return of(AdminActions.UpdateAdminLicenseSuccess({licenseData: dataInfo}));
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Membership could not changed. Please try again later'})))
        )
      )
    )
  );

  updateAdminLicenseAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseAvatar),
      switchMap(action => this.adminService.updateLicenseAvatar(action.licenseId, action.fileUpload)
        .pipe(
          switchMap(data => {
            this.openSnackBar('Membership is updated!');
            return of(AdminActions.UpdateAdminLicenseSuccess({licenseData: data}));
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Membership could not changed. Please try again later'})))
        )
      )
    )
  );


  updateBikeApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateBikeApp),
      switchMap((action) => this.adminService.updateBikeApp(action.bikeAppInfo)
        .pipe(
          map(data => {
            return AdminActions.SetBikeAppInfo({bikeAppInfo: data});
          }),
          catchError(err => of(InfoActions.SetCommonError({message: err.error.message})))
        )
      )
    )
  );

  getBikeAppInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetBikeAppInfo),
      mergeMap((action) => this.helperService.getBikeLatestVersion()
        .pipe(
          map(bikeAppInfo => AdminActions.SetBikeAppInfo({bikeAppInfo})),
          catchError(err => {
            let msg = err.statusText;
            if (err.error && err.error.message === 'property.notfound') {
              msg = 'No latest version information available.';
            }
            return of(InfoActions.SetCommonError({message: msg}));
          })
        ))
    )
  );

  getAdminRidesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminRidesList),
      switchMap(action => action.query.rideId
        ? this.adminService.getRideById(action.query.rideId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminRides({rides: [resp]});
            const action2 = AdminActions.SetAdminRidesPagingData({ridesPaging: {totalElements: 1, totalPages: 1}});
            return of(action1, action2);
          }),
          catchError(err => of(InfoActions.SetCommonError({message: err.error.message})))
        )
        : this.adminService.getAllRides(action.query ? action.query : {})
          .pipe(
            switchMap(resp => {
              const ridesPaging = buildPagingObject(resp);
              const action1 = AdminActions.SetAdminRides({rides: resp.content});
              const action2 = AdminActions.SetAdminRidesPagingData({ridesPaging});
              return of(action1, action2);
            }),
            catchError(err => of(InfoActions.SetCommonError({message: err.error.message})))
          )
      )
    )
  );

  getAdminRideExtended$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminRideExtended),
      switchMap(action => this.adminService.getRideById(action.rideId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminRideExtended({extendedRide: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            return of(InfoActions.SetCommonError({message: errMessage.error.message}));
          })
        )
      )
    )
  );

  getAdminTabletHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminBikeTabletHistory),
      switchMap(action => this.adminService.getBikeTabletHistory(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminBikeTabletHistory({tabletHistory: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            return of(InfoActions.SetCommonError({message: errMessage.error.message}));
          })
        )
      )
    )
  );

  updateCalfactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateCalfactors),
      switchMap((action) => this.adminService.updateCalfactors(action.calfactors)
        .pipe(
          map(data => {
            if (!data.error) {
              return InfoActions.SetCommonSuccess({message: `Calfactors added ${data.inserted}, updated ${data.updated}`});
            } else {
              return InfoActions.SetCommonError({message: verboseErrorMessage(data.error.message)});
            }
          })
        )
      )
    )
  );

  getAdminAppVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminAppVersions),
      switchMap(action => this.adminService.getAppVersions()
        .pipe(
          switchMap(appVersions => {
            const action1 = AdminActions.SetAdminAppVersions({appVersions});
            return of(action1);
          }),
          catchError(errMessage => {
            return of(InfoActions.SetCommonError({message: errMessage.error.message}));
          })
        )
      )
    )
  );

  setAdminRiderConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderConfirm),
      mergeMap(action => this.adminService.confirmRider(action.riderId)
        .pipe(
          map(resp => {
            this.openSnackBar('User confirmed!');
            return AdminActions.UpdateAdminRiderFieldSuccess({
              riderId: action.riderId,
              riderPart: {active: true}
            });
          }),
          catchError(err => of(
            InfoActions.SetCommonError({title: 'Error', message: 'Could not confirm the rider. Please try again later'})
          ))
        )
      )
    )
  );

  setAdminRiderReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderReset),
      mergeMap(action => this.adminService.resetRider(action.riderId)
        .pipe(
          map(resp => {
            this.openSnackBar('User has been reset!');
            return AdminActions.SetAdminRiderResetSuccess({riderId: action.riderId});
          }),
          catchError(err => of(
            InfoActions.SetCommonError({ title: 'Error', message: 'Could not reset the rider. Please try again later' })
          ))
        )
      )
    )
  );

  setAdminRiderResetResistance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderResetResistance),
      mergeMap(action => this.adminService.resetRiderResistance(action.riderId)
        .pipe(
          map(resp => {
            this.openSnackBar('User resistance has been reset!');
            return AdminActions.SetAdminRiderResetResistanceSuccess({riderId: action.riderId});
          }),
          catchError(err => of(
            InfoActions.SetCommonError({title: 'Error', message: 'Could not confirm the rider. Please try again later'})
          ))
        )
      )
    )
  );

  getAdminFrame$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminFrame),
      debounceTime(320),
      switchMap(action => this.adminService.getAdminFrame(action.frameId)
        .pipe(
          switchMap(frame => {
            const action1 = AdminActions.GetAdminFrameSuccess({frame});
            return of(action1);
          })
        )
      )
    )
  );

  updateAdminBike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminBike),
      switchMap(action => combineLatest([
          this.adminService.updateBikeInfo(action.bikeId, action.bike),
          this.adminService.updateBikeCalibration(action.bikeId, action.bike.statusUpdate, action.bike),
        ])
          .pipe(
            switchMap(([dataInfo, dataCalibration]) => {
              this.openSnackBar('Bike information is updated!');
              const newBikeInfo: AdminBikeModel = Object.assign({}, dataInfo);
              newBikeInfo.statusUpdate = action.bike.statusUpdate;
              const action1 = AdminActions.UpdateAdminBikeInfoSuccess({bike: newBikeInfo});
              const action2 = AdminActions.UpdateAdminBikeCalibrationSuccess({bike: dataCalibration});
              // @ts-ignore Ignore it bcs the completed data is correct
              const action3 = AdminActions.SetAdminBikeDetail({bikeDetail: action.bike});
              return of(action1, action2, action3);
            }),
            catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Bike could not changed. Please try again later'})))
          )
      )
    )
  );

  deleteAdminRiderFromLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.DeleteAdminRiderFromLicense),
      switchMap(action => this.adminService.deleteRiderFromLicense(action.licenseId, action.riderId)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`User has been successfully deleted from membership`);
            const action1 = AdminActions.DeleteAdminRiderFromLicenseSuccess({licenseId: action.licenseId, riderId: action.riderId});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  updateAdminBikeLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminBikeLicense),
      switchMap(action => this.adminService.switchBikeLicense(action.bikeId, action.license.id)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Bike membership switched`);
            const action1 = AdminActions.UpdateAdminBikeLicenseSuccess(action);
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  moveRideToRider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.MoveRideToRider),
      switchMap(({rideId, rider}) => this.adminService.moveRideToRider(rideId, rider.id)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Ride successfully assigned to new rider`);
            const action1 = AdminActions.MoveRideToRiderSuccess({rider, rideId});
            return of(action1);
          })
        )
      )
    )
  );

  getAdminRiderDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminRiderDetail),
      switchMap(action => this.adminService.getRiderById(action.riderId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminRiderDetail({riderDetail: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  createAdminLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.CreateAdminLicense),
      switchMap(action => this.adminService.createLicense(action.name, action.licenseType)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`New membership created`);
            const action1 = AdminActions.CreateAdminLicenseSuccess(resp);
            return of(action1);
          })
        )
      )
    )
  );

  getAdminBikeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminBikeSettings),
      switchMap(action => this.adminService.getBikeSettingsById(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.GetAdminBikeSettingsSuccess({bikeSettings: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  updateAdminBikeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminBikeSettings),
      switchMap(action => this.adminService.updateBikeSettings(action.bikeId, action.bikeSettings)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Update bike settings`);
            const action1 = AdminActions.UpdateAdminBikeSettingsSuccess(resp);
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  recalculateAdminRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.RecalculateAdminRide),
      withLatestFrom(this.store.select(AdminSelectors.selectExtendedAdminRideData)),
      mergeMap(([action, extendedRide]) => this.adminService.recalculateRide(action.bikeId, action.rideId)
        .pipe(
          mergeMap(resp => {
            const action1 = AdminActions.RecalculateAdminRideSuccess({bikeId: action.bikeId, rideId: action.rideId, ride: resp});
            if (extendedRide && extendedRide.id === action.rideId) {
              const action2 = AdminActions.GetAdminRideExtended({rideId: action.rideId});
              return of(action1, action2)
            }
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not delete the ride. Please try again later'})))
        )
      )
    )
  );

  setAdminRiderPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SetAdminRiderPassword),
      mergeMap(action => this.adminService.setRiderPassword(action.riderId, action.newPassword)
        .pipe(
          map(resp => {
            this.openSnackBar('Password changed!');
            return AdminActions.SetAdminRiderPasswordSuccess({riderId: action.riderId});
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: 'Could not lock the rider. Please try again later'})))
        )
      )
    )
  );

  getAdminLicenseDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicenseDiscount),
      switchMap(action => this.adminService.getLicenseDiscount(action.licenseId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminLicenseDiscount({discount: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  updateAdminLicenseDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseDiscount),
      switchMap(action => this.adminService.updateLicenseDiscount(action.licenseId, action.discount)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Update membership discount`);
            const action1 = AdminActions.SetAdminLicenseDiscount({discount: resp});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  updateAdminLicenseNeedSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseNeedSubscription),
      switchMap(action => this.adminService.updateLicenseNeedSubscription(action.licenseId, action.needSubscription)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Updated membership subscription order`);
            const action1 = AdminActions.SetAdminLicenseNeedSubscription({licenseId: action.licenseId, needSubscription: action.needSubscription});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  updateAdminLicenseResetSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseResetSubscription),
      switchMap(action => this.adminService.updateLicenseResetSubscription(action.licenseId)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Updated membership subscription order`);
            const action1 = AdminActions.SetAdminLicenseResetSubscription({license: resp});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  updateAdminLicenseResetCardSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseResetCardSubscription),
      switchMap(action => this.adminService.deleteSubscriptionPaymentSource(action.licenseId)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Updated membership subscription order`);
            const action1 = AdminActions.SetAdminLicenseResetSubscription({license: resp});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );


  getAdminLicenseDiscountLimit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicenseDiscountLimit),
      switchMap(action => this.adminService.getLicenseDiscountLimit(action.licenseId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminLicenseDiscountLimit({discountLimit: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  getAdminLicensePaymentInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicensePaymentInvoices),
      switchMap(action => this.adminService.getLicenseInvoices(action.licenseId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminLicensePaymentInvoices({paymentInvoices: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  getAdminLicenseHideSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminLicenseHideSubscription),
      switchMap(action => this.adminService.getSubscriptionHide(action.licenseId)
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminLicenseHideSubscription({licenseId: action.licenseId, hideSubscription: resp.is});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  updateAdminLicenseHideSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateAdminLicenseHideSubscription),
      switchMap(action => this.adminService.updateSubscriptionHide(action.licenseId, action.hideSubscription)
        .pipe(
          switchMap(resp => {
            this.openSnackBar(`Updated membership hide status`);
            const action1 = AdminActions.SetAdminLicenseHideSubscription({licenseId: action.licenseId, hideSubscription: action.hideSubscription});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        )
      )
    )
  );

  getMembershipStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetMembershipStats),
      switchMap(action => this.adminService.getMembershipStats()
        .pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetMembershipStats({membershipStats: resp});
            return of(action1);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            return of(action1);
          })
        )
      )
    )
  );

  updateBikeFirmware$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateBikeFirmware),
      switchMap((action) => this.adminService.updateBikeFirmware(action.bikeFirmwareInfo)
        .pipe(
          map(data => {
            return AdminActions.SetBikeFirmwareInfo({firmwareType: action.firmwareType, bikeFirmwareInfo: data});
          }),
          catchError(err => of(InfoActions.SetCommonError({message: err.error.message})))
        )
      )
    )
  );

  getBikeFirmwareInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetBikeFirmwareInfo),
      mergeMap((action) => this.adminService.getBikeFirmwareLatest()
        .pipe(
          map(bikeFirmwares => AdminActions.SetBikeFirmwares({bikeFirmwares})),
          catchError(err => of(InfoActions.SetCommonError({title: 'Error', message: verboseErrorMessage(err.error.message)})))
        ))
    )
  );

  GetAdminMembershipUsageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetAdminMembershipUsageList),
      switchMap((action) => {
        this.store.dispatch(AdminActions.SetAdminMembershipUsageDataLoading({isLoading: true}));
        return this.adminService.getMembershipUsage().pipe(
          switchMap(resp => {
            const action1 = AdminActions.SetAdminMembershipUsageList({usageData: resp});
            const action2 = AdminActions.SetAdminMembershipUsageDataLoading({isLoading: false});
            //@ts-ignore
            return of(action1, action2);
          }),
          catchError(errMessage => {
            const action1 = InfoActions.SetCommonError({title: 'Error', message: 'Something went wrong.'});
            const action2 = AdminActions.SetAdminMembershipUsageDataLoading({isLoading: false});
            //@ts-ignore
            return of(action1, action2);
          })
        )
        }
      )
    )
  );

  constructor(private adminService: AdminService,
              protected actions$: Actions,
              private helperService: HelperService,
              protected store: Store<AppState>,
              snackBar: MatSnackBar) {
    super(actions$, store, snackBar);
  }
}

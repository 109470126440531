import { Store } from '@ngrx/store';
import { AppState, PowerChartsState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { filter, tap } from 'rxjs/operators';
import { ChartType, InnerCardDataModel } from '@carol-nx/data';
import { formatChartData, isImperial } from '@carol-nx/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@UntilDestroy()
@Component({
  template: ''
})
export class DashboardPowerMainComponent implements OnInit {
  public powerChartsCards: {
    [Property in keyof PowerChartsState]?: InnerCardDataModel;
  } = {};

  public routeCard = {
    peakPower: '../peakpower',
    peakPowerPer: '../peakpowerperlb'
  }

  public powerChartsTypesOrder: Array<keyof PowerChartsState> = [
    'peakPower',
    'peakPowerPer',
    'maximumAnaerobicPower',
    'maximumAerobicPower',
    'functionalThresholdPower',
  ];

  public powerChartsCardsTitles: {
    [Property in keyof PowerChartsState]: string;
  } = {
    peakPower: 'DASHBOARD_INFO_POWER_PEAK_POWER',
    peakPowerPer: isImperial() ? 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_LB' : 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_KG',
    maximumAnaerobicPower: 'DASHBOARD_INFO_POWER_MAX_ANAEROBIC_POWER',
    maximumAerobicPower: 'DASHBOARD_INFO_POWER_MAX_AEROBIC_POWER',
    functionalThresholdPower: 'DASHBOARD_INFO_POWER_FUNCTIONAL_THRESHOLD_POWER'
  };

  public powerChartTypes: {
    [Property in keyof PowerChartsState]: ChartType;
  } = {
    peakPower: ChartType.PeakPower,
    peakPowerPer: isImperial() ? ChartType.PeakPowerPerLB : ChartType.PeakPowerPerKG,
    maximumAnaerobicPower: ChartType.MaxAnaerobicPower,
    maximumAerobicPower: ChartType.MaxAerobicPower,
    functionalThresholdPower: ChartType.FunctionalThresholdPower
  };

  constructor(protected store: Store<AppState>, protected changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.store.dispatch(RiderActions.GetRiderPeakPowerStats());
    this.store.dispatch(isImperial()
      ? RiderActions.GetRiderPeakPowerPerLbStats()
      : RiderActions.GetRiderPeakPowerPerKgStats()
    );
    this.store.dispatch(RiderActions.GetRiderMaximumAerobicPower());
    this.store.dispatch(RiderActions.GetRiderMaximumAnaerobicPower());
    this.store.dispatch(RiderActions.GetRiderFunctionalThresholdPower());

    this.store.select(RiderSelectors.selectRiderPowerChartsStats).pipe(
      filter(data => !!data),
      tap(data => {
        Object.keys(this.powerChartsCardsTitles).forEach(powerType => {
          const powerData = {...data[powerType]};
          this.powerChartsCards[powerType] = data[powerType] && {
            title: this.powerChartsCardsTitles[powerType],
            chartData: formatChartData(this.powerChartTypes[powerType], powerData),
            tooltipData: ''
          };
        });
        this.changeDetectorRef.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe();
  }
}

<div class="page-wrapper animated fadeIn">

  <div class="intro">
    If you have any issues or need help, please contact us using the form below
  </div>

  <form class="contact-form" [formGroup]="messageForm">
    <mat-form-field floatLabel="auto">
      <mat-label>Email address</mat-label>
      <input matInput formControlName="replyTo" required>
    </mat-form-field>

    <mat-form-field floatLabel="auto">
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject" required>
    </mat-form-field>

    <mat-form-field floatLabel="auto">
      <mat-label>Describe the issue</mat-label>
      <textarea formControlName="body" matInput matTextareaAutosize
                required></textarea>
    </mat-form-field>

    <carol-ui-button (click)="sendMessage()" [isDisabled]="!messageForm.valid || formSent" >Send</carol-ui-button>
  </form>

  <div class="outro">
    You can also email us at support@carolbike.com or call us UK: +44 20 3808 5137 or US: +1 646 809 7241
  </div>

</div>

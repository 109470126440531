import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFormStateTypes } from '@carol-nx/data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppState, AuthActions, AuthSelectors } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { AuthService, DialogService } from '@carol-nx/services';
import { CommonErrorModalComponent } from '../common-error-modal/common-error-modal.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'carol-ui-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit, AfterViewInit {
  @Input()
  public fromWeb: boolean;
  @Output()
  private onLoginSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onLoginSuccess: EventEmitter<{ username, password }> = new EventEmitter<any>();
  @Output()
  private onLoginError: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onResetPassword: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onResetPasswordSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onResetPasswordError: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onStartRegistration: EventEmitter<any> = new EventEmitter<any>();

  public formState: Observable<AuthFormStateTypes>;
  public loginForm: FormGroup;

  public rememberMe = true;

  constructor(private formBuilder: FormBuilder,
              private store: Store<AppState>,
              private authService: AuthService,
              private changeDetectorRef: ChangeDetectorRef,
              private dialogService: DialogService) {

  }

  get formStates() {
    return AuthFormStateTypes;
  }

  public onBlurInput(controlName: string): void {
    if (this.loginForm.controls[controlName].value === '') {
      this.loginForm.controls[controlName].markAsUntouched();
    }
  }

  public ngOnInit(): void {
    this.formState = this.store.select(AuthSelectors.selectAuthFormState);
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  public ngAfterViewInit() {
    // TODO: Rewrite it Angular way. Use element references (ElementRef)
    setTimeout(() => {
      const el = document.getElementById('usi');
      el?.setAttribute('autocomplete', 'off');
      el?.setAttribute('autofill', 'off');
    }, 0);
  }

  public loginSubmit(): void {
    const { username, password } = this.loginForm.value;

    if (this.loginForm.valid && !!username && !!password) {
      this.onLoginSubmit.emit(null);
      this.store.dispatch(AuthActions.LogIn({
        username, password, rememberMe: this.rememberMe, callBack: async (resp) => {
          if (resp.error) {
            console.log('resp.error', resp.error);
            let alert;
            if (resp.status === 401) {
              const accountLocked = resp.error.message === 'auth.account.locked';
              if (accountLocked) {
                this.dialogService.openCertainModal(CommonErrorModalComponent, {
                  data: {
                    title: 'Please verify your registered email before logging in',
                    message: 'Please check for an email from CAROL in your Inbox or potentially other folders (such as Other, Promotions, Junk, etc) and click the verification link'
                  }
                }).afterClosed().toPromise().then(() => {
                  this.onLoginError.emit();
                });
              } else {
                alert = ['Wrong login or password'];
                Object.keys(this.loginForm.controls).forEach(controlName =>
                  this.loginForm.controls[controlName].setErrors({ 'incorrect': true }));
                this.changeDetectorRef.detectChanges();
              }
            } else {
              alert = ['Something went wrong please try again later', 'Error', { username, ...resp }];
            }
            console.log('alert', alert);
            if (alert)
              this.onLoginError.emit(alert);
          } else {
            this.onLoginSuccess.emit(resp);
          }
        }
      }));
    } else {
      this.loginForm.controls.username.markAsTouched();
      this.loginForm.controls.password.markAsTouched();
    }
  }

  public async resetPassword(email: string) {
    this.onResetPassword.emit(email);
    this.authService.sendResetLinkByEmailOrNickname(email).then(
      resp => {
        this.onResetPasswordSuccess.emit();
        this.switchState(AuthFormStateTypes.CheckEmail);
      },
      err => {
        let error;
        if (err.status === 401) {
          error = 'Please verify your registered email before changing your password. Please check for an email from CAROL in your Inbox or potentially other folders (such as Other, Promotions, Junk, etc) and click the verification link.';
        } else if (err.status === 400) {
          error = 'Sorry, the values send with the request were not sufficent to identify a rider!';
        } else {
          error = 'Something was wrong, please check your email and try again later';
        }

        this.onResetPasswordError.emit(error);
      }
    );
  }

  public togglePass(input: HTMLInputElement) {
    const type = input.type;
    input.type = type === 'password' ? 'text' : 'password';
  }

  public startRegistration() {
    this.onStartRegistration.emit();
  }

  public switchState(stateType: AuthFormStateTypes) {
    this.store.dispatch(AuthActions.SetAuthFormState({ formState: stateType }));
  }

  public tryToLogin(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      this.loginSubmit();
    }
  }

  public onChangeInput(fieldName: string, value: string): void {
    this.loginForm.controls[fieldName].setValue(value);
    for (let controlName in this.loginForm.controls) {
      if (controlName !== fieldName) {
        this.loginForm.controls[controlName].updateValueAndValidity();
      }
    }
  }
}

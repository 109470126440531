import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-app-modal.component.html',
  styleUrls: ['./download-app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

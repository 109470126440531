<div class="page-wrapper">
  <span (click)="close()" class="close">&#215;</span>
  <ng-container>
    <h2>{{'HABIT_SCORE' | translate}}</h2>
    <div class="flex-wrapper">
      <div class="chart-wrapper">
        <carol-nx-habit-score-chart [doNotLoad]="true" [initDate]="data" class="habit-score-chart"></carol-nx-habit-score-chart>
      </div>
      <div>
        <h2 [innerHTML]="'SPEEDOMETER_INFO_HABIT_SCORE_DIALOG_TITLE' | translate:({ value: habitScore|async|coalesce:'N/A' })"></h2>
        <carol-nx-info-content [chartData]="chartData"></carol-nx-info-content>
      </div>
    </div>
  </ng-container>
</div>

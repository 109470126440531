import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { RideTypes } from '@carol-nx/data';
import { DialogService, PowerService } from '@carol-nx/services';
import { AppState } from '@carol-nx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { animate, style, transition, trigger } from '@angular/animations';
import { TrendsMainComponent } from '@carol-nx/ui';
import { TrainerService } from '../../services/trainer.service';

@UntilDestroy()
@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slidePage', [
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ])
  ]
})
export class TrendsComponent extends TrendsMainComponent {
  private swipeCoord: [number, number];
  private swipeTime: number;

  constructor(
    protected store: Store<AppState>,
    private dialogService: DialogService,
    protected ref: ChangeDetectorRef,
    private trainerService: TrainerService,
    protected powerService: PowerService
  ) {
    super(store, ref, powerService);
    this.trainerService.init();
    this.loaderSubject.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.dialogService.showLoader();
    });
  }

  public isActive(rideType: RideTypes) {
    return this.selectedType === rideType;
  }

  public animationPageIndex(): number {
    return this.getCurrentRideIndex() * 100 + this.getCurrentSubRideIndex();
  }

  public tabSwipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          if (!this.typeIsLast()) {
            this.selectForwardType();
          }
        } else if (swipe === 'previous') {
          if (!this.typeIsFirst()) {
            this.selectBackType();
          }
        }
      }
    }
  }

  public swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        this.swipeSubTypeChange(swipe);
      }
    }
  }
}

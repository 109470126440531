import { Action, createReducer, on } from '@ngrx/store';
import { AdminState, initialAdminState } from '../state/admin.state';
import { AdminActions } from '../actions';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { FirmwareType } from '@carol-nx/data';

export const adminFeatureKey = 'admin';

const adminReducer = createReducer(
  initialAdminState,
  on(AdminActions.SetAdminRides,
    (state: AdminState, {rides}) => ({...state, rides})
  ),
  on(AdminActions.SetAdminRidesPagingData,
    (state: AdminState, {ridesPaging}) => ({...state, ridesPaging: {...state.ridesPaging, ...ridesPaging}})
  ),
  on(AdminActions.SetSelectedAdminRideId,
    (state, {rideId}) => ({...state, selectedRideId: rideId})
  ),
  on(AdminActions.DeleteAdminRideSuccess,
    (state: AdminState, {rideId}) => {
      const allRides = Object.assign([], state.rides);
      const updatedRides = remove(allRides, (ride) => {
        return ride.id !== rideId;
      });
      return {
        ...state,
        rides: updatedRides
      };
    }
  ),
  on(AdminActions.SetAdminRideExtended,
    (state: AdminState, {extendedRide}) => ({...state, selectedRideExtendData: extendedRide})
  ),
  on(AdminActions.SetAdminRidersList,
    (state: AdminState, {riders}) => ({...state, riders: [...(state.riders ? state.riders : []), ...riders]})
  ),
  on(AdminActions.ResetAdminRidersList,
    (state: AdminState, {riders}) => ({...state, riders: [...riders]})
  ),
  on(AdminActions.SetAdminRidersPagingData,
    (state: AdminState, {pagingData}) => ({...state, ridersPaging: {...pagingData}})
  ),
  on(AdminActions.SetSelectedAdminRiderId,
    (state, {riderId}) => ({...state, selectedRiderId: riderId})
  ),
  on(AdminActions.SetAdminLicensesList,
    (state: AdminState, {licenses}) => ({...state, licenses: [...(state.licenses ? state.licenses : []), ...licenses]})
  ),
  on(AdminActions.ResetAdminLicensesList,
    (state: AdminState) => ({...state, licenses: []})
  ),
  on(AdminActions.SetAdminLicensesPagingData,
    (state: AdminState, {pagingData}) => ({...state, licensesPaging: {...pagingData}})
  ),
  on(AdminActions.SetOwnerToLicenseSuccess,
    (state: AdminState, {license, newOwner}) => {
      const licenses = cloneDeep(state.licenses);
      const licensesPaging = Object.assign({}, state.licensesPaging);
      if (licenses) {
        licenses.map(el => {
          if (el.owners && el.id === license.id) {
            el.owners = [...el.owners, newOwner];
          }
          return el;
        });
      }
      const riders = cloneDeep(state.riders);
      if (riders) {
        const rider = riders.find(f => f.id === newOwner.id);
        if (rider && rider.ownedLicenses) {
          rider.ownedLicenses.push(license);
        }
      }

      return {...state, licenses, licensesPaging, riders};
    }
  ),
  on(AdminActions.DeleteOwnerLicenseSuccess,
    (state: AdminState, {licenseId, deleteOwner}) => {
      const licenses = cloneDeep(state.licenses);
      const licensesPaging = Object.assign({}, state.licensesPaging);
      if (licenses) {
        licenses.map(el => {
          if (el.id === licenseId && el.owners) {
            el.owners = el.owners.filter(f => f.id !== deleteOwner.id);
          }
          return el;
        });
      }
      const riders = cloneDeep(state.riders);
      if (riders) {
        const rider = riders.find(f => f.id === deleteOwner.id);
        if (rider && rider.ownedLicenses) {
          rider.ownedLicenses = rider.ownedLicenses.filter(f => f.id !== licenseId);
        }
      }
      return {...state, licenses, licensesPaging, riders};
    }
  ),
  on(AdminActions.DeleteAdminRiderFromLicenseSuccess,
    (state: AdminState, {licenseId, riderId}) => {
      const riders = cloneDeep(state.riders);
      if (riders) {
        const rider = riders.find(f => f.id === riderId);
        if (rider && rider.licenses) {
          rider.licenses = rider.licenses.filter(f => f.id !== licenseId);
        }
      }
      return {...state, riders};
    }
  ),
  on(AdminActions.UpdateAdminRiderInfoSuccess,
    (state: AdminState, {rider}) => {
      const allRiders = Object.assign([], state.riders);
      if (rider) {
        const findedRiderIndex = allRiders.findIndex(f => f.id === rider.id);
        if (findedRiderIndex != null) {
          allRiders[findedRiderIndex] = rider;
        } else {
          allRiders.push(rider);
          allRiders.sort((r1, r2) => r1.id - r2.id);
        }
      }
      return {
        ...state,
        riders: allRiders
      };
    }
  ),
  on(AdminActions.DeleteAdminRiderSuccess,
    (state: AdminState, {riderId}) => {
      const allRiders = Object.assign([], state.riders);
      const riderIndex = allRiders.findIndex(rider => rider.id === riderId);
      if (riderIndex != null) {
        let newRiderInfo = Object.assign({}, allRiders[riderIndex]);
        const preffix = `deleted${riderId}-`;
        newRiderInfo.nickname = `${preffix}${newRiderInfo.nickname}`;
        newRiderInfo.email = `${preffix}${newRiderInfo.email}`;
        allRiders[riderIndex] = newRiderInfo;
      }
      return {
        ...state,
        riders: allRiders
      };
    }
  ),
  on(AdminActions.UpdateAdminRiderFieldSuccess,
    (state: AdminState, {riderId, riderPart}) => {
      const allRiders = Object.assign([], state.riders);
      const findedRiderIndex = allRiders.findIndex(f => f.id === riderId);
      if (findedRiderIndex != null && riderPart) {
        const rider = Object.assign({}, allRiders[findedRiderIndex]);
        for (const key in riderPart) {
          if (riderPart.hasOwnProperty(key)) {
            rider[key] = riderPart[key];
          }
        }
        allRiders[findedRiderIndex] = rider;
      }
      return {
        ...state,
        riders: allRiders
      };
    }
  ),
  on(AdminActions.SetAdminBikesLoading,
    (state: AdminState, {isLoading}) => ({...state, bikesIsLoading: isLoading})
  ),
  on(AdminActions.SetSelectedAdminBikeId,
    (state, {selectedBikeId}) => ({...state, selectedBikeId})
  ),
  on(AdminActions.SetAdminBikesPage,
    (state: AdminState, {bikesData}) => {
      const resultBikes = [...(state.bikes ? state.bikes : []), ...bikesData];
      return {...state, bikes: resultBikes};
    }
  ),
  on(AdminActions.ResetAdminBikes,
    (state: AdminState, {bikesData}) => {
      return {...state, bikes: bikesData ? bikesData : []};
    }
  ),
  on(AdminActions.ResetAdminBikesForModal,
    (state: AdminState, { bikesData }) => {
      return { ...state, bikesForModal: bikesData ? bikesData : [] };
    }
  ),
  on(AdminActions.SetAdminBikesPagingData,
    (state: AdminState, {pagingData}) => {
      return {...state, bikesPaging: pagingData};
    }
  ),
  on(AdminActions.UpdateAdminBikeInfoSuccess,
    (state: AdminState, {bike}) => {
      const allBikes = Object.assign([], state.bikes);
      if (bike) {
        const findedBikeIndex = allBikes.findIndex(f => f.id === bike.id);
        if (findedBikeIndex != null) {
          allBikes[findedBikeIndex] = Object.assign({}, allBikes[findedBikeIndex], bike);
        } else {
          allBikes.push(bike);
          allBikes.sort((r1, r2) => r1.id - r2.id);
        }
      }
      return {
        ...state,
        bikes: allBikes
      };
    }
  ),
  on(AdminActions.SetSelectedAdminLicense,
    (state, {selectedLicense}) => ({...state, selectedLicense})
  ),
  on(AdminActions.SetSelectedAdminLicenseId,
    (state, {selectedLicenseId}) => ({...state, selectedLicenseId})
  ),
  on(AdminActions.UpdateAdminLicenseSuccess,
    (state: AdminState, {licenseData}) => {
      const allLicenses = Object.assign([], state.licenses);
      let selectedLicense = state.selectedLicense;
      if (licenseData) {
        const findedLicenseIndex = allLicenses.findIndex(f => f.id === licenseData.id);
        if (findedLicenseIndex != null) {
          let newLicenseData = Object.assign({}, allLicenses[findedLicenseIndex]);
          newLicenseData = Object.assign(newLicenseData, licenseData);
          allLicenses[findedLicenseIndex] = newLicenseData;
        } else {
          allLicenses.push(licenseData);
          allLicenses.sort((r1, r2) => r1.id - r2.id);
        }
        if (selectedLicense.id === licenseData.id) {
          selectedLicense = Object.assign({}, selectedLicense, licenseData);
        }
      }
      const licensesPaging = Object.assign({}, state.licensesPaging);
      return {
        ...state,
        licenses: allLicenses,
        licensesPaging,
        selectedLicense
      };
    }
  ),
  on(AdminActions.SetBikeAppInfo,
    (state, {bikeAppInfo}) => ({...state, bikeAppInfo})
  ),
  on(AdminActions.SetAdminBikeDetail,
    (state, {bikeDetail}) => ({...state, bikeDetail})
  ),
  on(AdminActions.SetAdminBikeTabletHistory,
    (state, {tabletHistory}) => ({...state, tabletHistory})
  ),
  on(AdminActions.SetAdminAppVersions,
    (state, {appVersions}) => ({...state, appVersions})
  ),
  on(AdminActions.UpdateAdminBikeLicenseSuccess,
    (state: AdminState, {bikeId, license}) => {
      const allBikes = Object.assign([], state.bikes);
      const updatedBikeId = allBikes.findIndex(f => f.id === bikeId);
      if (updatedBikeId >= 0) {
        const updatedBike = Object.assign({}, allBikes[updatedBikeId]);
        updatedBike.licenseInfo = license;
        allBikes[updatedBikeId] = updatedBike;
      }
      return {
        ...state,
        bikes: allBikes
      };
    }
  ),
  on(AdminActions.GetAdminFrameSuccess,
    (state: AdminState, {frame}) => ({...state, selectedBikeFrame: frame})
  ),
  on(AdminActions.MoveRideToRiderSuccess,
    (state: AdminState, {rider, rideId}) => {
      const updatedRides = state.rides.map(el => {
        if (el.id === rideId) {
          const changingRide = Object.assign({}, el);
          changingRide.rider = rider;
          el = Object.assign({}, changingRide);
        }
        return el;
      });
      return {...state, rides: updatedRides};
    }
  ),
  on(AdminActions.SetAdminRiderDetail,
    (state, {riderDetail}) => ({...state, riderDetail})
  ),
  on(AdminActions.GetAdminBikeSettingsSuccess,
    (state: AdminState, {bikeSettings}) => ({...state, bikeSettings})
  ),
  on(AdminActions.RecalculateAdminRideSuccess,
    (state: AdminState, {bikeId, rideId, ride}) => {
      const allRides = Object.assign([], state.rides);
      const updatedRideIndex = allRides.findIndex(f => f.id === rideId);
      if (updatedRideIndex >= 0) {
        const updatedRide = Object.assign({}, ride);
        allRides[updatedRideIndex] = updatedRide;
      }
      return {...state, rides: allRides};
    }
  ),
  on(AdminActions.SetAdminLicenseDiscount,
    (state, {discount}) => ({...state, licenseDiscount: discount})
  ),
  on(AdminActions.SetAdminLicenseNeedSubscription,
    (state: AdminState, {licenseId, needSubscription}) => {
      let selectedLicense = state.selectedLicense;
      if (selectedLicense?.id === licenseId) {
        selectedLicense = Object.assign({}, selectedLicense);
        selectedLicense.needSubscription = needSubscription;
      }
      let licenses = state.licenses;
      if (licenses) {
        const foundLicenseIndex = licenses.findIndex(f => f.id === licenseId);
        if (foundLicenseIndex && licenses[foundLicenseIndex]) {
          licenses = Object.assign([], licenses);
          licenses[foundLicenseIndex].needSubscription = needSubscription;
        }
      }
      return {...state, selectedLicense, licenses};
    }
  ),
  on(AdminActions.SetAdminLicenseResetSubscription,
    (state: AdminState, {license}) => {
      let selectedLicense = state.selectedLicense;
      if (selectedLicense?.id === license.id) {
        selectedLicense = Object.assign({}, license);
      }
      let licenses = state.licenses;
      if (licenses) {
        const foundLicenseIndex = licenses.findIndex(f => f.id === license.id);
        if (foundLicenseIndex) {
          licenses = Object.assign([], licenses);
          Object.assign(licenses[foundLicenseIndex], license)
        }
      }
      return {...state, selectedLicense, licenses};
    }
  ),
  on(AdminActions.SetAdminLicenseDiscountLimit,
    (state, {discountLimit}) => ({...state, licenseDiscountLimit: discountLimit})
  ),
  on(AdminActions.SetAdminLicensePaymentInvoices,
    (state, {paymentInvoices}) => ({...state, paymentInvoices})
  ),
  on(AdminActions.SetAdminLicenseHideSubscription,
    // (state, {hideSubscription}) => ({...state, hideSubscription})
    (state: AdminState, {licenseId, hideSubscription}) => {
      let selectedLicense = state.selectedLicense;
      if (selectedLicense?.id === licenseId) {
        selectedLicense = Object.assign({}, selectedLicense);
        selectedLicense.hideSubscription = hideSubscription;
      }
      let licenses = state.licenses;
      if (licenses) {
        const foundLicenseIndex = licenses.findIndex(f => f.id === licenseId);
        if (foundLicenseIndex) {
          licenses = Object.assign([], licenses);
          licenses[foundLicenseIndex].hideSubscription = hideSubscription;
        }
      }
      return {...state, selectedLicense, licenses};
    }
  ),
  on(AdminActions.SetMembershipStats,
    (state, {membershipStats}) => ({...state, membershipStats})
  ),
  on(AdminActions.SetBikeFirmwares,
    (state, {bikeFirmwares}) => ({...state, firmwares: bikeFirmwares})
  ),
  on(AdminActions.SetBikeFirmwareInfo,
    (state: AdminState, {firmwareType, bikeFirmwareInfo}) => {
      let firmwares = {...state.firmwares};
      switch (firmwareType) {
        case FirmwareType.Ftdi:
          firmwares.ftdi = {...bikeFirmwareInfo};
          break;
        case FirmwareType.Indoor:
          firmwares.indoor = {...bikeFirmwareInfo};
          break;
      }
      return {...state, firmwares}
    }
  ),
  on(AdminActions.SetAdminMembershipUsageDataLoading,
    (state: AdminState, {isLoading}) => ({...state, membershipUsageDataIsLoading: isLoading})
  ),
  on(AdminActions.SetAdminMembershipUsageList,
    (state: AdminState, {usageData}) => ({...state, membershipUsage: usageData})
  ),
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}

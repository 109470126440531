<div class="page-wrapper animated fadeIn">

  <div class="trends-switcher">
    <div class="switcher-option" [class.active]="isActive(type)"
         *ngFor="let type of rideTypes"
         (click)="setParentRideType(type)"
         (touchend)="tabSwipe($event, 'end')" (touchstart)="tabSwipe($event, 'start')">
      {{rideTypeTitleMap[type]}}
    </div>
    <div (touchend)="tabSwipe($event, 'end')" (touchstart)="tabSwipe($event, 'start')" class="sliders-holder">
      <div class="slide" (click)="selectBackType()" [class.disabled]="typeIsFirst()">
        <mat-icon>chevron_left</mat-icon>
      </div>
      <div class="slide" (click)="selectForwardType()" [class.disabled]="typeIsLast()">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
  </div>
  <div (touchend)="swipe($event, 'end')" (touchstart)="swipe($event, 'start')" *ngIf="trendCharts.length" [@slidePage]="animationPageIndex()" class="trends">
    <mat-card *ngFor="let trendChart of trendCharts" class="mat-wrapper">
      <mat-card-title>{{trendChart.title}}</mat-card-title>
      <mat-card-content>
        <app-chart [data]="trendChart.trends"
                   [lineColor]="trendChart.chartColor">
        </app-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div (touchend)="swipe($event, 'end')" (touchstart)="swipe($event, 'start')" *ngIf="!trendCharts.length && isLoaded" [@slidePage]="animationPageIndex()">
    <div class="placeholder-page">
      <carol-ui-no-ride-data></carol-ui-no-ride-data>
    </div>
  </div>
</div>

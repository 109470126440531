import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, AuthActions } from '@carol-nx/store';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { saveTokensToStorage } from '@carol-nx/utils';
import { AuthService, DialogService } from '@carol-nx/services';
import { Router } from '@angular/router';
import { MessageModalComponent } from '../components/modals/message-modal/message-modal.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
              private dialogService: DialogService,
              private router: Router,
              private store: Store<AppState>) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    if (request.url.includes('check') || request.url.includes('auth/reset') ||
      request.url.includes('login') || request.url.includes('logout') ||
      request.url.includes('/web-api/country') || request.url.includes('googleapis') ||
      request.url.includes('register') || request.url.includes('assets')) {
      return next.handle(request);
    }

    if (accessTokenExpiresAt && !this.tokenExpired(+accessTokenExpiresAt)) {
      request = this.addAuthenticationToken(request);
      return next.handle(request);
    } else {
      console.log('token has been expired');
      return this.handleExpiredTokenRequest(next, request);
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('accessToken');
    if (!token || request.url.includes('refresh-token')) {
      return request;
    }

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + token)
    });
  }

  private tokenExpired(tokenExpTime: number): boolean {
    return Date.now() > tokenExpTime;
  }

  private handleExpiredTokenRequest(next: HttpHandler, request: HttpRequest<any>) {
    // close all modals if stack somewhere
    this.dialogService.closeModal();
    return next.handle(request).pipe(
      catchError(error => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes('refresh-token') ||
          request.url.includes('login')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes('refresh-token')) {
            this.authService.logOut();
          }

          return throwError(error.error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return throwError(error.error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject
            .pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          // Call auth.refreshAccessToken(this is an Observable that will be returned)
          const refreshToken = JSON.parse(localStorage.getItem('rememberMe')) ? localStorage.getItem('refreshToken') : sessionStorage.getItem('refreshToken');
          return refreshToken && this.authService
            .refreshTokens(refreshToken)
            .pipe(
              switchMap((tokens: any) => {
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                this.refreshTokenInProgress = false;
                saveTokensToStorage(tokens);
                this.store.dispatch(AuthActions.LogInSuccess({tokens}));
                this.refreshTokenSubject.next(tokens.accessToken);

                return next.handle(this.addAuthenticationToken(request));
              }),
              catchError(() => {
                this.refreshTokenInProgress = false;

                this.authService.logOut();
                this.dialogService.openCertainModal(MessageModalComponent, {
                  data: {
                    title: 'Token expired',
                    description: 'You are redirected to the login page',
                    buttonText: 'Ok'
                  },
                  disableClose: true
                }).afterClosed().subscribe(() => {
                  this.store.dispatch(AuthActions.LogOut());
                  this.router.navigate(['/auth']);
                });
                return throwError(error.error);
              })
            ) || throwError('Refresh token is ' + typeof refreshToken);
        }
      })
    );
  }
}

import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {AppState, initialAppState} from '../state';
import * as authReducer from './auth.reducer';
import * as riderReducer from './rider.reducer';
import * as ridesReducer from './rides.reducer';
import * as infoReducer from './info.reducer';
import * as bookingsReduces from './booking.reducer';
import * as adminReducer from './admin.reducer';
import * as leaderboardReducer from './leaderboard.reducer';
import * as ownerReducer from './owner.reducer';

export const reducers: ActionReducerMap<AppState> = {
  [adminReducer.adminFeatureKey]: adminReducer.reducer,
  [authReducer.authFeatureKey]: authReducer.reducer,
  [bookingsReduces.bookingFeatureKey]: bookingsReduces.reducer,
  [infoReducer.infoFeatureKey]: infoReducer.reducer,
  [riderReducer.riderFeatureKey]: riderReducer.reducer,
  [ridesReducer.ridesFeatureKey]: ridesReducer.reducer,
  [leaderboardReducer.leaderboardFeatureKey]: leaderboardReducer.reducer,
  [ownerReducer.ownerFeatureKey]: ownerReducer.reducer
};

export function clearOnLogoutMetaReducer(reducer) {
  return (state, action) => {
    if (action.type === '[Auth] Log out') {
      return reducer(initialAppState, action);
    }
    return reducer(state, action);
  };
}

const environment = {
  dev: true,
  production: false,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearOnLogoutMetaReducer] : [clearOnLogoutMetaReducer];

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {PowerTypes, TopUserModel} from '@carol-nx/data';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  private leaderboardApiUrl = '/leaderboard-api';
  constructor(public http: HttpClient) {
  }

  public getWeeklyPowerTopUsersByPowerTypeAndGender(
    leaderboardApiCtrl: string,
    powerType: PowerTypes,
    licenseId?: number,
    riderId?: number,
    gender?: number,
    page?: number,
    size?: number,
  ): Observable<any> {
    const genderObj = gender !== undefined ? {gender: String(gender)} : {};
    const licenseObj = licenseId !== undefined ? {licenseId: String(licenseId)} : {};
    const pageObj = page !== undefined ? {page: String(page)} : {};
    const sizeObj = size !== undefined ? {size: String(size)} : {}

    switch (leaderboardApiCtrl) {
      case 'global':
        return this.http.get(`${this.leaderboardApiUrl}/rider/${riderId}/personal/power/${powerType}/top-users`,
          {params: {...genderObj, ...pageObj, ...sizeObj}});
      case 'company':
        return this.http.get(`${this.leaderboardApiUrl}/rider/${riderId}/personal/power/${powerType}/top-users`,
          {params: {...genderObj, ...licenseObj, ...pageObj, ...sizeObj}});
      case 'friends':
        return this.http.get(`${this.leaderboardApiUrl}/rider/${riderId}/friends/power/${powerType}/top-users/pageable`,
          {params: {...genderObj, ...pageObj, ...sizeObj}});
    }
  }

  public getWeeklyUserPositionByPowerTypeAndGender(
    leaderboardApiCtrl: string,
    powerType: PowerTypes,
    licenseId?: number,
    riderId?: number,
    gender?: number,
    size?: number,
  ): Observable<TopUserModel> {
    const genderObj = gender !== undefined ? {gender: String(gender)} : {};
    const licenseObj = licenseId !== undefined ? {licenseId: String(licenseId)} : {};
    const sizeObj = size !== undefined ? {size: String(size)} : {}

    switch (leaderboardApiCtrl) {
      case 'global':
        return this.http.get(`${this.leaderboardApiUrl}/rider/${riderId}/personal/power/${powerType}/score`,
          {params: {...genderObj, ...sizeObj}});
      case 'company':
        return this.http.get(`${this.leaderboardApiUrl}/rider/${riderId}/personal/power/${powerType}/score`,
          {params: {...genderObj, ...licenseObj, ...sizeObj}});
      case 'friends':
        return of(undefined);
    }
  }
}

<div class="container">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="mat-typography">

    <mat-form-field>
      <input matInput placeholder="Search rider by email, id, name or nickname"
             [(ngModel)]="selectedUser"
             [formControl]="riderSearch"
             [matAutocomplete]="userSearchAutoComplete"/>
      <mat-autocomplete #userSearchAutoComplete="matAutocomplete" [displayWith]="userDisplay">
        <mat-option *ngFor="let user of searchingUsers | async"
                    [value]="user">
          {{user.firstName}} {{user.lastName}} - {{user.nickname}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button cdkFocusInitial
            color="primary"
            [disabled]="!riderSearch.valid || isLoading"
            (click)="riderSelected()">
      Select rider
    </button>
  </mat-dialog-actions>
</div>

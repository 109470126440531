import { Pipe, PipeTransform } from '@angular/core';

/*
 * Applies Coalescing to the value
 * Takes a fallback value argument.
 * Usage:
 *   value | coalesce:fallbackValue
 * Example:
 *   {{ null | coalesce:'some fallback value' }}
 *   formats to: 'some fallback value'
*/
@Pipe({
  name: 'coalesce'
})
export class CoalescePipe implements PipeTransform {

  transform<T, K>(value: T, fallbackValue: K): T | K {
    return value == undefined ? fallbackValue : value;
  }

}

import { Component } from '@angular/core';
import { SimpleList } from '@carol-nx/data';
import { TranslateService } from '@ngx-translate/core';
import { isImperial } from '@carol-nx/utils';

@Component({
  template: ''
})
export class DefinitionsMainComponent {
  public readonly definitionsArr: SimpleList[];

  constructor(translate: TranslateService) {
    const imperial = isImperial();
    const unit = {
      unitFull: translate.instant(imperial ? 'DEFINITIONS_POUND' : 'DEFINITIONS_KILOGRAM'),
      unit: translate.instant(imperial ? 'LB' : 'KG')
    };
    this.definitionsArr = [
      {
        title: translate.instant('DASHBOARD_INFO_TITLE_PEAK_POWER'),
        text: translate.instant('DEFINITIONS.PEAK_POWER_DESCRIPTION')
      },
      {
        title: translate.instant(imperial ? 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_LB' : 'DASHBOARD_INFO_POWER_PEAK_POWER_PER_KG'),
        text: translate.instant('DEFINITIONS.PEAK_POWER_PER_DESCRIPTION', unit)
      },
      {
        title: translate.instant('DASHBOARD_INFO_TITLE_FITNESS_SCORE'),
        text: translate.instant('DEFINITIONS.FITNESS_SCORE_DESCRIPTION')
      },
      {
        title: translate.instant(imperial ? 'FITNESS_SCORE_PER_LB' : 'FITNESS_SCORE_PER_KG'),
        text: translate.instant('DEFINITIONS.FITNESS_SCORE_PER_DESCRIPTION', unit)
      },
      {
        title: translate.instant('HABIT_SCORE'),
        text: translate.instant('DEFINITIONS.HABIT_SCORE_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_FITNESS_PEAK_HEART_RATE'),
        text: translate.instant('DEFINITIONS.PEAK_HEART_RATE_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.CADENCE_TITLE'),
        text: translate.instant('DEFINITIONS.CADENCE_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_CALORIES'),
        text: translate.instant('DEFINITIONS.CALORIES_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.CALORIES_EPOC_TITLE'),
        text: translate.instant('DEFINITIONS.CALORIES_EPOC_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_VO2MAX'),
        text: translate.instant('DEFINITIONS.VO2MAX_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_POWER_FUNCTIONAL_THRESHOLD_POWER'),
        text: translate.instant('DEFINITIONS.FTP_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_POWER_MAX_AEROBIC_POWER'),
        text: translate.instant('DEFINITIONS.MAEP_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_POWER_MAX_ANAEROBIC_POWER'),
        text: translate.instant('DEFINITIONS.MANP_DESCRIPTION')
      },
      {
        title: translate.instant('SPEEDOMETER_INFO_AVERAGE_POWER'),
        text: translate.instant('DEFINITIONS.AVG_POWER_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_TITLE_ENERGY_OUTPUT'),
        text: translate.instant('DEFINITIONS.ENERGY_OUTPUT_DESCRIPTION')
      },
      {
        title: translate.instant('DASHBOARD_INFO_ACTIVITY_RIDES_PER_WEEK'),
        text: translate.instant('DEFINITIONS.RIDES_PER_WEEK_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.AVERAGE_SPEED_TITLE'),
        text: translate.instant('DEFINITIONS.AVERAGE_SPEED_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.DISTANCE_TITLE'),
        text: translate.instant('DEFINITIONS.DISTANCE_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.PEERS_TITLE'),
        text: translate.instant('DEFINITIONS.PEERS_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.STARS_TITLE'),
        text: translate.instant('DEFINITIONS.STARS_DESCRIPTION')
      },
      {
        title: translate.instant('DEFINITIONS.DISCLAIMER_INVALID_RIDE_TITLE'),
        text: translate.instant('DEFINITIONS.DISCLAIMER_INVALID_RIDE_DESCRIPTION')
      }
    ];
  }
}

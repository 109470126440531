import { createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { CAROL_DATE_FORMATS } from '@carol-nx/data';

const selectRiderState = (state: AppState) => state && state.rider;

export const selectRider = createSelector(
  selectRiderState,
  state => state?.user
);

export const selectRiderId = createSelector(
  selectRiderState,
  state => state?.clientRiderId || state?.riderId
);

export const selectRealRiderId = createSelector(
  selectRiderState,
  state => state?.riderId
);

export const selectSelectedRiderId = createSelector(
  selectRiderState,
  state => state.selectedRiderId
);

export const selectRiderStats = createSelector(
  selectRiderState,
  (state) => state.stats
);

export const selectRidersLicenses = createSelector(
  selectRiderState,
  state => state.licenses
);

export const selectRidersOwnedLicenses = createSelector(
  selectRiderState,
  state => state.ownedLicenses
);

export const selectRiderCurrentLicense = createSelector(
  selectRiderState,
  state => state?.currentLicense
);

export const selectOwnerMenuAvailable = createSelector(
  selectRiderState,
  state => state?.ownerMenuAvailable
);

export const selectClientsMenuAvailable = createSelector(
  selectRiderState,
  state => !!state?.trainees?.length
);

export const selectCurrentBike = createSelector(
  selectRiderState,
  state => state.currentBike
);

export const selectOwnedBikes = createSelector(
  selectRiderState,
  state => state.ownedBikes
);

export const selectOwnedRiders = createSelector(
  selectRiderState,
  state => state.ownedRiders
);

export const selectRidersPaging = createSelector(
    selectRiderState,
    (state) => state.ridersPaging
);

export const selectFitnessScoreChartStats = createSelector(
  selectRiderState,
  state => state && state.fitnessScoreChartStats,
);

export const selectFitnessScorePerLbChartStats = createSelector(
  selectRiderState,
  state => state && state.fitnessScorePerLbChartStats,
);

export const selectFitnessScorePerKgChartStats = createSelector(
  selectRiderState,
  state => state && state.fitnessScorePerKgChartStats,
);

export const selectVO2MaxChartStats = createSelector(
  selectRiderState,
  state => state && state.VO2MaxChartStats
);

export const selectPeakHeartRateChartStats = createSelector(
  selectRiderState,
  state => state && state.peakHeartRateChartStats
);

export const selectRiderCaloriesChartStats = createSelector(
  selectRiderState,
  state => state && state.caloriesChartStats,
);

export const selectRiderPeakPowerStats = createSelector(
  selectRiderState,
  state => state && state.peakPowerStats,
);

export const selectRiderPeakPower = createSelector(
  selectRiderState,
  state => state && state.peakPowerData,
);

export const selectRiderPeakHeartRate = createSelector(
  selectRiderState,
  state => state && state.peakHeartRateData,
);

export const selectRiderPeakPowerPerLb = createSelector(
  selectRiderState,
  state => state && state.peakPowerPerLbData,
);

export const selectRiderPeakPowerPerKg = createSelector(
  selectRiderState,
  state => state && state.peakPowerPerKgData,
);

export const selectRiderTotalPower = createSelector(
  selectRiderState,
  state => state && state.totalPowerData,
);

export const selectRiderTotalPowerStats = createSelector(
  selectRiderState,
  state => state && state.totalPowerStats,
);

export const selectRiderStreaks = createSelector(
  selectRiderState,
  state => state && state.streaks
);

export const selectRiderCaloriesByRideTypeData = createSelector(
  selectRiderState,
  state => state && state.caloriesData,
);

export const selectRiderPowerChartsStats = createSelector(
  selectRiderState,
  state => state && state.powerChartsStats,
);

export const selectRiderTrendsData = createSelector(
  selectRiderState,
  state => state.trends
);

export const selectRiderHabitScoreChart = createSelector(
  selectRiderState,
  state => state.habitScoreChart
);

export const selectRiderHabitScore = createSelector(
  selectRiderState,
  state => state.habitScore
);

export const selectRiderMonthlyActivity = createSelector(
  selectRiderState,
  state => state.datesWithRides
);

export const selectRiderLastRideAchievements = createSelector(
  selectRiderState,
  state => state?.lastRideAchievements
);

export const selectRiderSharePreviewImageUrl = createSelector(
  selectRiderState,
  state => state.sharePreviewImageUrl
);

export const selectRiderTrainers = createSelector(
  selectRiderState,
  state => state.trainers
);

export const selectRiderTrainersPaging = createSelector(
  selectRiderState,
  state => state.trainersPaging
);

export const selectRiderTrainees = createSelector(
  selectRiderState,
  state => state.trainees
);

export const selectRiderTraineesPaging = createSelector(
  selectRiderState,
  state => state.traineesPaging
);

export const selectRiderTrainee = createSelector(
  selectRiderState,
  state => state.selectedTrainee
);

export const selectRidersDisplayDateFormat = createSelector(
  selectRiderState,
  state => (state.countryId && state.countryId === 222)
    ? CAROL_DATE_FORMATS.display.usDateFormat
    : CAROL_DATE_FORMATS.display.commonDate
);

export const selectRidersDisplayDateTimeFormat = createSelector(
  selectRiderState,
  state => (state.countryId && state.countryId === 222)
    ? CAROL_DATE_FORMATS.display.usDateTimeFormat
    : CAROL_DATE_FORMATS.display.commonDateTime
);

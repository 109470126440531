import {
  AdminLicenseModel,
  AdminRiderModel,
  BikeModel,
  CaloriesModel,
  ChartType,
  LastRideAchievements,
  LicenseModel,
  MonthlyStatsModel,
  OwnerModel,
  PowerStatsChartModel,
  PowerTypes,
  QueryParamsModel,
  RideStats,
  RideTypes,
  ScorePositionModel,
  StatsModel,
  StreaksModel,
  TablePagingData,
  TopUserModel,
  Trainee,
  Trainer,
  TrendsModel,
  UserModel
} from '@carol-nx/data';
import { createAction, props } from '@ngrx/store';

export const GetRiderData = createAction(
  '[Rider] Get Rider data',
  props<{riderId: number}>()
);

export const GetRiderLastRideAchievements = createAction(
  '[Rider] Get Rider Last Ride Achievements'
);

export const SetRiderLastRideAchievements = createAction(
  '[Rider] Set Rider Last Ride Achievements',
  props<{lastRideAchievements: LastRideAchievements}>()
);

export const SetRiderData = createAction(
  '[Rider] Set Rider data',
  props<{riderData: any}>()
);

export const SetClientRiderId = createAction(
  '[Rider] Set Client Rider Id',
  props<{clientRiderId: number}>()
);

export const GetExistingRiderData = createAction(
  '[Rider] Get existing Rider data',
  props<{accessToken: string, riderId: number}>()
);

export const SetRiderPositionsByRideTypeAndRideStats = createAction(
  '[Rider] Set Rider Positions By Ride Type And Ride Stats',
  props<{positions: ScorePositionModel, rideType: RideTypes, rideStats: RideStats}>()
);

export const SetRiderChartDataByPowerType = createAction(
  '[Rider] Set Rider chart data',
  props<{chartData: number[]}>()
);

export const GetRiderTrendsByRideType = createAction(
  '[Rider] Get Rider trends by ride type',
  props<{rideType: RideTypes}>()
);

export const GetRiderHabitScoreChart = createAction(
  '[Rider] Get Rider habitScoreChart',
  props<{ date?: number }>()
);

export const AddTopRidersList = createAction(
  '[Rider] Add top Riders list',
  props<{powerType: PowerTypes, RidersList: TopUserModel[]}>()
);

export const GetRiderStreaks = createAction(
  '[Rider] Get Rider streaks',
);

export const SetRiderStreaks = createAction(
  '[Rider] Set Rider streaks',
  props<{streaks: StreaksModel}>()
);

export const GetRiderMonthlyActivity = createAction(
  '[Rider] Get monthly activity',
  props<{month: number, year: number}>()
);

export const GetRiderLastActivity = createAction(
  '[Rider] Get last activity'
);

export const SetRiderMonthlyActivity = createAction(
  '[Rider] Set monthly activity',
  props<{datesWithRides: MonthlyStatsModel[]}>()
);

export const GetCaloriesByRideType = createAction(
  '[Rider] Get calories by ride type',
  props<{rideType: RideTypes}>()
);

export const SetCaloriesByRideType = createAction(
  '[Rider] Set calories by ride type',
  props<{caloriesData: CaloriesModel, rideType: RideTypes}>()
);

export const GetTotalPowerByRideType = createAction(
  '[Rider] Get total power by ride type',
  props<{rideType: RideTypes}>()
);


export const SetTotalPowerByRideType = createAction(
  '[Rider] Set total power by ride type',
  props<{totalPower: PowerStatsChartModel, rideType: RideTypes}>()
);

export const GetCalories = createAction(
  '[Rider] Get calories'
);

export const SetCalories = createAction(
  '[Rider] Set calories',
  props<{calories: CaloriesModel}>()
);

export const SetPeakPowerByRideType = createAction(
  '[Rider] Set peak power by ride type',
  props<{peakPower: PowerStatsChartModel, rideType: RideTypes}>()
);

export const SetPeakPowerPerLbByRideType = createAction(
  '[Rider] Set peak power per lb by ride type',
  props<{peakPowerPerLb: PowerStatsChartModel, rideType: RideTypes}>()
);

export const GetRiderPeakPowerStats = createAction(
  '[Rider] Get Rider Peak Power Stats'
);

export const GetPeakPowerByRideType = createAction(
  '[Rider] Get peak power by ride type',
  props<{rideType: RideTypes}>()
);

export const GetPeakPowerPerLbByRideType = createAction(
  '[Rider] Get peak power per lb by ride type',
  props<{rideType: RideTypes}>()
);

export const SetRiderPeakPowerStats = createAction(
  '[Rider] Set Rider Peak Power Stats',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderTotalPowerStats = createAction(
  '[Rider] Get Rider Total Power Stats'
);

export const SetRiderTotalPowerStats = createAction(
  '[Rider] Set Rider Total Power Stats',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderPeakPowerPerLbStats = createAction(
  '[Rider] Get Rider Peak Power Per Lb Stats'
);

export const SetRiderPeakPowerPerLbStats = createAction(
  '[Rider] Set Rider Peak Power Per Lb Stats',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderPeakPowerPerKgStats = createAction(
  '[Rider] Get Rider Peak Power Per Kg Stats'
);

export const SetRiderPeakPowerPerKgStats = createAction(
  '[Rider] Set Rider Peak Power Per Kg Stats',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderMaximumAnaerobicPower = createAction(
  '[Rider] Get Rider Maximum Anaerobic Power'
);

export const SetRiderMaximumAnaerobicPower = createAction(
  '[Rider] Set Rider Maximum Anaerobic Power',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderMaximumAerobicPower = createAction(
  '[Rider] Get Rider Maximum Aerobic Power'
);

export const SetRiderMaximumAerobicPower = createAction(
  '[Rider] Set Rider Maximum Aerobic Power',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderFunctionalThresholdPower = createAction(
  '[Rider] Get Rider Functional Threshold Power'
);

export const SetRiderFunctionalThresholdPower = createAction(
  '[Rider] Set Rider Functional Threshold Power',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderFitnessScoreChartStats = createAction(
  '[Rider] Get Rider Fitness Score Chart Data'
);
export const SetRiderFitnessScoreChartStats = createAction(
  '[Rider] Set Rider Fitness Score Chart Data',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderFitnessScorePerLbChartStats = createAction(
  '[Rider] Get Rider Fitness Score Per Lb Chart Data'
);

export const SetRiderFitnessScorePerLbChartStats = createAction(
  '[Rider] Set Rider Fitness Score Per Lb Chart Data',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderFitnessScorePerKgChartStats = createAction(
  '[Rider] Get Rider Fitness Score Per Kg Chart Data'
);

export const SetRiderFitnessScorePerKgChartStats = createAction(
  '[Rider] Set Rider Fitness Score Per Kg Chart Data',
  props<{stats: PowerStatsChartModel}>()
);

export const GetRiderVO2MaxChartStats = createAction(
  '[Rider] Get Rider VO2Max Chart Data'
);

export const SetRiderVO2MaxChartStats = createAction(
  '[Rider] Set Rider VO2Max Per Kg Chart Data',
  props<{ stats: PowerStatsChartModel }>()
);

export const GetRiderPeakHeartRateChartStats = createAction(
  '[Rider] Get Rider Peak Heart rate Chart Data'
);

export const SetRiderPeakHeartRateChartStats = createAction(
  '[Rider] Set Rider Peak Heart rate Chart Data',
  props<{stats: PowerStatsChartModel}>()
);

export const GetPeakHeartRateByRideType = createAction(
  '[Rider] Get peak heart rate by ride type',
  props<{rideType: RideTypes}>()
);

export const SetPeakHeartRateByRideType = createAction(
  '[Rider] Set peak heart rate by ride type',
  props<{peakHeartRate: PowerStatsChartModel, rideType: RideTypes}>()
);

export const GetRiderError = createAction(
  '[Rider] Get rider error',
  props<{err: any}>()
);

export const GetRiderStatisticValues = createAction(
  '[Rider] Get rider stats',
  props<{riderId: number}>()
);

export const SetRiderStatisticValues = createAction(
  '[Rider] Set rider stats',
  props<{ stats: StatsModel }>()
);

export const UploadRiderAvatar = createAction(
  '[Rider] Upload avatar',
  props<{file: any}>()
);

export const UpdateRiderInfo = createAction(
  '[Rider] Update rider info',
  props<{riderInfo: any, onSuccess?: (resp?: any) => void, onError?: (err?: any) => void}>()
);

export const SetRidersTrendsData = createAction(
  '[Rider] Set trends',
  props<{trends: TrendsModel}>()
);

export const SetRidersHabitScoreChart = createAction(
  '[Rider] Set habitScoreChart',
  props<{ habitScoreChart: number[] }>()
);

export const SetRidersHabitScore = createAction(
  '[Rider] Set habitScore',
  props<{ habitScore: number }>()
);

export const UnsetRidersTrendsData = createAction(
  '[Rider] Unset trends'
);

export const GetRidersLicenses = createAction(
  '[Rider] Get riders licenses',
  props<{riderId?: number, callBack?: () => void}>()
);

export const SetRidersLicenses = createAction(
  '[Rider] Set riders licenses',
  props<{licenses: LicenseModel[]}>()
);

export const GetRidersOwnedLicenses = createAction(
  '[Rider] Get riders owned licenses',
  props<{riderId?: number}>()
);

export const SetRidersOwnedLicenses = createAction(
  '[Rider] Set riders owned licenses',
  props<{ownedLicenses: AdminLicenseModel[]}>()
);

export const SetCurrentLicense = createAction(
  '[Rider] Set current license',
  props<{license: LicenseModel}>()
);

export const UpdatePassword = createAction(
  '[Rider] Update password',
  props<{oldPassword: string, newPassword: string, callback?: (resp) => void}>()
);

export const GetOwnerMenuAvailable = createAction(
  '[Rider] Get owner menu available',
  props<{riderId?: number}>()
);

export const SetOwnerMenuAvailable = createAction(
  '[Rider] Set owner menu available',
  props<{available: boolean}>()
);

export const SetSelectedBike = createAction(
  '[Rider] Set selected bike',
  props<{bike: BikeModel}>()
);

export const GetOwnedBikes = createAction(
  '[Rider] Get rider owned bikes'
);

export const SetOwnedBikes = createAction(
  '[Rider] Set rider owned bikes',
  props<{bikes: BikeModel[]}>()
);

export const UpdateOwnedBike = createAction(
  '[Rider] Update owned bike',
  props<{bikeId: number, bike: BikeModel}>()
);

export const UpdateOwnedBikeSuccess = createAction(
  '[Rider] Update owned bike success',
  props<{bikeId: number, bike: BikeModel}>()
);

export const GetOwnedRidersPage = createAction(
  '[Rider] Get rider owned riders',
  props<{query?: QueryParamsModel}>()
);

export const GetOwnedRidersBySearchString = createAction(
  '[Rider] Get rider owned riders by search string',
  props<{query?: QueryParamsModel}>()
);

export const SetOwnedRiders = createAction(
  '[Rider] Set rider owned riders',
  props<{riders: OwnerModel[]}>()
);

export const ResetOwnedRiders = createAction(
  '[Rider] Reset rider owned riders',
  props<{riders: OwnerModel[]}>()
);

export const RemoveOwnedRider = createAction(
  '[Rider] Remove rider from license owners list',
  props<{riderId: number}>()
);

export const SetRidersPagingData = createAction(
  '[Rider] Set rider owned riders paging data',
  props<{pagingData: TablePagingData}>()
);

export const SetCurrentRider = createAction(
  '[Rider] Set current rider',
  props<{rider: AdminRiderModel}>()
);

export const UpdateOwnedLicense = createAction(
  '[Rider] Update owned license',
  props<{licenseInfo: AdminLicenseModel, fileUpload: any}>()
);

export const UpdateOwnedLicenseSuccess = createAction(
  '[Rider] Update owned license success',
  props<{license: AdminLicenseModel}>()
);

export const UpdateOwnedLicenseAvatar = createAction(
  '[Rider] Update owned license avatar',
  props<{licenseId: number, fileUpload: any}>()
);

export const SetOwnerToLicense = createAction(
  '[Rider] Set owner to license',
  props<{licenseId: number, newOwner: OwnerModel}>()
);

export const SetOwnerToLicenseSuccess = createAction(
  '[Rider] Set owner to license success',
  props<{licenseId: number, newOwner: OwnerModel}>()
);

export const DeleteOwnerLicense = createAction(
  '[Rider] Delete owner from license',
  props<{licenseId: number, deleteOwner: OwnerModel}>()
);

export const DeleteOwnerLicenseSuccess = createAction(
  '[Rider] Delete owner from license success',
  props<{licenseId: number, deleteOwner: OwnerModel}>()
);

export const GetRiderOwnedLicensesShortly = createAction(
  '[Rider] Get riders owned licenses shortly',
  props<{riderId?: number}>()
);

export const SetRidersOwnedLicensesShortly = createAction(
  '[Rider] Set riders owned licenses shortly',
  props<{ownedLicensesShortly: LicenseModel[]}>()
);

export const GetChartsByRideTypes = createAction(
  '[Rider] Get charts by ride types',
  props<{chartType: ChartType, rideTypes: RideTypes[]}>()
);

export const SetChartsByRideTypes = createAction(
  '[Rider] Set charts by ride types',
  props<{chartType: ChartType, rideTypes: RideTypes[], resp: PowerStatsChartModel[]}>()
);

export const UnsubscribeRider = createAction(
  '[Rider] Unsubscribe Rider',
  props<{riderId: number, uuid: string, onSuccess?: () => void}>()
);

export const UnsubscribeRiderSuccess = createAction(
  '[Rider] Unsubscribe Rider success',
  props<{riderId: number, uuid: string}>()
);

export const SetRiderSharePreviewImageUrl = createAction(
  '[Rider] Set share preview image url',
  props<{previewImageUrl: string}>()
);

export const SetRiderPushNotificationToken = createAction(
  '[Rider] Set push notification token',
  props<{token: string}>()
);

export const SetRiderPushNotificationTokenSuccess = createAction(
  '[Rider] Set push notification token success',
  props<{token: string}>()
);

export const GetRiderBikeInfo = createAction(
  '[Rider] Get rider bike info',
  props<{bikeId: number, riderId?: number}>()
);

export const GetRiderLocale = createAction(
  '[Rider] Get rider locale',
  props<{riderId: number}>()
);

export const SetRiderLocale = createAction(
  '[Rider] Set rider locale',
  props<{riderId: number, locale: 'EN' | 'DE' | 'ES'}>()
);

export const SetRiderLocaleSuccess = createAction(
  '[Rider] Set rider locale success',
  props<{locale: 'EN' | 'DE' | 'ES'}>()
)

export const GetRiderTrainers = createAction(
  '[Rider] Get rider Trainers',
  props<{query?: QueryParamsModel}>()
);

export const SetRiderTrainers = createAction(
  '[Rider] Set rider Trainers',
  props<{trainers: Trainer[], trainersPaging: TablePagingData}>()
);

export const AddRiderTrainer = createAction(
  '[Rider] Add rider Trainer',
  props<{trainer: Trainer}>()
);

export const FindRiderTrainer = createAction(
  '[Rider] Find Rider Trainer',
  props<{email: string}>()
);

export const AssignRiderTrainer = createAction(
  '[Rider] Assign Rider Trainer',
  props<{email: string, callBack?: () => void}>()
);

export const AssignRiderTrainerSuccess = createAction(
  '[Rider] Success Assign Rider Trainer',
);

export const DeleteRiderTrainer = createAction(
  '[Rider] Delete Rider Trainer',
  props<{trainerId: number}>()
);

export const DeleteRiderTrainerSuccess = createAction(
  '[Rider] Success Delete Rider Trainer',
  props<{trainerId: number}>()
);

export const SetSelectedRiderId = createAction(
  '[Rider] Set selected rider id',
  props<{riderId: number}>()
);

export const GetRidersTrainees = createAction(
  '[Rider] Get riders trainees',
  props<{riderId?: number, query?: QueryParamsModel, callBack?: () => void}>()
);

export const GetSelectedTrainee = createAction(
  '[Rider] Get selected trainee',
  props<{riderId: number, callBack?: () => void}>()
);

export const SetSelectedTrainee = createAction(
  '[Rider] Set selected trainee',
  props<{selectedTrainee: UserModel, callBack?: () => void}>()
);

export const UnsetSelectedTrainee = createAction(
  '[Rider] Unset selected trainee',
  props<{callBack?: () => void}>()
);

export const SetRiderTrainees = createAction(
  '[Rider] Set rider Trainees',
  props<{trainees: Trainee[], traineesPaging?: TablePagingData}>()
);

export const AppendRiderTrainee = createAction(
  '[Rider] Append Rider Trainee',
  props<{trainerId?: number, riderId: number, callBack?: () => void}>()
);

export const AppendRiderTraineeSuccess = createAction(
  '[Rider] Success Append Rider Trainee',
);

export const DeclineRiderTrainee = createAction(
  '[Rider] Decline Rider Trainee',
  props<{trainerId: number, riderId: number, callBack?: () => void}>()
);

export const AppendFollow = createAction(
  '[Rider] Append follow',
  props<{observedRider: TopUserModel}>()
);

export const AppendFollowSuccess = createAction(
  '[Rider] Append follow success',
  props<{riderId: number, observedRider: TopUserModel}>()
);

export const RemoveFollow = createAction(
  '[Rider] Remove follow',
  props<{observedRider: TopUserModel}>()
);

export const RemoveFollowSuccess = createAction(
  '[Rider] Remove follow success',
  props<{riderId: number, observedRider: TopUserModel}>()
);

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState, RiderActions, RiderSelectors } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChartType, GaugeChartModel, InnerCardDataModel, StatsModel, StreaksModel } from '@carol-nx/data';
import { filter, map } from 'rxjs/operators';
import { formatChartData, pluralize, riderStatsToRidesPerWeekChart } from '@carol-nx/utils';
import { TrainerService } from '../../../services/trainer.service';
import { RiderService } from '../../../services/rider.service';

@Component({
  selector: 'carol-nx-dashboard-activity',
  templateUrl: './dashboard-activity.component.html',
  styleUrls: ['./dashboard-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardActivityComponent implements OnInit {
  public userStreaks: Observable<StreaksModel>;
  public ridesChartData: Observable<InnerCardDataModel>;
  public userStats: Observable<StatsModel>;

  constructor(private store: Store<AppState>, trainerService: TrainerService,
              private riderService: RiderService) {
    trainerService.init();
  }

  protected readonly pluralize = pluralize;

  public ngOnInit(): void {
    this.riderService.selectRiderIdWithStats();

    this.store.dispatch(RiderActions.GetRiderStreaks());
    this.userStreaks = this.store.select(RiderSelectors.selectRiderStreaks);
    this.userStats = this.store.select(RiderSelectors.selectRiderStats);

    const chartDataToInnerCard = (chartData: GaugeChartModel): InnerCardDataModel => ({
      title: 'DASHBOARD_INFO_ACTIVITY_RIDES_PER_WEEK',
      chartData: formatChartData(ChartType.RidesPerWeek, chartData)
    });

    this.ridesChartData = this.userStats.pipe(
      filter(data => !!data),
      map(riderStatsToRidesPerWeekChart),
      map(chartDataToInnerCard)
    );
  }
}

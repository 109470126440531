import {ChangeDetectionStrategy, Component, ElementRef, Input} from '@angular/core';
import {TopUserModel, UserModel} from '@carol-nx/data';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input()
  public avatarPath: string;

  @Input()
  public rider: UserModel | TopUserModel;

  constructor(
    public elementRef: ElementRef
  ) {
  }

}

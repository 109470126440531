import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import {
  GenderFilter,
  Language,
  LicenseModel,
  ModalTypes,
  PowerTypeValue,
  RideTypes,
  ThemeTypes,
  UserModel
} from '@carol-nx/data';
import {
  AuthService,
  DialogService,
  GenderService,
  HelperService,
  PowerService,
  RoutesService
} from '@carol-nx/services';
import {
  AppState,
  AuthActions,
  AuthSelectors,
  InfoActions,
  InfoSelectors,
  LeaderboardActions,
  LeaderboardSelectors,
  OwnerActions,
  RiderActions,
  RiderSelectors
} from '@carol-nx/store';
import { LogoutModalComponent } from '@carol-nx/ui';
import { isOnMobileDevice, setInLocalStorage } from '@carol-nx/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { DownloadAppModalComponent } from './components/modals/download-app-modal/download-app-modal.component';
import { TranslateService } from '@ngx-translate/core';

const MAIN_NAV = {
  active: true,
  items: [
    { title: 'NAV_ITEMS.DASHBOARD', link: '/main', icon: 'home' },
    { title: 'NAV_ITEMS.RIDES', link: '/rides', icon: 'speedometer' },
    { title: 'NAV_ITEMS.TRENDS', link: '/trends', icon: 'graph' },
    { title: 'NAV_ITEMS.LEADERBOARDS', link: '/leaderboards', icon: 'graph' },
    { title: 'NAV_ITEMS.BOOKINGS', link: '/bookings', icon: 'calendar' },
    { title: 'NAV_ITEMS.DEFINITIONS', link: '/definitions', icon: 'info' },
    { title: 'NAV_ITEMS.CONTACT', link: '/contact', icon: 'info' },
    { title: 'NAV_ITEMS.PREFERENCES', link: '/preferences', icon: 'settings' }
  ]
};

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('mainSideNav', { static: false })
  public mainSideNav: MatSidenav;

  @ViewChild('fullscreen', { static: false })
  public fullscreenElement: ElementRef;

  public languages = [Language.English, Language.Spanish, Language.German];
  public curLang = 'en';

  public activeNavName: 'admin' | 'membership' | '' = '';

  public rider: Observable<UserModel>;
  public client: Observable<UserModel>;
  public darkMode: Observable<boolean>;
  public mobileQuery: MediaQueryList;
  public adminMenuIsOpen: boolean;
  public pageTitle: Observable<string> = this.store.select(InfoSelectors.selectPageTitle);

  public mainNav = MAIN_NAV;
  public adminNav = {
    items: [
      {
        link: '/admin/riders',
        title: 'NAV_ITEMS.RIDERS',
        icon: 'people'
      },
      {
        link: '/admin/rides/',
        title: 'NAV_ITEMS.RIDES',
        icon: 'speedometer'
      },
      {
        link: '/admin/bikes/',
        title: 'NAV_ITEMS.BIKES',
        icon: 'disc'
      },
      {
        link: '/admin/memberships/',
        title: 'NAV_ITEMS.MEMBERSHIPS',
        icon: 'docs'
      },
      {
        link: '/admin/settings/',
        title: 'NAV_ITEMS.SETTINGS',
        icon: 'settings'
      },
      {
        link: '/admin/memberships-report/',
        title: 'NAV_ITEMS.MEMBERSHIP_REPORT',
        icon: 'memberships'
      }
    ],
    active: false
  };
  public licenseNav = {
    items: [
      {
        link: '/membership/riders',
        title: 'NAV_ITEMS.RIDERS',
        icon: 'people'
      },
      {
        link: '/membership/memberships',
        title: 'NAV_ITEMS.MEMBERSHIPS',
        icon: 'docs'
      },
      {
        link: '/membership/bikes',
        title: 'NAV_ITEMS.BIKES',
        icon: 'disc'
      }
    ],
    active: false
  };

  public gendersList = [
    { view: 'GENDER.ALL', val: GenderFilter.ALL },
    { view: 'GENDER.MALE', val: GenderFilter.MALE },
    { view: 'GENDER.FEMALE', val: GenderFilter.FEMALE }
  ];

  public isAuthenticated: Observable<boolean>;
  public openedModal: Observable<ModalTypes>;
  public isOwnerMenuAvailable: Observable<boolean>;
  public genderFilter = GenderFilter;
  public licenses = new BehaviorSubject<LicenseModel[]>([]);
  public leaderboardLicenses: LicenseModel[];
  public ownedLicenses: LicenseModel[];
  public currentLicense: Observable<LicenseModel>;
  public leaderboardTypeId = new BehaviorSubject<number>(null);
  public isOpenMenu = false;
  public clientId: number;
  public containSubType: boolean;
  public activeSubTypes = new BehaviorSubject<PowerTypeValue[]>([]);
  public activeSubType: PowerTypeValue;
  public rideSubTypes: BehaviorSubject<RideTypes[]>;
  public rideSubTypeSelected: RideTypes;

  readonly mobileQueryListener: () => void;
  private isClientsMenuAvailable: Observable<boolean>;

  get isAdminRole(): boolean {
    return this.authService.isAdminRole;
  }

  get isSupportRole(): boolean {
    return this.authService.isSupportRole;
  }

  get isApkRole(): boolean {
    return this.authService.isApkRole;
  }

  get curGender(): GenderFilter {
    return this.genderService.curGender.getValue();
  }

  get curGenderLabel(): string {
    switch (this.genderService.curGender.getValue()) {
      case GenderFilter.FEMALE:
        return 'GENDER.FEMALE';
      case GenderFilter.MALE:
        return 'GENDER.MALE';
      default:
        return 'GENDER.ALL';
    }
  }

  get modalTypes(): typeof ModalTypes {
    return ModalTypes;
  }

  get isHorizontalOrientation(): boolean {
    return window.innerWidth >= window.innerHeight * 1.432;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private routesService: RoutesService,
    private dialogService: DialogService,
    location: Location,
    private helperService: HelperService,
    private genderService: GenderService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private powerService: PowerService
  ) {
    const defaultLanguage = window.localStorage.getItem('lastSelectedLang') as Language || Language.English;
    this.translate.use(defaultLanguage);
    this.curLang = defaultLanguage;
    this.mobileQuery = media.matchMedia('(max-width: 640px), (orientation: portrait) and (max-width: 1280px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener); // Using deprecated addListener for support Safari v.13
    this.rider = this.store.select(RiderSelectors.selectRider);
    this.client = this.store.select(RiderSelectors.selectRiderTrainee).pipe(
      tap(client => this.handleMenuWithClientInfo(client))
    );
    this.isAuthenticated = this.store.select(AuthSelectors.selectAuthStatus);
    this.openedModal = this.store.select(InfoSelectors.selectOpenedModal);
    this.isOwnerMenuAvailable = this.store.select(RiderSelectors.selectOwnerMenuAvailable);
    this.isClientsMenuAvailable = this.store.select(RiderSelectors.selectClientsMenuAvailable);

    this.darkMode = this.store.select(InfoSelectors.selectTheme)
      .pipe(map(theme => theme && theme === 'dark'));

    this.routesService.trackUrlNavigations();

    const path = location.path();
    this.foldNavListByPath(path);

    this.store.select(LeaderboardSelectors.selectActivePowerTypeValue)
      .pipe(untilDestroyed(this))
      .subscribe(powerType => {
        this.containSubType = powerType.subType && powerType.subType.length > 0;
        this.activeSubTypes.next(this.containSubType ? powerType.subType : []);
        if (this.containSubType) {
          this.activeSubType = powerType.subType[0];
          this.changeDetectorRef.detectChanges();
        }
      });

    this.store.select(LeaderboardSelectors.selectActiveSubPowerTypeValue)
      .pipe(untilDestroyed(this), filter(powerType => !!powerType))
      .subscribe(powerType => {
        this.activeSubType = powerType;
        this.changeDetectorRef.detectChanges();
      });
  }

  get isLeaderboards(): boolean {
    return this.router.url.startsWith('/leaderboards');
  }

  get isMemberships(): boolean {
    return this.router.url.startsWith('/membership');
  }

  get isFilterIcon(): boolean {
    if (this.isLeaderboards || this.isSubTypesToShow) {
      return true;
    }

    return this.isMemberships && !this.router.url.startsWith('/membership/memberships');
  }

  get isHideTitle(): boolean {
    return this.isMemberships || this.isLeaderboards && this.licenses.getValue().length && this.leaderboardTypeId.getValue() === 2;
  }

  get isSubTypesToShow(): boolean {
    return !!this.rideSubTypes.getValue();
  }

  @HostListener('window:message', ['$event'])
  cardTitleOverflow(message) {
    if (message?.data?.cardTitleOverflow && this.mainSideNav.opened && !this.mobileQuery.matches) {
      this.mainSideNav.toggle();
    }
  }

  public ngOnInit() {
    this.defineCurrentDateAndShowDownloadPopup();

    this.isAuthenticated.pipe(
      filter(isAuthenticated => !!isAuthenticated),
      tap(() => this.routesService.goToLastUrl()),
      untilDestroyed(this)
    ).subscribe();

    const initRiderData = (riderId: number) => {
      this.store.dispatch(RiderActions.GetRiderData({ riderId }));
      this.store.dispatch(RiderActions.GetRidersLicenses({
        riderId
      }));
      this.store.dispatch(RiderActions.GetOwnerMenuAvailable({ riderId }));
      this.store.dispatch(RiderActions.GetRidersTrainees({ riderId }));
    };
    this.authService.checkIfCanLogIn().pipe(
      filter(riderId => !!riderId),
      tap(initRiderData),
      untilDestroyed(this)
    ).subscribe();

    this.compileMainMenu();

    this.checkingGlobalParam();

    this.licenses.pipe(
      distinctUntilChanged(isEqual),
      tap(licenses => this.selectLicense(licenses[0])),
      untilDestroyed(this)
    ).subscribe();

    const scrollToTop = () => {
      this.document.defaultView.scrollTo(0, 0);
      this.fullscreenElement.nativeElement.scrollTo(0, 0);
    };

    this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      tap(() => this.routesService.setPageTitle()),
      tap(scrollToTop),
      tap(() => this.checkChangeLicenseList()),
      untilDestroyed(this)
    ).subscribe();

    this.store.select(RiderSelectors.selectRidersOwnedLicenses).pipe(untilDestroyed(this)).subscribe(res => {
      if (!!res && !!res.length) {
        this.ownedLicenses = res;
      } else {
        this.ownedLicenses = [];
      }
      this.checkChangeLicenseList();
    });

    this.store.select(RiderSelectors.selectRidersLicenses).pipe(untilDestroyed(this)).subscribe(res => {
      if (!!res && !!res.length) {
        this.leaderboardLicenses = res;
      } else {
        this.leaderboardLicenses = [];
      }
      this.checkChangeLicenseList();
    });

    this.router.events.pipe(untilDestroyed(this)).subscribe(() => this.checkChangeLicenseList());

    this.store.select(LeaderboardSelectors.selectActiveLeaderboardTypeId).pipe(untilDestroyed(this)).subscribe(leaderboardTypeId => this.leaderboardTypeId.next(leaderboardTypeId));

    this.currentLicense = this.store.select(RiderSelectors.selectRiderCurrentLicense);

    this.listenFullscreenChange();

    this.rideSubTypes = this.powerService.getSubTypes();
    this.powerService.getSubTypeSelected().pipe(untilDestroyed(this))
      .subscribe((rideType: RideTypes) => {
        this.rideSubTypeSelected = rideType;
      });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener); // Using deprecated removeListener for support Safari v.13
  }

  public logOut() {
    if (this.clientId) {
      this.store.dispatch(RiderActions.SetClientRiderId({ clientRiderId: undefined }));
      this.store.dispatch(RiderActions.SetSelectedTrainee({ selectedTrainee: undefined }));
      this.router.navigate(['/main']);
    } else {
      this.store.dispatch(InfoActions.OpenModal({ modalType: ModalTypes.LogoutModal }));

      // TODO: just make overlay blurred
      const background = document.getElementById('fullscreen');
      background.style.filter = 'blur(0.21rem)';
      this.dialogService.openCertainModal(LogoutModalComponent, {
        minWidth: '17.25rem',
        minHeight: '19.17rem',
        panelClass: 'logout-modal',
        autoFocus: false
      })
        .afterClosed()
        .subscribe(doLogout => {
          background.style.filter = 'unset';
          if (doLogout) {
            this.mainSideNav.close();
            this.authService.logOut();
            this.store.dispatch(AuthActions.LogOut());
            this.router.navigate(['/auth']);
          }
          this.store.dispatch(InfoActions.CloseModal());
        });
    }
  }

  public changeThemeMode(event) {
    this.helperService.setTheme(event.checked ? ThemeTypes.Dark : ThemeTypes.Light);
  }

  public foldNavList(navName: string) {
    if (navName === 'admin') {
      this.adminNav.active = !this.adminNav.active;
      this.mainNav.active = false;
      this.licenseNav.active = false;
    } else if (navName === 'main') {
      this.mainNav.active = !this.mainNav.active;
      this.adminNav.active = false;
      this.licenseNav.active = false;
    } else if (navName === 'membership') {
      this.licenseNav.active = !this.licenseNav.active;
      this.mainNav.active = false;
      this.adminNav.active = false;
    }
  }

  public clickMenu(event) {
    const href = event.target.href;
    if (href && !href.startsWith('javascript') && !this.isHorizontalOrientation) {
      this.mainSideNav.close();
    }
  }

  public selectLicense(license: LicenseModel) {
    this.store.dispatch(RiderActions.SetCurrentLicense({ license }));
    if (this.isLeaderboards && this.isHideTitle) {
      this.store.dispatch(LeaderboardActions.GetTopUsersList({}));
    }
    if (this.isMemberships && license) {
      this.store.dispatch(OwnerActions.GetLicenseStatisticValues({ licenseId: license.id }));
    }
  }

  public selectGender(gender: GenderFilter) {
    this.genderService.switchGender(gender);
  }

  public menuOpened() {
    this.isOpenMenu = true;
  }

  public menuClosed() {
    this.isOpenMenu = false;
  }

  public menuChanged() {
    window.postMessage({ showMenuChanging: true, mainSideNavOpened: !this.mainSideNav.opened }, '*');
    this.mainSideNav.toggle();
  }

  public updateLang(lang: Language) {
    window.localStorage.setItem('lastSelectedLang', lang);
    this.curLang = lang;
    this.translate.use(lang);
  }

  public selectSubType(subType: PowerTypeValue) {
    this.store.dispatch(LeaderboardActions.SetActiveSubPowerTypeValue({ activeSubPowerTypeValue: subType }));
  }

  public selectRideSubType(rideType: RideTypes) {
    this.powerService.setSubTypeSelected(rideType);
  }

  public getSubTypeName(rideType: RideTypes): string {
    return this.powerService.getSubTypeName(rideType);
  }

  private checkChangeLicenseList() {
    if (this.isMemberships && this.ownedLicenses) {
      this.licenses.next(this.ownedLicenses);
    } else if (this.isLeaderboards && this.leaderboardLicenses) {
      this.licenses.next(this.leaderboardLicenses);
    }
  }

  private checkingGlobalParam() {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('accessToken') &&
      queryParams.has('accessTokenExpiresAt') &&
      queryParams.has('riderId') &&
      queryParams.has('refreshToken') &&
      queryParams.has('refreshTokenExpiresAt') &&
      queryParams.has('authorities')) {
      const tokens: any = {
        tokenType: 'Bearer',
        accessToken: queryParams.get('accessToken'),
        accessTokenExpiresAt: Number.parseInt(queryParams.get('accessTokenExpiresAt'), 10),
        riderId: Number.parseInt(queryParams.get('riderId'), 10),
        refreshToken: queryParams.get('refreshToken'),
        refreshTokenExpiresAt: Number.parseInt(queryParams.get('refreshTokenExpiresAt'), 10),
        authorities: queryParams.getAll('authorities')
      };

      this.store.dispatch(AuthActions.LogInSuccess({ tokens }));
      setInLocalStorage(tokens);
      this.store.dispatch(RiderActions.GetRiderData({ riderId: tokens.riderId }));
      this.store.dispatch(RiderActions.GetRidersLicenses({
        riderId: tokens.riderId,
        callBack: () => RiderActions.SetCurrentLicense({ license: {} })
      }));
      this.store.dispatch(RiderActions.GetRidersOwnedLicenses({ riderId: tokens.riderId }));
    }

    if (localStorage.getItem('theme') === 'dark') {
      this.helperService.setTheme(ThemeTypes.Dark);
    } else {
      this.helperService.setTheme(ThemeTypes.Light);
    }
  }

  private foldNavListByPath(path: string) {
    switch (true) {
      case path.startsWith('/admin'):
        this.foldNavList('admin');
        break;
      case path.startsWith('/membership'):
        this.foldNavList('membership');
        break;
      default:
        this.foldNavList('main');
        break;
    }
  }

  private compileMainMenu(): void {
    this.adminMenuIsOpen = this.authService.isAdminRole || this.authService.isSupportRole;

    if (!this.authService.isAdminRole) {
      this.adminNav.items.pop();
    }

    const handleClientsMenu = isAvailable => {
      const clientsMenuItem = {
        title: 'NAV_ITEMS.CLIENTS',
        link: '/clients',
        icon: 'people_alt'
      };

      remove(this.mainNav.items, item => item.link === '/clients');

      if (isAvailable) {
        this.mainNav.items.push(clientsMenuItem);
      }

      this.changeDetectorRef.detectChanges();
    };

    this.isClientsMenuAvailable.pipe(
      distinctUntilChanged(),
      tap(handleClientsMenu),
      untilDestroyed(this)
    ).subscribe();
  }

  private defineCurrentDateAndShowDownloadPopup() {
    if (!isOnMobileDevice()) {
      return;
    }

    if (!window.localStorage.getItem('lastPopupDate')) {
      window.localStorage.setItem('lastPopupDate', `${+new Date()}`);
    }

    const lastDate = window.localStorage.getItem('lastPopupDate');

    if (dayjs(+new Date()).diff(+lastDate, 'day') >= 7) {
      this.dialogService.openCertainModal(DownloadAppModalComponent);
      window.localStorage.setItem('lastPopupDate', `${+new Date()}`);
    }
  }

  private handleMenuWithClientInfo(client: UserModel) {
    this.clientId = client && client.riderId;

    if (this.clientId) {
      this.mainNav = {
        active: true,
        items: [
          { title: 'Dashboard', link: `/client/${this.clientId}/main`, icon: 'home' },
          { title: 'Rides', link: `/client/${this.clientId}/rides`, icon: 'speedometer' },
          { title: 'Trends', link: `/client/${this.clientId}/trends`, icon: 'graph' }
        ]
      };
    } else {
      this.mainNav = MAIN_NAV;
    }
  }

  private listenFullscreenChange(): void {
    this.document.getElementById('fullscreen').addEventListener('fullscreenchange', () => {
      if (this.document.fullscreenElement) {
        this.mainSideNav.close();
      } else {
        this.mainSideNav.open();
      }
    });
  }
}

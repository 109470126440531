<mat-card class="card-chart" *ngIf="(_data | async) as data">
  <mat-card-title [routerLink]="route" [class.router-link]="route" carolNxResize (resizeEvent)="titleResize()" resizeInitAfterMs="10" resizeDebounceMs="10">
    <div #titleContent [matTooltip]="data.title | translate">{{data.title | translate}}</div>
  </mat-card-title>
  <mat-card-content>
    <carol-nx-svg-icon *ngIf="iconInfo" icon="info-alt" [native]="true" class="info-icon" (click)="clickIcon()">
    </carol-nx-svg-icon>
    <carol-nx-gauge-chart *ngIf="isValidChartData || data.chartData.validSubscription === false"
                          [showZeroValue]="true"
                          [chartData]="data.chartData">
    </carol-nx-gauge-chart>
    <ng-container *ngIf="!isValidChartData && data.chartData.validSubscription">
      <div class="no-data">
        <carol-ui-no-ride-data></carol-ui-no-ride-data>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>

import {CountryModel, ModalTypes, CommonMessageModel} from '@carol-nx/data';

export interface InfoState {
  error?: CommonMessageModel;
  success?: CommonMessageModel;
  errorTitle?: string;
  pageTitle?: string;
  countries?: CountryModel[];
  openedModal?: ModalTypes;
  theme: string;
  connected: boolean;
  tableDataLoaded: boolean;
}

export const initialInfoState: InfoState = {
  theme: 'dark',
  connected: true,
  tableDataLoaded: false,
};

import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

@Directive({
  selector: '[carolNxResize]'
})
export class ResizeDirective implements OnDestroy, AfterViewInit {
  @Output()
  public resizeEvent = new EventEmitter<{ newWidth: number, newHeight: number, oldWidth: number, oldHeight: number }>();
  @Input()
  public resizeDebounceMs = 0;
  @Input()
  public resizeInitAfterMs = 0;

  private width: number;
  private height: number;
  private mainSideNavOpened: boolean;
  private timer: number;
  private eventsPool = new Subject<any>();

  constructor(private element: ElementRef) {
  }

  @HostListener('window:message', ['$event'])
  public showMenuChanging(message: { data: { showMenuChanging: boolean; mainSideNavOpened: boolean; }; }) {
    if (message?.data?.showMenuChanging) {
      this.sendResizeEvent(message?.data?.mainSideNavOpened);
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    if (window) {
      this.sendResizeEvent(this.mainSideNavOpened);
    }
  }

  ngAfterViewInit() {
    this.width = this.element.nativeElement.clientWidth;
    this.height = this.element.nativeElement.clientHeight;
    this.eventsPool.pipe(
      filter(event => !!event),
      debounceTime(this.resizeDebounceMs),
      tap(e => this.resizeEvent.next(e))
    ).subscribe();

    this.sendResizeEvent(this.mainSideNavOpened);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  private sendResizeEvent(mainSideNavOpened: boolean) {
    const self = this;
    this.timer = setTimeout(() => {
      if (self.element && self.element.nativeElement) {
        const { clientWidth, clientHeight } = self.element.nativeElement;
        if (self.width !== clientWidth || self.height !== clientHeight || self.mainSideNavOpened !== mainSideNavOpened) {
          const oldWidth = self.width;
          const oldHeight = self.height;
          self.width = clientWidth;
          self.height = clientHeight;
          self.mainSideNavOpened = mainSideNavOpened;
          self.eventsPool.next({ newWidth: clientWidth, newHeight: clientHeight, oldWidth, oldHeight });
        }
      }
    }, this.resizeInitAfterMs);
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SimpleList} from '@carol-nx/data';

@Component({
  selector: 'carol-nx-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleListComponent {

  @Input()
  public list: SimpleList[];
}

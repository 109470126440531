import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { QueryParamsModel } from '@carol-nx/data';
import { AppState, InfoActions } from '@carol-nx/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(private router: Router, private store: Store<AppState>) {
  }

  public trackUrlNavigations() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd || event instanceof NavigationStart),
      tap((e: any) => {
        const path = e.url.match(/[^?]+/);
        const params = this.router.parseUrl(e.url).queryParams;

        if (path[0] !== '/auth' && !path[0].startsWith('/auth/') && path[0] !== '/admin' && path[0] !== '/membership' && !params.licenseId) {
          localStorage.setItem('lastVisitedUrl', path);
          localStorage.setItem('lastVisitedUrlParams', JSON.stringify(params));
        }
      }),
    ).subscribe();
  }

  public goToLastUrl() {
    const lastUrl = localStorage.getItem('lastVisitedUrl');
    const lastUrlParams = JSON.parse(localStorage.getItem('lastVisitedUrlParams'));
    if (lastUrl && lastUrl !== '/auth' && !lastUrl.startsWith('/auth/')) {
      this.router.navigate([lastUrl], {queryParams: lastUrlParams});
    } else {
      this.router.navigate(['main']);
    }
  }

  public updateQueryParams(activatedRoute: ActivatedRoute, params: QueryParamsModel) {
    this.router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: {...params},
        queryParamsHandling: 'merge',
      });
  }

  public setPageTitle() {
    let route: ActivatedRoute = this.router?.routerState?.root;
    while (route!.firstChild) {
      route = route.firstChild;
    }
    const pageTitle = route!.snapshot.data['pageTitle'] || '';
    this.store.dispatch(InfoActions.SetPageTitle({pageTitle}));
  };

}

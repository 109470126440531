import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TopUserModel} from '@carol-nx/data';

@Component({
  selector: 'carol-nx-add-follow',
  templateUrl: './add-follow-modal.component.html',
  styleUrls: ['./add-follow-modal.component.scss']
})
export class AddFollowModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {topUser: TopUserModel}) {
  }

}

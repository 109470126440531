import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { BikeModel, PaymentIntentWithBilling, QueryParamsModel } from '@carol-nx/data';
import { getCurrentTimeZone } from '@carol-nx/utils';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {
  private ownerApiUrl = `/owner-api`;

  constructor(private http: HttpClient) { }

  public getRidersOwnedLicenses(riderId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/license/list/rider/${riderId}`);
  }

  public getLicenseInfo(licenseId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/license/${licenseId}/owner`, {});
  }


  public setOwnerToLicense(licenseId: number, riderId: number): Observable<any> {
    return this.http.post(`${this.ownerApiUrl}/license/${licenseId}/owner/${riderId}`, {});
  }

  public deleteOwnerFromLicense(licenseId: number, riderId: number): Observable<any> {
    return this.http.delete(`${this.ownerApiUrl}/license/${licenseId}/owner/${riderId}`);
  }

  public getOwnedRidersById(riderId: number, query?: QueryParamsModel): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/license/owner/${riderId}/riders`, {params: query as HttpParams});
  }

  public getRiderOwnedLicensesShortly(riderId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/license/list/rider/${riderId}/short`);
  }

  public updateOwnedBike(riderId: number, bikeId: number, bike: BikeModel): Observable<any> {
    return this.http.post(`${this.ownerApiUrl}/rider/${riderId}/bike/${bikeId}`, bike);
  }

  public postPaymentIntent(bikeId: number, paymentIntent: PaymentIntentWithBilling, amount: number, currencyAmount: string): Observable<any> {
    return this.http.post(`${this.ownerApiUrl}/bike/${bikeId}/subscription/payment-intent`, {
      billing: paymentIntent,
      amount,
      currencyAmount
    });
  }

  public getPrice(bikeId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/bike/${bikeId}/subscription/price`);
  }

  public postSubscription(bikeId: number, priceId: string, quantity: number, paymentIntent: PaymentIntentWithBilling, flag3ds: boolean): Observable<any> {
    return this.http.post(`${this.ownerApiUrl}/bike/${bikeId}/subscription`, {paymentIntentTO: paymentIntent, priceId, quantity, flag3ds});
  }

  public getSubscription(bikeId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/bike/${bikeId}/subscription`);
  }

  public getBikeCountry(bikeId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/bike/${bikeId}/subscription/country`);
  }

  public getNextPrice(bikeId: number, priceId: string, quantity: number, countryId: number, postCode: string, city: string, address: string): Observable<any> {
    const taxRequestTO = {
      priceId,
      quantity,
      countryId: countryId.toString(),
      postCode,
      city,
      address
    };
    return this.http.post(`${this.ownerApiUrl}/bike/${bikeId}/subscription/customer-next-price`, taxRequestTO);
  }

  public getLicenseMonthlyRideCalendar(licenseId: number, month: number, year: number): Observable<any> {
    let timezoneRegion: string = getCurrentTimeZone();
    return this.http.get(`${this.ownerApiUrl}/license/${licenseId}/stats/monthly/${year}/${month}`, {params: {timezoneRegion}});
  }

  public getLicenseMonthlyLastRides(licenseId: number): Observable<any> {
    let timezoneRegion: string = getCurrentTimeZone();
    return this.http.get(`${this.ownerApiUrl}/license/${licenseId}/stats/calendar/last`, {params: {timezoneRegion}});
  }

  public removeRiderFromLicense(riderId: number, licenseId: number): Observable<any> {
    return this.http.delete(`${this.ownerApiUrl}/license/${licenseId}/riders/${riderId}`);
  }

  public getLicenseStats(licenseId: number): Observable<any> {
    return this.http.get(`${this.ownerApiUrl}/license/${licenseId}/stats`);
  }

  private getRidesCount(licenseId: number) {
    return this.http.get(`${this.ownerApiUrl}/license/${licenseId}/stats/ride-count`);
  }

  public getLicenseStatisticValues(licenseId: number): Observable<any> {
    return forkJoin({
      licenseCommonStats: this.getLicenseStats(licenseId),
      rideCounts: this.getRidesCount(licenseId),
      licenseDetails: this.getLicenseInfo(licenseId)
    });
  }
}

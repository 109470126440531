import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions, InfoActions, RiderActions } from '../actions';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '@carol-nx/services';
import { saveTokensToStorage, setInLocalStorage, verboseErrorMessage } from '@carol-nx/utils';

@Injectable()
export class AuthEffects {

  logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LogIn),
      mergeMap(action => {
        return this.authService.login({password: action.password, username: action.username})
          .pipe(
            switchMap(resp => {
              setInLocalStorage({rememberMe: action.rememberMe});
              saveTokensToStorage(resp);
              const action1 = RiderActions.GetRiderData({riderId: resp.riderId});
              const action2 = RiderActions.GetRidersLicenses({riderId: resp.riderId});
              const action3 = AuthActions.LogInSuccess({});
              const action4 = RiderActions.GetOwnerMenuAvailable({riderId: resp.riderId});
              if (action.callBack) {
                action.callBack(resp);
              }
              return of(action1, action2, action3, action4);
            }),
            catchError((err) => {
              if (action.callBack) {
                action.callBack(err);
              }
              return of(InfoActions.SetCommonError({message: verboseErrorMessage(err.error.message)}));
            })
          );
        }
      )
    )
  );

  confirmRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ConfirmRegistration),
      mergeMap(action => {
          return this.authService.confirmRegistration(action.hash, action.uuid)
            .pipe(
              switchMap(resp => {
                setInLocalStorage(resp);
                if(action.success) {
                  action.success(resp);
                }
                return of(AuthActions.ConfirmRegistrationSuccess(resp));
              }),
              catchError((err) => {
                if (action.error) {
                  action.error(err.error.message);
                }
                return of(AuthActions.ConfirmRegistrationFail(err));
              })
            );
        }
      )
    )
  );

  constructor(private actions$: Actions,
              private authService: AuthService) {
  }

}

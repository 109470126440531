import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from './reducers';
import {AuthEffects} from './effects/auth.effects';
import {RiderEffects} from './effects/rider.effects';
import {RidesEffects} from './effects/rides.effects';
import {InfoEffects} from './effects/info.effects';
import {BookingEffects} from './effects/booking.effects';
import {AdminEffects} from './effects/admin.effects';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {LeaderboardEffects} from "./effects/leaderboard.effects";
import {OwnerEffects} from "./effects/owner.effects";

@NgModule({
  imports: [
    MatSnackBarModule,
    StoreModule.forRoot(
      reducers,
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        }
      }),
    EffectsModule.forRoot([
      AuthEffects,
      RiderEffects,
      RidesEffects,
      InfoEffects,
      BookingEffects,
      AdminEffects,
      LeaderboardEffects,
      OwnerEffects
    ]),
    StoreDevtoolsModule.instrument(),
    CommonModule,
  ],
})
export class CarolStoreModule {}

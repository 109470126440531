import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PowerTypeValue, TopUserModel, UserModel } from '@carol-nx/data';
import { AuthService, DialogService, GenderService } from '@carol-nx/services';
import {
  AppState,
  AuthSelectors,
  LeaderboardActions,
  LeaderboardSelectors,
  RiderActions,
  RiderSelectors
} from '@carol-nx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, tap, throttleTime } from 'rxjs/operators';
import { AddFollowModalComponent } from '../add-follow-modal/add-follow-modal.component';

@UntilDestroy()
@Component({
  selector: 'carol-nx-leaderboard-list',
  templateUrl: './leaderboard-list.component.html',
  styleUrls: ['./leaderboard-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaderboardListComponent implements OnInit, AfterViewInit {

  @ViewChild('scroller')
  public scroller: CdkVirtualScrollViewport;

  @Input()
  public isSlideshow: boolean;

  @Input()
  public leaderboardTypeId: number;

  @Output()
  public appendFollow = new EventEmitter<TopUserModel>();

  @Output()
  public removeFollow = new EventEmitter<TopUserModel>();

  public isEmptyTopUsersList: boolean = false;
  public topUsers = new BehaviorSubject<TopUserModel[]>(null);
  public currentUser = new BehaviorSubject<TopUserModel>(null);
  public isLastPage: boolean;
  public authenticated: Observable<boolean>;
  public selectedPowerTypeValue: Observable<PowerTypeValue>;
  public riderId: number;
  public rider: UserModel;

  public numberOfElements: number;
  public isLoading: boolean = true;
  public showRiderPosition = new BehaviorSubject(null);

  private startScrollIndex = new BehaviorSubject(0);

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private actions: Actions,
    private dialogService: DialogService,
    private genderService: GenderService,
    private authService: AuthService
  ) {
    this.authenticated = this.store.select(AuthSelectors.selectAuthStatus);
  }

  public ngOnInit(): void {
    this.store.select(RiderSelectors.selectRider).pipe(
      tap(rider => this.rider = rider),
      untilDestroyed(this)
    ).subscribe();

    this.selectedPowerTypeValue = this.store.select(LeaderboardSelectors.selectActivePowerTypeValue);

    this.authService.checkIfCanLogIn().pipe(untilDestroyed(this)).subscribe(riderId => {
      this.riderId = riderId;
    });

    const isLoaded = (topUsers: TopUserModel[]) => {
      if (topUsers) {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }
    }
    const topUsersHandler = (topUsers: TopUserModel[]) => {
      if (topUsers) {
        this.isEmptyTopUsersList = Array.isArray(topUsers) && !topUsers.length;
      }
      this.numberOfElements = topUsers && topUsers.length || 0;
      this.changeDetectorRef.detectChanges();
    }

    this.authenticated.pipe(
      filter(isAuth => !!isAuth),
      tap(() => {
        this.store.dispatch(LeaderboardActions.GetTopUsersList({callBack: isLastPage => this.isLastPage = isLastPage}));

        this.store.select(LeaderboardSelectors.selectUserScoreByPowerType).pipe(
          tap(topUser => {
            this.currentUser.next(topUser);
            this.calcCustomRiderPosition();
          }),
        ).subscribe();
        this.store.select(LeaderboardSelectors.selectTopUsersSliderDataByPowerType).pipe(
          tap(isLoaded),
          distinctUntilChanged(isEqual),
          tap(topUsersHandler)
        ).subscribe(topUsers => {
          this.topUsers.next(topUsers);
        });
      }),
      untilDestroyed(this)
    ).subscribe();

    this.actions.pipe(
      ofType(RiderActions.AppendFollowSuccess),
      untilDestroyed(this)
    ).subscribe((action) => {
      this.dialogService.openCertainModal(AddFollowModalComponent, {
        width: '40rem',
        panelClass: 'borderless-modal',
        autoFocus: false,
        data: {topUser: action.observedRider}
      });
    });

    this.actions.pipe(
      ofType(RiderActions.RemoveFollowSuccess),
      untilDestroyed(this)
    ).subscribe(() => {
      this.store.dispatch(LeaderboardActions.GetTopUsersList({}));
    });

  }

  public ngAfterViewInit(): void {
    this.scroller.renderedRangeStream.subscribe(_ => this.calcCustomRiderPosition());

    this.scroller.scrolledIndexChange.subscribe(s => {
      this.startScrollIndex.next(s);
      this.calcCustomRiderPosition();
    });

    this.scroller.elementScrolled().pipe(
      map(() => this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < 140) && !this.isLastPage),
      throttleTime(200)
    ).subscribe(() => {
        this.ngZone.run(() => {
          this.onScroll();
        });
      }
    );
  }

  public onScroll() {
    this.isLoading = true;
    this.store.dispatch(LeaderboardActions.GetTopUsersList({
      page: this.numberOfElements ? Math.floor(this.numberOfElements / 10) : 0,
      callBack: isLastPage => this.isLastPage = isLastPage
    }));
  }

  public addFollow(user: TopUserModel) {
    if (this.rider?.validSubscription) {
      this.appendFollow.emit(user);
    }
  }

  public delFollow(user: TopUserModel) {
    if (this.leaderboardTypeId === 3 && this.rider?.validSubscription) {
      this.removeFollow.emit(user);
    }
  }

  public calcCustomRiderPosition() {
    const startIntex = this.startScrollIndex.getValue();
    const loadUsers = this.topUsers.getValue();
    if (this.scroller && !!loadUsers) {
      const fontSize = this.getElementFontSize(this.scroller.elementRef);
      const currentUser = this.currentUser.getValue();
      if (currentUser == null) {
        this.showRiderPosition.next(null);
        return;
      } else if (fontSize > 0) {
        const itemHeight = 4 * fontSize;
        const viewItemsCount = this.scroller.getViewportSize() / itemHeight;
        const findIndex = loadUsers.findIndex(p => p.riderId === this.currentUser.getValue().riderId);
        if (!currentUser.rank || findIndex < 0 || findIndex >= startIntex + viewItemsCount) {
          const pos = Math.min(this.scroller.elementRef.nativeElement.clientHeight - itemHeight, (loadUsers.length - startIntex) * itemHeight);
          this.showRiderPosition.next(`${pos}px`);
        } else if (findIndex > startIntex) {
          this.showRiderPosition.next(null);
        } else {
          this.showRiderPosition.next(0);
        }
      } else {
        this.showRiderPosition.next(0);
      }
    }
  }

  private getElementFontSize(elementRef: ElementRef): number {
    const chartContainerElement = elementRef.nativeElement;
    const styleFontSize = window.getComputedStyle(chartContainerElement as Element, null).getPropertyValue('font-size');
    return parseFloat(styleFontSize);
  }
}

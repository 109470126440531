import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../state';
import { RiderSelectors } from '../selectors';
import { ActionCreator, Creator, Store } from '@ngrx/store';
import { Actions, createEffect, CreateEffectMetadata, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';

export class EffectsMain {

  constructor(protected actions$: Actions, protected store: Store<AppState>,
              protected snackBar: MatSnackBar) {
  }

  protected openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, (action ? action : null), {
      duration: 700,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  protected createEffectWithMergeMap<AC extends ActionCreator<string, Creator>>(action, dataService: (AC, number) => Observable<any>, operatorFunction: OperatorFunction<[AC, number], [AC, number]> = tap(), realId: boolean = false): Observable<AC> | ((...args: AC[]) => Observable<AC>) & CreateEffectMetadata {
    return createEffect(() =>
      combineLatest([
        this.actions$.pipe(ofType(action)),
        realId ? this.store.select(RiderSelectors.selectRealRiderId) : this.store.select(RiderSelectors.selectRiderId)
      ]).pipe(
        operatorFunction,
        filter(([action, riderId]) => action && !!riderId),
        mergeMap(([action, riderId]) => dataService(action, riderId))
      )
    );
  }

  protected createEffectSwitchMap<AC extends ActionCreator<string, Creator>>(action, dataService: (AC, number) => Observable<any>, realId: boolean = false): Observable<AC> | ((...args: AC[]) => Observable<AC>) & CreateEffectMetadata {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(action),
        switchMap((action) => (realId ? this.store.select(RiderSelectors.selectRealRiderId) : this.store.select(RiderSelectors.selectRiderId))
          .pipe(
            filter(riderId => !!riderId),
            debounceTime(0),
            switchMap((riderId) => dataService(action, riderId))
          )
        )
      )
    );
  }

  protected createEffectWithLatestSwitchMap<AC extends ActionCreator<string, Creator>>(action, dataService: (AC, number) => Observable<any>, operatorFunction: OperatorFunction<[AC, number], [AC, number]> = tap()): Observable<AC> | ((...args: AC[]) => Observable<AC>) & CreateEffectMetadata {
    return createEffect(() => this.actions$.pipe(ofType(action)).pipe(
        withLatestFrom(this.store.select(RiderSelectors.selectRiderId).pipe(filter(riderId => !!riderId))),
        operatorFunction,
        switchMap(([action, riderId]) => dataService(action, riderId))
      )
    );
  }

  protected createEffectWithLatestMergeMap<AC extends ActionCreator<string, Creator>>(action, dataService: (AC, number) => Observable<any>, operatorFunction: OperatorFunction<[AC, number], [AC, number]> = tap()): Observable<AC> | ((...args: AC[]) => Observable<AC>) & CreateEffectMetadata {
    return createEffect(() => this.actions$.pipe(ofType(action)).pipe(
        withLatestFrom(this.store.select(RiderSelectors.selectRiderId).pipe(filter(riderId => !!riderId))),
        operatorFunction,
        filter(([action, riderId]) => action && !!riderId),
        mergeMap(([action, riderId]) => dataService(action, riderId))
      )
    );
  }
}

<div class="page-wrapper">
  <span class="close" (click)="close()">&#215;</span>
  <ng-container *ngIf="isValidChartData || chartData.validSubscription === false">
    <h2 *ngIf="isValidChartData">{{'SPEEDOMETER_INFO_HEADER'| translate:({title: title | translate}) }}</h2>
    <div class="flex-wrapper">
      <div>
        <carol-nx-gauge-chart [chartData]="chartData"
                              [showZeroValue]="true"></carol-nx-gauge-chart>
      </div>
      <carol-nx-info-content [chartData]="chartData"></carol-nx-info-content>
    </div>
  </ng-container>
  <ng-container *ngIf="!isValidChartData && chartData.validSubscription">
    <div class="no-data">
      <carol-nx-svg-icon icon="ride" [native]="true" class="license-icon"></carol-nx-svg-icon>
      <carol-nx-info-content [isInvalidRide]="isInvalidRide" [chartData]="chartData"></carol-nx-info-content>
    </div>
  </ng-container>
</div>

import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RiderSelectors } from '@carol-nx/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HabitScorePopupComponent } from '../habit-score-popup/habit-score-popup.component';
import { DialogService } from '@carol-nx/services';

@UntilDestroy()
@Component({
  selector: 'carol-nx-habit-score-dashboard',
  templateUrl: './habit-score-dashboard.component.html',
  styleUrls: ['./habit-score-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HabitScoreDashboardComponent implements OnInit {
  public habitScore: Observable<number>;

  constructor(private store: Store, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.habitScore = this.store.select(RiderSelectors.selectRiderHabitScore);
  }

  public clickIcon(): void {
    const modal = this.dialogService.openCertainModal(HabitScorePopupComponent, {
      maxWidth: 'max(1280px, 50em)'
    });

    // TODO: just make overlay blurred
    const background = document.getElementById('fullscreen');
    background.style.filter = 'blur(5px)';
    modal.afterClosed().subscribe(_ => {
      background.style.filter = 'unset';
    });
  }
}

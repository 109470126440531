import { Store } from '@ngrx/store';
import { AppState, RiderActions, RiderSelectors, RidesSelectors } from '@carol-nx/store';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ChartType, MAIN_LINE_COLOR } from '@carol-nx/data';
import { formatChartData } from '@carol-nx/utils';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable } from 'rxjs';

export class DashboardMainMainComponent {
  public fitness;
  public burn;
  public power;
  public energy;
  public validSubscription: Observable<boolean>;

  constructor(protected store: Store<AppState>) {
  }

  init(): void {
    this.store.dispatch(RiderActions.GetRiderFitnessScoreChartStats());
    this.store.dispatch(RiderActions.GetCalories());
    this.store.dispatch(RiderActions.GetRiderPeakPowerStats());
    this.store.dispatch(RiderActions.GetRiderTotalPowerStats());

    this.validSubscription = this.store.select(RiderSelectors.selectRider).pipe(
      filter(rider => !!rider),
      map(rider => rider.validSubscription),
    );

    this.fitness = combineLatest([this.store.select(RidesSelectors.selectLastRide), this.store.select(RiderSelectors.selectFitnessScoreChartStats)]).pipe(
      filter(([lastRide, fitnessScoreChartStats]) => !!fitnessScoreChartStats && !!lastRide),
      distinctUntilChanged(isEqual),
      map(([lastRide, fitnessScoreChartStats]) => {
          let result = {
            title: 'DASHBOARD_INFO_TITLE_FITNESS_SCORE',
            link: 'fitness',
            subtitle: null,
            chartData: formatChartData(ChartType.FitnessScore, fitnessScoreChartStats)
          };
          if (!lastRide.octaneScore) {
            result.chartData.mainColor = MAIN_LINE_COLOR;
            if (result.chartData?.vsPeers?.color) {
              result.chartData.vsPeers.color = MAIN_LINE_COLOR;
            }
          }
          return result;
        }
      )
    );


    this.burn = this.store.select(RiderSelectors.selectRiderCaloriesChartStats).pipe(
      filter(caloriesChartStats => !!caloriesChartStats),
      distinctUntilChanged(isEqual),
      map(caloriesChartStats => ({
          title: 'DASHBOARD_INFO_CALORIES',
          link: 'burn',
          subtitle: null,
        chartData: formatChartData(ChartType.CaloriesExclEPOC, caloriesChartStats as any)
        })
      )
    );

    this.power = this.store.select(RiderSelectors.selectRiderPeakPowerStats).pipe(
      filter(peakPowerStats => !!peakPowerStats),
      distinctUntilChanged(isEqual),
      map(peakPowerStats => ({
          title: 'DASHBOARD_INFO_TITLE_PEAK_POWER',
          subtitle: null,
          link: 'power',
        chartData: formatChartData(ChartType.PeakPower, peakPowerStats)
        })
      )
    );

    this.energy = this.store.select(RiderSelectors.selectRiderTotalPowerStats).pipe(
      filter(totalPowerStats => !!totalPowerStats),
      distinctUntilChanged(isEqual),
      map(totalPowerStats => ({
          title: 'DASHBOARD_INFO_TITLE_ENERGY_OUTPUT',
          link: 'energy',
          subtitle: null,
        chartData: formatChartData(ChartType.EnergyOutput, totalPowerStats)
        })
      )
    );
  }
}

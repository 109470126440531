import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AppState,
  OwnerActions,
  OwnerSelectors,
  RiderActions,
  RiderSelectors,
  RidesActions,
  RidesSelectors
} from '@carol-nx/store';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { MonthlyStatsModel } from '@carol-nx/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CustomDateAdapter } from '../custom-date-adapter';

interface Day {
  date: Date;
  value: string;
  class: string[];
}

@UntilDestroy()
@Component({
  selector: 'carol-nx-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls: ['./activity-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ActivityCalendarComponent implements OnInit {

  @Input()
  public initDate: string;

  public title: string;
  public dates: Day[][];
  public datePointer: Date;
  private monthlyActivity: MonthlyStatsModel[];
  private licenseIdSelected: number = undefined;
  private lastRideDate: Date;
  private riderId: number;
  private validSubscription: boolean;

  constructor(
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private datePipe: DatePipe,
    private dateAdapter: CustomDateAdapter
  ) {
  }

  public get isFirstSunday(): boolean {
    return this.dateAdapter.isFirstSunday();
  }

  private get isMembership() {
    return this.router.url.startsWith('/membership');
  }

  public ngOnInit(): void {
    this.datePointer = this.initDate ? new Date(this.initDate) : new Date();
    let selector: MemoizedSelector<AppState, MonthlyStatsModel[], DefaultProjectorFn<MonthlyStatsModel[]>>;
    if (this.isMembership) {
      selector = OwnerSelectors.selectLicenseMonthlyActivity;
    } else {
      selector = RiderSelectors.selectRiderMonthlyActivity;
    }

    combineLatest([
      this.store.select(selector),
      this.store.select(RidesSelectors.selectLastRide)
    ]).pipe(
      filter(([userMonthlyActivity, lastRide]) => Array.isArray(userMonthlyActivity) && (!!lastRide || this.isMembership)),
      tap(([userMonthlyActivity, lastRide]) => {
        this.monthlyActivity = userMonthlyActivity;

        if (lastRide) {
          this.lastRideDate = new Date(lastRide.start);
        }

        if (this.dates) {
          this.updateDates(); // refresh highlighted days
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.store.select(RiderSelectors.selectRider).pipe(
      filter(rider => !!rider),
      untilDestroyed(this)
    ).subscribe(rider => {
      this.riderId = rider.riderId;
      this.validSubscription = rider.validSubscription;
      if (!this.isMembership) {
        this.store.dispatch(RidesActions.GetLastRideByRiderId({riderId: this.riderId}));
      }
      this.initCalendar();
    });
  }

  public goBack(): void {
    this.clearCalendar();
    this.datePointer.setMonth(this.datePointer.getMonth() - 1);
    this.buildMonthCalendar();
  }


  public goForward() {
    if (!this.isCurrentMonth(this.datePointer)) {
      this.clearCalendar();
      this.datePointer.setMonth(this.datePointer.getMonth() + 1);
      this.buildCalendar();
    }
  }

  public isCurrentMonth(date: Date): boolean {
    let now = new Date();
    return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
  }

  private initCalendar() {
    if (this.isMembership) {
      this.store.select(RiderSelectors.selectRiderCurrentLicense).pipe(
        filter(license => !!license),
        tap(license => {
          this.licenseIdSelected = license.id;
          this.buildCalendar();
        }),
        untilDestroyed(this)
      ).subscribe();
    } else {
      this.buildCalendar();
    }
  }

  private buildCalendar() {
    if (this.isCurrentMonth(this.datePointer)) {
      this.buildLastCalendar();
    } else {
      this.buildMonthCalendar();
    }
  }

  private buildLastCalendar(): void {
    const today = new Date();
    const endDay = this.endOfWeek(today);
    const startDay = this.startOfWeek(new Date(new Date(today).setDate(today.getDate() - 4 * 7)));

    this.title = `${this.datePipe.transform(startDay, 'd MMM')} - ${this.datePipe.transform(endDay, 'd MMM')}`;
    this.dates = this.buildDates(today, startDay, endDay);
    if (this.isMembership) {
      this.store.dispatch(OwnerActions.GetLicenseLastActivity({licenseId: this.licenseIdSelected}));
    } else {
      this.store.dispatch(RiderActions.GetRiderLastActivity());
    }
  }

  private startOfWeek(date: Date): Date {
    const first = date.getDate() - date.getDay() + this.dateAdapter.getFirstDayOfWeek(); // First day is the day of the month - the day of the week
    return new Date(new Date(date).setDate(first));
  }

  private endOfWeek(date: Date): Date {
    const first = date.getDate() - date.getDay() + this.dateAdapter.getFirstDayOfWeek(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    return new Date(new Date(date).setDate(last));
  }

  private startOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  private endOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  private buildMonthCalendar(): void {
    const today = this.datePointer;

    const endDay = this.endOfWeek(this.endOfMonth(today));
    const startDay = this.startOfWeek(this.startOfMonth(today));

    this.title = `${this.datePipe.transform(today, 'MMMM y')}`;
    this.dates = this.buildDates(today, startDay, endDay);
    const year: number = today.getFullYear();
    const month: number = today.getMonth() + 1; // months are zero indexed. So January is 0 and December is 11.

    if (this.isMembership) {
      if (this.licenseIdSelected) {
        this.store.dispatch(OwnerActions.GetLicenseMonthlyActivity({
          licenseId: this.licenseIdSelected,
          month,
          year
        }));
      }
    } else {
      this.store.dispatch(RiderActions.GetRiderMonthlyActivity({month, year}));
    }

  }

  private buildDates(today: Date, startDay: Date, endDay: Date): Day[][] {
    const calendar: Day[][] = [];
    let date = new Date(new Date(startDay).setDate(startDay.getDate() - 1));
    while (date < endDay) {
      calendar.push(Array(7).fill(0).map(() => {
        const day = new Date(date.setDate(date.getDate() + 1));

        return {
          date: day,
          value: day.getDate() + '',
          class: today.getMonth() !== day.getMonth() ? ['not-current-month'] : []
        } as Day;
      }));
    }
    return calendar;
  }

  private updateDates(): void {
    const monthlyActivity = [...this.monthlyActivity].reverse();
    this.dates.forEach(week => week.forEach(date => {
      for (const strDate of monthlyActivity) {
        const highlightDate = new Date(strDate.date + ' 00:00');
        if (
          highlightDate.getDate() === date.date.getDate()
          && highlightDate.getMonth() === date.date.getMonth()
          && highlightDate.getFullYear() === date.date.getFullYear()
        ) {
          if (this.validSubscription === false) {
            date.class.push('not-valid-subscription');
          }
          if (
            this.lastRideDate
            && highlightDate.getDate() === this.lastRideDate.getDate()
            && highlightDate.getMonth() === this.lastRideDate.getMonth()
            && highlightDate.getFullYear() === this.lastRideDate.getFullYear()
          ) {
            date.class.push('last-rides-day');
            break;
          } else {
            date.class.push('other-rides-day');
            break;
          }
        }
      }
    }));
    this.changeDetectorRef.markForCheck();
  }

  private clearCalendar(): void {
    this.dates = undefined;
    this.changeDetectorRef.detectChanges(); // this case really need detectChanges instead of markForCheck
  }
}

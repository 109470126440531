<div *ngIf="rider" class="info-holder">
    <div *ngIf="rider.avatarPath"  class="avatar"
         [ngStyle]="{backgroundImage: 'url(' + (rider.avatarPath | avatarFullPath) + ')'}">
    </div>
    <div *ngIf="rider.avatarPath" class="avatar blurred"
         [ngStyle]="{backgroundImage: 'url(' + (rider.avatarPath | avatarFullPath) + ')'}">
    </div>
  <div *ngIf="!rider.avatarPath && rider.firstName" class="avatar initials">
    <div class="initials-inner">{{rider.firstName[0].toUpperCase() + rider.lastName[0].toUpperCase()}}</div>
  </div>
</div>
<div *ngIf="avatarPath" class="info-holder">
  <div class="avatar"
       [ngStyle]="{backgroundImage: 'url(' + (avatarPath | avatarFullPath) + ')'}">
  </div>
  <div class="avatar blurred"
       [ngStyle]="{backgroundImage: 'url(' + (avatarPath | avatarFullPath) + ')'}">
  </div>
</div>

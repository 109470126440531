import {createAction, props} from '@ngrx/store';
import {AuthFormStateTypes} from '@carol-nx/data';

export const LogIn = createAction(
  '[Auth] Log in',
  props<{username: string, password: string, rememberMe: boolean, callBack?: (resp?: any) => void}>()
);

export const LogOut = createAction(
  '[Auth] Log out',
);

export const LogInSuccess = createAction(
  '[Auth] Log in Success',
  props<{tokens?: any}>()
);

export const LogInFail = createAction(
  '[Auth] Log in Fail',
  props<{errMsg: string}>()
);

export const RestorePassword = createAction(
  '[Auth] Restore pass',
  props<{email: string}>()
);

export const RestorePasswordSuccess = createAction(
  '[Auth] Restore pass success',
);

export const RestorePasswordFail = createAction(
  '[Auth] Restore pass fail',
  props<{errMsg: string}>()
);

export const SetAuthStatus = createAction(
  '[Auth] Set authentication status',
  props<{isAuth: boolean}>()
);

export const SetUserData = createAction(
  '[Auth] Set user data',
  props<{userData: any}>()
);

export const SetAuthFormState = createAction(
  '[Auth] Set auth state',
  props<{formState: AuthFormStateTypes}>()
);

export const CheckEmail = createAction(
  '[Auth] Check new reg email',
  props<{email: string}>()
);

export const CheckEmailError = createAction(
  '[Auth] Check new reg email error',
  props<{errMsg: string}>()
);

export const LoadExistingUserData = createAction(
  '[Auth] Load existing user data',
  props<{accessToken: string, riderId: number}>()
);

// TODO: is not used may be delete it
// export const RefreshTokens = createAction(
//   '[Auth] Refresh tokens',
//   props<{refreshToken?: string}>()
// );

export const ConfirmRegistration = createAction(
  '[Auth] Confirm Registration',
  props<{hash: string, uuid: string, success?: (response) => void, error?: (response) => void}>()
);

export const ConfirmRegistrationSuccess = createAction(
  '[Auth] Confirm Registration success',
  props<{request: any}>()
);

export const ConfirmRegistrationFail = createAction(
  '[Auth] Confirm Registration Fail',
  props<{error: any}>()
);

export const ConfirmAlreadyActive = createAction(
  '[Auth] Confirm already active',
  props<{error: any}>()
);

import { createAction, props } from '@ngrx/store';
import {
  CountryModel,
  MonthlyStatsModel,
  PaymentCard,
  PaymentIntent,
  PaymentIntentWithBilling,
  Price,
  Subscription
} from '@carol-nx/data';

export const GetPrice = createAction(
  '[Owner] Get price',
  props<{bikeId: number}>()
);

export const GetNextPrice = createAction(
  '[Owner] Get next price',
  props<{bikeId: number, priceId: string, quantity: number, countryId: number, postCode?: string, city?: string, address?: string}>()
);

export const SetPrice = createAction(
  '[Owner] Set price',
  props<{price: Price}>()
);

export const GetSubscription = createAction(
  '[Owner] Get subscription',
  props<{bikeId: number}>()
);

export const CreateSubscription = createAction(
  '[Owner] Create subscription',
  props<{bikeId: number, priceId: string, quantity: number, paymentIntent: PaymentIntentWithBilling, flag3ds: boolean}>()
);

export const SuccessSubscription = createAction(
  '[Owner] Success subscription',
  props<{subscription: Subscription}>()
);

export const FailSubscription = createAction(
  '[Owner] Fail subscription',
  props<{error: string, detail?: string}>()
);

export const SetSubscription = createAction(
  '[Owner] Set subscription',
  props<{subscription: Subscription}>()
);

export const CreatePaymentSource = createAction(
  '[Owner] Create payment source',
  props<{
    bikeId: number,
    paymentCard: PaymentCard,
    before3dsFunc: () => void,
    amount: number,
    currencyAmount: string
  }>()
);

export const SuccessPaymentIntent = createAction(
  '[Owner] Success payment intent',
  props<{paymentIntent: PaymentIntent}>()
);

export const FailPaymentIntent = createAction(
  '[Owner] Fail payment intent',
  props<{error: string, detail?: string}>()
);

export const GetBikeCountry = createAction(
  '[Owner] Get bike country',
  props<{bikeId: number}>()
);

export const SetBikeCountry = createAction(
  '[Owner] Set bike country',
  props<{country: CountryModel}>()
);

export const FailBikeCountry = createAction(
  '[Owner] Fail bike country',
  props<{err: any}>()
);


export const SetSubscriptionGlobalSetting = createAction(
  '[Owner] Set subscription global setting',
  props<{subscriptionGlobalSetting: boolean}>()
);

export const RemoveRiderFromLicense = createAction(
  '[Owner] Remove rider from license',
  props<{riderId: number, licenseId: number}>()
);

export const RemoveRiderFromLicenseSuccess = createAction(
  '[Owner] Remove rider from license success',
  props<{riderId: number, licenseId: number}>()
);

export const GetLicenseStatisticValues = createAction(
  '[Owner] Get license stats',
  props<{licenseId: number}>()
);

export const SetLicenseStatisticValues = createAction(
  '[Owner] Set license stats',
  props<{stats: any}>()
);

export const SetLicenseDetails = createAction(
  '[Owner] Set license details',
  props<{licenseDetails: any}>()
);

export const GetLicenseMonthlyActivity = createAction(
  '[Owner] Get license monthly activity',
  props<{licenseId: number, month: number, year: number}>()
);

export const GetLicenseLastActivity = createAction(
  '[Owner] Get license last activity',
  props<{licenseId: number}>()
);

export const SetLicenseMonthlyActivity = createAction(
  '[Owner] Set license monthly activity',
  props<{licenseDatesWithRides: MonthlyStatsModel[]}>()
);

export enum RideTypes {
  IntenseRides = 'INTENSE',
  FreeCustomRides = 'FREE_CUSTOM',
  FreeRides = 'FREE',
  EnergiserRides = 'ENERGISER',
  RapidFatBurnRides = 'FAT_BURN',
  RapidFatBurn30Rides = 'FAT_BURN_30',
  RapidFatBurn45Rides = 'FAT_BURN_45',
  RapidFatBurn60Rides = 'FAT_BURN_60',
  RampUpRides = 'RAMP_UP',
  EnduranceRides = 'ENDURANCE',
  FreeRideWithLaps = 'FREE_RIDE_WITH_LAPS',
  IntermediateRides = 'INTERMEDIATE',
  GibalaIntervalsRides = 'GIBALA_INTERVALS',
  CoyleIntervalsRides = 'COYLE_INTERVALS',
  DrAdamsonIntervals = 'DR_ADAMSON_INTERVALS',
  FtpTest20Rides = 'FTP_TEST_20',
  FtpTest2x8Rides = 'FTP_TEST_2_X_8',
  FitnessTestsRides = 'FITNESS_TESTS',
  IntensesRides = 'INTENSES',
  IntermediatesRides = 'INTERMEDIATES',
  EnergisersRides = 'ENERGISERS',
  REHIT = 'REHIT',
  Intermediate3x15 = 'INTERMEDIATE_3X15',
  Energiser3x10 = 'ENERGISER_3X10',
  EkblomBakRides = 'ASTRAND_RHYMING',
  NorwegianZoneIntervals = 'NORWEGIAN_ZONE_INTERVALS'
}

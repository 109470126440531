import { ChartType } from '@carol-nx/data';
import { Action, createReducer, on } from '@ngrx/store';
import { RiderActions } from '../actions';
import { RiderState } from '@carol-nx/store';

export const riderFeatureKey = 'rider';

const initialRiderState: RiderState = {
};

const riderReducer = createReducer(
  initialRiderState,
  on(RiderActions.SetRiderData,
    ((state, {riderData}) => ({...state, ...riderData, user: {...state?.user, ...riderData}}))
  ),

  on(RiderActions.SetClientRiderId,
    ((state, {clientRiderId}) => ({...state, clientRiderId}))
  ),

  on(RiderActions.SetRiderLastRideAchievements,
    ((state, {lastRideAchievements}) => ({...state, lastRideAchievements}))
  ),

  on(RiderActions.SetRiderStatisticValues,
    ((state, {stats}) => ({...state, stats}))
  ),

  on(RiderActions.GetRiderError,
    ((state, {err}) => ({...state, errors: err.statusText}))
  ),

  on(RiderActions.SetRidersTrendsData,
    (state, {trends}) => {
      return ({...state, trends})
    }
  ),

  on(RiderActions.SetRidersHabitScoreChart,
    (state, { habitScoreChart }) => {
      return ({ ...state, habitScoreChart, habitScore: habitScoreChart[habitScoreChart.length - 1] });
    }
  ),

  on(RiderActions.SetRidersHabitScore,
    (state: RiderState, { habitScore }) => ({ ...state, habitScore })
  ),

  on(RiderActions.UnsetRidersTrendsData,
    (state) => ({...state, trends: undefined})
  ),

  on(RiderActions.SetRiderPositionsByRideTypeAndRideStats,
    (state: RiderState, {positions, rideType, rideStats}) =>
      ({...state, positions})
  ),

  on(RiderActions.SetRidersLicenses,
    (state, {licenses}) => ({...state, licenses})
  ),

  on(RiderActions.SetRidersOwnedLicenses,
    (state, {ownedLicenses}) => ({...state, ownedLicenses})
  ),

  on(RiderActions.SetCurrentLicense,
    (state: RiderState, {license}) => ({...state, currentLicense: license})
  ),

  on(RiderActions.SetOwnerMenuAvailable,
    (state: RiderState, {available}) => ({...state, ownerMenuAvailable: available})
  ),

  on(RiderActions.SetOwnedBikes,
    (state: RiderState, {bikes}) => ({...state, ownedBikes: bikes})
  ),

  on(RiderActions.SetSelectedBike,
    (state: RiderState, {bike}) => ({...state, currentBike: bike})
  ),

  on(RiderActions.UpdateOwnedBikeSuccess,
    (state: RiderState, {bikeId, bike}) => {
      const allBikes = Object.assign([], state.ownedBikes);
      const updatedBike = Object.assign({}, bike);
      updatedBike.id = bikeId;
      const index = allBikes.findIndex(f => f.id === bikeId);
      if (index >= 0) {
        allBikes[index] = updatedBike;
      } else {
        allBikes.push(updatedBike);
      }
      return {
        ...state,
        ownedBikes: allBikes
      };
    }
  ),

  on(RiderActions.SetOwnedRiders,
    (state: RiderState, {riders}) => {
      const resultRiders = [...(state.ownedRiders ? state.ownedRiders : []), ...riders];
      return {...state, ownedRiders: resultRiders};
    }
  ),

  on(RiderActions.RemoveOwnedRider,
    (state: RiderState, {riderId}) => {
      const updatedRiders = [...state.ownedRiders.filter(el => el.id !== riderId)];
      return {...state, ownedRiders: updatedRiders};
    }
  ),

  on(RiderActions.ResetOwnedRiders,
    (state: RiderState, {riders}) => {
      return {...state, ownedRiders: riders ? riders : []};
    }
  ),

  on(RiderActions.SetRidersPagingData,
    (state: RiderState, {pagingData}) => ({...state, ridersPaging: {...pagingData}})
  ),

  on(RiderActions.SetCurrentRider,
    (state: RiderState, {rider}) => ({...state, currentRider: rider})
  ),

  on(RiderActions.SetCalories,
    (state, {calories}) => (
      {...state, caloriesChartStats: calories})
  ),

  on(RiderActions.SetCaloriesByRideType,
    (state, {rideType, caloriesData}) => (
      {...state, caloriesData: {...state.caloriesData, [rideType]: caloriesData}})
  ),

  on(RiderActions.SetPeakPowerByRideType,
    (state, {rideType, peakPower}) => (
      {...state, peakPowerData: {...state.peakPowerData, [rideType]: peakPower}})
  ),

  on(RiderActions.SetPeakPowerPerLbByRideType,
    (state, {rideType, peakPowerPerLb}) => (
      {...state, peakPowerPerLbData: {...state.peakPowerPerLbData, [rideType]: peakPowerPerLb}})
  ),

  on(RiderActions.SetRiderPeakPowerStats,
    (state, {stats}) => ({
      ...state,
      peakPowerStats: stats,
      powerChartsStats: {
        ...state.powerChartsStats,
        peakPower: stats
      }
    })
  ),

  on(RiderActions.SetRiderPeakPowerPerLbStats,
    (state, {stats}) => ({
      ...state,
      powerChartsStats: {
        ...state.powerChartsStats,
        peakPowerPer: stats
      }
    })
  ),
  on(RiderActions.SetRiderPeakPowerPerKgStats,
    (state, {stats}) => ({
      ...state,
      powerChartsStats: {
        ...state.powerChartsStats,
        peakPowerPer: stats
      }
    })
  ),
  on(RiderActions.SetRiderMaximumAnaerobicPower,
    (state, {stats}) => ({
      ...state,
      powerChartsStats: {
        ...state.powerChartsStats,
        maximumAnaerobicPower: stats
      }
    })
  ),
  on(RiderActions.SetRiderMaximumAerobicPower,
    (state, {stats}) => ({
      ...state,
      powerChartsStats: {
        ...state.powerChartsStats,
        maximumAerobicPower: stats
      }
    })
  ),

  on(RiderActions.SetRiderFunctionalThresholdPower,
    (state, {stats}) => ({
      ...state,
      powerChartsStats: {
        ...state.powerChartsStats,
        functionalThresholdPower: stats
      }
    })
  ),

  on(RiderActions.SetTotalPowerByRideType,
    (state, {rideType, totalPower}) => (
      {...state, totalPowerData: {...state.totalPowerData, [rideType]: totalPower}})
  ),

  on(RiderActions.UpdateOwnedLicenseSuccess,
    (state: RiderState, {license}) => {
      const allLicenses = Object.assign([], state.ownedLicenses);
      const index = allLicenses.findIndex(f => f.id === license.id);
      if (index >= 0) {
        allLicenses[index] = Object.assign({}, allLicenses[index], license);
      } else {
        allLicenses.push(license);
      }
      let currentLicense = state.currentLicense;
      if (currentLicense.id === license.id) {
        currentLicense = Object.assign({}, license);
      }
      return {
        ...state,
        ownedLicenses: allLicenses,
        currentLicense
      };
    }
  ),

  on(RiderActions.SetOwnerToLicenseSuccess,
    (state: RiderState, {licenseId, newOwner}) => {
      const allOwnedLicenses = Object.assign([], state.ownedLicenses);
      const index = allOwnedLicenses.findIndex(f => f.id === licenseId);
      let currentLicense = state.currentLicense;
      if (index >= 0) {
        const updatedLicense = Object.assign({}, allOwnedLicenses[index]);
        updatedLicense.owners = updatedLicense.owners.concat(newOwner);
        allOwnedLicenses[index] = updatedLicense;
        if (currentLicense && currentLicense.id === licenseId) {
          currentLicense = Object.assign({}, updatedLicense);
        }
      }
      return {
        ...state,
        ownedLicenses: allOwnedLicenses,
        currentLicense
      };
    }
  ),

  on(RiderActions.DeleteOwnerLicenseSuccess,
    (state: RiderState, {licenseId, deleteOwner}) => {
      const allOwnedLicenses = Object.assign([], state.ownedLicenses);
      const index = allOwnedLicenses.findIndex(f => f.id === licenseId);
      if (index >= 0) {
        const updatedLicense = Object.assign({}, allOwnedLicenses[index]);
        updatedLicense.owners = updatedLicense.owners.filter(f => f.id !== deleteOwner.id);
        allOwnedLicenses[index] = updatedLicense;
      }
      return {...state, ownedLicenses: allOwnedLicenses};
    }
  ),

  on(RiderActions.SetRidersOwnedLicensesShortly,
    (state, {ownedLicensesShortly}) => ({...state, ownedLicensesShortly})
  ),

  on(RiderActions.SetRiderTotalPowerStats,
    (state, {stats}) => (
      {...state, totalPowerStats: stats})
  ),

  on(RiderActions.SetRiderStreaks,
    (state, {streaks}) => ({...state, streaks})
  ),

  on(RiderActions.SetRiderMonthlyActivity,
    (state, {datesWithRides}) => ({...state, datesWithRides})
  ),

  on(RiderActions.SetRiderFitnessScoreChartStats,
    (state, fitnessScoreChartStats) => (
      {...state, fitnessScoreChartStats: fitnessScoreChartStats.stats})
  ),

  on(RiderActions.SetRiderFitnessScorePerLbChartStats,
    (state, fitnessScorePerLbChartStats) => (
      {...state, fitnessScorePerLbChartStats: fitnessScorePerLbChartStats.stats})
  ),

  on(RiderActions.SetRiderFitnessScorePerKgChartStats,
    (state, fitnessScorePerKgChartStats) => (
      {...state, fitnessScorePerKgChartStats: fitnessScorePerKgChartStats.stats})
  ),

  on(RiderActions.SetRiderVO2MaxChartStats,
    (state, VO2MaxChartStats) => (
      { ...state, VO2MaxChartStats: VO2MaxChartStats.stats })
  ),

  on(RiderActions.SetRiderPeakHeartRateChartStats,
    (state, peakHeartRateChartStats) => (
      { ...state, peakHeartRateChartStats: peakHeartRateChartStats.stats })
  ),

  on(RiderActions.SetPeakHeartRateByRideType,
    (state, {rideType, peakHeartRate}) => (
      {...state, peakHeartRateData: {...state.peakHeartRateData, [rideType]: peakHeartRate}})
  ),

  on(RiderActions.SetRiderSharePreviewImageUrl,
    (state, sharePreviewImageUrl) => (
      {...state, sharePreviewImageUrl: sharePreviewImageUrl.previewImageUrl})
  ),

  on(RiderActions.SetChartsByRideTypes,
    (state, {chartType, rideTypes, resp}) => {
      let result;
      for (let i = 0; i < rideTypes.length; i++) {
        result = {...result, [rideTypes[i]]: resp[i]};
      }
      switch (chartType) {
        case ChartType.PeakPower:
          return {...state, peakPowerData: result};
        case ChartType.PeakPowerPerLB:
          return {...state, peakPowerPerLbData: result};
        case ChartType.PeakPowerPerKG:
          return {...state, peakPowerPerKgData: result};
        case ChartType.EnergyOutput:
          return {...state, totalPowerData: result};
        case ChartType.PeakHeartRate:
          return {...state, peakHeartRateData: result};
        case ChartType.CaloriesInclEPOC:
        case ChartType.CaloriesExclEPOC:
          return {...state, caloriesData: result};
      }
    }
  ),

  on(RiderActions.SetSelectedRiderId,
    (state, {riderId}) => ({...state, selectedRiderId: riderId})
  ),

  on(RiderActions.SetRiderLocaleSuccess,
    (state, {locale}) => {
      window.localStorage.setItem('locale', locale);
      return {...state, locale}
    }
  ),

  on(RiderActions.SetRiderTrainers, (state, {trainers, trainersPaging}) => ({...state, trainers, trainersPaging})),

  on(RiderActions.AddRiderTrainer, (state, {trainer}) => {
    const index = state.trainers?.findIndex(el => el.id === trainer.id);
    return index === -1 ? {...state, trainers: [trainer, ...state.trainers]} : state;
  }),

  on(RiderActions.DeleteRiderTrainerSuccess, (state, {trainerId}) => ({
    ...state,
    trainers: state.trainers?.filter(el => el.id !== trainerId)
  })),

  on(RiderActions.SetRiderTrainees, (state, {trainees, traineesPaging}) => ({...state, trainees, traineesPaging})),

  on(RiderActions.SetSelectedTrainee,
    ((state, {selectedTrainee}) => ({...state, selectedTrainee}))
  ),

  on(RiderActions.UnsetSelectedTrainee,
    ((state) => ({...state, selectedTrainee: undefined}))
  ),
);

export function reducer(state: RiderState | undefined, action: Action) {
  return riderReducer(state, action);
}

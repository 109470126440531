import {AuthFormStateTypes} from '@carol-nx/data';

export interface AuthState {
  authenticated: boolean;
  formState: AuthFormStateTypes;
  regEmail?: string;
  regEmailError?: string;
  accessToken?: string;
  accessTokenExpiresAt?: number;
  refreshToken?: string;
  refreshTokenExpiresAt?: number;
}

export const initialAuthState: AuthState = {
  authenticated: false,
  formState: AuthFormStateTypes.Login
};

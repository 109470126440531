import {AppState} from '../state';
import {createSelector} from '@ngrx/store';

const selectAuthState = (state: AppState) => state && state.auth;

export const selectAuthStatus = createSelector(
  selectAuthState,
  (state) => state.authenticated
);

export const selectAccessToken = createSelector(
  selectAuthState,
  state => state.accessToken
);

export const selectRefreshToken = createSelector(
  selectAuthState,
  state => state.refreshToken
);

export const selectAuthFormState = createSelector(
  selectAuthState,
  (state) => state.formState
);

export const selectTempEmail = createSelector(
  selectAuthState,
  (state) => state.regEmail
);


<div #holder (resizeEvent)="onResize()" carolNxResize class="svg-holder" resizeInitAfterMs="{{resizeInitAfter}}">
  <svg #chart class="chart"></svg>
  <div [style.width]="width+'px'" class="peers-wrapper">
    <carol-nx-simple-line-chart *ngIf="initPeers | async"
                                [barHeightUnit]="'0.2em'"
                                [barWidth]="width"
                                [chartData]="(_chartData | async).vsPeers"
                                [fullWidth]="true"
                                [vsPeers]="true">
    </carol-nx-simple-line-chart>
  </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {AuthFormStateTypes} from '@carol-nx/data';

@Pipe({
  name: 'formStateTitle'
})
export class FormStateTitlePipe implements PipeTransform {

  transform(value: AuthFormStateTypes, ...args: unknown[]): unknown {
    switch (value) {
      case AuthFormStateTypes.Login:
        return 'Login';
      case AuthFormStateTypes.Restore:
        return 'Reset password';
      default:
        return '';
    }
  }

}

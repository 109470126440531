import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, RiderActions, RiderSelectors, RidesActions } from '@carol-nx/store';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RiderService {
  constructor(private store: Store<AppState>) {
  }

  public selectRiderIdWithStats(): void {
    this.store.select(RiderSelectors.selectRiderId).pipe(
      filter(riderId => !!riderId),
      tap(riderId => this.store.dispatch(RiderActions.GetRiderStatisticValues({riderId}))),
      take(1)
    ).subscribe();
  }

  public selectRiderId(): void {
    this.store.select(RiderSelectors.selectRiderId).pipe(
      filter(riderId => !!riderId),
      tap(riderId => this.store.dispatch(RidesActions.GetLastRideByRiderId({riderId}))),
      take(1)
    ).subscribe();
  }
}

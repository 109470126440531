import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthRoles, FIRST_TIME_PREFIX, ThemeTypes } from '@carol-nx/data';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authApiUrl = `/rider-api/auth`;

  constructor(public http: HttpClient,
              public router: Router,
              public helperService: HelperService) {
  }

  get isAdminRole(): boolean {
    return AuthService.haveRole(AuthRoles.ADMIN);
  }

  get isSupportRole(): boolean {
    return AuthService.haveRole(AuthRoles.SUPPORT);
  }

  public login(loginData: any): Observable<any> {
    const { username, password } = loginData;
    return this.http.post(`${this.authApiUrl}/login`, {
      password,
      username
    });
  }

  public logOut(callBack?: () => void) {
    sessionStorage.clear();

    Object.keys(localStorage).forEach(key => {
      if (!key.includes(FIRST_TIME_PREFIX)) {
        localStorage.removeItem(key);
      }
    });

    const queryParams = new URLSearchParams(location.search);
    const theme = queryParams.get('theme');
    this.helperService.setTheme(theme === 'light' ? ThemeTypes.Light : ThemeTypes.Dark);
    if (callBack) {
      callBack();
    }
  }

  public sendResetLinkByEmailOrNickname(email: string): Promise<any> {
    return this.http.post(`${this.authApiUrl}/reset`, { email }).toPromise();
  }

  public confirmPasswordReset(hash: string, uuid: string, password: string): Promise<any> {
    return this.http.post(`${this.authApiUrl}/reset-confirm`, { hash, password, uuid }).toPromise();
  }

  public checkIfCanLogIn(): Observable<number> {
    let refreshTokenExpires: string;
    if (JSON.parse(localStorage.getItem('rememberMe'))) {
      refreshTokenExpires = localStorage.getItem('refreshTokenExpiresAt');
    } else {
      refreshTokenExpires = sessionStorage.getItem('refreshTokenExpiresAt');
    }
    const riderId = +localStorage.getItem('riderId');
    if (+refreshTokenExpires > Date.now()) {
      return of(riderId);
    } else {
      this.logOut();
      return of(null);
    }
  }

  public signUp(userData: any): Promise<any> {
    const langFromStorage = window.localStorage.getItem('locale');
    let locale = langFromStorage && ['EN', 'DE', 'ES'].includes(langFromStorage) ? langFromStorage : this.helperService.detectBrowserLocale();
    return this.http.post(`${this.authApiUrl}/register`, userData, {
      headers: {
        'Accept-Language': locale
      }
    }).toPromise();
  }

  public confirmRegistration(hash: string, uuid: string): Observable<any> {
    return this.http.post(`${this.authApiUrl}/register-confirm`, { hash, uuid });
  }

  public refreshTokens(refreshToken: string): Observable<any> {
    return this.http.post(`${this.authApiUrl}/refresh-token`, { refreshToken });
  }

  public checkEmailFast(email: string): Observable<any> {
    return this.http.post(`${this.authApiUrl}/check-email/fast`, { email });
  }

  public checkNicknameFast(nickname: string): Observable<any> {
    return this.http.post(`${this.authApiUrl}/check-nickname/fast`, { data: nickname });
  }

  get isApkRole(): boolean {
    return AuthService.haveRole(AuthRoles.APK);
  }

  private static haveRole(role: string): boolean {
    const rights = localStorage.getItem('authorities');
    return rights && rights.includes(role);
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {InfoActions} from '../actions';
import {InfoState, initialInfoState} from '../state/info.state';

export const infoFeatureKey = 'info';

const infoReducer = createReducer(
  initialInfoState,
  on(InfoActions.SetCommonError,
    (state, {title, message}) => {
      return {...state, error: {title, message}};
    }
  ),

  on(InfoActions.SetCommonSuccess,
    (state, {title, message}) => {
      return {...state, success: {title, message}};
    }
  ),

  on(InfoActions.SetCountriesList,
    ((state, {countries}) => ({...state, countries}))
  ),

  on(InfoActions.OpenModal,
    ((state, {modalType}) => ({...state, openedModal: modalType}))
  ),

  on(InfoActions.CloseModal,
    (state => {
      return {...state, openedModal: null, error: null, success: null};
    })
  ),

  on(InfoActions.ClearCommonMessages,
    (state => ({...state, error: null, success: null}))
  ),

  on(InfoActions.SetTheme,
    ((state, {theme}) => ({...state, theme}))
  ),

  on(InfoActions.ToggleNetworkStatusAlert,
    ((state, {connected}) => ({...state, connected}))
  ),

  on(InfoActions.SetPageTitle,
    ((state, {pageTitle}) => ({...state, pageTitle}))
  ),

  on(InfoActions.SetTableDataLoaded,
    ((state, {tableDataLoaded}) => ({...state, tableDataLoaded}))
  )
);

export function reducer(state: InfoState | undefined, action: Action) {
  return infoReducer(state, action);
}

export enum RideStats {
  AveragePower = 'AVERAGEPOWER',
  AverageSpeed = 'AVERAGESPEED',
  Calories = 'CALORIES',
  CaloriesInclEpoc = 'CALORIESINCLEPOC',
  Distance = 'DISTANCE',
  Ftp = 'FTP',
  HeartRateMax = 'HEARTRATEMAX',
  Maep = 'MAEP',
  Manp = 'MANP',
  FitnessScore = 'OCTANESCORE',
  FitnessScorePerLb = 'OSPERLB',
  PeakPower = 'PEAKPOWER',
  PeakPowerPerLb = 'PEAKPOWERPERLB',
  RpmMax = 'RPM_MAX',
  TotalPower = 'TOTALPOWER'
}

<form>
  <fieldset>
    <div class="title">Metric</div>
    <div class="content">
      <mat-radio-group (change)="selectChartType($event)">
        <mat-radio-button [value]="metric.fitness">Fitness Score</mat-radio-button>
        <mat-radio-button [value]="metric.burn">Calories</mat-radio-button>
        <mat-radio-button [value]="metric.power">Peak Power</mat-radio-button>
        <mat-radio-button [value]="metric.energy">Energy Output</mat-radio-button>
      </mat-radio-group>
    </div>
  </fieldset>

  <fieldset>
    <div class="title">Background</div>
    <div class="content no-padding">
      <div class="images-slider">
        <div class="bg-selector" (click)="selectImage()"></div>
        <div class="images">
          <img *ngFor="let bg of backgrounds; let i = index" [id]="'bgImg' + i"
               [src]="bg.cropped" (click)="selectBackgroundImage(i)"/>
        </div>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="title">Preview</div>
    <div class="content preview" [class.no-padding]="previewIsReady" #finalPreview>
      <div class="preview-holder">
        <div class="preview-image" *ngIf="!previewIsReady; else shareData">
          <carol-nx-svg-icon [icon]="'preview'" [native]="true"></carol-nx-svg-icon>
          <div class="label">No preview</div>
        </div>
      </div>
    </div>
  </fieldset>

  <carol-ui-button (click)="shareStats()">Share</carol-ui-button>
</form>

<ng-template #shareData>
  <img *ngIf="previewUrl as url" [src]="url" alt="CAROL"/>
  <div class="chart-holder">
    <div class="blur-bg"></div>
    <div class="inner-chart-holder">
      <carol-nx-gauge-chart
        *ngIf="selectedChartData"
        [hideBaseValue]="true"
        [hideBestValue]="true"
        [class.vs-peers]="selectedChartData?.vsPeers"
        [chartData]="selectedChartData">
      </carol-nx-gauge-chart>
    </div>
  </div>
  <div class="chart-meta">
    <div class="info">
      <div>{{ selectedMetricTitle }}</div>
      <carol-nx-svg-logo></carol-nx-svg-logo>
    </div>
    <div *ngIf="selectedChartData?.base" class="base">
      <div class="caption">Base</div>
      <div class="score">{{ selectedChartData.base }}</div>
    </div>
    <div *ngIf="selectedChartData?.base && selectedChartData?.best" class="sep"></div>
    <div *ngIf="selectedChartData?.best" class="best">
      <div class="caption">Best</div>
      <div class="score">{{ selectedChartData.best }}</div>
    </div>
  </div>
</ng-template>

<mat-dialog-content>
  <div class="avatar-holder">
    <avatar [rider]="rider"></avatar>
  </div>
  <p>Are you sure you want to logout?</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="true">Logout</button>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>

<div class="habit-score-chart-box">
  <div>
    <div class="mat-card-title">{{'HABIT_SCORE' | translate}}</div>
    <carol-nx-svg-icon (click)="clickIcon()" [native]="true" class="info-icon" icon="info-alt">
    </carol-nx-svg-icon>
  </div>
  <div class="habit-score-chart-wrapper">
    <carol-nx-habit-score-chart class="habit-score-chart"></carol-nx-habit-score-chart>
    <span class="habit-score">{{habitScore|async|coalesce:'N/A'}}</span>
  </div>
</div>

import {Action, createReducer, on} from '@ngrx/store';
import {AuthActions} from '../actions';
import {AuthState, initialAuthState} from '../state/auth.state'; // do not short it

export const authFeatureKey = 'auth';

const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.SetAuthFormState,
    (state, {formState}) => {
      return {...state, formState};
    }
  ),
  on(AuthActions.LogInSuccess,
    ((state, tokens) => ({...state, authenticated: true, ...tokens}))
  ),
  on(AuthActions.LogInFail,
    (state, {errMsg}) => ({...state, authenticated: false})
  ),
  on(AuthActions.CheckEmailError,
    (state, {errMsg}) => ({...state, regEmailError: errMsg})
  ),
  on(AuthActions.LogOut,
    (state => initialAuthState)
  ),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

import {Injectable} from '@angular/core';
import {GenderFilter} from '@carol-nx/data';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenderService {
  public curGender: BehaviorSubject<GenderFilter> = new BehaviorSubject(GenderFilter.ALL);

  public switchGender(genderVal: GenderFilter) {
    this.curGender.next(genderVal);
  }
}

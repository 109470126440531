import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private riderApiUrl = '/rider-api/rider';
  private bikeApiUrl = '/rider-api/bike';
  private iph: BehaviorSubject<any> = new BehaviorSubject<any>('');
  trackIphChange: Observable<any> = this.iph.asObservable();

  private bikeLocation: BehaviorSubject<any> = new BehaviorSubject<any>('');
  trackBikeLocationChange: Observable<any> = this.bikeLocation.asObservable();

  constructor(private http: HttpClient) {
  }

  public selectIph(data: any) {
    this.iph.next(data);
  }

  public selectBikeLocation(data: any) {
    this.bikeLocation.next(data);
  }

  public getBookingsList(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/booking`);
  }

  public createBooking(riderId: number, bookingData: any): Observable<any> {
    return this.http.post(`${this.riderApiUrl}/${riderId}/booking`, bookingData);
  }

  public deleteBooking(riderId: number, bookingId: number): Observable<any> {
    return this.http.delete(`${this.riderApiUrl}/${riderId}/booking/${bookingId}`);
  }

  public getBikesForBooking(riderId: number): Observable<any> {
    return this.http.get(`${this.riderApiUrl}/${riderId}/bike/bookable`);
  }

  public getReservedTimeSlotsForBike(bikeId: number): Observable<any> {
    return this.http.get(`${this.bikeApiUrl}/${bikeId}/booking`);
  }
}

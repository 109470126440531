import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BookingActions, InfoActions } from '../actions';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppState } from '../state';
import { Store } from '@ngrx/store';
import { BookingSelectors, RiderSelectors } from '../selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { BookingBikeModel, BookingModel, ModalTypes, TimeSlotModel } from '@carol-nx/data';
import { BookingService } from '@carol-nx/services';
import { verboseErrorMessage } from '@carol-nx/utils';

@Injectable()
export class BookingEffects {

  createBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.CreateBooking),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId)),
      switchMap(([action, riderId]) => this.bookingService.createBooking(riderId, {
          bikeId: action.bikeId,
          fromTime: action.fromTime
        })
          .pipe(
            switchMap(resp => {
              const action1 = BookingActions.GetBookingsList();
              const action2 = InfoActions.SetCommonSuccess({
                title: 'Booking complete',
                message: 'Your booking successfully complete. You will also receive notification by email'
              });
              if (action.success) {
                action.success(resp);
              }
              return of(action1, action2);
            }),
            catchError(err => this.handleError(err, action.onError))
          )
      )
    )
  );

  deleteBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.DeleteBooking),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId)),
      switchMap(([action, riderId]) => this.bookingService.deleteBooking(riderId, action.bookingId)
        .pipe(
          switchMap(resp => {
            const action1 = BookingActions.DeleteBookingFromList({bookingId: action.bookingId});
            const action2 = InfoActions.SetCommonSuccess({message: 'Your booking was successfully deleted'});
            return of(action1, action2);
          }),
          catchError(err => this.handleError(err))
        )
      )
    )
  );

  getBikesForBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.GetBikesForBooking),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId).pipe(filter(riderId => !!riderId))),
      switchMap(([action, riderId]) => {
          return this.bookingService.getBikesForBooking(riderId)
            .pipe(
              switchMap((resp: BookingBikeModel[]) => of(BookingActions.SetBikesForBooking({bookingBikes: resp}))),
              catchError(err => this.handleError(err))
            )
        }
      )
    )
  );

  getBookingsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.GetBookingsList),
      withLatestFrom(this.store.select(RiderSelectors.selectRiderId).pipe(filter(riderId => !!riderId))),
      switchMap(([action, riderId]) => this.bookingService.getBookingsList(riderId).pipe(
          switchMap((resp: BookingModel[]) => of(BookingActions.SetBookingsList({bookings: resp}))),
          catchError(err => this.handleError(err))
        )
      )
    )
  );

  getBookedTimeSlotsForBike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.GetBookedTimeSlotsForBike),
      withLatestFrom(this.store.select(BookingSelectors.selectBookingBikeId)),
      switchMap(([action, bikeId]) => this.bookingService.getReservedTimeSlotsForBike(bikeId)
        .pipe(
          map((slots: TimeSlotModel[]) => BookingActions.SetBookedTimeSlotsForBike({bookedSlots: slots})),
          catchError(err => this.handleError(err))
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private bookingService: BookingService) {
  }

  private handleError(err: HttpErrorResponse, onError?) {
    const action1 = InfoActions.SetCommonError({
      message: verboseErrorMessage(err.error.message),
      title: err.error.error
    });
    const action2 = InfoActions.OpenModal({modalType: ModalTypes.CommonErrorModal});
    if (onError) {
      onError(verboseErrorMessage(err.error.message));
    }
    return of(action1, action2);
  }
}

import {initialOwnerState, OwnerState} from "../state/owner.state";

import { Action, createReducer, on } from "@ngrx/store";
import {OwnerActions} from "../actions";


export const ownerFeatureKey = 'owner';

const ownerReducer = createReducer(
  initialOwnerState,

  on(OwnerActions.SetPrice,
    ((state, {price}) => {
      return {...state, price};
    })
  ),

  on(OwnerActions.SetSubscription,
    ((state, {subscription}) => {
      return {...state, subscription};
    })
  ),

  on(OwnerActions.SuccessPaymentIntent,
    ((state, {paymentIntent}) => {
      return {...state, paymentIntent};
    })
  ),

  on(OwnerActions.SetBikeCountry,
    ((state, {country}) => {
      return {...state, bikeCountry: country};
    })
  ),

  on(OwnerActions.SetSubscriptionGlobalSetting,
    ((state, {subscriptionGlobalSetting}) => {
      return {...state, subscriptionGlobalSetting};
    })
  ),

  on(OwnerActions.SetLicenseStatisticValues,
    ((state, {stats}) => ({...state, stats}))
  ),

  on(OwnerActions.SetLicenseDetails,
    ((state, {licenseDetails}) => ({...state, licenseDetails}))
  ),

   on(OwnerActions.SetLicenseMonthlyActivity,
    (state, {licenseDatesWithRides}) => ({...state, licenseDatesWithRides})
  ),

);

export function reducer(state: OwnerState | undefined, action: Action) {
  return ownerReducer(state, action);
}

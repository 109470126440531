import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AdminBikeCalibrationModel,
  AdminBikeInfoModel,
  AdminBikeSettingsModel,
  AdminLicenseModel,
  AdminQueriesModel,
  DiscountBase
} from '@carol-nx/data';
import { CustomHttpParamEncoder } from '@carol-nx/utils';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private adminApiUrl = `/admin-api`;
  private bikeApiUrl = `/bike-api`;

  constructor(private http: HttpClient) {
  }

  /*
   * Rider Controller
   */
  public getRiders(query?: AdminQueriesModel): Observable<any> {
    const fromObject = query as { [param: string]: string | string[] };
    const httpParams = new HttpParams({fromObject, encoder: new CustomHttpParamEncoder()});
    return this.http.get(`${this.adminApiUrl}/rider`, {params: httpParams});
  }

  public unblockRider(riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/unblock`, {});
  }

  public blockRider(riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/block`, {});
  }

  public adminRoleRider(riderId: number, admin: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/admin`, {is: admin});
  }

  public serviceRoleRider(riderId: number, service: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/service`, {is: service});
  }

  public factoryRoleRider(riderId: number, factory: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/factory`, {is: factory});
  }

  public supportRoleRider(riderId: number, support: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/support`, {is: support});
  }

  public updateRiderInfo(riderId: number, riderInfo: any): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}`, riderInfo);
  }

  public deleteRiderById(rideId: number): Observable<any> {
    return this.http.delete(`${this.adminApiUrl}/rider/${rideId}`);
  }

  public getRiderById(riderId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/rider/${riderId}`);
  }

  /*
   * Ride controller
   */
  public getAllRides(query?: AdminQueriesModel): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/ride`, {params: query as HttpParams});
  }

  public getRideById(rideId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/ride/${rideId}`);
  }

  public deleteRideById(rideId: number): Observable<any> {
    return this.http.delete(`${this.adminApiUrl}/ride/${rideId}`);
  }

  public recalculateRide(bikeId: number, rideId: number): Observable<any> {
    return this.http.post(`${this.bikeApiUrl}/bike/${bikeId}/ride/${rideId}/recalc`, {});
  }

  public moveRideToRider(rideId: number, riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/ride/${rideId}/move/${riderId}`, {});
  }

  public downloadTelemetry(rideId: number): void {
    this.http.get(`${this.adminApiUrl}/ride/telemetry_${rideId}.xlsx`, {responseType: 'arraybuffer'}).subscribe((data) => {
      const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `telemetry_${rideId}.xlsx`);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
      }, 100);
    });
  }

  /*
   * Licensee controller
   */
  public getAllLicensees(query?: AdminQueriesModel): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license`, {params: query as HttpParams});
  }

  public getAllLicenseesNames(query?: AdminQueriesModel): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/names`, {params: query as HttpParams});
  }

  public setOwner(licenseId: number, ownerId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseId}/owner/${ownerId}`, {});
  }

  public deleteOwner(licenseId: number, ownerId: number): Observable<any> {
    return this.http.delete(`${this.adminApiUrl}/license/${licenseId}/owner/${ownerId}`);
  }

  public setRiderToLicense(licenseId: number, ownerId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseId}/rider/${ownerId}`, {});
  }

  public deleteRiderFromLicense(licenseId: number, riderId: number): Observable<any> {
    return this.http.delete(`${this.adminApiUrl}/license/${licenseId}/rider/${riderId}`);
  }

  public getLicenseDetail(licenseId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/${licenseId}`);
  }

  public updateLicense(licenseData: AdminLicenseModel): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseData.id}`, licenseData);
  }

  public updateLicenseAvatar(licenseId: number, fileUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileUpload);
    return this.dataSend(`${this.adminApiUrl}/license/${licenseId}/avatar`, 'POST', formData);
  }

  public createLicense(name: string, type: string): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license`, {name, type});
  }

  /*
   * Auth controller
   */
  public login(password: string, username: string): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/auth/login`, {authenticationRequest: {password, username}});
  }

  /*
   * Bike controller
   */
  public getBikesPage(pagingData?: AdminQueriesModel): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/bike`,
      {params: pagingData as HttpParams});
  }

  public getBikeDetail(bikeId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/bike/${bikeId}`);
  }

  public getBikeTabletHistory(bikeId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/bike/${bikeId}/tablet-history`);
  }

  public updateBikeInfo(bikeId: number, bike: AdminBikeInfoModel): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/bike/${bikeId}`, bike);
  }

  public updateBikeCalibration(bikeId: number, statusUpdate: string, calibration: AdminBikeCalibrationModel): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/bike/${bikeId}/calibration?statusUpdate=${statusUpdate}`, calibration);
  }

  public updateBikeApp(bikeAppInfo: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', bikeAppInfo.file.files[0]);
    formData.append('version', bikeAppInfo.version);
    // const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data;');
    return this.dataSend(`${this.adminApiUrl}/bikeapp`, 'PUT', formData);
  }

  public updateCalfactors(calfactors: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', calfactors.file.files[0]);
    return this.dataSend(`${this.adminApiUrl}/bike/calfactors/upload`, 'PUT', formData);
  }

  public confirmRider(riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/confirm`, {});
  }

  private dataSend(url: string, method: string, body: FormData): Observable<any> {
    return new Observable(observer => {
      fetch(url, {
        method,
        body,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
      })
        .then(res => {
          return res.json();
        })
        .then(bodyRes => {
          observer.next(bodyRes);
          /*Complete the Observable as it won't produce any more event */
          observer.complete();
        });
    });
  }

  public getAppVersions(): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/bikeapp`);
  }

  public updateBikeFirmware(bikeAppInfo: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', bikeAppInfo.file.files[0]);
    formData.append('version', bikeAppInfo.version);
    formData.append('type', bikeAppInfo.type);
    // const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data;');
    return this.dataSend(`${this.adminApiUrl}/bikeapp/firmware`, 'PUT', formData);
  }

  public getBikeFirmwareLatest(): Observable<any> {
    return this.http.get(`${this.bikeApiUrl}/bikeapp/firmware`);
  }

  public resetRider(riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/reset`, {});
  }

  public resetRiderResistance(riderId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/resistance/reset`, {});
  }

  public getAdminFrame(frameId: number): Observable<any> {
    const httpParams = new HttpParams().append('id', frameId.toString());
    return this.http.get(`${this.adminApiUrl}/bike/frame`, {params: httpParams});
  }

  public switchBikeLicense(bikeId: number, licenseId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/bike/${bikeId}/license/${licenseId}`, {});
  }

  public getBikeSettingsById(bikeId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/bike/${bikeId}/settings`);
  }

  public updateBikeSettings(bikeId: number, bikeSettings: AdminBikeSettingsModel): Observable<any> {
    return this.http.put(`${this.adminApiUrl}/bike/${bikeId}/settings`, bikeSettings);
  }

  public setRiderPassword(riderId: number, newPassword: string): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/rider/${riderId}/password`, {data: newPassword});
  }

  public getLicenseDiscount(licenseId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/${licenseId}/subscription/discount`);
  }

  public updateLicenseDiscount(licenseId: number, discount: DiscountBase): Observable<any> {
    return this.http.put(`${this.adminApiUrl}/license/${licenseId}/subscription/discount`, discount);
  }

  public updateLicenseNeedSubscription(licenseId: number, needSubscription: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseId}/subscription-need`, {is: needSubscription});
  }

  public updateLicenseResetSubscription(licenseId: number): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseId}/subscription-reset`, {});
  }


  public getLicenseDiscountLimit(licenseId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/${licenseId}/subscription/discount-limit`);
  }

  public getLicenseInvoices(licenseId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/${licenseId}/subscription/invoices`);
  }

  public getSubscriptionHide(licenseId: number): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/license/${licenseId}/subscription-free`);
  }

  public updateSubscriptionHide(licenseId: number, hideSubscription: boolean): Observable<any> {
    return this.http.post(`${this.adminApiUrl}/license/${licenseId}/subscription-free`, {is: hideSubscription});
  }

  public getMembershipStats(): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/stats/subscription`);
  }

  public getMembershipUsage(): Observable<any> {
    return this.http.get(`${this.adminApiUrl}/stats/subscription/usage`);
  }

  public deleteSubscriptionPaymentSource(licenseId: number): Observable<any> {
    return this.http.delete(`${this.adminApiUrl}/license/${licenseId}/subscription/payment-source`);
  }

}

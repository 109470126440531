import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { InnerCardDataModel } from '@carol-nx/data';
import { DialogService } from '@carol-nx/services';
import { BehaviorSubject } from 'rxjs';
import { PowerInfoBoxComponent } from '../power-info-box/power-info-box.component';
import { isValidChartData } from '@carol-nx/utils';

@Component({
  selector: 'carol-nx-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardChartComponent implements OnInit, AfterViewInit{

  @ViewChild('titleContent') cardTitle: ElementRef;

  @Input()
  set data(cardData: InnerCardDataModel) {
    this._data.next(cardData);
  }
  @Input()
  public route: string;

  @Input()
  public iconInfo = true;

  public _data = new BehaviorSubject<InnerCardDataModel>(null);

  get isValidChartData(): boolean {
    return isValidChartData(this._data.getValue().chartData);
  }

  constructor(private dialogService: DialogService,
              @Inject(DOCUMENT) document) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.checkTitleSize();
  }

  public clickIcon(): void {
    const modal = this.dialogService.openCertainModal(PowerInfoBoxComponent, {
      data: this._data.getValue(),
      maxWidth: 'max(1280px, 50em)'
    })

    // TODO: just make overlay blurred
    const background = document.getElementById('fullscreen');
    background.style.filter = 'blur(0.21rem)';
    modal.afterClosed().subscribe(_ => {
      background.style.filter = 'unset';
    });
  }

  public titleResize(): void {
    this.checkTitleSize();
  }

  private checkTitleSize(): void {
    if (this.cardTitle?.nativeElement) {
      const element = this.cardTitle.nativeElement as Element;
      const style = window.getComputedStyle(element, null).getPropertyValue('font-size');
      const fontSize = parseFloat(style);
      if (this.cardTitle.nativeElement.offsetHeight > fontSize * 2) {
        this.cardTitle.nativeElement.style['white-space'] = 'nowrap';
        this.cardTitle.nativeElement.style['text-overflow'] = 'ellipsis';
        this.cardTitle.nativeElement.style['overflow'] = 'hidden';
        window.postMessage({cardTitleOverflow: true}, '*');
      }
    }
  }
}

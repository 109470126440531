import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AppState, RiderActions, RiderSelectors} from '@carol-nx/store';
import {Store} from '@ngrx/store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {domToJpeg} from 'modern-screenshot';
import {filter, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

export enum PowerShareType {
  fitness = 1,
  burn,
  power,
  energy,
}

@UntilDestroy()
@Component({
  selector: 'carol-ui-share-form',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent implements OnInit {
  @ViewChild('finalPreview') finalPreview: ElementRef;
  @Input()
  private camera: any;
  @Input()
  private cameraResultType: any;
  @Input()
  public chartData;
  @Output()
  public resultIsReady: EventEmitter<string> = new EventEmitter<string>();
  public selectedChartData;
  public selectedMetric: PowerShareType;
  public previewUrl: string;
  public previewIsReady: boolean;
  public backgrounds = [
    {
      cropped: '/assets/imgs/share-bg/1-Carol_Bike_White_BG_Perspective_Back_002-mini.jpg',
      full: '/assets/imgs/share-bg/1-Carol_Bike_White_BG_Perspective_Back_002.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/2-Carol_Bike_White_BG_Side_003-mini.jpg',
      full: '/assets/imgs/share-bg/2-Carol_Bike_White_BG_Side_003.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/3-Carol_Bike_White_BG_First_Person_001-mini.jpg',
      full: '/assets/imgs/share-bg/3-Carol_Bike_White_BG_First_Person_001.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/4-Carol_Bike_White_BG_Front_003-mini.jpg',
      full: '/assets/imgs/share-bg/4-Carol_Bike_White_BG_Front_003.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/5-Carol_Bike_Black_BG_Perspective_Back_006-mini.jpg',
      full: '/assets/imgs/share-bg/5-Carol_Bike_Black_BG_Perspective_Back_006.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/6-Carol_Bike_Black_BG_Side_007-mini.jpg',
      full: '/assets/imgs/share-bg/6-Carol_Bike_Black_BG_Side_007.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/7-Carol_Bike_Black_BG_First_Person_005-mini.jpg',
      full: '/assets/imgs/share-bg/7-Carol_Bike_Black_BG_First_Person_005.jpg'
    },
    {
      cropped: '/assets/imgs/share-bg/8-Carol_Bike_Black_BG_Perspective_Front_007-mini.jpg',
      full: '/assets/imgs/share-bg/8-Carol_Bike_Black_BG_Perspective_Front_007.jpg'
    }
  ];

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  public get metric() {
    return PowerShareType;
  };

  public ngOnInit(): void {
    const assignPreviewUrl = (previewUrl: string): void => {
      this.previewIsReady = !!previewUrl
      this.previewUrl = previewUrl;

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    };

    this.store.select(RiderSelectors.selectRiderSharePreviewImageUrl).pipe(
      filter(previewUrl => !!previewUrl),
      tap(assignPreviewUrl),
      untilDestroyed(this)
    ).subscribe();
  }

  public selectBackgroundImage(ind): void {
    this.http.get(this.backgrounds[ind].full, { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          this.store.dispatch(RiderActions.SetRiderSharePreviewImageUrl({ previewImageUrl: base64data as string }));
        };
        reader.readAsDataURL(res);
      });
  }

  public async selectImage() {
    const image = await this.camera.getPhoto({
      quality: 80,
      resultType: this.cameraResultType.DataUrl,
    });
    const newFile = await fetch(image.dataUrl);
    this.store.dispatch(RiderActions.SetRiderSharePreviewImageUrl({previewImageUrl: newFile.url}));
  }

  get selectedMetricTitle(): string {
    switch (this.selectedMetric) {
      case PowerShareType.burn:
        return 'Calories';
      case PowerShareType.energy:
        return 'Energy Output';
      case PowerShareType.fitness:
        return 'Fitness Score';
      case PowerShareType.power:
        return 'Peak Power'
    }
  }

  public selectChartType(newVal: any) {
    this.selectedMetric = newVal.value;
    this.selectedChartData = null;
    this.previewIsReady = true;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    switch (this.selectedMetric) {
      case PowerShareType.fitness:
        this.selectedChartData = this.chartData.fitness;
        break;
      case PowerShareType.burn:
        this.selectedChartData = this.chartData.burn;
        break;
      case PowerShareType.power:
        this.selectedChartData = this.chartData.power;
        break;
      case PowerShareType.energy:
        this.selectedChartData = this.chartData.energy;
        break;
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  public shareStats() {
    if (!this.selectedMetric) {
      return alert('Please select the metric to share');
    }
    domToJpeg(this.finalPreview.nativeElement, {quality: 1, scale: 3, font: {preferredFormat: 'woff2'}})
      .then((data) => this.resultIsReady.emit(data))
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }
}

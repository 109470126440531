import { AppState } from '../state';
import { createSelector } from '@ngrx/store';
import { PowerTypes } from '@carol-nx/data';

const selectLeaderboardState = (state: AppState) => state.leaderboard;

export const selectGenderFilter = createSelector(
  selectLeaderboardState,
  state => state.genderFilter
);

export const selectLeaderboardFilterValues = createSelector(
  selectLeaderboardState,
  state => state.leaderboardFilterValues
);

export const selectTopListsData = createSelector(
  selectLeaderboardState,
  state => state.topListsData
);

export const selectTopListsTotalElements = createSelector(
  selectLeaderboardState,
  state => state.topListsTotalElements
);

export const selectTopListsPositions = createSelector(
  selectLeaderboardState,
  state => state.topListsPosition
);

export const selectLeaderboardTypeValues = createSelector(
  selectLeaderboardState,
  state => state && state.leaderboardTypeValues,
);

export const selectActiveLeaderboardTypeId = createSelector(
  selectLeaderboardState,
  state => state.activeLeaderboardTypeId
);

export const selectActiveLeaderboardTypeValue = createSelector(
  selectLeaderboardState,
  selectLeaderboardTypeValues,
  selectActiveLeaderboardTypeId,
  (state, leaderboardTypes, activeLeaderboardTypeId) => {
    return leaderboardTypes.filter(el => el && el.id === activeLeaderboardTypeId)[0];
  }
);

export const selectActivePowerTypeValue = createSelector(
  selectLeaderboardState,
  state => state.activePowerTypeValue
);

export const selectActiveSubPowerTypeValue = createSelector(
  selectLeaderboardState,
  state => state.activeSubPowerTypeValue
);

export const selectTopUsersSliderDataByPowerType = createSelector(
  selectActivePowerTypeValue,
  selectActiveSubPowerTypeValue,
  selectTopListsData,
  (activePowerType, activeSubPowerType, topLists) => {
    const parentPowerType = activePowerType.powerType;
    let currentSubPowerType: PowerTypes | undefined = activeSubPowerType?.powerType;
    const powerType = currentSubPowerType ? currentSubPowerType : parentPowerType;
    return activePowerType && topLists && topLists[powerType];
  }
);

export const selectUserScoreByPowerType = createSelector(
  selectActivePowerTypeValue,
  selectActiveSubPowerTypeValue,
  selectTopListsPositions,
  (activePowerType, activeSubPowerType, topPositions) => {
    const parentPowerType = activePowerType.powerType;
    let currentSubPowerType: PowerTypes | undefined = activeSubPowerType?.powerType;
    const powerType = currentSubPowerType ? currentSubPowerType : parentPowerType;
    return activePowerType && topPositions && topPositions[powerType];
  }
);

import {AppState} from '../state';
import {createSelector} from '@ngrx/store';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

const selectBookingState = (state: AppState) => state && state.booking;

export const selectBookingList = createSelector(
  selectBookingState,
  (state) => state.bookings
);

export const selectBookingLocationName = createSelector(
  selectBookingState,
  state => state.selectedLocation
);

export const selectBookingBikeId = createSelector(
  selectBookingState,
  state => state.selectedBikeId
);

export const selectBookingBikes = createSelector(
  selectBookingState,
  (state) => state.bikes
);

export const selectBookingBikeLocations = createSelector(
  selectBookingBikes,
  ((bikes) => {
    if (bikes) {
      const allLocations = bikes.reduce((acc, cv, ci) => {
        if (cv.location !== acc[ci]) {
          const capitalized = cv.location[0].toUpperCase() + cv.location.substring(1);
          acc.push({title: capitalized, value: cv.location});
        }
        return acc;
      }, []);
      return uniqBy(sortBy(allLocations, ['title', 'value']), 'value');
    }
    return [];
  })
);

export const selectBookingBikeByLocation = createSelector(
  selectBookingBikes,
  selectBookingLocationName,
  ((bikes, locationName) => {
    if (bikes) {
      return filter(bikes, (bike => bike.location === locationName));
    }
    return [];
  })
);

export const selectBookedSlots = createSelector(
  selectBookingState,
  (state => state.bookedSlots)
);

export const selectAvailableBookingSlots = createSelector(
  selectBookingState,
  state => {
    if (state.availableBookingSlots) {
      if (state.availableBookingSlots.length && state.bookedSlots && state.bookedSlots.length) {
        return state.availableBookingSlots.filter(slot => !state.bookedSlots.some(bSlot => bSlot.fromTime === slot.fromTime));
      } else {
        return state.availableBookingSlots;
      }
    }
  }
);

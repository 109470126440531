<div class="sticky-user current" *ngIf="!!(currentUser | async)?.rank && (showRiderPosition | async) != null && !isEmptyTopUsersList" [style.top]="(showRiderPosition | async)">
  <ng-container *ngTemplateOutlet="userItem;context:{user: (currentUser | async)}"></ng-container>
</div>
<cdk-virtual-scroll-viewport #scroller itemSize="72" class="content">
  <mat-list role="list">
    <ng-container *cdkVirtualFor="let topUser of (topUsers | async); let i = index">
      <mat-list-item class="list-item fadeInUp" [class.animated]="isSlideshow"
                     [ngClass]="'delay-' + (i+1) + '-s'"
                     [class.current]="topUser.riderId===riderId">
        <ng-container *ngTemplateOutlet="userItem;context:{user: topUser}"></ng-container>
      </mat-list-item>
    </ng-container>
    <div class="spinner-item" *ngIf="isLoading">
      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50">
      </mat-progress-spinner>
    </div>
  </mat-list>
  <carol-ui-no-ride-data *ngIf="!isLoading && isEmptyTopUsersList"></carol-ui-no-ride-data>
</cdk-virtual-scroll-viewport>


<ng-template #userItem let-topUser="user">
  <div class="rank">{{topUser.rank || 'N/A' }}</div>
  <div class="avatar-holder">
    <avatar [rider]="topUser"></avatar>
    <mat-icon *ngIf="!isSlideshow && topUser.riderId!==riderId && !topUser.following"
              class="icon-follower action-follow" [ngClass]="{'add-active': rider?.validSubscription}"  (click)="addFollow(topUser)">
      add
    </mat-icon>
    <mat-icon *ngIf="!isSlideshow && topUser.riderId!==riderId && topUser.following"
              class="icon-follower"
              [ngClass]="{'observable': leaderboardTypeId !== 3, 'action-follow': leaderboardTypeId === 3,
               'del-active': rider?.validSubscription && leaderboardTypeId === 3, 'done-active': rider?.validSubscription && leaderboardTypeId !== 3}"
              (click)="delFollow(topUser)">
      {{leaderboardTypeId !== 3 ? 'done' : 'remove'}}
    </mat-icon>
  </div>
  <div class="user-label">
    {{topUser.firstName}} {{topUser.lastName}}
  </div>
  <div class="score">
    {{topUser.value | formatScore : (selectedPowerTypeValue | async).powerType}}
  </div>
</ng-template>

import { AppState } from '../state';
import { createSelector } from '@ngrx/store';

const selectAdminState = (state: AppState) => state && state.admin;

export const selectAdminRides = createSelector(
  selectAdminState,
  (state) => state && state.rides
);

export const selectAdminRideId = createSelector(
  selectAdminState,
  (state) => state.selectedRideId
);

export const selectAdminRideById = createSelector(
  selectAdminRides,
  selectAdminRideId,
  (rides, rideId) => rides && rides.filter(el => el.id === rideId)[0]
);

export const  selectExtendedAdminRideData = createSelector(
  selectAdminState,
  (state) => state.selectedRideExtendData
);

export const selectAdminRidesPaging = createSelector(
  selectAdminState,
  (state) => state.ridesPaging
);

export const selectAdminRiders = createSelector(
  selectAdminState,
  (state) => state.riders
);

export const selectAdminRidersPaging = createSelector(
  selectAdminState,
  (state) => state.ridersPaging
);

const selectRiderId = createSelector(
  selectAdminState,
  state => state.selectedRiderId
);

export const selectAdminRiderById = createSelector(
  selectAdminRiders,
  selectRiderId,
  (riders, riderId) => riders && riders.filter(el => el.id === riderId)[0]
);

export const selectAdminLicenses = createSelector(
  selectAdminState,
  state => state.licenses
);

export const selectAdminLicensesPaging = createSelector(
  selectAdminState,
  (state) => state.licensesPaging
);

export const selectBikesLoadingStatus = createSelector(
  selectAdminState,
  state => state.bikesIsLoading
);

export const selectAdminBikes = createSelector(
  selectAdminState,
  (state) => state.bikes
);

export const selectAdminBikesForModal = createSelector(
  selectAdminState,
  (state) => state.bikesForModal
);

export const selectAdminSelectedBikeId = createSelector(
  selectAdminState,
  (state => state.selectedBikeId)
);

export const selectAdminBikesPaging = createSelector(
  selectAdminState,
  (state) => state.bikesPaging
);

export const selectAdminSelectedLicenseId = createSelector(
  selectAdminState,
  (state => state.selectedLicenseId)
);

export const selectAdminSelectedLicense = createSelector(
  selectAdminState,
  (state => state.selectedLicense)
);

export const selectBikeAppInfo = createSelector(
  selectAdminState,
  (state) => state.bikeAppInfo
);

export const selectAdminBikeDetail = createSelector(
  selectAdminState,
  (state) => state.bikeDetail
);


export const selectAdminBikeTabletHistory = createSelector(
  selectAdminState,
  (state) => state.tabletHistory
);

export const selectAdminAppVersions = createSelector(
  selectAdminState,
  (state) => state.appVersions
);

export const selectAdminBikeFrame = createSelector(
  selectAdminState,
  (state) => state.selectedBikeFrame
);

export const selectAdminRiderDetail = createSelector(
  selectAdminState,
  (state) => state.riderDetail
);

export const selectAdminBikeSettings = createSelector(
  selectAdminState,
  (state) => state.bikeSettings
);

export const selectAdminLicenseDiscount = createSelector(
  selectAdminState,
  (state) => state.licenseDiscount
);

export const selectAdminLicenseDiscountLimit = createSelector(
  selectAdminState,
  (state) => state.licenseDiscountLimit
);

export const selectAdminLicensePaymentInvoices = createSelector(
  selectAdminState,
  (state) => state.paymentInvoices
);

export const selectMembershipStats = createSelector(
  selectAdminState,
  (state) => state.membershipStats
);

export const selectBikeFirmwares = createSelector(
  selectAdminState,
  (state) => state.firmwares
);

export const selectMembershipUsageDataLoading = createSelector(
  selectAdminState,
  state => state.membershipUsageDataIsLoading
);

export const selectMembershipUsage = createSelector(
  selectAdminState,
  state => state.membershipUsage
);

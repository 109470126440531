import {
  GenderFilter,
  LeaderboardFilter,
  LeaderboardType,
  PowerTypes,
  PowerTypeValue,
  TopUserModel
} from '@carol-nx/data';
import { PowerService } from 'libs/services/src/lib/power.service'; //do not change

export interface LeaderboardState {
  leaderboardTypeValues: LeaderboardType[];
  activeLeaderboardTypeId: number;
  powerTypeValues: PowerTypeValue[];
  activePowerTypeValue: PowerTypeValue;
  activeSubPowerTypeValue: PowerTypeValue | null;
  topListsData?: TopListsByPowerType;
  topListsPosition?: TopListPositionByPowerType;
  topListsTotalElements?: number;
  leaderboardFilterValues: LeaderboardFilter[];
  genderFilter: GenderFilter;
}

export const initialLeaderboardState: LeaderboardState = {
  activeSubPowerTypeValue: null,
  leaderboardTypeValues: [
    {
      id: 1,
      name: 'global',
      title: 'Global'
    },
    {
      id: 2,
      name: 'company',
      title: 'Local'
    },
    {
      id: 3,
      name: 'friends',
      title: 'Following'
    }
  ],
  powerTypeValues: [
    {
      id: 1,
      name: 'fitnessScore',
      title: 'Fitness Score',
      powerType: PowerTypes.FITNESS_SCORE,
      active: true,
    },
    {
      id: 2,
      name: 'fitnessScorePerLb',
      title: 'Fitness Score per Lb',
      powerType: PowerTypes.FITNESS_SCORE_PER_LB,
      active: false,
      metric: 'PER_LB'
    },
    {
      id: 3,
      name: 'fitnessScorePerKg',
      title: 'Fitness Score per Kg',
      powerType: PowerTypes.FITNESS_SCORE_PER_KG,
      active: false,
      metric: 'PER_KG'
    },
    {
      id: 4,
      name: 'peakPower',
      title: 'Peak Power',
      powerType: PowerTypes.PEAK,
      active: false,
    },
    {
      id: 5,
      name: 'peakPowerPerLb',
      title: 'Peak Power Per Lb',
      powerType: PowerTypes.PEAK_PER_LB,
      active: false,
      metric: 'PER_LB'
    },
    {
      id: 6,
      name: 'peakPowerPerKg',
      title: 'Peak Power Per Kg',
      powerType: PowerTypes.PEAK_PER_KG,
      active: false,
      metric: 'PER_KG'
    },
    {
      id: 7,
      name: 'totalRehitPower',
      title: 'Energy Output - REHIT',
      powerType: PowerTypes.TOTAL_REHIT,
      active: false,
      subType: [
        {
          id: 1,
          name: 'totalRehitPower',
          title: PowerService.subTypesNames.REHIT,
          powerType: PowerTypes.TOTAL_REHIT,
          active: false,
        },
        {
          id: 2,
          name: 'totalIntensePower',
          title: PowerService.subTypesNames.INTENSE,
          powerType: PowerTypes.TOTAL_INTENSE,
          active: false,
        },
        {
          id: 3,
          name: 'totalIntermediate3x15Power',
          title: PowerService.subTypesNames.INTERMEDIATE_3X15,
          powerType: PowerTypes.TOTAL_INTERMEDIATE_3X15,
          active: false
        },
        {
          id: 4,
          name: 'totalIntermediatePower',
          title: PowerService.subTypesNames.INTERMEDIATE,
          powerType: PowerTypes.TOTAL_INTERMEDIATE,
          active: false,
        },
        {
          id: 5,
          name: 'totalEnergiser3x10Power',
          title: PowerService.subTypesNames.ENERGISER_3X10,
          powerType: PowerTypes.TOTAL_ENERGISER_3X10,
          active: false
        },
        {
          id: 6,
          name: 'totalEnergiserPower',
          title: PowerService.subTypesNames.ENERGISER,
          powerType: PowerTypes.TOTAL_ENERGISER,
          active: false,
        }
      ]
    },
    {
      id: 8,
      name: 'totalFatBurnPower',
      title: 'Energy Output - Fat Burn',
      powerType: PowerTypes.TOTAL_FAT_BURN,
      active: false,
      subType: [
        {
          id: 1,
          name: 'totalFatBurnPower',
          title: PowerService.subTypesNames.FAT_BURN,
          powerType: PowerTypes.TOTAL_FAT_BURN,
          active: false,
        },
        {
          id: 2,
          name: 'totalFatBurn30Power',
          title: PowerService.subTypesNames.FAT_BURN_30,
          powerType: PowerTypes.TOTAL_FAT_BURN_30,
          active: false,
        },
        {
          id: 3,
          name: 'totalFatBurn45Power',
          title: PowerService.subTypesNames.FAT_BURN_45,
          powerType: PowerTypes.TOTAL_FAT_BURN_45,
          active: false,
        },
        {
          id: 4,
          name: 'totalFatBurn60Power',
          title: PowerService.subTypesNames.FAT_BURN_60,
          powerType: PowerTypes.TOTAL_FAT_BURN_60,
          active: false,
        },
      ]
    },
    {
      id: 9,
      name: 'totalFreeAndCustom',
      title: 'Energy Output - Free & Custom',
      powerType: PowerTypes.TOTAL_FREE_AND_CUSTOM,
      active: false,
      subType: [
        {
          id: 1,
          name: 'totalFreeCustomPower',
          title: PowerService.subTypesNames.FREE_CUSTOM,
          powerType: PowerTypes.TOTAL_FREE_AND_CUSTOM,
          active: false
        },
        {
          id: 2,
          name: 'totalFreePower',
          title: PowerService.subTypesNames.FREE,
          powerType: PowerTypes.TOTAL_FREE,
          active: false
        },
        {
          id: 3,
          name: 'totalAdamsonPower',
          title: PowerService.subTypesNames.DR_ADAMSON_INTERVALS,
          powerType: PowerTypes.TOTAL_ADAMSON,
          active: false
        },
        {
          id: 4,
          name: 'totalGibalaPower',
          title: PowerService.subTypesNames.GIBALA_INTERVALS,
          powerType: PowerTypes.TOTAL_GIBALA,
          active: false
        },
        {
          id: 5,
          name: 'totalCoylePower',
          title: PowerService.subTypesNames.COYLE_INTERVALS,
          powerType: PowerTypes.TOTAL_COYLE,
          active: false
        },
        {
          id: 6,
          name: 'totalNorwegianPower',
          title: PowerService.subTypesNames.NORWEGIAN_ZONE_INTERVALS,
          powerType: PowerTypes.TOTAL_NORWEGIAN_ZONE,
          active: false
        }
      ]
    },
  ],
  leaderboardFilterValues: [
    {
      title: 'All',
      value: GenderFilter.ALL
    },
    {
      title: 'Male',
      value: GenderFilter.MALE
    },
    {
      title: 'Female',
      value: GenderFilter.FEMALE
    }
  ],
  activeLeaderboardTypeId: 1,
  activePowerTypeValue: {
    id: 1,
    name: 'fitnessScore',
    title: 'Fitness Score',
    powerType: PowerTypes.FITNESS_SCORE,
    active: true,
  },
  genderFilter: GenderFilter.ALL
};

type TopListsByPowerType = Record<PowerTypes, TopUserModel[]>;
type TopListPositionByPowerType = Record<PowerTypes, TopUserModel>;

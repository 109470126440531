import { Observable } from 'rxjs';
import { AppState, RiderSelectors } from '@carol-nx/store';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HabitScorePopupComponent } from '@carol-nx/ui';
import { DialogService } from '@carol-nx/services';

@Component({
  selector: 'carol-habit-score-card',
  templateUrl: './habit-score-card.component.html',
  styleUrls: ['./habit-score-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HabitScoreCardComponent implements OnInit {
  @Input()
  public initDate: number;
  public habitScore: Observable<number>;

  constructor(private store: Store<AppState>, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.habitScore = this.store.select(RiderSelectors.selectRiderHabitScore);
  }

  public clickIcon(): void {
    const modal = this.dialogService.openCertainModal(HabitScorePopupComponent, {
      data: this.initDate,
      maxWidth: 'max(1280px, 50em)'
    });

    // TODO: just make overlay blurred
    const background = document.getElementById('fullscreen');
    background.style.filter = 'blur(0.21rem)';
    modal.afterClosed().subscribe(_ => {
      background.style.filter = 'unset';
    });
  }
}

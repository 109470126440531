<h1>Privacy Policy | CAROL Bike</h1>
<h3>Privacy Notice</h3>
<p>The website carolbike.com, the CAROL Bike app, and the CAROL mobile app (together, the “Site”) are provided by Integrated Health Partners Limited (“we”/“us”/“our”). In doing so, we may receive and process personal information relating to you. As the controller of this information, we are providing this Privacy Notice (“Notice”) to explain our approach to personal information. This Notice forms part of our Terms and Conditions, which govern the use of the Site.</p>
<p>Our sole intention is to process personal information fairly and transparently, as required by data protection laws, including the General Data Protection Regulation (GDPR). Before obtaining information from you (including through the use of cookies), we intend to alert you to this Notice, inform you of how we intend to process the information, and—unless the processing is necessary for at least one of the five reasons outlined in clause 2 below—process the information only if you consent to that processing. The GDPR also defines “special categories” of personal information that are considered particularly sensitive. These categories require a higher level of protection, as explained below.</p>
<p>You may browse parts of our website without providing any information about yourself and without accepting cookies. In such cases, it is unlikely that we will obtain and process any information relating to you.</p>
<p>We begin this Notice by laying out the conditions that we must satisfy before processing your data. However, you may wish to start with the table in clause 4, which summarizes what we intend to collect, or the table in clause 8.5, which summarizes our use of cookies. The Notice also explains some of the security measures we adopt to protect your personal information and informs you of certain things that we will or will not do. This Notice should be read in conjunction with our Terms and Conditions.</p>
<p>Occasionally, when you receive a new service or product from us or discuss receiving a new service or product but decide against it, we might wish to provide you with further information about similar services or products by email or other written electronic communications. In that situation, we will always give you the opportunity to refuse to receive that further information—and if you change your mind, please let us know. We will endeavor to remind you of your right to opt out on each occasion that we provide such information.</p>
<h3>1 Identity and contact details</h3>
<p>1.1 Registered number: 6107341</p>
<p>1.2 Registered office: 2 Underwood Row, London, N1 7LQ, United Kingdom</p>
<p>1.3 Contact email: info@carolbike.com</p>
<h3>2 When we are allowed to collect information from you</h3>
<p>We will only collect personal information relating to you if one of the following conditions has been satisfied:</p>
<p>2.1 You have clearly told us that you are willing to allow us to collect that information for the purpose or purposes that we have specified.</p>
<p>2.2 The processing is necessary for the fulfillment of a contract that we have with you.</p>
<p>2.3 The processing is necessary so that we can comply with the law.</p>
<p>2.4 The processing is necessary to protect someone’s life.</p>
<p>2.5 The processing is necessary to perform a task in the public interest.</p>
<p>2.6 The processing is necessary for our (or another’s) legitimate interest; in this case, we will balance those interests against your interests.</p>
<h3>3 How to consent</h3>
<p>3.1 At the time of collecting the information, we will endeavor to explain how we intend to use the information and which of these purposes apply. If we rely on consent, we will provide you with the opportunity to tell us that you are willing to provide the information.</p>
<p>3.2 If at any time you change your mind and decide that you do not consent, please inform us, and we will endeavor to stop processing your information in a specified manner, or we will delete your data if there is no ongoing reason for us to continue possessing it.</p>
<p>3.3 If you do not consent to a particular item of processing, we will endeavor to ensure that the Site and our service continue to operate without the need for that information.</p>
<h3>4 Information we expect to collect from you</h3>
<p>4.1 We envisage asking you for the following types of information [Information type: Purpose(s); related details. Justification(s).]:</p>
<p>Email address: To provide marketing messages and product updates (e.g., our newsletter); To contact you in response to a query you have sent us; To process an order; To create a user account to use the ​​CAROL Bike. You have clearly told us that you are willing to allow us to collect that information for the specific purpose or purposes that we have specified.</p>
<p>Full name: To contact you in answer to a query you sent us; To process an order; To create a user account to use the CAROL Bike. You have clearly told us that you are willing to allow us to collect that information for the specific purpose or purposes that we have specified; Its collection is necessary for the fulfillment of a contract with you.</p>
<p>Telephone number: To contact you in answer to a query you sent us; To process an order. You have clearly told us that you are willing to allow us to collect that information for the certain purpose or purposes that we have specified; Its collection is necessary for the fulfillment of a contract with you.</p>
<p>Credit card details: To process an order. Its collection is necessary for the fulfillment of a contract with you.</p>
<p>Address: To process an order. Its collection is necessary for the fulfillment of a contract with you.</p>
<p>Physical Activity Readiness Questionnaire: To create a user account to use the CAROL Bike. You have clearly told us that you are willing to allow us to collect that information for the certain purpose or purposes that we have specified.</p>
<p>Gender, date of birth, height, weight, activity level: To create a user account to use the CAROL Bike; To calculate settings to control the CAROL Bike. You have clearly told us that you are willing to allow us to collect that information for the certain purpose or purposes that we have specified.</p>
<p>4.2 We may collect personal information about you from a number of sources, including the following:</p>
<p>4.2.1 From you when you agree to take a service or product from us; this may include your contact details, date of birth, how you will pay for the product or service, and your bank details.</p>
<p>4.2.2 From you when you contact us with an inquiry or in response to a communication from us; this may provide information to us about how you use our services.</p>
<p>4.2.3 From usage statistics when you use a CAROL Bike; this may include date and time of usage, equipment identifier, heart rate data, power data, rpm data, resistance setting, and the how-do-you-feel indicator. The data will be used to calculate your fitness score (Octane Score) and personal bests, to track and visualize your improvement over time, to participate optionally in leaderboards, and for similar purposes. The data will be available for you to view on our website and on our Bike and mobile apps and assists us in analytical, reporting, and product development purposes; the data is also available for the owner of the CAROL Bike listed for reporting, billing, and (optionally) coaching purposes.</p>
<p>4.2.4 From documents that are available to the public, such as the electoral register.</p>
<p>4.2.5 From third parties to whom you have provided information with your consent to pass it on to other organizations or persons; when we receive such information, we will inform you as soon as is reasonably practicable.</p>
<p>4.3 If you refuse to provide requested information—and that information is necessary for a service we provide to you—we may need to cease providing that service.</p>
<p>4.4 At the time of collecting information, by whichever method is used, we will endeavor to alert you and inform you about our purposes and the legal basis for processing that information, as well as whether we intend to share the information with anyone else. If at any time you believe we have prompted you to provide information without explaining our rationale, you are free to object and request information regarding our reason for requesting it.</p>
<h3>5 Using your personal information</h3>
<p>5.1 Data protection, privacy, and security are important to us; we will use your personal information only for specified purposes and will not keep such personal information longer than is necessary to fulfill these purposes. The following are examples of such purposes. We have also indicated below which GDPR justification applies; however, its application will depend on the circumstances of each case. At the time of collecting the data, we will provide further information, and you may request additional information at any time.</p>
<p>5.1.1 To help us identify you when you contact us. This will normally be necessary for the performance of our contract.</p>
<p>5.1.2 To help us identify accounts, services, and/or products you may have from us or selected partners from time to time. We may do this by automatic means using a scoring system, which uses the personal information you have provided and/or any information we hold about you as well as personal information from third-party agencies (including credit reference agencies). We will use your information for this purpose only if you agree to it.</p>
<p>5.1.3 To help us administer—and to contact you about the improved administration of—any accounts, services, or products we have provided previously or currently, or that we may provide in the future. This is often necessary, but sometimes the improvements will not be necessary, in which case we will request your consent.</p>
<p>5.1.4 To allow us to carry out marketing analysis and rider profiling (including with transactional information) and to conduct research (including creating statistical and testing information). This will sometimes require that you consent, but at other times, the usage is considered exempt as market research.</p>
<p>5.1.5 To help prevent and detect fraud or loss. This will only be undertaken in certain circumstances when we consider it necessary or when the law requires it.</p>
<p>5.1.6 To allow us to contact you by written electronic means (such as email, text, or multimedia messages) about products and services we offer, where:</p>
<p>5.1.6.1 These products are similar to those you have already purchased from us;</p>
<p>5.1.6.2 You were given the opportunity to opt out of being contacted by us at the time your personal information was originally collected and at the time of our subsequent communications with you; and</p>
<p>5.1.6.3 You have not opted out of having us contact you.</p>
<p>5.1.7 To allow us to contact you in any way (including mail, email, telephone, visit, text, or multimedia messages) about products and services offered by us and selected partners where you have expressly consented to this contact.</p>
<p>5.1.8 We may monitor and record communications with you (including phone conversations and emails) for quality assurance and compliance purposes.</p>
<p>5.1.8.1 Before doing so, we will always tell you of our intentions and of the specific purpose of making the recording. Sometimes, such recordings will be necessary to comply with the law. At other times, the recording will be necessary for our legitimate interest, but in that case, we will record the call only if our interest outweighs yours. This will depend on all of the circumstances—in particular, the importance of the information and whether we can obtain the information in another, less intrusive way.</p>
<p>5.1.8.2 If we think the recording would be useful for us but that it is not strictly necessary, we will ask whether you consent to the recording, and we will provide an option for you to tell us that you consent. In those situations, if you do not consent, the call will either automatically end or will not be recorded.</p>
<p>5.1.9 When it is required by law, we will check your information with fraud prevention agencies. If you provide false or inaccurate information and we suspect fraud, we intend to record this.</p>
<p>5.2 We will not disclose your personal information to any third party except in accordance with this Notice, and particularly in the following circumstances:</p>
<p>5.2.1 The third party will be processing the data on our behalf as a data processor (where we will act as the data controller). In this situation, we will always have a contract with the data processor, as set out in the GDPR. This contract imposes significant restrictions on how the data processor operates so that you can be confident your data is protected to the same degree as provided in this Notice.</p>
<p>5.2.2 Sometimes it may be necessary to share data with another data controller. Before doing so, we will always notify you. If we receive information about you from a third party, we will inform you as soon as reasonably practicable, as is required by the GDPR.</p>
<p>5.2.3 Sometimes we may consider it to be in your interest to send your information to a third party. If that is the case, we will always ask for your consent before sending the information.</p>
<p>5.3 If you provide us personal information on behalf of someone else, you confirm that you have provided that person with the information set out in this Notice and that they have not objected to such use of their personal information.</p>
<p>5.4 In connection with any transaction that we enter into with you:</p>
<p>5.4.1 We may carry out one or more credit checks if you have given us your express consent.</p>
<p>5.4.2 We may carry out one or more fraud prevention checks with licensed fraud prevention agencies.</p>
<p>5.4.3 We and any licensed fraud prevention agencies may keep a record of the search. Information held about you by these agencies may be linked to records relating to other people living at the same address with whom you are financially linked. These records may also be taken into account in credit and fraud prevention checks. Information from your application and payment details of your account will be recorded with one or more of these agencies and may be shared with other organizations to help make credit and insurance decisions about you and members of your household with whom you are financially linked as well as for debt collection and fraud prevention. This includes individuals who have relocated and who have missed payments.</p>
<p>5.4.4 If you provide false or inaccurate information to us and we suspect fraud, we will record this and may share it with other individuals and organizations. We, as well as other credit and insurance organizations, may also use technology to detect and prevent fraud.</p>
<p>5.4.5 If you require details of those credit agencies and fraud prevention agencies from which we obtain, and with which we record, information about you, please write to our Data Protection Manager at Integrated Health Partners Limited, 2 Underwood Row, London, N1 7LQ, United Kingdom.</p>
<p>5.4.6 We may need to transmit the payment and delivery information provided by you during the order process for the purpose of obtaining authorization from your bank or from PayPal.</p>
<p>5.5 We may allow other people and organizations to use personal information that we hold about you in the following circumstances:</p>
<p>5.5.1 If we, or substantially all of our assets, are acquired or are in the process of being acquired by a third party, in which case personal information held by us about our customers will be one of the transferred assets.</p>
<p>5.5.2 If we have been legitimately asked to provide information for legal or regulatory purposes or as part of legal proceedings or prospective legal proceedings.</p>
<p>5.5.3 We may employ companies and individuals to perform functions on our behalf, and we may disclose your personal information to these parties for the purposes set out above—for example, for fulfilling orders, delivering packages, sending postal mail and email, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit and debit card payments, and providing customer support. Those parties will be bound by strict contractual provisions with us and will have access only to such personal information as is needed to perform their functions, and they may not use it for any other purpose. Furthermore, they must process the personal information in accordance with this Notice and as permitted by the GDPR. From time to time, these other people and organizations to whom we may pass your personal information may be outside the European Economic Area. We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Notice and the GDPR.</p>
<h3>6 Protecting information</h3>
<p>6.1 We have strict security measures to protect personal information.</p>
<p>6.2 We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software to encrypt information that you provide.</p>
<p>6.3 We reveal only the last five digits of your credit card number when confirming an order. We transmit the entire credit card number to the appropriate credit card company during order processing.</p>
<p>6.4 We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personally identifiable rider information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.</p>
<p>6.5 It is important for you to protect against unauthorized access to your password and to your computer. Be sure to sign off when you finish using a shared computer.</p>
<h3>7 The internet</h3>
<p>7.1 If you communicate with us using the internet, we may occasionally email you about our services and products. When you first give us personal information through the Site, we will normally give you the opportunity to say whether you would prefer that we not contact you by email. In addition, you can always send us an email (at the address set out below) at any time if you change your mind.</p>
<p>7.2 Please remember that communications over the internet, such as emails and webmail (messages sent through a website), are not secure unless they have been encrypted. Your communications may go through several countries before they are delivered—this is the nature of the internet. We cannot accept responsibility for any unauthorized access or loss of personal information that is beyond our control.</p>
<h3 id="cookies">8 Cookies and other internet tracking technology</h3>
<p>8.1 Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our Site. By continuing to browse the Site, you are agreeing to our use of cookies.</p>
<p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you consent. Cookies contain information that is transferred to your computer’s hard drive. The cookies we use are listed in the table in clause 8.5. Some websites do not use cookies but use related technology for gaining information about website users, such as JavaScript, web beacons (also known as action tags or single-pixel gifs), and other technologies to measure the effectiveness of their ads and to personalize advertising content. Multiple cookies may be found in a single file, depending on which browser you use.</p>
<p>8.2 Where applicable, this section of the Notice also relates to that technology, but the term “cookie” is used throughout the Notice.</p>
<p>8.3 Some of these cookies are essential to services you have requested from us, whereas others are used to improve services for you, such as in the following examples:</p>
<p>8.3.1 Allowing you to efficiently navigate between pages;</p>
<p>8.3.2 Enabling a service to recognize your computer, so you need not provide the same information during a task;</p>
<p>8.3.3 Recognizing that you have already provided a username and password, so you need not enter it for every web page requested; and</p>
<p>8.3.4 Measuring how many people are using services, so they can be made easier to use and so there is enough capacity to ensure that the services are fast.</p>
<p>8.4 To learn more about cookies, visit: www.allaboutcookies.org, www.youronlinechoices.eu, or www.google.com/policies/technologies/cookies/.</p>
<p>8.5 The distinctions between the different types of cookies used are as follows:</p>
<p>8.5.1 First-party versus third-party cookies: We set first-party cookies ourselves; third-party cookies are set by other entities via our Site.</p>
<p>8.5.2 Session versus persistent cookies: Session cookies persist only for the duration of the visit; persistent cookies last longer.</p>
<p>8.5.3 Identifying information removed: Although we have removed identifying information, the cookies will still contain personal information if the relevant information can be reassembled.</p>
<p>8.5.4 Categories 1–4 in the ICC UK Cookie Guide are explained below. Category 1 cookies do not require the user’s consent, although users must still be notified of the cookies. Categories 2–4 require the user’s specific and informed consent.</p>
<p>Category 1: Strictly necessary cookies. These are cookies that are required for the operation of our website. They include cookies that enable you to log in to secure areas of our website, use a shopping cart, and use e-billing services. In this category, we include cookies that are used only for electronic communication. (The ICC does not refer to these cookies, but the law is the same.)</p>
<p>Note that cookies for which another person is the controller will never be necessary for a service requested of you. However, if you have asked another person to send a cookie on your behalf for an essential feature of your website, that would fall under Category 1.</p>
<p>Category 2: Analytical/performance cookies. These allow us to recognize and count the number of visitors, and to see how visitors move around our website when they are using it. This helps us improve our website’s functionality, such as by ensuring that users are easily finding what they are looking for.</p>
<p>Category 3: Functionality cookies. These are used to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or geographical region).</p>
<p>Category 4: Targeting cookies. These cookies record your visit to our website, the pages you have visited, and the links you have followed. We use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
<p>8.6 As with any other information we may collect from you, we will work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software to encrypt the information you provide.</p>
<p>8.7 You can block cookies by activating the setting on your browser that allows you to refuse the setting of all, or only some, cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all, or certain parts, of our Site.</p>
<p>8.8 The Site may include links to third-party websites. We do not provide any personally identifiable customer information to these third-party websites unless you have consented in accordance with this Notice. However, please review the clause, “Cookies and other internet tracking technology.”</p>
<p>8.9 We exclude all liability for loss that you may incur when using these third-party websites.</p>
<h3>9 Further information</h3>
<p>9.1 If you would like any more information, or if you have any comments about this Notice, please write us at Data Protection Manager, Integrated Health Partners Limited, 2 Underwood Row, London, N1 7LQ, United Kingdom; or email us at info@carolbike.com.</p>
<p>9.2 Please note that we may need to amend this Notice on occasion—for example, if we change the cookies that we use. If we do, we will publish the amended version on the Site. We will endeavor to alert you to the change, but it is also your responsibility to check regularly to determine whether this Notice has changed.</p>
<p>9.3 You can request a copy of this Notice by writing to the above address or by emailing us at info@carolbike.com. This Notice applies to personal information that we retain about individuals; it does not apply to information we retain about companies or other organizations.</p>
<p>9.4 If you would like access to the personal information that we hold about you, you may request it in writing at the above address or by email at info@carolbike.com. Typically, there is no fee for such a request; however, if the request is unfounded, repetitive, or excessive, we may request a fee or refuse to comply with the request. You may also request that we send the personal information we hold about you to another controller.</p>
<p>9.5 We aim to keep the personal information we hold about you accurate and up to date. If you inform us that we are holding any inaccurate or incomplete personal information about you, we will promptly amend, complete, or delete it. Please write to us at the above address or email us at info@carolbike.com to update your personal information. You have the right to complain to the Information Commissioner’s Office if we do not promptly amend, complete, or delete inaccurate personal information.</p>
<p>9.6 You may request that we delete the personal information that we hold about you if we relied on your consent in holding that information or if it is no longer necessary for us to hold it. You can also restrict or object to our processing of your personal information in certain circumstances by writing to us at the above address or emailing us at info@carolbike.com.</p>
<p>9.7 We will notify you if there is a breach, or a likely breach, of your data-protection rights.</p>
<h3>10 Klarna</h3>
<p>10.1 In order to offer you Klarna’s payment options, we will convey to Klarna certain aspects of your personal information, such as contact and order details, in order for Klarna to assess whether you qualify for their payment options and to tailor those payment options for you.</p>
<p>You can find general information on Klarna here. Your personal data is handled in accordance with applicable data protection laws and in accordance with the information in Klarna’s privacy policy.</p>

import { createSelector } from "@ngrx/store";
import { AppState } from "../state";

const selectOwnerState = (state: AppState) => state && state.owner;

export const selectOwnerPrice = createSelector(
  selectOwnerState,
  state => state.price
);

export const selectOwnerSubscription = createSelector(
  selectOwnerState,
  state => state.subscription
);

export const selectOwnerPaymentIntent = createSelector(
  selectOwnerState,
  state => state.paymentIntent
);


export const selectBikeCountry = createSelector(
  selectOwnerState,
  state => state.bikeCountry
);

export const selectOwnedStats = createSelector(
  selectOwnerState,
  (state) => state.stats
);

export const selectLicenseMonthlyActivity = createSelector(
  selectOwnerState,
  state => state.licenseDatesWithRides
);

export const selectLicenseDetails = createSelector(
  selectOwnerState,
  (state) => state.licenseDetails
);

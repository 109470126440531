import {Component, ElementRef, OnInit} from '@angular/core';
import {SvgService} from '@carol-nx/services';

@Component({
  selector: 'carol-nx-svg-logo',
  templateUrl: './svg-logo.component.html',
  styleUrls: ['./svg-logo.component.scss']
})
export class SvgLogoComponent implements OnInit {
  private readonly logoPath = 'assets/svg/new-graphic/icon-logo-light.svg';

  constructor(private svgService: SvgService,
              private elem: ElementRef) { }

  ngOnInit() {
    this.initLogo();
  }

  private initLogo() {
    const logo = this.elem.nativeElement.querySelector('#logo-svg');
    this.svgService.loadSvg(this.logoPath).then((svg: string) => {
      logo.innerHTML = svg;
    }).catch(
      console.error
    );
  }
}

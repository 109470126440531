<mat-card class="mat-wrapper">
  <mat-card-title>
    <div>{{'HABIT_SCORE' | translate}}</div>
  </mat-card-title>
  <mat-card-content class="habit-score-chart-box">
    <carol-nx-svg-icon (click)="clickIcon()" [native]="true" class="info-icon" icon="info-alt">
    </carol-nx-svg-icon>
    <div class="habit-score-chart-wrapper">
      <carol-nx-habit-score-chart [initDate]="initDate" class="habit-score-chart"></carol-nx-habit-score-chart>
      <span class="habit-score">{{habitScore|async|coalesce:'N/A'}}</span>
    </div>
  </mat-card-content>
</mat-card>
